/*!
 * 
 * antd v4.24.16
 * 
 * Copyright 2015-present, Alipay, Inc.
 * All rights reserved.
 *       
 */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

[class^="ant-"]::-ms-clear {
  display: none;
}

[class*="ant-"]::-ms-clear {
  display: none;
}

[class^="ant-"] input::-ms-clear {
  display: none;
}

[class*="ant-"] input::-ms-clear {
  display: none;
}

[class^="ant-"] input::-ms-reveal {
  display: none;
}

[class*="ant-"] input::-ms-reveal {
  display: none;
}

html, body {
  width: 100%;
  height: 100%;
}

input::-ms-clear {
  display: none;
}

input::-ms-reveal {
  display: none;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: #0000;
  font-family: sans-serif;
  line-height: 1.15;
}

@-ms-viewport {
  width: device-width;
}

body {
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  background-color: #fff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 14px;
  line-height: 1.5715;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  color: #000000d9;
  margin-top: 0;
  margin-bottom: .5em;
  font-weight: 500;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

abbr[title], abbr[data-original-title] {
  cursor: help;
  text-decoration: underline;
  border-bottom: 0;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1em;
}

input[type="text"], input[type="password"], input[type="number"], textarea {
  -webkit-appearance: none;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1em;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: .5em;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1em;
}

dfn {
  font-style: italic;
}

a {
  color: #1890ff;
  cursor: pointer;
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
  outline: none;
  text-decoration: none;
  transition: color .3s;
}

a:hover {
  color: #40a9ff;
}

a:active {
  color: #096dd9;
}

a:active, a:hover, a:focus {
  outline: 0;
  text-decoration: none;
}

a[disabled] {
  color: #00000040;
  cursor: not-allowed;
}

pre, code, kbd, samp {
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}

figure {
  margin: 0 0 1em;
}

img {
  vertical-align: middle;
  border-style: none;
}

a, area, button, [role="button"], input:not([type="range"]), label, select, summary, textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  color: #00000073;
  text-align: left;
  caption-side: bottom;
  padding-top: .75em;
  padding-bottom: .3em;
}

input, button, select, optgroup, textarea {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
  width: 100%;
  max-width: 100%;
  margin-bottom: .5em;
  padding: 0;
  display: block;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

template {
  display: none;
}

mark {
  background-color: #feffe6;
  padding: .2em;
}

::selection {
  color: #fff;
  background: #1890ff;
}

.clearfix:before {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
  content: "";
  display: table;
}

.anticon {
  color: inherit;
  text-align: center;
  text-transform: none;
  vertical-align: -.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  align-items: center;
  font-style: normal;
  line-height: 0;
  display: inline-flex;
}

.anticon > * {
  line-height: 1;
}

.anticon svg {
  display: inline-block;
}

.anticon:before {
  display: none;
}

.anticon .anticon-icon {
  display: block;
}

.anticon > .anticon {
  vertical-align: 0;
  line-height: 0;
}

.anticon[tabindex] {
  cursor: pointer;
}

.anticon-spin, .anticon-spin:before {
  animation: 1s linear infinite loadingCircle;
  display: inline-block;
}

.ant-fade-enter, .ant-fade-appear, .ant-fade-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-fade-enter.ant-fade-enter-active, .ant-fade-appear.ant-fade-appear-active {
  animation-name: antFadeIn;
  animation-play-state: running;
}

.ant-fade-leave.ant-fade-leave-active {
  pointer-events: none;
  animation-name: antFadeOut;
  animation-play-state: running;
}

.ant-fade-enter, .ant-fade-appear {
  opacity: 0;
  animation-timing-function: linear;
}

.ant-fade-leave {
  animation-timing-function: linear;
}

@keyframes antFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes antFadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ant-move-up-enter, .ant-move-up-appear, .ant-move-up-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-move-up-enter.ant-move-up-enter-active, .ant-move-up-appear.ant-move-up-appear-active {
  animation-name: antMoveUpIn;
  animation-play-state: running;
}

.ant-move-up-leave.ant-move-up-leave-active {
  pointer-events: none;
  animation-name: antMoveUpOut;
  animation-play-state: running;
}

.ant-move-up-enter, .ant-move-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(.08, .82, .17, 1);
}

.ant-move-up-leave {
  animation-timing-function: cubic-bezier(.6, .04, .98, .34);
}

.ant-move-down-enter, .ant-move-down-appear, .ant-move-down-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-move-down-enter.ant-move-down-enter-active, .ant-move-down-appear.ant-move-down-appear-active {
  animation-name: antMoveDownIn;
  animation-play-state: running;
}

.ant-move-down-leave.ant-move-down-leave-active {
  pointer-events: none;
  animation-name: antMoveDownOut;
  animation-play-state: running;
}

.ant-move-down-enter, .ant-move-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(.08, .82, .17, 1);
}

.ant-move-down-leave {
  animation-timing-function: cubic-bezier(.6, .04, .98, .34);
}

.ant-move-left-enter, .ant-move-left-appear, .ant-move-left-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-move-left-enter.ant-move-left-enter-active, .ant-move-left-appear.ant-move-left-appear-active {
  animation-name: antMoveLeftIn;
  animation-play-state: running;
}

.ant-move-left-leave.ant-move-left-leave-active {
  pointer-events: none;
  animation-name: antMoveLeftOut;
  animation-play-state: running;
}

.ant-move-left-enter, .ant-move-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(.08, .82, .17, 1);
}

.ant-move-left-leave {
  animation-timing-function: cubic-bezier(.6, .04, .98, .34);
}

.ant-move-right-enter, .ant-move-right-appear, .ant-move-right-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-move-right-enter.ant-move-right-enter-active, .ant-move-right-appear.ant-move-right-appear-active {
  animation-name: antMoveRightIn;
  animation-play-state: running;
}

.ant-move-right-leave.ant-move-right-leave-active {
  pointer-events: none;
  animation-name: antMoveRightOut;
  animation-play-state: running;
}

.ant-move-right-enter, .ant-move-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(.08, .82, .17, 1);
}

.ant-move-right-leave {
  animation-timing-function: cubic-bezier(.6, .04, .98, .34);
}

@keyframes antMoveDownIn {
  0% {
    transform-origin: 0 0;
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    transform-origin: 0 0;
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes antMoveDownOut {
  0% {
    transform-origin: 0 0;
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    transform-origin: 0 0;
    opacity: 0;
    transform: translateY(100%);
  }
}

@keyframes antMoveLeftIn {
  0% {
    transform-origin: 0 0;
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    transform-origin: 0 0;
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes antMoveLeftOut {
  0% {
    transform-origin: 0 0;
    opacity: 1;
    transform: translateX(0%);
  }

  100% {
    transform-origin: 0 0;
    opacity: 0;
    transform: translateX(-100%);
  }
}

@keyframes antMoveRightIn {
  0% {
    transform-origin: 0 0;
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    transform-origin: 0 0;
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes antMoveRightOut {
  0% {
    transform-origin: 0 0;
    opacity: 1;
    transform: translateX(0%);
  }

  100% {
    transform-origin: 0 0;
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes antMoveUpIn {
  0% {
    transform-origin: 0 0;
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    transform-origin: 0 0;
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes antMoveUpOut {
  0% {
    transform-origin: 0 0;
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    transform-origin: 0 0;
    opacity: 0;
    transform: translateY(-100%);
  }
}

@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}

[ant-click-animating="true"], [ant-click-animating-without-extra-node="true"] {
  position: relative;
}

html {
  --antd-wave-shadow-color: #1890ff;
  --scroll-bar: 0;
}

[ant-click-animating-without-extra-node="true"]:after, .ant-click-animating-node {
  border-radius: inherit;
  box-shadow: 0 0 #1890ff;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: .2;
  content: "";
  pointer-events: none;
  animation-name: fadeEffect, waveEffect;
  animation-duration: 2s, .4s;
  animation-timing-function: cubic-bezier(.08, .82, .17, 1), cubic-bezier(.08, .82, .17, 1);
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-play-state: running, running;
  animation-delay: 0s, 0s;
  animation-fill-mode: forwards;
  animation-timeline: auto, auto;
  display: block;
  position: absolute;
  inset: 0;
}

@keyframes waveEffect {
  100% {
    box-shadow: 0 0 #1890ff;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}

@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}

.ant-slide-up-enter, .ant-slide-up-appear, .ant-slide-up-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-slide-up-enter.ant-slide-up-enter-active, .ant-slide-up-appear.ant-slide-up-appear-active {
  animation-name: antSlideUpIn;
  animation-play-state: running;
}

.ant-slide-up-leave.ant-slide-up-leave-active {
  pointer-events: none;
  animation-name: antSlideUpOut;
  animation-play-state: running;
}

.ant-slide-up-enter, .ant-slide-up-appear {
  transform-origin: 0 0;
  opacity: 0;
  animation-timing-function: cubic-bezier(.23, 1, .32, 1);
  transform: scale(0);
}

.ant-slide-up-leave {
  animation-timing-function: cubic-bezier(.755, .05, .855, .06);
}

.ant-slide-down-enter, .ant-slide-down-appear, .ant-slide-down-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-slide-down-enter.ant-slide-down-enter-active, .ant-slide-down-appear.ant-slide-down-appear-active {
  animation-name: antSlideDownIn;
  animation-play-state: running;
}

.ant-slide-down-leave.ant-slide-down-leave-active {
  pointer-events: none;
  animation-name: antSlideDownOut;
  animation-play-state: running;
}

.ant-slide-down-enter, .ant-slide-down-appear {
  transform-origin: 0 0;
  opacity: 0;
  animation-timing-function: cubic-bezier(.23, 1, .32, 1);
  transform: scale(0);
}

.ant-slide-down-leave {
  animation-timing-function: cubic-bezier(.755, .05, .855, .06);
}

.ant-slide-left-enter, .ant-slide-left-appear, .ant-slide-left-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-slide-left-enter.ant-slide-left-enter-active, .ant-slide-left-appear.ant-slide-left-appear-active {
  animation-name: antSlideLeftIn;
  animation-play-state: running;
}

.ant-slide-left-leave.ant-slide-left-leave-active {
  pointer-events: none;
  animation-name: antSlideLeftOut;
  animation-play-state: running;
}

.ant-slide-left-enter, .ant-slide-left-appear {
  transform-origin: 0 0;
  opacity: 0;
  animation-timing-function: cubic-bezier(.23, 1, .32, 1);
  transform: scale(0);
}

.ant-slide-left-leave {
  animation-timing-function: cubic-bezier(.755, .05, .855, .06);
}

.ant-slide-right-enter, .ant-slide-right-appear, .ant-slide-right-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-slide-right-enter.ant-slide-right-enter-active, .ant-slide-right-appear.ant-slide-right-appear-active {
  animation-name: antSlideRightIn;
  animation-play-state: running;
}

.ant-slide-right-leave.ant-slide-right-leave-active {
  pointer-events: none;
  animation-name: antSlideRightOut;
  animation-play-state: running;
}

.ant-slide-right-enter, .ant-slide-right-appear {
  transform-origin: 0 0;
  opacity: 0;
  animation-timing-function: cubic-bezier(.23, 1, .32, 1);
  transform: scale(0);
}

.ant-slide-right-leave {
  animation-timing-function: cubic-bezier(.755, .05, .855, .06);
}

@keyframes antSlideUpIn {
  0% {
    transform-origin: 0 0;
    opacity: 0;
    transform: scaleY(.8);
  }

  100% {
    transform-origin: 0 0;
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes antSlideUpOut {
  0% {
    transform-origin: 0 0;
    opacity: 1;
    transform: scaleY(1);
  }

  100% {
    transform-origin: 0 0;
    opacity: 0;
    transform: scaleY(.8);
  }
}

@keyframes antSlideDownIn {
  0% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: scaleY(.8);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes antSlideDownOut {
  0% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: scaleY(1);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: scaleY(.8);
  }
}

@keyframes antSlideLeftIn {
  0% {
    transform-origin: 0 0;
    opacity: 0;
    transform: scaleX(.8);
  }

  100% {
    transform-origin: 0 0;
    opacity: 1;
    transform: scaleX(1);
  }
}

@keyframes antSlideLeftOut {
  0% {
    transform-origin: 0 0;
    opacity: 1;
    transform: scaleX(1);
  }

  100% {
    transform-origin: 0 0;
    opacity: 0;
    transform: scaleX(.8);
  }
}

@keyframes antSlideRightIn {
  0% {
    transform-origin: 100% 0;
    opacity: 0;
    transform: scaleX(.8);
  }

  100% {
    transform-origin: 100% 0;
    opacity: 1;
    transform: scaleX(1);
  }
}

@keyframes antSlideRightOut {
  0% {
    transform-origin: 100% 0;
    opacity: 1;
    transform: scaleX(1);
  }

  100% {
    transform-origin: 100% 0;
    opacity: 0;
    transform: scaleX(.8);
  }
}

.ant-zoom-enter, .ant-zoom-appear, .ant-zoom-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-zoom-enter.ant-zoom-enter-active, .ant-zoom-appear.ant-zoom-appear-active {
  animation-name: antZoomIn;
  animation-play-state: running;
}

.ant-zoom-leave.ant-zoom-leave-active {
  pointer-events: none;
  animation-name: antZoomOut;
  animation-play-state: running;
}

.ant-zoom-enter, .ant-zoom-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(.08, .82, .17, 1);
  transform: scale(0);
}

.ant-zoom-enter-prepare, .ant-zoom-appear-prepare {
  transform: none;
}

.ant-zoom-leave {
  animation-timing-function: cubic-bezier(.78, .14, .15, .86);
}

.ant-zoom-big-enter, .ant-zoom-big-appear, .ant-zoom-big-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-zoom-big-enter.ant-zoom-big-enter-active, .ant-zoom-big-appear.ant-zoom-big-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}

.ant-zoom-big-leave.ant-zoom-big-leave-active {
  pointer-events: none;
  animation-name: antZoomBigOut;
  animation-play-state: running;
}

.ant-zoom-big-enter, .ant-zoom-big-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(.08, .82, .17, 1);
  transform: scale(0);
}

.ant-zoom-big-enter-prepare, .ant-zoom-big-appear-prepare {
  transform: none;
}

.ant-zoom-big-leave {
  animation-timing-function: cubic-bezier(.78, .14, .15, .86);
}

.ant-zoom-big-fast-enter, .ant-zoom-big-fast-appear, .ant-zoom-big-fast-leave {
  animation-duration: .1s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-zoom-big-fast-enter.ant-zoom-big-fast-enter-active, .ant-zoom-big-fast-appear.ant-zoom-big-fast-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}

.ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active {
  pointer-events: none;
  animation-name: antZoomBigOut;
  animation-play-state: running;
}

.ant-zoom-big-fast-enter, .ant-zoom-big-fast-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(.08, .82, .17, 1);
  transform: scale(0);
}

.ant-zoom-big-fast-enter-prepare, .ant-zoom-big-fast-appear-prepare {
  transform: none;
}

.ant-zoom-big-fast-leave {
  animation-timing-function: cubic-bezier(.78, .14, .15, .86);
}

.ant-zoom-up-enter, .ant-zoom-up-appear, .ant-zoom-up-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-zoom-up-enter.ant-zoom-up-enter-active, .ant-zoom-up-appear.ant-zoom-up-appear-active {
  animation-name: antZoomUpIn;
  animation-play-state: running;
}

.ant-zoom-up-leave.ant-zoom-up-leave-active {
  pointer-events: none;
  animation-name: antZoomUpOut;
  animation-play-state: running;
}

.ant-zoom-up-enter, .ant-zoom-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(.08, .82, .17, 1);
  transform: scale(0);
}

.ant-zoom-up-enter-prepare, .ant-zoom-up-appear-prepare {
  transform: none;
}

.ant-zoom-up-leave {
  animation-timing-function: cubic-bezier(.78, .14, .15, .86);
}

.ant-zoom-down-enter, .ant-zoom-down-appear, .ant-zoom-down-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-zoom-down-enter.ant-zoom-down-enter-active, .ant-zoom-down-appear.ant-zoom-down-appear-active {
  animation-name: antZoomDownIn;
  animation-play-state: running;
}

.ant-zoom-down-leave.ant-zoom-down-leave-active {
  pointer-events: none;
  animation-name: antZoomDownOut;
  animation-play-state: running;
}

.ant-zoom-down-enter, .ant-zoom-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(.08, .82, .17, 1);
  transform: scale(0);
}

.ant-zoom-down-enter-prepare, .ant-zoom-down-appear-prepare {
  transform: none;
}

.ant-zoom-down-leave {
  animation-timing-function: cubic-bezier(.78, .14, .15, .86);
}

.ant-zoom-left-enter, .ant-zoom-left-appear, .ant-zoom-left-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-zoom-left-enter.ant-zoom-left-enter-active, .ant-zoom-left-appear.ant-zoom-left-appear-active {
  animation-name: antZoomLeftIn;
  animation-play-state: running;
}

.ant-zoom-left-leave.ant-zoom-left-leave-active {
  pointer-events: none;
  animation-name: antZoomLeftOut;
  animation-play-state: running;
}

.ant-zoom-left-enter, .ant-zoom-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(.08, .82, .17, 1);
  transform: scale(0);
}

.ant-zoom-left-enter-prepare, .ant-zoom-left-appear-prepare {
  transform: none;
}

.ant-zoom-left-leave {
  animation-timing-function: cubic-bezier(.78, .14, .15, .86);
}

.ant-zoom-right-enter, .ant-zoom-right-appear, .ant-zoom-right-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-zoom-right-enter.ant-zoom-right-enter-active, .ant-zoom-right-appear.ant-zoom-right-appear-active {
  animation-name: antZoomRightIn;
  animation-play-state: running;
}

.ant-zoom-right-leave.ant-zoom-right-leave-active {
  pointer-events: none;
  animation-name: antZoomRightOut;
  animation-play-state: running;
}

.ant-zoom-right-enter, .ant-zoom-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(.08, .82, .17, 1);
  transform: scale(0);
}

.ant-zoom-right-enter-prepare, .ant-zoom-right-appear-prepare {
  transform: none;
}

.ant-zoom-right-leave {
  animation-timing-function: cubic-bezier(.78, .14, .15, .86);
}

@keyframes antZoomIn {
  0% {
    opacity: 0;
    transform: scale(.2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes antZoomOut {
  0% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(.2);
  }
}

@keyframes antZoomBigIn {
  0% {
    opacity: 0;
    transform: scale(.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(.8);
  }
}

@keyframes antZoomUpIn {
  0% {
    transform-origin: 50% 0;
    opacity: 0;
    transform: scale(.8);
  }

  100% {
    transform-origin: 50% 0;
    transform: scale(1);
  }
}

@keyframes antZoomUpOut {
  0% {
    transform-origin: 50% 0;
    transform: scale(1);
  }

  100% {
    transform-origin: 50% 0;
    opacity: 0;
    transform: scale(.8);
  }
}

@keyframes antZoomLeftIn {
  0% {
    transform-origin: 0%;
    opacity: 0;
    transform: scale(.8);
  }

  100% {
    transform-origin: 0%;
    transform: scale(1);
  }
}

@keyframes antZoomLeftOut {
  0% {
    transform-origin: 0%;
    transform: scale(1);
  }

  100% {
    transform-origin: 0%;
    opacity: 0;
    transform: scale(.8);
  }
}

@keyframes antZoomRightIn {
  0% {
    transform-origin: 100%;
    opacity: 0;
    transform: scale(.8);
  }

  100% {
    transform-origin: 100%;
    transform: scale(1);
  }
}

@keyframes antZoomRightOut {
  0% {
    transform-origin: 100%;
    transform: scale(1);
  }

  100% {
    transform-origin: 100%;
    opacity: 0;
    transform: scale(.8);
  }
}

@keyframes antZoomDownIn {
  0% {
    transform-origin: 50% 100%;
    opacity: 0;
    transform: scale(.8);
  }

  100% {
    transform-origin: 50% 100%;
    transform: scale(1);
  }
}

@keyframes antZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1);
  }

  100% {
    transform-origin: 50% 100%;
    opacity: 0;
    transform: scale(.8);
  }
}

.ant-motion-collapse-legacy {
  overflow: hidden;
}

.ant-motion-collapse-legacy-active {
  transition: height .2s cubic-bezier(.645, .045, .355, 1), opacity .2s cubic-bezier(.645, .045, .355, 1) !important;
}

.ant-motion-collapse {
  overflow: hidden;
  transition: height .2s cubic-bezier(.645, .045, .355, 1), opacity .2s cubic-bezier(.645, .045, .355, 1) !important;
}

.ant-affix {
  z-index: 10;
  position: fixed;
}

.ant-alert {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  word-wrap: break-word;
  border-radius: 2px;
  align-items: center;
  margin: 0;
  padding: 8px 15px;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: flex;
  position: relative;
}

.ant-alert-content {
  flex: 1;
  min-width: 0;
}

.ant-alert-icon {
  margin-right: 8px;
}

.ant-alert-description {
  font-size: 14px;
  line-height: 22px;
  display: none;
}

.ant-alert-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}

.ant-alert-success .ant-alert-icon {
  color: #52c41a;
}

.ant-alert-info {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
}

.ant-alert-info .ant-alert-icon {
  color: #1890ff;
}

.ant-alert-warning {
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
}

.ant-alert-warning .ant-alert-icon {
  color: #faad14;
}

.ant-alert-error {
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
}

.ant-alert-error .ant-alert-icon {
  color: #ff4d4f;
}

.ant-alert-error .ant-alert-description > pre {
  margin: 0;
  padding: 0;
}

.ant-alert-action {
  margin-left: 8px;
}

.ant-alert-close-icon {
  cursor: pointer;
  background-color: #0000;
  border: none;
  outline: none;
  margin-left: 8px;
  padding: 0;
  font-size: 12px;
  line-height: 12px;
  overflow: hidden;
}

.ant-alert-close-icon .anticon-close {
  color: #00000073;
  transition: color .3s;
}

.ant-alert-close-icon .anticon-close:hover {
  color: #000000bf;
}

.ant-alert-close-text {
  color: #00000073;
  transition: color .3s;
}

.ant-alert-close-text:hover {
  color: #000000bf;
}

.ant-alert-with-description {
  align-items: flex-start;
  padding: 15px 15px 15px 24px;
}

.ant-alert-with-description.ant-alert-no-icon {
  padding: 15px;
}

.ant-alert-with-description .ant-alert-icon {
  margin-right: 15px;
  font-size: 24px;
}

.ant-alert-with-description .ant-alert-message {
  color: #000000d9;
  margin-bottom: 4px;
  font-size: 16px;
  display: block;
}

.ant-alert-message {
  color: #000000d9;
}

.ant-alert-with-description .ant-alert-description {
  display: block;
}

.ant-alert.ant-alert-motion-leave {
  opacity: 1;
  transition: max-height .3s cubic-bezier(.78, .14, .15, .86), opacity .3s cubic-bezier(.78, .14, .15, .86), padding-top .3s cubic-bezier(.78, .14, .15, .86), padding-bottom .3s cubic-bezier(.78, .14, .15, .86), margin-bottom .3s cubic-bezier(.78, .14, .15, .86);
  overflow: hidden;
}

.ant-alert.ant-alert-motion-leave-active {
  opacity: 0;
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0 !important;
}

.ant-alert-banner {
  border: 0;
  border-radius: 0;
  margin-bottom: 0;
}

.ant-alert.ant-alert-rtl {
  direction: rtl;
}

.ant-alert-rtl .ant-alert-icon {
  margin-left: 8px;
  margin-right: auto;
}

.ant-alert-rtl .ant-alert-action, .ant-alert-rtl .ant-alert-close-icon {
  margin-left: auto;
  margin-right: 8px;
}

.ant-alert-rtl.ant-alert-with-description {
  padding-left: 15px;
  padding-right: 24px;
}

.ant-alert-rtl.ant-alert-with-description .ant-alert-icon {
  margin-left: 15px;
  margin-right: auto;
}

.ant-anchor {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0 0 0 2px;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: relative;
}

.ant-anchor-wrapper {
  background-color: #0000;
  margin-left: -4px;
  padding-left: 4px;
  overflow: auto;
}

.ant-anchor-ink {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ant-anchor-ink:before {
  content: " ";
  background-color: #f0f0f0;
  width: 2px;
  height: 100%;
  margin: 0 auto;
  display: block;
  position: relative;
}

.ant-anchor-ink-ball {
  background-color: #fff;
  border: 2px solid #1890ff;
  border-radius: 8px;
  width: 8px;
  height: 8px;
  transition: top .3s ease-in-out;
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.ant-anchor-ink-ball.ant-anchor-ink-ball-visible {
  display: inline-block;
}

.ant-anchor-fixed .ant-anchor-ink .ant-anchor-ink-ball {
  display: none;
}

.ant-anchor-link {
  padding: 4px 0 4px 16px;
}

.ant-anchor-link-title {
  color: #000000d9;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 3px;
  transition: all .3s;
  display: block;
  position: relative;
  overflow: hidden;
}

.ant-anchor-link-title:only-child {
  margin-bottom: 0;
}

.ant-anchor-link-active > .ant-anchor-link-title {
  color: #1890ff;
}

.ant-anchor-link .ant-anchor-link {
  padding-top: 2px;
  padding-bottom: 2px;
}

.ant-anchor-rtl {
  direction: rtl;
}

.ant-anchor-rtl.ant-anchor-wrapper {
  margin-left: 0;
  margin-right: -4px;
  padding-left: 0;
  padding-right: 4px;
}

.ant-anchor-rtl .ant-anchor-ink {
  left: auto;
  right: 0;
}

.ant-anchor-rtl .ant-anchor-ink-ball {
  left: 0;
  right: 50%;
  transform: translateX(50%);
}

.ant-anchor-rtl .ant-anchor-link {
  padding: 4px 16px 4px 0;
}

.ant-select-auto-complete {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
}

.ant-select-auto-complete .ant-select-clear {
  right: 13px;
}

.ant-select-single .ant-select-selector {
  display: flex;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  position: absolute;
  inset: 0 11px;
}

.ant-select-single .ant-select-selector .ant-select-selection-search-input {
  width: 100%;
}

.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 30px;
  transition: all .3s, visibility;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  -webkit-user-select: none;
  user-select: none;
  position: relative;
}

.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  pointer-events: none;
  transition: none;
}

.ant-select-single .ant-select-selector:after, .ant-select-single .ant-select-selector .ant-select-selection-item:after, .ant-select-single .ant-select-selector .ant-select-selection-placeholder:after {
  visibility: hidden;
  content: " ";
  width: 0;
  display: inline-block;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 25px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #bfbfbf;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 32px;
  padding: 0 11px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 30px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector:after {
  line-height: 30px;
}

.ant-select-single.ant-select-customize-input .ant-select-selector:after {
  display: none;
}

.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
  width: 100%;
  position: static;
}

.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
  padding: 0 11px;
  position: absolute;
  left: 0;
  right: 0;
}

.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder:after {
  display: none;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector:after, .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item, .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 38px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  height: 24px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector:after, .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item, .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 22px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 22px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selection-search {
  left: 7px;
  right: 7px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 7px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 28px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 21px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 11px;
}

.ant-select-selection-overflow {
  flex-wrap: wrap;
  flex: auto;
  max-width: 100%;
  display: flex;
  position: relative;
}

.ant-select-selection-overflow-item {
  flex: none;
  align-self: center;
  max-width: 100%;
}

.ant-select-multiple .ant-select-selector {
  flex-wrap: wrap;
  align-items: center;
  padding: 1px 4px;
  display: flex;
}

.ant-select-show-search.ant-select-multiple .ant-select-selector {
  cursor: text;
}

.ant-select-disabled.ant-select-multiple .ant-select-selector {
  cursor: not-allowed;
  background: #f5f5f5;
}

.ant-select-multiple .ant-select-selector:after {
  visibility: hidden;
  content: " ";
  width: 0;
  margin: 2px 0;
  line-height: 24px;
  display: inline-block;
}

.ant-select-multiple.ant-select-show-arrow .ant-select-selector, .ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 24px;
}

.ant-select-multiple .ant-select-selection-item {
  box-sizing: border-box;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  margin-top: 2px;
  margin-bottom: 2px;
  background: #f5f5f5;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  flex: none;
  max-width: 100%;
  height: 24px;
  margin-inline-end: 4px;
  padding-inline: 8px 4px;
  line-height: 22px;
  transition: font-size .3s, line-height .3s, height .3s;
  display: flex;
  position: relative;
}

.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: #bfbfbf;
  cursor: not-allowed;
  border-color: #d9d9d9;
}

.ant-select-multiple .ant-select-selection-item-content {
  white-space: pre;
  text-overflow: ellipsis;
  margin-right: 4px;
  display: inline-block;
  overflow: hidden;
}

.ant-select-multiple .ant-select-selection-item-remove {
  color: #00000073;
  text-align: center;
  text-transform: none;
  vertical-align: -.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 10px;
  font-style: normal;
  font-weight: bold;
  line-height: 0;
  line-height: inherit;
  cursor: pointer;
  align-items: center;
  display: inline-flex;
}

.ant-select-multiple .ant-select-selection-item-remove > * {
  line-height: 1;
}

.ant-select-multiple .ant-select-selection-item-remove svg {
  display: inline-block;
}

.ant-select-multiple .ant-select-selection-item-remove:before {
  display: none;
}

.ant-select-multiple .ant-select-selection-item-remove .ant-select-multiple .ant-select-selection-item-remove-icon {
  display: block;
}

.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: middle;
}

.ant-select-multiple .ant-select-selection-item-remove:hover {
  color: #000000bf;
}

.ant-select-multiple .ant-select-selection-overflow-item + .ant-select-selection-overflow-item .ant-select-selection-search {
  margin-inline-start: 0;
}

.ant-select-multiple .ant-select-selection-search {
  max-width: 100%;
  margin-inline-start: 7px;
  position: relative;
}

.ant-select-multiple .ant-select-selection-search-input, .ant-select-multiple .ant-select-selection-search-mirror {
  height: 24px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 24px;
  transition: all .3s;
}

.ant-select-multiple .ant-select-selection-search-input {
  width: 100%;
  min-width: 4.1px;
}

.ant-select-multiple .ant-select-selection-search-mirror {
  z-index: 999;
  white-space: pre;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.ant-select-multiple .ant-select-selection-placeholder {
  transition: all .3s;
  position: absolute;
  top: 50%;
  left: 11px;
  right: 11px;
  transform: translateY(-50%);
}

.ant-select-multiple.ant-select-lg .ant-select-selector:after {
  line-height: 32px;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 30px;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-search {
  height: 32px;
  line-height: 32px;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-search-input, .ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror {
  height: 32px;
  line-height: 30px;
}

.ant-select-multiple.ant-select-sm .ant-select-selector:after {
  line-height: 16px;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-item {
  height: 16px;
  line-height: 14px;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  height: 16px;
  line-height: 16px;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-search-input, .ant-select-multiple.ant-select-sm .ant-select-selection-search-mirror {
  height: 16px;
  line-height: 14px;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  left: 7px;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  margin-inline-start: 3px;
}

.ant-select-disabled .ant-select-selection-item-remove {
  display: none;
}

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  background-color: #fff;
  border-color: #ff4d4f !important;
}

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-open .ant-select-selector, .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-focused .ant-select-selector {
  border-color: #ff7875;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #ff4d4f33;
}

.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  background-color: #fff;
  border-color: #faad14 !important;
}

.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-open .ant-select-selector, .ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-focused .ant-select-selector {
  border-color: #ffc53d;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #faad1433;
}

.ant-select-status-error.ant-select-has-feedback .ant-select-clear, .ant-select-status-warning.ant-select-has-feedback .ant-select-clear, .ant-select-status-success.ant-select-has-feedback .ant-select-clear, .ant-select-status-validating.ant-select-has-feedback .ant-select-clear {
  right: 32px;
}

.ant-select-status-error.ant-select-has-feedback .ant-select-selection-selected-value, .ant-select-status-warning.ant-select-has-feedback .ant-select-selection-selected-value, .ant-select-status-success.ant-select-has-feedback .ant-select-selection-selected-value, .ant-select-status-validating.ant-select-has-feedback .ant-select-selection-selected-value {
  padding-right: 42px;
}

.ant-select {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  cursor: pointer;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: inline-block;
  position: relative;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
  position: relative;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: pointer;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: text;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: auto;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #40a9ff;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #1890ff33;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: #00000040;
  cursor: not-allowed;
  background: #f5f5f5;
}

.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #f5f5f5;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: not-allowed;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  appearance: none;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: none;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #40a9ff;
  border-right-width: 1px;
}

.ant-select-selection-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  font-weight: normal;
  overflow: hidden;
}

@media (-ms-high-contrast: none) {
  .ant-select-selection-item {
    flex: auto;
  }

  .ant-select-selection-item ::-ms-backdrop {
    flex: auto;
  }
}

.ant-select-selection-placeholder {
  color: #bfbfbf;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  flex: 1;
  overflow: hidden;
}

@media (-ms-high-contrast: none) {
  .ant-select-selection-placeholder {
    flex: auto;
  }

  .ant-select-selection-placeholder ::-ms-backdrop {
    flex: auto;
  }
}

.ant-select-arrow {
  color: #00000040;
  text-transform: none;
  vertical-align: -.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  pointer-events: none;
  align-items: center;
  height: 12px;
  margin-top: -6px;
  font-size: 12px;
  font-style: normal;
  line-height: 1;
  display: flex;
  position: absolute;
  top: 50%;
  right: 11px;
}

.ant-select-arrow > * {
  line-height: 1;
}

.ant-select-arrow svg {
  display: inline-block;
}

.ant-select-arrow:before {
  display: none;
}

.ant-select-arrow .ant-select-arrow-icon {
  display: block;
}

.ant-select-arrow .anticon {
  vertical-align: top;
  transition: transform .3s;
}

.ant-select-arrow .anticon > svg {
  vertical-align: top;
}

.ant-select-arrow .anticon:not(.ant-select-suffix) {
  pointer-events: auto;
}

.ant-select-disabled .ant-select-arrow {
  cursor: not-allowed;
}

.ant-select-arrow > :not(:last-child) {
  margin-inline-end: 8px;
}

.ant-select-clear {
  z-index: 1;
  color: #00000040;
  text-align: center;
  text-transform: none;
  cursor: pointer;
  opacity: 0;
  text-rendering: auto;
  background: #fff;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  font-size: 12px;
  font-style: normal;
  line-height: 1;
  transition: color .3s, opacity .15s;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 11px;
}

.ant-select-clear:before {
  display: block;
}

.ant-select-clear:hover {
  color: #00000073;
}

.ant-select:hover .ant-select-clear {
  opacity: 1;
}

.ant-select-dropdown {
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  z-index: 1050;
  box-sizing: border-box;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  margin: 0;
  padding: 4px 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: absolute;
  top: -9999px;
  left: -9999px;
  overflow: hidden;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
}

.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-bottomLeft, .ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpIn;
}

.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-topLeft, .ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownIn;
}

.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpOut;
}

.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownOut;
}

.ant-select-dropdown-hidden {
  display: none;
}

.ant-select-dropdown-empty {
  color: #00000040;
}

.ant-select-item-empty {
  color: #00000040;
  min-height: 32px;
  padding: 5px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  display: block;
  position: relative;
}

.ant-select-item {
  color: #000000d9;
  cursor: pointer;
  min-height: 32px;
  padding: 5px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  transition: background .3s;
  display: block;
  position: relative;
}

.ant-select-item-group {
  color: #00000073;
  cursor: default;
  font-size: 12px;
}

.ant-select-item-option {
  display: flex;
}

.ant-select-item-option-content {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: auto;
  overflow: hidden;
}

.ant-select-item-option-state {
  flex: none;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #000000d9;
  background-color: #e6f7ff;
  font-weight: 600;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #1890ff;
}

.ant-select-item-option-disabled {
  color: #00000040;
  cursor: not-allowed;
}

.ant-select-item-option-disabled.ant-select-item-option-selected {
  background-color: #f5f5f5;
}

.ant-select-item-option-grouped {
  padding-left: 24px;
}

.ant-select-lg {
  font-size: 16px;
}

.ant-select-borderless .ant-select-selector {
  box-shadow: none !important;
  background-color: #0000 !important;
  border-color: #0000 !important;
}

.ant-select.ant-select-in-form-item {
  width: 100%;
}

.ant-select-compact-item:not(.ant-select-compact-last-item) {
  margin-right: -1px;
}

.ant-select-compact-item:not(.ant-select-compact-last-item).ant-select-compact-item-rtl {
  margin-left: -1px;
  margin-right: 0;
}

.ant-select-compact-item:hover > *, .ant-select-compact-item:focus > *, .ant-select-compact-item:active > *, .ant-select-compact-item.ant-select-focused > * {
  z-index: 2;
}

.ant-select-compact-item[disabled] > * {
  z-index: 0;
}

.ant-select-compact-item:not(.ant-select-compact-first-item):not(.ant-select-compact-last-item).ant-select > .ant-select-selector {
  border-radius: 0;
}

.ant-select-compact-item.ant-select-compact-first-item.ant-select:not(.ant-select-compact-last-item):not(.ant-select-compact-item-rtl) > .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-select-compact-item.ant-select-compact-last-item.ant-select:not(.ant-select-compact-first-item):not(.ant-select-compact-item-rtl) > .ant-select-selector, .ant-select-compact-item.ant-select.ant-select-compact-first-item.ant-select-compact-item-rtl:not(.ant-select-compact-last-item) > .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-select-compact-item.ant-select.ant-select-compact-last-item.ant-select-compact-item-rtl:not(.ant-select-compact-first-item) > .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-select-rtl {
  direction: rtl;
}

.ant-select-rtl .ant-select-arrow, .ant-select-rtl .ant-select-clear {
  right: initial;
  left: 11px;
}

.ant-select-dropdown-rtl {
  direction: rtl;
}

.ant-select-dropdown-rtl .ant-select-item-option-grouped {
  padding-left: 12px;
  padding-right: 24px;
}

.ant-select-rtl.ant-select-multiple.ant-select-show-arrow .ant-select-selector, .ant-select-rtl.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-left: 24px;
  padding-right: 4px;
}

.ant-select-rtl.ant-select-multiple .ant-select-selection-item {
  text-align: right;
}

.ant-select-rtl.ant-select-multiple .ant-select-selection-item-content {
  text-align: right;
  margin-left: 4px;
  margin-right: 0;
}

.ant-select-rtl.ant-select-multiple .ant-select-selection-search-mirror {
  left: auto;
  right: 0;
}

.ant-select-rtl.ant-select-multiple .ant-select-selection-placeholder {
  left: auto;
  right: 11px;
}

.ant-select-rtl.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  right: 7px;
}

.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  text-align: right;
  left: 9px;
  right: 0;
}

.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  left: 25px;
  right: 11px;
}

.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-left: 18px;
  padding-right: 0;
}

.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 6px;
}

.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item, .ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-left: 21px;
  padding-right: 0;
}

.ant-empty {
  text-align: center;
  margin: 0 8px;
  font-size: 14px;
  line-height: 1.5715;
}

.ant-empty-image {
  height: 100px;
  margin-bottom: 8px;
}

.ant-empty-image img {
  height: 100%;
}

.ant-empty-image svg {
  height: 100%;
  margin: auto;
}

.ant-empty-footer {
  margin-top: 16px;
}

.ant-empty-normal {
  color: #00000040;
  margin: 32px 0;
}

.ant-empty-normal .ant-empty-image {
  height: 40px;
}

.ant-empty-small {
  color: #00000040;
  margin: 8px 0;
}

.ant-empty-small .ant-empty-image {
  height: 35px;
}

.ant-empty-img-default-ellipse {
  fill: #f5f5f5;
  fill-opacity: .8;
}

.ant-empty-img-default-path-1 {
  fill: #aeb8c2;
}

.ant-empty-img-default-path-2 {
  fill: url("#linearGradient-1");
}

.ant-empty-img-default-path-3 {
  fill: #f5f5f7;
}

.ant-empty-img-default-path-4, .ant-empty-img-default-path-5 {
  fill: #dce0e6;
}

.ant-empty-img-default-g {
  fill: #fff;
}

.ant-empty-img-simple-ellipse {
  fill: #f5f5f5;
}

.ant-empty-img-simple-g {
  stroke: #d9d9d9;
}

.ant-empty-img-simple-path {
  fill: #fafafa;
}

.ant-empty-rtl {
  direction: rtl;
}

.ant-avatar {
  box-sizing: border-box;
  color: #fff;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 32px;
  list-style: none;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.ant-avatar-image {
  background: none;
}

.ant-avatar .ant-image-img {
  display: block;
}

.ant-avatar-string {
  transform-origin: 0;
  position: absolute;
  left: 50%;
}

.ant-avatar.ant-avatar-icon {
  font-size: 18px;
}

.ant-avatar.ant-avatar-icon > .anticon {
  margin: 0;
}

.ant-avatar-lg {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.ant-avatar-lg-string {
  transform-origin: 0;
  position: absolute;
  left: 50%;
}

.ant-avatar-lg.ant-avatar-icon {
  font-size: 24px;
}

.ant-avatar-lg.ant-avatar-icon > .anticon {
  margin: 0;
}

.ant-avatar-sm {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  line-height: 24px;
}

.ant-avatar-sm-string {
  transform-origin: 0;
  position: absolute;
  left: 50%;
}

.ant-avatar-sm.ant-avatar-icon {
  font-size: 14px;
}

.ant-avatar-sm.ant-avatar-icon > .anticon {
  margin: 0;
}

.ant-avatar-square {
  border-radius: 2px;
}

.ant-avatar > img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.ant-avatar-group {
  display: inline-flex;
}

.ant-avatar-group .ant-avatar {
  border: 1px solid #fff;
}

.ant-avatar-group .ant-avatar:not(:first-child) {
  margin-left: -8px;
}

.ant-avatar-group-popover .ant-avatar + .ant-avatar {
  margin-left: 3px;
}

.ant-avatar-group-rtl .ant-avatar:not(:first-child) {
  margin-left: 0;
  margin-right: -8px;
}

.ant-avatar-group-popover.ant-popover-rtl .ant-avatar + .ant-avatar {
  margin-left: 0;
  margin-right: 3px;
}

.ant-popover {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  z-index: 1030;
  white-space: normal;
  text-align: left;
  cursor: auto;
  -webkit-user-select: text;
  user-select: text;
  max-width: 100vw;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5715;
  list-style: none;
  position: absolute;
  top: 0;
  left: 0;
}

.ant-popover-content {
  position: relative;
}

.ant-popover:after {
  content: "";
  background: #ffffff03;
  position: absolute;
}

.ant-popover-hidden {
  display: none;
}

.ant-popover-placement-top, .ant-popover-placement-topLeft, .ant-popover-placement-topRight {
  padding-bottom: 15.3137px;
}

.ant-popover-placement-right, .ant-popover-placement-rightTop, .ant-popover-placement-rightBottom {
  padding-left: 15.3137px;
}

.ant-popover-placement-bottom, .ant-popover-placement-bottomLeft, .ant-popover-placement-bottomRight {
  padding-top: 15.3137px;
}

.ant-popover-placement-left, .ant-popover-placement-leftTop, .ant-popover-placement-leftBottom {
  padding-right: 15.3137px;
}

.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-popover-inner {
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
  }
}

.ant-popover-title {
  color: #000000d9;
  border-bottom: 1px solid #f0f0f0;
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 16px 4px;
  font-weight: 500;
}

.ant-popover-inner-content {
  color: #000000d9;
  width: max-content;
  max-width: 100%;
  padding: 12px 16px;
}

.ant-popover-message {
  color: #000000d9;
  padding: 4px 0 12px;
  font-size: 14px;
  display: flex;
}

.ant-popover-message-icon {
  color: #faad14;
  margin-right: 8px;
  font-size: 14px;
  display: inline-block;
}

.ant-popover-buttons {
  text-align: right;
  margin-bottom: 4px;
}

.ant-popover-buttons button:not(:first-child) {
  margin-left: 8px;
}

.ant-popover-arrow {
  pointer-events: none;
  background: none;
  width: 22px;
  height: 22px;
  display: block;
  position: absolute;
  overflow: hidden;
}

.ant-popover-arrow-content {
  --antd-arrow-background-color: #fff;
  content: "";
  pointer-events: auto;
  pointer-events: none;
  border-radius: 0 0 2px;
  width: 11.3137px;
  height: 11.3137px;
  margin: auto;
  display: block;
  position: absolute;
  inset: 0;
}

.ant-popover-arrow-content:before {
  background: var(--antd-arrow-background-color);
  content: "";
  clip-path: inset(33%);
  -webkit-clip-path: path("M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z");
  clip-path: path("M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z");
  background-position: -10px -10px;
  background-repeat: no-repeat;
  width: 33.9411px;
  height: 33.9411px;
  position: absolute;
  top: -11.3137px;
  left: -11.3137px;
}

.ant-popover-placement-top .ant-popover-arrow, .ant-popover-placement-topLeft .ant-popover-arrow, .ant-popover-placement-topRight .ant-popover-arrow {
  bottom: 0;
  transform: translateY(100%);
}

.ant-popover-placement-top .ant-popover-arrow-content, .ant-popover-placement-topLeft .ant-popover-arrow-content, .ant-popover-placement-topRight .ant-popover-arrow-content {
  transform: translateY(-11px)rotate(45deg);
  box-shadow: 3px 3px 7px #00000012;
}

.ant-popover-placement-top .ant-popover-arrow {
  left: 50%;
  transform: translateY(100%)translateX(-50%);
}

.ant-popover-placement-topLeft .ant-popover-arrow {
  left: 16px;
}

.ant-popover-placement-topRight .ant-popover-arrow {
  right: 16px;
}

.ant-popover-placement-right .ant-popover-arrow, .ant-popover-placement-rightTop .ant-popover-arrow, .ant-popover-placement-rightBottom .ant-popover-arrow {
  left: 0;
  transform: translateX(-100%);
}

.ant-popover-placement-right .ant-popover-arrow-content, .ant-popover-placement-rightTop .ant-popover-arrow-content, .ant-popover-placement-rightBottom .ant-popover-arrow-content {
  transform: translateX(11px)rotate(135deg);
  box-shadow: 3px 3px 7px #00000012;
}

.ant-popover-placement-right .ant-popover-arrow {
  top: 50%;
  transform: translateX(-100%)translateY(-50%);
}

.ant-popover-placement-rightTop .ant-popover-arrow {
  top: 12px;
}

.ant-popover-placement-rightBottom .ant-popover-arrow {
  bottom: 12px;
}

.ant-popover-placement-bottom .ant-popover-arrow, .ant-popover-placement-bottomLeft .ant-popover-arrow, .ant-popover-placement-bottomRight .ant-popover-arrow {
  top: 0;
  transform: translateY(-100%);
}

.ant-popover-placement-bottom .ant-popover-arrow-content, .ant-popover-placement-bottomLeft .ant-popover-arrow-content, .ant-popover-placement-bottomRight .ant-popover-arrow-content {
  transform: translateY(11px)rotate(-135deg);
  box-shadow: 2px 2px 5px #0000000f;
}

.ant-popover-placement-bottom .ant-popover-arrow {
  left: 50%;
  transform: translateY(-100%)translateX(-50%);
}

.ant-popover-placement-bottomLeft .ant-popover-arrow {
  left: 16px;
}

.ant-popover-placement-bottomRight .ant-popover-arrow {
  right: 16px;
}

.ant-popover-placement-left .ant-popover-arrow, .ant-popover-placement-leftTop .ant-popover-arrow, .ant-popover-placement-leftBottom .ant-popover-arrow {
  right: 0;
  transform: translateX(100%);
}

.ant-popover-placement-left .ant-popover-arrow-content, .ant-popover-placement-leftTop .ant-popover-arrow-content, .ant-popover-placement-leftBottom .ant-popover-arrow-content {
  transform: translateX(-11px)rotate(-45deg);
  box-shadow: 3px 3px 7px #00000012;
}

.ant-popover-placement-left .ant-popover-arrow {
  top: 50%;
  transform: translateX(100%)translateY(-50%);
}

.ant-popover-placement-leftTop .ant-popover-arrow {
  top: 12px;
}

.ant-popover-placement-leftBottom .ant-popover-arrow {
  bottom: 12px;
}

.ant-popover-pink .ant-popover-inner, .ant-popover-pink .ant-popover-arrow-content, .ant-popover-magenta .ant-popover-inner, .ant-popover-magenta .ant-popover-arrow-content {
  background-color: #eb2f96;
}

.ant-popover-red .ant-popover-inner, .ant-popover-red .ant-popover-arrow-content {
  background-color: #f5222d;
}

.ant-popover-volcano .ant-popover-inner, .ant-popover-volcano .ant-popover-arrow-content {
  background-color: #fa541c;
}

.ant-popover-orange .ant-popover-inner, .ant-popover-orange .ant-popover-arrow-content {
  background-color: #fa8c16;
}

.ant-popover-yellow .ant-popover-inner, .ant-popover-yellow .ant-popover-arrow-content {
  background-color: #fadb14;
}

.ant-popover-gold .ant-popover-inner, .ant-popover-gold .ant-popover-arrow-content {
  background-color: #faad14;
}

.ant-popover-cyan .ant-popover-inner, .ant-popover-cyan .ant-popover-arrow-content {
  background-color: #13c2c2;
}

.ant-popover-lime .ant-popover-inner, .ant-popover-lime .ant-popover-arrow-content {
  background-color: #a0d911;
}

.ant-popover-green .ant-popover-inner, .ant-popover-green .ant-popover-arrow-content {
  background-color: #52c41a;
}

.ant-popover-blue .ant-popover-inner, .ant-popover-blue .ant-popover-arrow-content {
  background-color: #1890ff;
}

.ant-popover-geekblue .ant-popover-inner, .ant-popover-geekblue .ant-popover-arrow-content {
  background-color: #2f54eb;
}

.ant-popover-purple .ant-popover-inner, .ant-popover-purple .ant-popover-arrow-content {
  background-color: #722ed1;
}

.ant-popover-rtl {
  text-align: right;
  direction: rtl;
}

.ant-popover-rtl .ant-popover-message-icon {
  margin-left: 8px;
  margin-right: 0;
}

.ant-popover-rtl .ant-popover-message-title {
  padding-left: 16px;
}

.ant-popover-rtl .ant-popover-buttons {
  text-align: left;
}

.ant-popover-rtl .ant-popover-buttons button {
  margin-left: 0;
  margin-right: 8px;
}

.ant-back-top {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  z-index: 10;
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: fixed;
  bottom: 50px;
  right: 100px;
}

.ant-back-top:empty {
  display: none;
}

.ant-back-top-rtl {
  direction: rtl;
  left: 100px;
  right: auto;
}

.ant-back-top-content {
  color: #fff;
  text-align: center;
  background-color: #00000073;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  transition: all .3s;
  overflow: hidden;
}

.ant-back-top-content:hover {
  background-color: #000000d9;
  transition: all .3s;
}

.ant-back-top-icon {
  font-size: 24px;
  line-height: 40px;
}

@media screen and (width <= 768px) {
  .ant-back-top {
    right: 60px;
  }

  .ant-back-top-rtl {
    left: 60px;
    right: auto;
  }
}

@media screen and (width <= 480px) {
  .ant-back-top {
    right: 20px;
  }

  .ant-back-top-rtl {
    left: 20px;
    right: auto;
  }
}

.ant-badge {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1;
  list-style: none;
  display: inline-block;
  position: relative;
}

.ant-badge-count {
  z-index: auto;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  background: #ff4d4f;
  border-radius: 10px;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  box-shadow: 0 0 0 1px #fff;
}

.ant-badge-count a, .ant-badge-count a:hover {
  color: #fff;
}

.ant-badge-count-sm {
  border-radius: 7px;
  min-width: 14px;
  height: 14px;
  padding: 0;
  font-size: 12px;
  line-height: 14px;
}

.ant-badge-multiple-words {
  padding: 0 8px;
}

.ant-badge-dot {
  z-index: auto;
  background: #ff4d4f;
  border-radius: 100%;
  width: 6px;
  min-width: 6px;
  height: 6px;
  box-shadow: 0 0 0 1px #fff;
}

.ant-badge-dot.ant-scroll-number {
  transition: background 1.5s;
}

.ant-badge-count, .ant-badge-dot, .ant-badge .ant-scroll-number-custom-component {
  transform-origin: 100% 0;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}

.ant-badge-count.anticon-spin, .ant-badge-dot.anticon-spin, .ant-badge .ant-scroll-number-custom-component.anticon-spin {
  animation: 1s linear infinite antBadgeLoadingCircle;
}

.ant-badge-status {
  line-height: inherit;
  vertical-align: baseline;
}

.ant-badge-status-dot {
  vertical-align: middle;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  display: inline-block;
  position: relative;
  top: -1px;
}

.ant-badge-status-success {
  background-color: #52c41a;
}

.ant-badge-status-processing {
  background-color: #1890ff;
  position: relative;
}

.ant-badge-status-processing:after {
  content: "";
  border: 1px solid #1890ff;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: 1.2s ease-in-out infinite antStatusProcessing;
  position: absolute;
  top: 0;
  left: 0;
}

.ant-badge-status-default {
  background-color: #d9d9d9;
}

.ant-badge-status-error {
  background-color: #ff4d4f;
}

.ant-badge-status-warning {
  background-color: #faad14;
}

.ant-badge-status-pink, .ant-badge-status-magenta {
  background: #eb2f96;
}

.ant-badge-status-red {
  background: #f5222d;
}

.ant-badge-status-volcano {
  background: #fa541c;
}

.ant-badge-status-orange {
  background: #fa8c16;
}

.ant-badge-status-yellow {
  background: #fadb14;
}

.ant-badge-status-gold {
  background: #faad14;
}

.ant-badge-status-cyan {
  background: #13c2c2;
}

.ant-badge-status-lime {
  background: #a0d911;
}

.ant-badge-status-green {
  background: #52c41a;
}

.ant-badge-status-blue {
  background: #1890ff;
}

.ant-badge-status-geekblue {
  background: #2f54eb;
}

.ant-badge-status-purple {
  background: #722ed1;
}

.ant-badge-status-text {
  color: #000000d9;
  margin-left: 8px;
  font-size: 14px;
}

.ant-badge-zoom-appear, .ant-badge-zoom-enter {
  animation: .3s cubic-bezier(.12, .4, .29, 1.46) both antZoomBadgeIn;
}

.ant-badge-zoom-leave {
  animation: .3s cubic-bezier(.71, -.46, .88, .6) both antZoomBadgeOut;
}

.ant-badge-not-a-wrapper .ant-badge-zoom-appear, .ant-badge-not-a-wrapper .ant-badge-zoom-enter {
  animation: .3s cubic-bezier(.12, .4, .29, 1.46) antNoWrapperZoomBadgeIn;
}

.ant-badge-not-a-wrapper .ant-badge-zoom-leave {
  animation: .3s cubic-bezier(.71, -.46, .88, .6) antNoWrapperZoomBadgeOut;
}

.ant-badge-not-a-wrapper:not(.ant-badge-status) {
  vertical-align: middle;
}

.ant-badge-not-a-wrapper .ant-scroll-number-custom-component, .ant-badge-not-a-wrapper .ant-badge-count {
  transform: none;
}

.ant-badge-not-a-wrapper .ant-scroll-number-custom-component, .ant-badge-not-a-wrapper .ant-scroll-number {
  transform-origin: 50%;
  display: block;
  position: relative;
  top: auto;
}

@keyframes antStatusProcessing {
  0% {
    opacity: .5;
    transform: scale(.8);
  }

  100% {
    opacity: 0;
    transform: scale(2.4);
  }
}

.ant-scroll-number {
  direction: ltr;
  overflow: hidden;
}

.ant-scroll-number-only {
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  height: 20px;
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
  display: inline-block;
  position: relative;
}

.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  height: 20px;
  margin: 0;
}

.ant-scroll-number-symbol {
  vertical-align: top;
}

@keyframes antZoomBadgeIn {
  0% {
    opacity: 0;
    transform: scale(0)translate(50%, -50%);
  }

  100% {
    transform: scale(1)translate(50%, -50%);
  }
}

@keyframes antZoomBadgeOut {
  0% {
    transform: scale(1)translate(50%, -50%);
  }

  100% {
    opacity: 0;
    transform: scale(0)translate(50%, -50%);
  }
}

@keyframes antNoWrapperZoomBadgeIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes antNoWrapperZoomBadgeOut {
  0% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes antBadgeLoadingCircle {
  0% {
    transform-origin: 50%;
  }

  100% {
    transform-origin: 50%;
    transform: translate(50%, -50%)rotate(360deg);
  }
}

.ant-ribbon-wrapper {
  position: relative;
}

.ant-ribbon {
  box-sizing: border-box;
  color: #fff;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  white-space: nowrap;
  background-color: #1890ff;
  border-radius: 2px;
  height: 22px;
  margin: 0;
  padding: 0 8px;
  font-size: 14px;
  line-height: 22px;
  list-style: none;
  position: absolute;
  top: 8px;
}

.ant-ribbon-text {
  color: #fff;
}

.ant-ribbon-corner {
  color: currentColor;
  transform-origin: top;
  border: 4px solid;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 100%;
  transform: scaleY(.75);
}

.ant-ribbon-corner:after {
  width: inherit;
  height: inherit;
  color: #00000040;
  border: inherit;
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
}

.ant-ribbon-color-pink, .ant-ribbon-color-magenta {
  color: #eb2f96;
  background: #eb2f96;
}

.ant-ribbon-color-red {
  color: #f5222d;
  background: #f5222d;
}

.ant-ribbon-color-volcano {
  color: #fa541c;
  background: #fa541c;
}

.ant-ribbon-color-orange {
  color: #fa8c16;
  background: #fa8c16;
}

.ant-ribbon-color-yellow {
  color: #fadb14;
  background: #fadb14;
}

.ant-ribbon-color-gold {
  color: #faad14;
  background: #faad14;
}

.ant-ribbon-color-cyan {
  color: #13c2c2;
  background: #13c2c2;
}

.ant-ribbon-color-lime {
  color: #a0d911;
  background: #a0d911;
}

.ant-ribbon-color-green {
  color: #52c41a;
  background: #52c41a;
}

.ant-ribbon-color-blue {
  color: #1890ff;
  background: #1890ff;
}

.ant-ribbon-color-geekblue {
  color: #2f54eb;
  background: #2f54eb;
}

.ant-ribbon-color-purple {
  color: #722ed1;
  background: #722ed1;
}

.ant-ribbon.ant-ribbon-placement-end {
  border-bottom-right-radius: 0;
  right: -8px;
}

.ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner {
  border-color: currentColor #0000 #0000 currentColor;
  right: 0;
}

.ant-ribbon.ant-ribbon-placement-start {
  border-bottom-left-radius: 0;
  left: -8px;
}

.ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner {
  border-color: currentColor currentColor #0000 #0000;
  left: 0;
}

.ant-badge-rtl {
  direction: rtl;
}

.ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-badge-count, .ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-badge-dot, .ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-scroll-number-custom-component {
  transform-origin: 0 0;
  direction: ltr;
  left: 0;
  right: auto;
  transform: translate(-50%, -50%);
}

.ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-scroll-number-custom-component {
  transform-origin: 0 0;
  left: 0;
  right: auto;
  transform: translate(-50%, -50%);
}

.ant-badge-rtl .ant-badge-status-text {
  margin-left: 0;
  margin-right: 8px;
}

.ant-badge:not(.ant-badge-not-a-wrapper).ant-badge-rtl .ant-badge-zoom-appear, .ant-badge:not(.ant-badge-not-a-wrapper).ant-badge-rtl .ant-badge-zoom-enter {
  animation-name: antZoomBadgeInRtl;
}

.ant-badge:not(.ant-badge-not-a-wrapper).ant-badge-rtl .ant-badge-zoom-leave {
  animation-name: antZoomBadgeOutRtl;
}

.ant-ribbon-rtl {
  direction: rtl;
}

.ant-ribbon-rtl.ant-ribbon-placement-end {
  right: unset;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
  left: -8px;
}

.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner {
  right: unset;
  border-color: currentColor currentColor #0000 #0000;
  left: 0;
}

.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner:after {
  border-color: currentColor currentColor #0000 #0000;
}

.ant-ribbon-rtl.ant-ribbon-placement-start {
  right: -8px;
  left: unset;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}

.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner {
  right: 0;
  left: unset;
  border-color: currentColor #0000 #0000 currentColor;
}

.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner:after {
  border-color: currentColor #0000 #0000 currentColor;
}

@keyframes antZoomBadgeInRtl {
  0% {
    opacity: 0;
    transform: scale(0)translate(-50%, -50%);
  }

  100% {
    transform: scale(1)translate(-50%, -50%);
  }
}

@keyframes antZoomBadgeOutRtl {
  0% {
    transform: scale(1)translate(-50%, -50%);
  }

  100% {
    opacity: 0;
    transform: scale(0)translate(-50%, -50%);
  }
}

.ant-breadcrumb {
  box-sizing: border-box;
  color: #00000073;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
}

.ant-breadcrumb .anticon {
  font-size: 14px;
}

.ant-breadcrumb ol {
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.ant-breadcrumb a {
  color: #00000073;
  transition: color .3s;
}

.ant-breadcrumb a:hover, .ant-breadcrumb li:last-child, .ant-breadcrumb li:last-child a {
  color: #000000d9;
}

li:last-child > .ant-breadcrumb-separator {
  display: none;
}

.ant-breadcrumb-separator {
  color: #00000073;
  margin: 0 8px;
}

.ant-breadcrumb-link > .anticon + span, .ant-breadcrumb-link > .anticon + a, .ant-breadcrumb-overlay-link > .anticon {
  margin-left: 4px;
}

.ant-breadcrumb-rtl {
  direction: rtl;
}

.ant-breadcrumb-rtl:before {
  content: "";
  display: table;
}

.ant-breadcrumb-rtl:after {
  clear: both;
  content: "";
  display: table;
}

.ant-breadcrumb-rtl > span {
  float: right;
}

.ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + span, .ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + a, .ant-breadcrumb-rtl .ant-breadcrumb-overlay-link > .anticon {
  margin-left: 0;
  margin-right: 4px;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
  color: #ff4d4f;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
  color: #fff;
  background-color: #ff4d4f;
}

.ant-dropdown {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  z-index: 1050;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: block;
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.ant-dropdown:before {
  z-index: -9999;
  opacity: .0001;
  content: " ";
  position: absolute;
  inset: -4px 0 -4px -7px;
}

.ant-dropdown-wrap {
  position: relative;
}

.ant-dropdown-wrap .ant-btn > .anticon-down {
  font-size: 10px;
}

.ant-dropdown-wrap .anticon-down:before {
  transition: transform .2s;
}

.ant-dropdown-wrap-open .anticon-down:before {
  transform: rotate(180deg);
}

.ant-dropdown-hidden, .ant-dropdown-menu-hidden, .ant-dropdown-menu-submenu-hidden {
  display: none;
}

.ant-dropdown-show-arrow.ant-dropdown-placement-topLeft, .ant-dropdown-show-arrow.ant-dropdown-placement-top, .ant-dropdown-show-arrow.ant-dropdown-placement-topRight {
  padding-bottom: 15.3137px;
}

.ant-dropdown-show-arrow.ant-dropdown-placement-bottomLeft, .ant-dropdown-show-arrow.ant-dropdown-placement-bottom, .ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
  padding-top: 15.3137px;
}

.ant-dropdown-arrow {
  z-index: 1;
  pointer-events: none;
  border-radius: 0 0 2px;
  width: 11.3137px;
  height: 11.3137px;
  display: block;
  position: absolute;
}

.ant-dropdown-arrow:before {
  content: "";
  clip-path: inset(33%);
  -webkit-clip-path: path("M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z");
  clip-path: path("M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z");
  background: #fff -10px -10px no-repeat;
  width: 33.9411px;
  height: 33.9411px;
  position: absolute;
  top: -11.3137px;
  left: -11.3137px;
}

.ant-dropdown-placement-top > .ant-dropdown-arrow, .ant-dropdown-placement-topLeft > .ant-dropdown-arrow, .ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  bottom: 10px;
  transform: rotate(45deg);
  box-shadow: 3px 3px 7px -3px #0000001a;
}

.ant-dropdown-placement-top > .ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%)rotate(45deg);
}

.ant-dropdown-placement-topLeft > .ant-dropdown-arrow {
  left: 16px;
}

.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  right: 16px;
}

.ant-dropdown-placement-bottom > .ant-dropdown-arrow, .ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow, .ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  top: 9.41421px;
  transform: rotate(-135deg)translateY(-.5px);
  box-shadow: 2px 2px 5px -2px #0000001a;
}

.ant-dropdown-placement-bottom > .ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%)rotate(-135deg)translateY(-.5px);
}

.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow {
  left: 16px;
}

.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  right: 16px;
}

.ant-dropdown-menu {
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  margin: 0;
  padding: 4px 0;
  list-style-type: none;
  position: relative;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
}

.ant-dropdown-menu-item-group-title {
  color: #00000073;
  padding: 5px 12px;
  transition: all .3s;
}

.ant-dropdown-menu-submenu-popup {
  z-index: 1050;
  box-shadow: none;
  transform-origin: 0 0;
  background: none;
  position: absolute;
}

.ant-dropdown-menu-submenu-popup ul, .ant-dropdown-menu-submenu-popup li {
  list-style: none;
}

.ant-dropdown-menu-submenu-popup ul {
  margin-left: .3em;
  margin-right: .3em;
}

.ant-dropdown-menu-item {
  align-items: center;
  display: flex;
  position: relative;
}

.ant-dropdown-menu-item-icon {
  min-width: 12px;
  margin-right: 8px;
  font-size: 12px;
}

.ant-dropdown-menu-title-content {
  flex: auto;
}

.ant-dropdown-menu-title-content > a {
  color: inherit;
  transition: all .3s;
}

.ant-dropdown-menu-title-content > a:hover {
  color: inherit;
}

.ant-dropdown-menu-title-content > a:after {
  content: "";
  position: absolute;
  inset: 0;
}

.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
  clear: both;
  color: #000000d9;
  cursor: pointer;
  margin: 0;
  padding: 5px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  transition: all .3s;
}

.ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected {
  color: #1890ff;
  background-color: #e6f7ff;
}

.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover, .ant-dropdown-menu-item.ant-dropdown-menu-item-active, .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
  background-color: #f5f5f5;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled, .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-disabled, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-disabled, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-disabled {
  color: #00000040;
  cursor: not-allowed;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled:hover, .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-disabled:hover, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-disabled:hover, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-disabled:hover {
  color: #00000040;
  cursor: not-allowed;
  background-color: #fff;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled a, .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-disabled a, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-disabled a, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-disabled a {
  pointer-events: none;
}

.ant-dropdown-menu-item-divider, .ant-dropdown-menu-submenu-title-divider {
  background-color: #f0f0f0;
  height: 1px;
  margin: 4px 0;
  line-height: 0;
  overflow: hidden;
}

.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon, .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  position: absolute;
  right: 8px;
}

.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon, .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  color: #00000073;
  font-size: 10px;
  font-style: normal;
  margin-right: 0 !important;
}

.ant-dropdown-menu-item-group-list {
  margin: 0 8px;
  padding: 0;
  list-style: none;
}

.ant-dropdown-menu-submenu-title {
  padding-right: 24px;
}

.ant-dropdown-menu-submenu-vertical {
  position: relative;
}

.ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  transform-origin: 0 0;
  min-width: 100%;
  margin-left: 4px;
  position: absolute;
  top: 0;
  left: 100%;
}

.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title, .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: #00000040;
  cursor: not-allowed;
  background-color: #fff;
}

.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: #1890ff;
}

.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomLeft, .ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomLeft, .ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottom, .ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottom, .ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomRight, .ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}

.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topLeft, .ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topLeft, .ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-top, .ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-top, .ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topRight, .ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}

.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomLeft, .ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottom, .ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}

.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topLeft, .ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-top, .ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}

.ant-dropdown-trigger > .anticon.anticon-down, .ant-dropdown-link > .anticon.anticon-down, .ant-dropdown-button > .anticon.anticon-down {
  vertical-align: baseline;
  font-size: 10px;
}

.ant-dropdown-button {
  white-space: nowrap;
}

.ant-dropdown-button.ant-btn-group > .ant-btn-loading, .ant-dropdown-button.ant-btn-group > .ant-btn-loading + .ant-btn {
  cursor: default;
  pointer-events: none;
}

.ant-dropdown-button.ant-btn-group > .ant-btn-loading + .ant-btn:before {
  display: block;
}

.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
  padding-left: 8px;
  padding-right: 8px;
}

.ant-dropdown-menu-dark, .ant-dropdown-menu-dark .ant-dropdown-menu {
  background: #001529;
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item, .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title, .ant-dropdown-menu-dark .ant-dropdown-menu-item > a, .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a, .ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow:after, .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow:after, .ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow:after, .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow:after {
  color: #ffffffa6;
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover, .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover, .ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover, .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover {
  color: #fff;
  background: none;
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected, .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover, .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: #fff;
  background: #1890ff;
}

.ant-dropdown-rtl {
  direction: rtl;
}

.ant-dropdown-rtl.ant-dropdown:before {
  left: 0;
  right: -7px;
}

.ant-dropdown-menu.ant-dropdown-menu-rtl, .ant-dropdown-rtl .ant-dropdown-menu-item-group-title, .ant-dropdown-menu-submenu-rtl .ant-dropdown-menu-item-group-title {
  text-align: right;
  direction: rtl;
}

.ant-dropdown-menu-submenu-popup.ant-dropdown-menu-submenu-rtl {
  transform-origin: 100% 0;
}

.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup ul, .ant-dropdown-rtl .ant-dropdown-menu-submenu-popup li, .ant-dropdown-rtl .ant-dropdown-menu-item, .ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  text-align: right;
}

.ant-dropdown-rtl .ant-dropdown-menu-item > .anticon:first-child, .ant-dropdown-rtl .ant-dropdown-menu-submenu-title > .anticon:first-child, .ant-dropdown-rtl .ant-dropdown-menu-item > span > .anticon:first-child, .ant-dropdown-rtl .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  margin-left: 8px;
  margin-right: 0;
}

.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon, .ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  left: 8px;
  right: auto;
}

.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon, .ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  transform: scaleX(-1);
  margin-left: 0 !important;
}

.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  padding-left: 24px;
  padding-right: 12px;
}

.ant-dropdown-rtl .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  margin-left: 0;
  margin-right: 4px;
  left: 0;
  right: 100%;
}

.ant-btn {
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  color: #000000d9;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5715;
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
  display: inline-block;
  position: relative;
  box-shadow: 0 2px #00000004;
}

.ant-btn > .anticon {
  line-height: 1;
}

.ant-btn, .ant-btn:active, .ant-btn:focus {
  outline: 0;
}

.ant-btn:not([disabled]):hover {
  text-decoration: none;
}

.ant-btn:not([disabled]):active {
  box-shadow: none;
  outline: 0;
}

.ant-btn[disabled] {
  cursor: not-allowed;
}

.ant-btn[disabled] > * {
  pointer-events: none;
}

.ant-btn-lg {
  border-radius: 2px;
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
}

.ant-btn-sm {
  border-radius: 2px;
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
}

.ant-btn > a:only-child {
  color: currentColor;
}

.ant-btn > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn:hover, .ant-btn:focus {
  color: #40a9ff;
  background: #fff;
  border-color: #40a9ff;
}

.ant-btn:hover > a:only-child, .ant-btn:focus > a:only-child {
  color: currentColor;
}

.ant-btn:hover > a:only-child:after, .ant-btn:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn:active {
  color: #096dd9;
  background: #fff;
  border-color: #096dd9;
}

.ant-btn:active > a:only-child {
  color: currentColor;
}

.ant-btn:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-btn[disabled] > a:only-child, .ant-btn[disabled]:hover > a:only-child, .ant-btn[disabled]:focus > a:only-child, .ant-btn[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn[disabled] > a:only-child:after, .ant-btn[disabled]:hover > a:only-child:after, .ant-btn[disabled]:focus > a:only-child:after, .ant-btn[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn:hover, .ant-btn:focus, .ant-btn:active {
  background: #fff;
  text-decoration: none;
}

.ant-btn > span {
  display: inline-block;
}

.ant-btn-primary {
  color: #fff;
  text-shadow: 0 -1px #0000001f;
  background: #1890ff;
  border-color: #1890ff;
  box-shadow: 0 2px #0000000b;
}

.ant-btn-primary > a:only-child {
  color: currentColor;
}

.ant-btn-primary > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  color: #fff;
  background: #40a9ff;
  border-color: #40a9ff;
}

.ant-btn-primary:hover > a:only-child, .ant-btn-primary:focus > a:only-child {
  color: currentColor;
}

.ant-btn-primary:hover > a:only-child:after, .ant-btn-primary:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-primary:active {
  color: #fff;
  background: #096dd9;
  border-color: #096dd9;
}

.ant-btn-primary:active > a:only-child {
  color: currentColor;
}

.ant-btn-primary:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-btn-primary[disabled] > a:only-child, .ant-btn-primary[disabled]:hover > a:only-child, .ant-btn-primary[disabled]:focus > a:only-child, .ant-btn-primary[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-primary[disabled] > a:only-child:after, .ant-btn-primary[disabled]:hover > a:only-child:after, .ant-btn-primary[disabled]:focus > a:only-child:after, .ant-btn-primary[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-left-color: #40a9ff;
  border-right-color: #40a9ff;
}

.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #d9d9d9;
}

.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #40a9ff;
}

.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #d9d9d9;
}

.ant-btn-group .ant-btn-primary:last-child:not(:first-child), .ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: #40a9ff;
}

.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled], .ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: #d9d9d9;
}

.ant-btn-ghost {
  color: #000000d9;
  background: none;
  border-color: #d9d9d9;
}

.ant-btn-ghost > a:only-child {
  color: currentColor;
}

.ant-btn-ghost > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-ghost:hover, .ant-btn-ghost:focus {
  color: #40a9ff;
  background: none;
  border-color: #40a9ff;
}

.ant-btn-ghost:hover > a:only-child, .ant-btn-ghost:focus > a:only-child {
  color: currentColor;
}

.ant-btn-ghost:hover > a:only-child:after, .ant-btn-ghost:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-ghost:active {
  color: #096dd9;
  background: none;
  border-color: #096dd9;
}

.ant-btn-ghost:active > a:only-child {
  color: currentColor;
}

.ant-btn-ghost:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-ghost[disabled], .ant-btn-ghost[disabled]:hover, .ant-btn-ghost[disabled]:focus, .ant-btn-ghost[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-btn-ghost[disabled] > a:only-child, .ant-btn-ghost[disabled]:hover > a:only-child, .ant-btn-ghost[disabled]:focus > a:only-child, .ant-btn-ghost[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-ghost[disabled] > a:only-child:after, .ant-btn-ghost[disabled]:hover > a:only-child:after, .ant-btn-ghost[disabled]:focus > a:only-child:after, .ant-btn-ghost[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dashed {
  color: #000000d9;
  background: #fff;
  border-style: dashed;
  border-color: #d9d9d9;
}

.ant-btn-dashed > a:only-child {
  color: currentColor;
}

.ant-btn-dashed > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dashed:hover, .ant-btn-dashed:focus {
  color: #40a9ff;
  background: #fff;
  border-color: #40a9ff;
}

.ant-btn-dashed:hover > a:only-child, .ant-btn-dashed:focus > a:only-child {
  color: currentColor;
}

.ant-btn-dashed:hover > a:only-child:after, .ant-btn-dashed:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dashed:active {
  color: #096dd9;
  background: #fff;
  border-color: #096dd9;
}

.ant-btn-dashed:active > a:only-child {
  color: currentColor;
}

.ant-btn-dashed:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dashed[disabled], .ant-btn-dashed[disabled]:hover, .ant-btn-dashed[disabled]:focus, .ant-btn-dashed[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-btn-dashed[disabled] > a:only-child, .ant-btn-dashed[disabled]:hover > a:only-child, .ant-btn-dashed[disabled]:focus > a:only-child, .ant-btn-dashed[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-dashed[disabled] > a:only-child:after, .ant-btn-dashed[disabled]:hover > a:only-child:after, .ant-btn-dashed[disabled]:focus > a:only-child:after, .ant-btn-dashed[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-danger {
  color: #fff;
  text-shadow: 0 -1px #0000001f;
  background: #ff4d4f;
  border-color: #ff4d4f;
  box-shadow: 0 2px #0000000b;
}

.ant-btn-danger > a:only-child {
  color: currentColor;
}

.ant-btn-danger > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-danger:hover, .ant-btn-danger:focus {
  color: #fff;
  background: #ff7875;
  border-color: #ff7875;
}

.ant-btn-danger:hover > a:only-child, .ant-btn-danger:focus > a:only-child {
  color: currentColor;
}

.ant-btn-danger:hover > a:only-child:after, .ant-btn-danger:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-danger:active {
  color: #fff;
  background: #d9363e;
  border-color: #d9363e;
}

.ant-btn-danger:active > a:only-child {
  color: currentColor;
}

.ant-btn-danger:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-danger[disabled], .ant-btn-danger[disabled]:hover, .ant-btn-danger[disabled]:focus, .ant-btn-danger[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-btn-danger[disabled] > a:only-child, .ant-btn-danger[disabled]:hover > a:only-child, .ant-btn-danger[disabled]:focus > a:only-child, .ant-btn-danger[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-danger[disabled] > a:only-child:after, .ant-btn-danger[disabled]:hover > a:only-child:after, .ant-btn-danger[disabled]:focus > a:only-child:after, .ant-btn-danger[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-link {
  color: #1890ff;
  box-shadow: none;
  background: none;
  border-color: #0000;
}

.ant-btn-link > a:only-child {
  color: currentColor;
}

.ant-btn-link > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-link:hover, .ant-btn-link:focus {
  color: #40a9ff;
  background: none;
  border-color: #40a9ff;
}

.ant-btn-link:hover > a:only-child, .ant-btn-link:focus > a:only-child {
  color: currentColor;
}

.ant-btn-link:hover > a:only-child:after, .ant-btn-link:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-link:active {
  color: #096dd9;
  background: none;
  border-color: #096dd9;
}

.ant-btn-link:active > a:only-child {
  color: currentColor;
}

.ant-btn-link:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-link:hover {
  background: none;
}

.ant-btn-link:hover, .ant-btn-link:focus, .ant-btn-link:active {
  border-color: #0000;
}

.ant-btn-link[disabled], .ant-btn-link[disabled]:hover, .ant-btn-link[disabled]:focus, .ant-btn-link[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: none;
  border-color: #0000;
}

.ant-btn-link[disabled] > a:only-child, .ant-btn-link[disabled]:hover > a:only-child, .ant-btn-link[disabled]:focus > a:only-child, .ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-link[disabled] > a:only-child:after, .ant-btn-link[disabled]:hover > a:only-child:after, .ant-btn-link[disabled]:focus > a:only-child:after, .ant-btn-link[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-text {
  color: #000000d9;
  box-shadow: none;
  background: none;
  border-color: #0000;
}

.ant-btn-text > a:only-child {
  color: currentColor;
}

.ant-btn-text > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-text:hover > a:only-child, .ant-btn-text:focus > a:only-child {
  color: currentColor;
}

.ant-btn-text:hover > a:only-child:after, .ant-btn-text:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-text:active > a:only-child {
  color: currentColor;
}

.ant-btn-text:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-text:hover, .ant-btn-text:focus {
  color: #000000d9;
  background: #00000005;
  border-color: #0000;
}

.ant-btn-text:active {
  color: #000000d9;
  background: #00000007;
  border-color: #0000;
}

.ant-btn-text[disabled], .ant-btn-text[disabled]:hover, .ant-btn-text[disabled]:focus, .ant-btn-text[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: none;
  border-color: #0000;
}

.ant-btn-text[disabled] > a:only-child, .ant-btn-text[disabled]:hover > a:only-child, .ant-btn-text[disabled]:focus > a:only-child, .ant-btn-text[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-text[disabled] > a:only-child:after, .ant-btn-text[disabled]:hover > a:only-child:after, .ant-btn-text[disabled]:focus > a:only-child:after, .ant-btn-text[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous {
  color: #ff4d4f;
  background: #fff;
  border-color: #ff4d4f;
}

.ant-btn-dangerous > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff7875;
  background: #fff;
  border-color: #ff7875;
}

.ant-btn-dangerous:hover > a:only-child, .ant-btn-dangerous:focus > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous:hover > a:only-child:after, .ant-btn-dangerous:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous:active {
  color: #d9363e;
  background: #fff;
  border-color: #d9363e;
}

.ant-btn-dangerous:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous[disabled], .ant-btn-dangerous[disabled]:hover, .ant-btn-dangerous[disabled]:focus, .ant-btn-dangerous[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-btn-dangerous[disabled] > a:only-child, .ant-btn-dangerous[disabled]:hover > a:only-child, .ant-btn-dangerous[disabled]:focus > a:only-child, .ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous[disabled] > a:only-child:after, .ant-btn-dangerous[disabled]:hover > a:only-child:after, .ant-btn-dangerous[disabled]:focus > a:only-child:after, .ant-btn-dangerous[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous.ant-btn-primary {
  color: #fff;
  text-shadow: 0 -1px #0000001f;
  background: #ff4d4f;
  border-color: #ff4d4f;
  box-shadow: 0 2px #0000000b;
}

.ant-btn-dangerous.ant-btn-primary > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-primary > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous.ant-btn-primary:hover, .ant-btn-dangerous.ant-btn-primary:focus {
  color: #fff;
  background: #ff7875;
  border-color: #ff7875;
}

.ant-btn-dangerous.ant-btn-primary:hover > a:only-child, .ant-btn-dangerous.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-primary:hover > a:only-child:after, .ant-btn-dangerous.ant-btn-primary:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous.ant-btn-primary:active {
  color: #fff;
  background: #d9363e;
  border-color: #d9363e;
}

.ant-btn-dangerous.ant-btn-primary:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-primary:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous.ant-btn-primary[disabled], .ant-btn-dangerous.ant-btn-primary[disabled]:hover, .ant-btn-dangerous.ant-btn-primary[disabled]:focus, .ant-btn-dangerous.ant-btn-primary[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child, .ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child, .ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child, .ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child:after, .ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child:after, .ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child:after, .ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous.ant-btn-link {
  color: #ff4d4f;
  box-shadow: none;
  background: none;
  border-color: #0000;
}

.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-link > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous.ant-btn-link:hover, .ant-btn-dangerous.ant-btn-link:focus {
  color: #ff7875;
  background: none;
  border-color: #0000;
}

.ant-btn-dangerous.ant-btn-link:hover > a:only-child, .ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-link:hover > a:only-child:after, .ant-btn-dangerous.ant-btn-link:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous.ant-btn-link:active {
  color: #d9363e;
  background: none;
  border-color: #0000;
}

.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-link:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous.ant-btn-link[disabled], .ant-btn-dangerous.ant-btn-link[disabled]:hover, .ant-btn-dangerous.ant-btn-link[disabled]:focus, .ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: none;
  border-color: #0000;
}

.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child, .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child, .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child, .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child:after, .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child:after, .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child:after, .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous.ant-btn-text {
  color: #ff4d4f;
  box-shadow: none;
  background: none;
  border-color: #0000;
}

.ant-btn-dangerous.ant-btn-text > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-text > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous.ant-btn-text:hover, .ant-btn-dangerous.ant-btn-text:focus {
  color: #ff7875;
  background: #00000005;
  border-color: #0000;
}

.ant-btn-dangerous.ant-btn-text:hover > a:only-child, .ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-text:hover > a:only-child:after, .ant-btn-dangerous.ant-btn-text:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous.ant-btn-text:active {
  color: #d9363e;
  background: #00000007;
  border-color: #0000;
}

.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-text:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous.ant-btn-text[disabled], .ant-btn-dangerous.ant-btn-text[disabled]:hover, .ant-btn-dangerous.ant-btn-text[disabled]:focus, .ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: none;
  border-color: #0000;
}

.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child, .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child, .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child, .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child:after, .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child:after, .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child:after, .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-icon-only {
  vertical-align: -3px;
  border-radius: 2px;
  width: 32px;
  height: 32px;
  padding: 2.4px 0;
  font-size: 16px;
}

.ant-btn-icon-only > * {
  font-size: 16px;
}

.ant-btn-icon-only.ant-btn-lg {
  border-radius: 2px;
  width: 40px;
  height: 40px;
  padding: 4.9px 0;
  font-size: 18px;
}

.ant-btn-icon-only.ant-btn-lg > * {
  font-size: 18px;
}

.ant-btn-icon-only.ant-btn-sm {
  border-radius: 2px;
  width: 24px;
  height: 24px;
  padding: 0;
  font-size: 14px;
}

.ant-btn-icon-only.ant-btn-sm > * {
  font-size: 14px;
}

.ant-btn-icon-only > .anticon {
  justify-content: center;
  display: flex;
}

.ant-btn-icon-only .anticon-loading {
  padding: 0 !important;
}

a.ant-btn-icon-only {
  vertical-align: -1px;
}

a.ant-btn-icon-only > .anticon {
  display: inline;
}

.ant-btn-round {
  border-radius: 32px;
  height: 32px;
  padding: 4px 16px;
  font-size: 14px;
}

.ant-btn-round.ant-btn-lg {
  border-radius: 40px;
  height: 40px;
  padding: 6.4px 20px;
  font-size: 16px;
}

.ant-btn-round.ant-btn-sm {
  border-radius: 24px;
  height: 24px;
  padding: 0 12px;
  font-size: 14px;
}

.ant-btn-round.ant-btn-icon-only {
  width: auto;
}

.ant-btn-circle {
  text-align: center;
  border-radius: 50%;
  min-width: 32px;
  padding-left: 0;
  padding-right: 0;
}

.ant-btn-circle.ant-btn-lg {
  border-radius: 50%;
  min-width: 40px;
}

.ant-btn-circle.ant-btn-sm {
  border-radius: 50%;
  min-width: 24px;
}

.ant-btn:before {
  z-index: 1;
  border-radius: inherit;
  opacity: .35;
  content: "";
  pointer-events: none;
  background: #fff;
  transition: opacity .2s;
  display: none;
  position: absolute;
  inset: -1px;
}

.ant-btn .anticon {
  transition: margin-left .3s cubic-bezier(.645, .045, .355, 1);
}

.ant-btn .anticon.anticon-plus > svg, .ant-btn .anticon.anticon-minus > svg {
  shape-rendering: optimizespeed;
}

.ant-btn.ant-btn-loading {
  cursor: default;
  position: relative;
}

.ant-btn.ant-btn-loading:before {
  display: block;
}

.ant-btn > .ant-btn-loading-icon {
  transition: width .3s cubic-bezier(.645, .045, .355, 1), opacity .3s cubic-bezier(.645, .045, .355, 1);
}

.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 8px;
  animation: none;
}

.ant-btn > .ant-btn-loading-icon .anticon svg {
  animation: 1s linear infinite loadingCircle;
}

.ant-btn-group {
  display: inline-flex;
  position: relative;
}

.ant-btn-group > .ant-btn, .ant-btn-group > span > .ant-btn {
  position: relative;
}

.ant-btn-group > .ant-btn:hover, .ant-btn-group > span > .ant-btn:hover, .ant-btn-group > .ant-btn:focus, .ant-btn-group > span > .ant-btn:focus, .ant-btn-group > .ant-btn:active, .ant-btn-group > span > .ant-btn:active {
  z-index: 2;
}

.ant-btn-group > .ant-btn[disabled], .ant-btn-group > span > .ant-btn[disabled] {
  z-index: 0;
}

.ant-btn-group .ant-btn-icon-only {
  font-size: 14px;
}

.ant-btn-group .ant-btn + .ant-btn, .ant-btn + .ant-btn-group, .ant-btn-group span + .ant-btn, .ant-btn-group .ant-btn + span, .ant-btn-group > span + span, .ant-btn-group + .ant-btn, .ant-btn-group + .ant-btn-group {
  margin-left: -1px;
}

.ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: #0000;
}

.ant-btn-group .ant-btn {
  border-radius: 0;
}

.ant-btn-group > .ant-btn:first-child, .ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0;
}

.ant-btn-group > .ant-btn:only-child, .ant-btn-group > span:only-child > .ant-btn {
  border-radius: 2px;
}

.ant-btn-group > .ant-btn:first-child:not(:last-child), .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.ant-btn-group > .ant-btn:last-child:not(:first-child), .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.ant-btn-group-sm > .ant-btn:only-child, .ant-btn-group-sm > span:only-child > .ant-btn {
  border-radius: 2px;
}

.ant-btn-group-sm > .ant-btn:first-child:not(:last-child), .ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.ant-btn-group-sm > .ant-btn:last-child:not(:first-child), .ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.ant-btn-group > .ant-btn-group {
  float: left;
}

.ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0;
}

.ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: 8px;
}

.ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 8px;
}

.ant-btn-rtl.ant-btn-group .ant-btn + .ant-btn, .ant-btn-rtl.ant-btn + .ant-btn-group, .ant-btn-rtl.ant-btn-group span + .ant-btn, .ant-btn-rtl.ant-btn-group .ant-btn + span, .ant-btn-rtl.ant-btn-group > span + span, .ant-btn-rtl.ant-btn-group + .ant-btn, .ant-btn-rtl.ant-btn-group + .ant-btn-group, .ant-btn-group-rtl.ant-btn-group .ant-btn + .ant-btn, .ant-btn-group-rtl.ant-btn + .ant-btn-group, .ant-btn-group-rtl.ant-btn-group span + .ant-btn, .ant-btn-group-rtl.ant-btn-group .ant-btn + span, .ant-btn-group-rtl.ant-btn-group > span + span, .ant-btn-group-rtl.ant-btn-group + .ant-btn, .ant-btn-group-rtl.ant-btn-group + .ant-btn-group {
  margin-left: auto;
  margin-right: -1px;
}

.ant-btn-group.ant-btn-group-rtl {
  direction: rtl;
}

.ant-btn-group-rtl.ant-btn-group > .ant-btn:first-child:not(:last-child), .ant-btn-group-rtl.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-radius: 0 2px 2px 0;
}

.ant-btn-group-rtl.ant-btn-group > .ant-btn:last-child:not(:first-child), .ant-btn-group-rtl.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-radius: 2px 0 0 2px;
}

.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:first-child:not(:last-child), .ant-btn-group-rtl.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-radius: 0 2px 2px 0;
}

.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:last-child:not(:first-child), .ant-btn-group-rtl.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-radius: 2px 0 0 2px;
}

.ant-btn:focus > span, .ant-btn:active > span {
  position: relative;
}

.ant-btn > .anticon + span, .ant-btn > span + .anticon {
  margin-left: 8px;
}

.ant-btn.ant-btn-background-ghost {
  color: #fff;
  border-color: #fff;
}

.ant-btn.ant-btn-background-ghost, .ant-btn.ant-btn-background-ghost:hover, .ant-btn.ant-btn-background-ghost:active, .ant-btn.ant-btn-background-ghost:focus {
  background: none;
}

.ant-btn.ant-btn-background-ghost:hover, .ant-btn.ant-btn-background-ghost:focus {
  color: #40a9ff;
  border-color: #40a9ff;
}

.ant-btn.ant-btn-background-ghost:active {
  color: #096dd9;
  border-color: #096dd9;
}

.ant-btn.ant-btn-background-ghost[disabled] {
  color: #00000040;
  background: none;
  border-color: #d9d9d9;
}

.ant-btn-background-ghost.ant-btn-primary {
  color: #1890ff;
  text-shadow: none;
  border-color: #1890ff;
}

.ant-btn-background-ghost.ant-btn-primary > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-primary > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-primary:hover, .ant-btn-background-ghost.ant-btn-primary:focus {
  color: #40a9ff;
  border-color: #40a9ff;
}

.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child, .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child:after, .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-primary:active {
  color: #096dd9;
  border-color: #096dd9;
}

.ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-primary:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-primary[disabled], .ant-btn-background-ghost.ant-btn-primary[disabled]:hover, .ant-btn-background-ghost.ant-btn-primary[disabled]:focus, .ant-btn-background-ghost.ant-btn-primary[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child, .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child, .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child, .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child:after, .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child:after, .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child:after, .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-danger {
  color: #ff4d4f;
  text-shadow: none;
  border-color: #ff4d4f;
}

.ant-btn-background-ghost.ant-btn-danger > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-danger > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-danger:hover, .ant-btn-background-ghost.ant-btn-danger:focus {
  color: #ff7875;
  border-color: #ff7875;
}

.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child, .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child:after, .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-danger:active {
  color: #d9363e;
  border-color: #d9363e;
}

.ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-danger:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-danger[disabled], .ant-btn-background-ghost.ant-btn-danger[disabled]:hover, .ant-btn-background-ghost.ant-btn-danger[disabled]:focus, .ant-btn-background-ghost.ant-btn-danger[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child, .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child, .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child, .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child:after, .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child:after, .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child:after, .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-dangerous {
  color: #ff4d4f;
  text-shadow: none;
  border-color: #ff4d4f;
}

.ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-dangerous:hover, .ant-btn-background-ghost.ant-btn-dangerous:focus {
  color: #ff7875;
  border-color: #ff7875;
}

.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child, .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child:after, .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-dangerous:active {
  color: #d9363e;
  border-color: #d9363e;
}

.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-dangerous[disabled], .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover, .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus, .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child, .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child, .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child, .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child:after, .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child:after, .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child:after, .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
  color: #ff4d4f;
  text-shadow: none;
  border-color: #0000;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff7875;
  border-color: #0000;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child:after, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
  color: #d9363e;
  border-color: #0000;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled], .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child:after, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child:after, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child:after, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-two-chinese-chars:first-letter {
  letter-spacing: .34em;
}

.ant-btn-two-chinese-chars > :not(.anticon) {
  letter-spacing: .34em;
  margin-right: -.34em;
}

.ant-btn.ant-btn-block {
  width: 100%;
}

.ant-btn:empty {
  visibility: hidden;
  content: " ";
  width: 0;
  display: inline-block;
}

a.ant-btn {
  line-height: 30px;
  padding-top: .01px !important;
}

a.ant-btn-disabled {
  cursor: not-allowed;
}

a.ant-btn-disabled > * {
  pointer-events: none;
}

a.ant-btn-disabled, a.ant-btn-disabled:hover, a.ant-btn-disabled:focus, a.ant-btn-disabled:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: none;
  border-color: #0000;
}

a.ant-btn-disabled > a:only-child, a.ant-btn-disabled:hover > a:only-child, a.ant-btn-disabled:focus > a:only-child, a.ant-btn-disabled:active > a:only-child {
  color: currentColor;
}

a.ant-btn-disabled > a:only-child:after, a.ant-btn-disabled:hover > a:only-child:after, a.ant-btn-disabled:focus > a:only-child:after, a.ant-btn-disabled:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

a.ant-btn-lg {
  line-height: 38px;
}

a.ant-btn-sm {
  line-height: 22px;
}

.ant-btn-compact-item:not(.ant-btn-compact-last-item):not(.ant-btn-compact-item-rtl) {
  margin-right: -1px;
}

.ant-btn-compact-item:not(.ant-btn-compact-last-item).ant-btn-compact-item-rtl {
  margin-left: -1px;
}

.ant-btn-compact-item:hover, .ant-btn-compact-item:focus, .ant-btn-compact-item:active {
  z-index: 2;
}

.ant-btn-compact-item[disabled] {
  z-index: 0;
}

.ant-btn-compact-item:not(.ant-btn-compact-first-item):not(.ant-btn-compact-last-item).ant-btn {
  border-radius: 0;
}

.ant-btn-compact-item.ant-btn.ant-btn-compact-first-item:not(.ant-btn-compact-last-item):not(.ant-btn-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-btn-compact-item.ant-btn.ant-btn-compact-last-item:not(.ant-btn-compact-first-item):not(.ant-btn-compact-item-rtl), .ant-btn-compact-item.ant-btn.ant-btn-compact-item-rtl.ant-btn-compact-first-item:not(.ant-btn-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-btn-compact-item.ant-btn.ant-btn-compact-item-rtl.ant-btn-compact-last-item:not(.ant-btn-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-btn-icon-only.ant-btn-compact-item {
  flex: none;
}

.ant-btn-compact-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-item.ant-btn-primary:not([disabled]) {
  position: relative;
}

.ant-btn-compact-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-item.ant-btn-primary:not([disabled]):after {
  content: " ";
  background-color: #40a9ff;
  width: 1px;
  height: calc(100% + 2px);
  display: inline-block;
  position: absolute;
  top: -1px;
  left: -1px;
}

.ant-btn-compact-item-rtl.ant-btn-compact-first-item.ant-btn-compact-item-rtl:not(.ant-btn-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-btn-compact-item-rtl.ant-btn-compact-last-item.ant-btn-compact-item-rtl:not(.ant-btn-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-btn-compact-item-rtl.ant-btn-sm.ant-btn-compact-first-item.ant-btn-compact-item-rtl.ant-btn-sm:not(.ant-btn-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-btn-compact-item-rtl.ant-btn-sm.ant-btn-compact-last-item.ant-btn-compact-item-rtl.ant-btn-sm:not(.ant-btn-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-btn-compact-item-rtl.ant-btn-primary:not([disabled]) + .ant-btn-compact-item-rtl.ant-btn-primary:not([disabled]):after {
  right: -1px;
}

.ant-btn-compact-vertical-item:not(.ant-btn-compact-vertical-last-item) {
  margin-bottom: -1px;
}

.ant-btn-compact-vertical-item:hover, .ant-btn-compact-vertical-item:focus, .ant-btn-compact-vertical-item:active {
  z-index: 2;
}

.ant-btn-compact-vertical-item[disabled] {
  z-index: 0;
}

.ant-btn-compact-vertical-item:not(.ant-btn-compact-vertical-first-item):not(.ant-btn-compact-vertical-last-item) {
  border-radius: 0;
}

.ant-btn-compact-vertical-item.ant-btn-compact-vertical-first-item:not(.ant-btn-compact-vertical-last-item) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-btn-compact-vertical-item.ant-btn-compact-vertical-last-item:not(.ant-btn-compact-vertical-first-item) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.ant-btn-compact-vertical-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-vertical-item.ant-btn-primary:not([disabled]) {
  position: relative;
}

.ant-btn-compact-vertical-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-vertical-item.ant-btn-primary:not([disabled]):after {
  content: " ";
  background-color: #40a9ff;
  width: calc(100% + 2px);
  height: 1px;
  display: inline-block;
  position: absolute;
  top: -1px;
  left: -1px;
}

.ant-btn-rtl {
  direction: rtl;
}

.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child), .ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: #d9d9d9;
  border-right-color: #40a9ff;
}

.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled], .ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: #40a9ff;
  border-right-color: #d9d9d9;
}

.ant-btn-rtl.ant-btn > .ant-btn-loading-icon .anticon {
  padding-left: 8px;
  padding-right: 0;
}

.ant-btn-rtl.ant-btn > .anticon + span, .ant-btn-rtl.ant-btn > span + .anticon {
  margin-left: 0;
  margin-right: 8px;
}

.ant-menu-item-danger.ant-menu-item, .ant-menu-item-danger.ant-menu-item:hover, .ant-menu-item-danger.ant-menu-item-active {
  color: #ff4d4f;
}

.ant-menu-item-danger.ant-menu-item:active {
  background: #fff1f0;
}

.ant-menu-item-danger.ant-menu-item-selected, .ant-menu-item-danger.ant-menu-item-selected > a, .ant-menu-item-danger.ant-menu-item-selected > a:hover {
  color: #ff4d4f;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  background-color: #fff1f0;
}

.ant-menu-inline .ant-menu-item-danger.ant-menu-item:after {
  border-right-color: #ff4d4f;
}

.ant-menu-dark .ant-menu-item-danger.ant-menu-item, .ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover, .ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
  color: #ff4d4f;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  color: #fff;
  background-color: #ff4d4f;
}

.ant-menu {
  box-sizing: border-box;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  color: #000000d9;
  text-align: left;
  background: #fff;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 0;
  list-style: none;
  transition: background .3s, width .3s cubic-bezier(.2, 0, 0, 1);
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
}

.ant-menu:before {
  content: "";
  display: table;
}

.ant-menu:after {
  clear: both;
  content: "";
  display: table;
}

.ant-menu ul, .ant-menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ant-menu-overflow {
  display: flex;
}

.ant-menu-overflow-item {
  flex: none;
}

.ant-menu-hidden, .ant-menu-submenu-hidden {
  display: none;
}

.ant-menu-item-group-title {
  color: #00000073;
  height: 1.5715px;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 1.5715;
  transition: all .3s;
}

.ant-menu-horizontal .ant-menu-submenu {
  transition: border-color .3s cubic-bezier(.645, .045, .355, 1), background .3s cubic-bezier(.645, .045, .355, 1);
}

.ant-menu-submenu, .ant-menu-submenu-inline {
  transition: border-color .3s cubic-bezier(.645, .045, .355, 1), background .3s cubic-bezier(.645, .045, .355, 1), padding .15s cubic-bezier(.645, .045, .355, 1);
}

.ant-menu-submenu-selected {
  color: #1890ff;
}

.ant-menu-item:active, .ant-menu-submenu-title:active {
  background: #e6f7ff;
}

.ant-menu-submenu .ant-menu-sub {
  cursor: initial;
  transition: background .3s cubic-bezier(.645, .045, .355, 1), padding .3s cubic-bezier(.645, .045, .355, 1);
}

.ant-menu-title-content {
  transition: color .3s;
}

.ant-menu-item a {
  color: #000000d9;
}

.ant-menu-item a:hover {
  color: #1890ff;
}

.ant-menu-item a:before {
  content: "";
  background-color: #0000;
  position: absolute;
  inset: 0;
}

.ant-menu-item > .ant-badge a {
  color: #000000d9;
}

.ant-menu-item > .ant-badge a:hover {
  color: #1890ff;
}

.ant-menu-item-divider {
  border: 0 solid #f0f0f0;
  border-top-width: 1px;
  line-height: 0;
  overflow: hidden;
}

.ant-menu-item-divider-dashed {
  border-style: dashed;
}

.ant-menu-horizontal .ant-menu-item, .ant-menu-horizontal .ant-menu-submenu {
  margin-top: -1px;
}

.ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: #0000;
}

.ant-menu-item-selected, .ant-menu-item-selected a, .ant-menu-item-selected a:hover {
  color: #1890ff;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #e6f7ff;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border-right: 1px solid #f0f0f0;
}

.ant-menu-vertical-right {
  border-left: 1px solid #f0f0f0;
}

.ant-menu-vertical.ant-menu-sub, .ant-menu-vertical-left.ant-menu-sub, .ant-menu-vertical-right.ant-menu-sub {
  border-right: 0;
  min-width: 160px;
  max-height: calc(100vh - 100px);
  padding: 0;
  overflow: hidden;
}

.ant-menu-vertical.ant-menu-sub:not([class*="-active"]), .ant-menu-vertical-left.ant-menu-sub:not([class*="-active"]), .ant-menu-vertical-right.ant-menu-sub:not([class*="-active"]) {
  overflow: hidden auto;
}

.ant-menu-vertical.ant-menu-sub .ant-menu-item, .ant-menu-vertical-left.ant-menu-sub .ant-menu-item, .ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
  border-right: 0;
  margin-left: 0;
  left: 0;
}

.ant-menu-vertical.ant-menu-sub .ant-menu-item:after, .ant-menu-vertical-left.ant-menu-sub .ant-menu-item:after, .ant-menu-vertical-right.ant-menu-sub .ant-menu-item:after {
  border-right: 0;
}

.ant-menu-vertical.ant-menu-sub > .ant-menu-item, .ant-menu-vertical-left.ant-menu-sub > .ant-menu-item, .ant-menu-vertical-right.ant-menu-sub > .ant-menu-item, .ant-menu-vertical.ant-menu-sub > .ant-menu-submenu, .ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu, .ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: 0 0;
}

.ant-menu-horizontal.ant-menu-sub {
  min-width: 114px;
}

.ant-menu-horizontal .ant-menu-item, .ant-menu-horizontal .ant-menu-submenu-title {
  transition: border-color .3s, background .3s;
}

.ant-menu-item, .ant-menu-submenu-title {
  white-space: nowrap;
  cursor: pointer;
  margin: 0;
  padding: 0 20px;
  transition: border-color .3s, background .3s, padding .3s cubic-bezier(.645, .045, .355, 1);
  display: block;
  position: relative;
}

.ant-menu-item .ant-menu-item-icon, .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu-item .anticon, .ant-menu-submenu-title .anticon {
  min-width: 14px;
  font-size: 14px;
  transition: font-size .15s cubic-bezier(.215, .61, .355, 1), margin .3s cubic-bezier(.645, .045, .355, 1), color .3s;
}

.ant-menu-item .ant-menu-item-icon + span, .ant-menu-submenu-title .ant-menu-item-icon + span, .ant-menu-item .anticon + span, .ant-menu-submenu-title .anticon + span {
  opacity: 1;
  margin-left: 10px;
  transition: opacity .3s cubic-bezier(.645, .045, .355, 1), margin .3s, color .3s;
}

.ant-menu-item .ant-menu-item-icon.svg, .ant-menu-submenu-title .ant-menu-item-icon.svg {
  vertical-align: -.125em;
}

.ant-menu-item.ant-menu-item-only-child > .anticon, .ant-menu-submenu-title.ant-menu-item-only-child > .anticon, .ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon, .ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon {
  margin-right: 0;
}

.ant-menu-item:not(.ant-menu-item-disabled):focus-visible, .ant-menu-submenu-title:not(.ant-menu-item-disabled):focus-visible {
  box-shadow: 0 0 0 2px #bae7ff;
}

.ant-menu > .ant-menu-item-divider {
  margin: 1px 0;
  padding: 0;
}

.ant-menu-submenu-popup {
  z-index: 1050;
  box-shadow: none;
  transform-origin: 0 0;
  background: none;
  border-radius: 2px;
  position: absolute;
}

.ant-menu-submenu-popup:before {
  z-index: -1;
  opacity: .0001;
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  inset: -7px 0 0;
}

.ant-menu-submenu-placement-rightTop:before {
  top: 0;
  left: -7px;
}

.ant-menu-submenu > .ant-menu {
  background-color: #fff;
  border-radius: 2px;
}

.ant-menu-submenu > .ant-menu-submenu-title:after {
  transition: transform .3s cubic-bezier(.645, .045, .355, 1);
}

.ant-menu-submenu-popup > .ant-menu {
  background-color: #fff;
}

.ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow {
  color: #000000d9;
  width: 10px;
  transition: transform .3s cubic-bezier(.645, .045, .355, 1);
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.ant-menu-submenu-arrow:before, .ant-menu-submenu-arrow:after {
  content: "";
  background-color: currentColor;
  border-radius: 2px;
  width: 6px;
  height: 1.5px;
  transition: background .3s cubic-bezier(.645, .045, .355, 1), transform .3s cubic-bezier(.645, .045, .355, 1), top .3s cubic-bezier(.645, .045, .355, 1), color .3s cubic-bezier(.645, .045, .355, 1);
  position: absolute;
}

.ant-menu-submenu-arrow:before {
  transform: rotate(45deg)translateY(-2.5px);
}

.ant-menu-submenu-arrow:after {
  transform: rotate(-45deg)translateY(2.5px);
}

.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #1890ff;
}

.ant-menu-inline-collapsed .ant-menu-submenu-arrow:before, .ant-menu-submenu-inline .ant-menu-submenu-arrow:before {
  transform: rotate(-45deg)translateX(2.5px);
}

.ant-menu-inline-collapsed .ant-menu-submenu-arrow:after, .ant-menu-submenu-inline .ant-menu-submenu-arrow:after {
  transform: rotate(45deg)translateX(-2.5px);
}

.ant-menu-submenu-horizontal .ant-menu-submenu-arrow {
  display: none;
}

.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  transform: translateY(-2px);
}

.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after {
  transform: rotate(-45deg)translateX(-2.5px);
}

.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before {
  transform: rotate(45deg)translateX(2.5px);
}

.ant-menu-vertical .ant-menu-submenu-selected, .ant-menu-vertical-left .ant-menu-submenu-selected, .ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #1890ff;
}

.ant-menu-horizontal {
  box-shadow: none;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  line-height: 46px;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin-top: -1px;
  margin-bottom: 0;
  padding: 0 20px;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #1890ff;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover:after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover:after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active:after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active:after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open:after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open:after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected:after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected:after {
  border-bottom: 2px solid #1890ff;
}

.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
  vertical-align: bottom;
  display: inline-block;
  position: relative;
  top: 1px;
}

.ant-menu-horizontal > .ant-menu-item:after, .ant-menu-horizontal > .ant-menu-submenu:after {
  content: "";
  border-bottom: 2px solid #0000;
  transition: border-color .3s cubic-bezier(.645, .045, .355, 1);
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 20px;
}

.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0;
}

.ant-menu-horizontal > .ant-menu-item a {
  color: #000000d9;
}

.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #1890ff;
}

.ant-menu-horizontal > .ant-menu-item a:before {
  bottom: -2px;
}

.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #1890ff;
}

.ant-menu-horizontal:after {
  clear: both;
  content: " ";
  height: 0;
  display: block;
}

.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item {
  position: relative;
}

.ant-menu-vertical .ant-menu-item:after, .ant-menu-vertical-left .ant-menu-item:after, .ant-menu-vertical-right .ant-menu-item:after, .ant-menu-inline .ant-menu-item:after {
  opacity: 0;
  content: "";
  border-right: 3px solid #1890ff;
  transition: transform .15s cubic-bezier(.215, .61, .355, 1), opacity .15s cubic-bezier(.215, .61, .355, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  transform: scaleY(.0001);
}

.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title {
  text-overflow: ellipsis;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  line-height: 40px;
  overflow: hidden;
}

.ant-menu-vertical .ant-menu-submenu, .ant-menu-vertical-left .ant-menu-submenu, .ant-menu-vertical-right .ant-menu-submenu, .ant-menu-inline .ant-menu-submenu {
  padding-bottom: .02px;
}

.ant-menu-vertical .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
}

.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
}

.ant-menu-vertical .ant-menu-item-group-list .ant-menu-submenu-title, .ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 34px;
}

.ant-menu-inline {
  width: 100%;
}

.ant-menu-inline .ant-menu-selected:after, .ant-menu-inline .ant-menu-item-selected:after {
  opacity: 1;
  transition: transform .15s cubic-bezier(.645, .045, .355, 1), opacity .15s cubic-bezier(.645, .045, .355, 1);
  transform: scaleY(1);
}

.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 1px);
}

.ant-menu-inline .ant-menu-item-group-list .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
}

.ant-menu-inline.ant-menu-root .ant-menu-item, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  align-items: center;
  transition: border-color .3s, background .3s, padding .1s cubic-bezier(.215, .61, .355, 1);
  display: flex;
}

.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
  text-overflow: ellipsis;
  flex: auto;
  min-width: 0;
  overflow: hidden;
}

.ant-menu-inline.ant-menu-root .ant-menu-item > *, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > * {
  flex: none;
}

.ant-menu.ant-menu-inline-collapsed {
  width: 80px;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  text-overflow: clip;
  padding: 0 calc(50% - 8px);
  left: 0;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow, .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon, .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  margin: 0;
  font-size: 16px;
  line-height: 40px;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon + span, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span, .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon + span, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span, .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
  opacity: 0;
  display: inline-block;
}

.ant-menu.ant-menu-inline-collapsed .ant-menu-item-icon, .ant-menu.ant-menu-inline-collapsed .anticon {
  display: inline-block;
}

.ant-menu.ant-menu-inline-collapsed-tooltip {
  pointer-events: none;
}

.ant-menu.ant-menu-inline-collapsed-tooltip .ant-menu-item-icon, .ant-menu.ant-menu-inline-collapsed-tooltip .anticon {
  display: none;
}

.ant-menu.ant-menu-inline-collapsed-tooltip a {
  color: #ffffffd9;
}

.ant-menu.ant-menu-inline-collapsed .ant-menu-item-group-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 4px;
  padding-right: 4px;
  overflow: hidden;
}

.ant-menu-item-group-list {
  margin: 0;
  padding: 0;
}

.ant-menu-item-group-list .ant-menu-item, .ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}

.ant-menu-root.ant-menu-vertical, .ant-menu-root.ant-menu-vertical-left, .ant-menu-root.ant-menu-vertical-right, .ant-menu-root.ant-menu-inline {
  box-shadow: none;
}

.ant-menu-root.ant-menu-inline-collapsed .ant-menu-item > .ant-menu-inline-collapsed-noicon, .ant-menu-root.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title > .ant-menu-inline-collapsed-noicon {
  text-align: center;
  font-size: 16px;
}

.ant-menu-sub.ant-menu-inline {
  box-shadow: none;
  background: #fafafa;
  border: 0;
  border-radius: 0;
  padding: 0;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  list-style-type: disc;
  list-style-position: inside;
}

.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 32px;
}

.ant-menu-item-disabled, .ant-menu-submenu-disabled {
  cursor: not-allowed;
  background: none;
  color: #00000040 !important;
}

.ant-menu-item-disabled:after, .ant-menu-submenu-disabled:after {
  border-color: #0000 !important;
}

.ant-menu-item-disabled a, .ant-menu-submenu-disabled a {
  pointer-events: none;
  color: #00000040 !important;
}

.ant-menu-item-disabled > .ant-menu-submenu-title, .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  cursor: not-allowed;
  color: #00000040 !important;
}

.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before, .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before, .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after, .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after {
  background: #00000040 !important;
}

.ant-layout-header .ant-menu {
  line-height: inherit;
}

.ant-menu-inline-collapsed-tooltip a, .ant-menu-inline-collapsed-tooltip a:hover {
  color: #fff;
}

.ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover {
  color: #1890ff;
}

.ant-menu.ant-menu-root:focus-visible, .ant-menu-dark .ant-menu-item:focus-visible, .ant-menu-dark .ant-menu-submenu-title:focus-visible {
  box-shadow: 0 0 0 2px #096dd9;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
  color: #ffffffa6;
  background: #001529;
}

.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow, .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow, .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: .45;
  transition: all .3s;
}

.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:after, .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:after, .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:after, .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:before, .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:before, .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  background: #fff;
}

.ant-menu-dark.ant-menu-submenu-popup {
  background: none;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #000c17;
}

.ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item, .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  border-color: #001529 #001529 currentColor;
  border-bottom-style: none;
  border-bottom-width: 0;
  margin-top: 0;
  padding: 0 20px;
  top: 0;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: #1890ff;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a:before {
  bottom: 0;
}

.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a, .ant-menu-dark .ant-menu-item > span > a {
  color: #ffffffa6;
}

.ant-menu-dark.ant-menu-inline, .ant-menu-dark.ant-menu-vertical, .ant-menu-dark.ant-menu-vertical-left, .ant-menu-dark.ant-menu-vertical-right {
  border-right: 0;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item, .ant-menu-dark.ant-menu-vertical .ant-menu-item, .ant-menu-dark.ant-menu-vertical-left .ant-menu-item, .ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  border-right: 0;
  margin-left: 0;
  left: 0;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item:after, .ant-menu-dark.ant-menu-vertical .ant-menu-item:after, .ant-menu-dark.ant-menu-vertical-left .ant-menu-item:after, .ant-menu-dark.ant-menu-vertical-right .ant-menu-item:after {
  border-right: 0;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item, .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}

.ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-item-active, .ant-menu-dark .ant-menu-submenu-active, .ant-menu-dark .ant-menu-submenu-open, .ant-menu-dark .ant-menu-submenu-selected, .ant-menu-dark .ant-menu-submenu-title:hover {
  color: #fff;
  background-color: #0000;
}

.ant-menu-dark .ant-menu-item:hover > a, .ant-menu-dark .ant-menu-item-active > a, .ant-menu-dark .ant-menu-submenu-active > a, .ant-menu-dark .ant-menu-submenu-open > a, .ant-menu-dark .ant-menu-submenu-selected > a, .ant-menu-dark .ant-menu-submenu-title:hover > a, .ant-menu-dark .ant-menu-item:hover > span > a, .ant-menu-dark .ant-menu-item-active > span > a, .ant-menu-dark .ant-menu-submenu-active > span > a, .ant-menu-dark .ant-menu-submenu-open > span > a, .ant-menu-dark .ant-menu-submenu-selected > span > a, .ant-menu-dark .ant-menu-submenu-title:hover > span > a {
  color: #fff;
}

.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow, .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow, .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow, .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow, .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow, .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  opacity: 1;
}

.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after, .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after, .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after, .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after, .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after, .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after, .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before, .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before, .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before, .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before, .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before, .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before {
  background: #fff;
}

.ant-menu-dark .ant-menu-item:hover {
  background-color: #0000;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #1890ff;
}

.ant-menu-dark .ant-menu-item-selected {
  color: #fff;
  border-right: 0;
}

.ant-menu-dark .ant-menu-item-selected:after {
  border-right: 0;
}

.ant-menu-dark .ant-menu-item-selected > a, .ant-menu-dark .ant-menu-item-selected > span > a, .ant-menu-dark .ant-menu-item-selected > a:hover, .ant-menu-dark .ant-menu-item-selected > span > a:hover, .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon, .ant-menu-dark .ant-menu-item-selected .anticon, .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span, .ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #fff;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #1890ff;
}

.ant-menu-dark .ant-menu-item-disabled, .ant-menu-dark .ant-menu-submenu-disabled, .ant-menu-dark .ant-menu-item-disabled > a, .ant-menu-dark .ant-menu-submenu-disabled > a, .ant-menu-dark .ant-menu-item-disabled > span > a, .ant-menu-dark .ant-menu-submenu-disabled > span > a {
  opacity: .8;
  color: #ffffff59 !important;
}

.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title, .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: #ffffff59 !important;
}

.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before, .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before, .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after, .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after {
  background: #ffffff59 !important;
}

.ant-menu.ant-menu-rtl {
  text-align: right;
  direction: rtl;
}

.ant-menu-rtl .ant-menu-item-group-title {
  text-align: right;
}

.ant-menu-rtl.ant-menu-inline, .ant-menu-rtl.ant-menu-vertical {
  border-left: 1px solid #f0f0f0;
  border-right: none;
}

.ant-menu-rtl.ant-menu-dark.ant-menu-inline, .ant-menu-rtl.ant-menu-dark.ant-menu-vertical {
  border-left: none;
}

.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-item, .ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item, .ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item, .ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu, .ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu, .ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: 100% 0;
}

.ant-menu-rtl .ant-menu-item .ant-menu-item-icon, .ant-menu-rtl .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu-rtl .ant-menu-item .anticon, .ant-menu-rtl .ant-menu-submenu-title .anticon {
  margin-left: 10px;
  margin-right: auto;
}

.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon, .ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon, .ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .anticon, .ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .anticon {
  margin-left: 0;
}

.ant-menu-submenu-rtl.ant-menu-submenu-popup {
  transform-origin: 100% 0;
}

.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow, .ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow, .ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow, .ant-menu-rtl .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  left: 16px;
  right: auto;
}

.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:before, .ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:before, .ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  transform: rotate(-45deg)translateY(-2px);
}

.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:after, .ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:after, .ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
  transform: rotate(45deg)translateY(2px);
}

.ant-menu-rtl.ant-menu-vertical .ant-menu-item:after, .ant-menu-rtl.ant-menu-vertical-left .ant-menu-item:after, .ant-menu-rtl.ant-menu-vertical-right .ant-menu-item:after, .ant-menu-rtl.ant-menu-inline .ant-menu-item:after {
  left: 0;
  right: auto;
}

.ant-menu-rtl.ant-menu-vertical .ant-menu-item, .ant-menu-rtl.ant-menu-vertical-left .ant-menu-item, .ant-menu-rtl.ant-menu-vertical-right .ant-menu-item, .ant-menu-rtl.ant-menu-inline .ant-menu-item, .ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title, .ant-menu-rtl.ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-rtl.ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  text-align: right;
}

.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  padding-left: 34px;
  padding-right: 0;
}

.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title {
  padding-left: 34px;
  padding-right: 16px;
}

.ant-menu-rtl.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-submenu-title {
  padding: 0 calc(50% - 8px);
}

.ant-menu-rtl .ant-menu-item-group-list .ant-menu-item, .ant-menu-rtl .ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 28px 0 16px;
}

.ant-menu-sub.ant-menu-inline {
  border: 0;
}

.ant-menu-rtl.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 0;
  padding-right: 32px;
}

.ant-tooltip {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  z-index: 1070;
  width: intrinsic;
  visibility: visible;
  width: max-content;
  max-width: 250px;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: block;
  position: absolute;
}

.ant-tooltip-content {
  position: relative;
}

.ant-tooltip-hidden {
  display: none;
}

.ant-tooltip-placement-top, .ant-tooltip-placement-topLeft, .ant-tooltip-placement-topRight {
  padding-bottom: 14.3137px;
}

.ant-tooltip-placement-right, .ant-tooltip-placement-rightTop, .ant-tooltip-placement-rightBottom {
  padding-left: 14.3137px;
}

.ant-tooltip-placement-bottom, .ant-tooltip-placement-bottomLeft, .ant-tooltip-placement-bottomRight {
  padding-top: 14.3137px;
}

.ant-tooltip-placement-left, .ant-tooltip-placement-leftTop, .ant-tooltip-placement-leftBottom {
  padding-right: 14.3137px;
}

.ant-tooltip-inner {
  color: #fff;
  text-align: left;
  word-wrap: break-word;
  background-color: #000000bf;
  border-radius: 2px;
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  text-decoration: none;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
}

.ant-tooltip-arrow {
  z-index: 2;
  pointer-events: none;
  background: none;
  width: 22px;
  height: 22px;
  display: block;
  position: absolute;
  overflow: hidden;
}

.ant-tooltip-arrow-content {
  --antd-arrow-background-color: linear-gradient(to right bottom, #000000a6, #000000bf);
  content: "";
  pointer-events: auto;
  pointer-events: none;
  border-radius: 0 0 2px;
  width: 11.3137px;
  height: 11.3137px;
  margin: auto;
  display: block;
  position: absolute;
  inset: 0;
}

.ant-tooltip-arrow-content:before {
  background: var(--antd-arrow-background-color);
  content: "";
  clip-path: inset(33%);
  -webkit-clip-path: path("M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z");
  clip-path: path("M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z");
  background-position: -10px -10px;
  background-repeat: no-repeat;
  width: 33.9411px;
  height: 33.9411px;
  position: absolute;
  top: -11.3137px;
  left: -11.3137px;
}

.ant-tooltip-placement-top .ant-tooltip-arrow, .ant-tooltip-placement-topLeft .ant-tooltip-arrow, .ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: 0;
  transform: translateY(100%);
}

.ant-tooltip-placement-top .ant-tooltip-arrow-content, .ant-tooltip-placement-topLeft .ant-tooltip-arrow-content, .ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
  transform: translateY(-11px)rotate(45deg);
  box-shadow: 3px 3px 7px #00000012;
}

.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  transform: translateY(100%)translateX(-50%);
}

.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 13px;
}

.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 13px;
}

.ant-tooltip-placement-right .ant-tooltip-arrow, .ant-tooltip-placement-rightTop .ant-tooltip-arrow, .ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: 0;
  transform: translateX(-100%);
}

.ant-tooltip-placement-right .ant-tooltip-arrow-content, .ant-tooltip-placement-rightTop .ant-tooltip-arrow-content, .ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content {
  transform: translateX(11px)rotate(135deg);
  box-shadow: -3px 3px 7px #00000012;
}

.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  transform: translateX(-100%)translateY(-50%);
}

.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 5px;
}

.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 5px;
}

.ant-tooltip-placement-left .ant-tooltip-arrow, .ant-tooltip-placement-leftTop .ant-tooltip-arrow, .ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: 0;
  transform: translateX(100%);
}

.ant-tooltip-placement-left .ant-tooltip-arrow-content, .ant-tooltip-placement-leftTop .ant-tooltip-arrow-content, .ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content {
  transform: translateX(-11px)rotate(315deg);
  box-shadow: 3px -3px 7px #00000012;
}

.ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  transform: translateX(100%)translateY(-50%);
}

.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 5px;
}

.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 5px;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow, .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow, .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: 0;
  transform: translateY(-100%);
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow-content, .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content, .ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
  transform: translateY(11px)rotate(225deg);
  box-shadow: -3px -3px 7px #00000012;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  transform: translateY(-100%)translateX(-50%);
}

.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 13px;
}

.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 13px;
}

.ant-tooltip-pink .ant-tooltip-inner {
  background-color: #eb2f96;
}

.ant-tooltip-pink .ant-tooltip-arrow-content:before {
  background: #eb2f96;
}

.ant-tooltip-magenta .ant-tooltip-inner {
  background-color: #eb2f96;
}

.ant-tooltip-magenta .ant-tooltip-arrow-content:before {
  background: #eb2f96;
}

.ant-tooltip-red .ant-tooltip-inner {
  background-color: #f5222d;
}

.ant-tooltip-red .ant-tooltip-arrow-content:before {
  background: #f5222d;
}

.ant-tooltip-volcano .ant-tooltip-inner {
  background-color: #fa541c;
}

.ant-tooltip-volcano .ant-tooltip-arrow-content:before {
  background: #fa541c;
}

.ant-tooltip-orange .ant-tooltip-inner {
  background-color: #fa8c16;
}

.ant-tooltip-orange .ant-tooltip-arrow-content:before {
  background: #fa8c16;
}

.ant-tooltip-yellow .ant-tooltip-inner {
  background-color: #fadb14;
}

.ant-tooltip-yellow .ant-tooltip-arrow-content:before {
  background: #fadb14;
}

.ant-tooltip-gold .ant-tooltip-inner {
  background-color: #faad14;
}

.ant-tooltip-gold .ant-tooltip-arrow-content:before {
  background: #faad14;
}

.ant-tooltip-cyan .ant-tooltip-inner {
  background-color: #13c2c2;
}

.ant-tooltip-cyan .ant-tooltip-arrow-content:before {
  background: #13c2c2;
}

.ant-tooltip-lime .ant-tooltip-inner {
  background-color: #a0d911;
}

.ant-tooltip-lime .ant-tooltip-arrow-content:before {
  background: #a0d911;
}

.ant-tooltip-green .ant-tooltip-inner {
  background-color: #52c41a;
}

.ant-tooltip-green .ant-tooltip-arrow-content:before {
  background: #52c41a;
}

.ant-tooltip-blue .ant-tooltip-inner {
  background-color: #1890ff;
}

.ant-tooltip-blue .ant-tooltip-arrow-content:before {
  background: #1890ff;
}

.ant-tooltip-geekblue .ant-tooltip-inner {
  background-color: #2f54eb;
}

.ant-tooltip-geekblue .ant-tooltip-arrow-content:before {
  background: #2f54eb;
}

.ant-tooltip-purple .ant-tooltip-inner {
  background-color: #722ed1;
}

.ant-tooltip-purple .ant-tooltip-arrow-content:before {
  background: #722ed1;
}

.ant-tooltip-rtl {
  direction: rtl;
}

.ant-tooltip-rtl .ant-tooltip-inner {
  text-align: right;
}

.ant-space {
  display: inline-flex;
}

.ant-space-vertical {
  flex-direction: column;
}

.ant-space-align-center {
  align-items: center;
}

.ant-space-align-start {
  align-items: flex-start;
}

.ant-space-align-end {
  align-items: flex-end;
}

.ant-space-align-baseline {
  align-items: baseline;
}

.ant-space-item:empty {
  display: none;
}

.ant-space-compact {
  display: inline-flex;
}

.ant-space-compact-block {
  width: 100%;
  display: flex;
}

.ant-space-compact-vertical {
  flex-direction: column;
}

.ant-space-rtl, .ant-space-compact-rtl {
  direction: rtl;
}

.ant-picker-calendar {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  background: #fff;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
}

.ant-picker-calendar-header {
  justify-content: flex-end;
  padding: 12px 0;
  display: flex;
}

.ant-picker-calendar-header .ant-picker-calendar-year-select {
  min-width: 80px;
}

.ant-picker-calendar-header .ant-picker-calendar-month-select {
  min-width: 70px;
  margin-left: 8px;
}

.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-left: 8px;
}

.ant-picker-calendar .ant-picker-panel {
  background: #fff;
  border: 0;
  border-top: 1px solid #f0f0f0;
  border-radius: 0;
}

.ant-picker-calendar .ant-picker-panel .ant-picker-month-panel, .ant-picker-calendar .ant-picker-panel .ant-picker-date-panel {
  width: auto;
}

.ant-picker-calendar .ant-picker-panel .ant-picker-body {
  padding: 8px 0;
}

.ant-picker-calendar .ant-picker-panel .ant-picker-content {
  width: 100%;
}

.ant-picker-calendar-mini {
  border-radius: 2px;
}

.ant-picker-calendar-mini .ant-picker-calendar-header {
  padding-left: 8px;
  padding-right: 8px;
}

.ant-picker-calendar-mini .ant-picker-panel {
  border-radius: 0 0 2px 2px;
}

.ant-picker-calendar-mini .ant-picker-content {
  height: 256px;
}

.ant-picker-calendar-mini .ant-picker-content th {
  height: auto;
  padding: 0;
  line-height: 18px;
}

.ant-picker-calendar-mini .ant-picker-cell:before {
  pointer-events: none;
}

.ant-picker-calendar-full .ant-picker-panel {
  text-align: right;
  background: #fff;
  border: 0;
  width: 100%;
  display: block;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th, .ant-picker-calendar-full .ant-picker-panel .ant-picker-body td {
  padding: 0;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  height: auto;
  padding: 0 12px 5px 0;
  line-height: 18px;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:before {
  display: none;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:hover .ant-picker-calendar-date {
  background: #f5f5f5;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell .ant-picker-calendar-date-today:before {
  display: none;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
  background: #e6f7ff;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: #1890ff;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  border: 0;
  border-top: 2px solid #f0f0f0;
  border-radius: 0;
  width: auto;
  height: auto;
  margin: 0 4px;
  padding: 4px 8px 0;
  transition: background .3s;
  display: block;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
  line-height: 24px;
  transition: color .3s;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  color: #000000d9;
  text-align: left;
  width: auto;
  height: 86px;
  line-height: 1.5715;
  position: static;
  overflow-y: auto;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
  border-color: #1890ff;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: #000000d9;
}

@media only screen and (width <= 480px) {
  .ant-picker-calendar-header {
    display: block;
  }

  .ant-picker-calendar-header .ant-picker-calendar-year-select {
    width: 50%;
  }

  .ant-picker-calendar-header .ant-picker-calendar-month-select {
    width: calc(50% - 8px);
  }

  .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    width: 100%;
    margin-top: 8px;
    margin-left: 0;
  }

  .ant-picker-calendar-header .ant-picker-calendar-mode-switch > label {
    text-align: center;
    width: 50%;
  }
}

.ant-picker-calendar-rtl {
  direction: rtl;
}

.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-month-select, .ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-left: 0;
  margin-right: 8px;
}

.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel {
  text-align: left;
}

.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  padding: 0 0 5px 12px;
}

.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  text-align: right;
}

.ant-picker-status-error.ant-picker, .ant-picker-status-error.ant-picker:not(.ant-picker-disabled):hover {
  background-color: #fff;
  border-color: #ff4d4f;
}

.ant-picker-status-error.ant-picker-focused, .ant-picker-status-error.ant-picker:focus {
  border-color: #ff7875;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #ff4d4f33;
}

.ant-picker-status-error.ant-picker .ant-picker-active-bar {
  background: #ff7875;
}

.ant-picker-status-warning.ant-picker, .ant-picker-status-warning.ant-picker:not(.ant-picker-disabled):hover {
  background-color: #fff;
  border-color: #faad14;
}

.ant-picker-status-warning.ant-picker-focused, .ant-picker-status-warning.ant-picker:focus {
  border-color: #ffc53d;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #faad1433;
}

.ant-picker-status-warning.ant-picker .ant-picker-active-bar {
  background: #ffc53d;
}

.ant-picker {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  align-items: center;
  margin: 0;
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  transition: border .3s, box-shadow .3s;
  display: inline-flex;
  position: relative;
}

.ant-picker:hover, .ant-picker-focused {
  border-color: #40a9ff;
  border-right-width: 1px;
}

.ant-picker-focused {
  border-color: #40a9ff;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #1890ff33;
}

.ant-picker.ant-picker-disabled {
  cursor: not-allowed;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-picker.ant-picker-disabled .ant-picker-suffix {
  color: #00000040;
}

.ant-picker.ant-picker-borderless {
  box-shadow: none !important;
  background-color: #0000 !important;
  border-color: #0000 !important;
}

.ant-picker-input {
  align-items: center;
  width: 100%;
  display: inline-flex;
  position: relative;
}

.ant-picker-input > input {
  color: #000000d9;
  background: none;
  border: 0;
  border-radius: 2px;
  flex: auto;
  width: 100%;
  min-width: 1px;
  height: auto;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  transition: all .3s;
  display: inline-block;
  position: relative;
}

.ant-picker-input > input::-moz-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-picker-input > input:-ms-input-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-picker-input > input::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-picker-input > input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}

.ant-picker-input > input:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-picker-input > input:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-picker-input > input:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}

.ant-picker-input > input:focus, .ant-picker-input > input-focused {
  border-color: #40a9ff;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #1890ff33;
}

.ant-picker-input > input-disabled {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-picker-input > input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-picker-input > input[disabled] {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-picker-input > input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-picker-input > input-borderless, .ant-picker-input > input-borderless:hover, .ant-picker-input > input-borderless:focus, .ant-picker-input > input-borderless-focused, .ant-picker-input > input-borderless-disabled, .ant-picker-input > input-borderless[disabled] {
  box-shadow: none;
  background-color: #0000;
  border: none;
}

textarea.ant-picker-input > input {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  transition: all .3s, height;
}

.ant-picker-input > input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-picker-input > input-sm {
  padding: 0 7px;
}

.ant-picker-input > input:focus {
  box-shadow: none;
}

.ant-picker-input > input[disabled] {
  background: none;
}

.ant-picker-input:hover .ant-picker-clear {
  opacity: 1;
}

.ant-picker-input-placeholder > input {
  color: #bfbfbf;
}

.ant-picker-large {
  padding: 6.5px 11px;
}

.ant-picker-large .ant-picker-input > input {
  font-size: 16px;
}

.ant-picker-small {
  padding: 0 7px;
}

.ant-picker-suffix {
  color: #00000040;
  pointer-events: none;
  flex: none;
  align-self: center;
  margin-left: 4px;
  line-height: 1;
  display: flex;
}

.ant-picker-suffix > * {
  vertical-align: top;
}

.ant-picker-suffix > :not(:last-child) {
  margin-right: 8px;
}

.ant-picker-clear {
  color: #00000040;
  cursor: pointer;
  opacity: 0;
  background: #fff;
  line-height: 1;
  transition: opacity .3s, color .3s;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.ant-picker-clear > * {
  vertical-align: top;
}

.ant-picker-clear:hover {
  color: #00000073;
}

.ant-picker-separator {
  color: #00000040;
  vertical-align: top;
  cursor: default;
  width: 1em;
  height: 16px;
  font-size: 16px;
  display: inline-block;
  position: relative;
}

.ant-picker-focused .ant-picker-separator {
  color: #00000073;
}

.ant-picker-disabled .ant-picker-range-separator .ant-picker-separator {
  cursor: not-allowed;
}

.ant-picker-range {
  display: inline-flex;
  position: relative;
}

.ant-picker-range .ant-picker-clear {
  right: 11px;
}

.ant-picker-range:hover .ant-picker-clear {
  opacity: 1;
}

.ant-picker-range .ant-picker-active-bar {
  opacity: 0;
  pointer-events: none;
  background: #1890ff;
  height: 2px;
  margin-left: 11px;
  transition: all .3s ease-out;
  bottom: -1px;
}

.ant-picker-range.ant-picker-focused .ant-picker-active-bar {
  opacity: 1;
}

.ant-picker-range-separator {
  align-items: center;
  padding: 0 8px;
  line-height: 1;
}

.ant-picker-range.ant-picker-small .ant-picker-clear {
  right: 7px;
}

.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-left: 7px;
}

.ant-picker-dropdown {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  z-index: 1050;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.ant-picker-dropdown-hidden {
  display: none;
}

.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
  display: block;
  top: 2.58562px;
  transform: rotate(-135deg)translateY(1px);
}

.ant-picker-dropdown-placement-topLeft .ant-picker-range-arrow {
  display: block;
  bottom: 2.58562px;
  transform: rotate(45deg);
}

.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-topLeft, .ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-topRight, .ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-topLeft, .ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}

.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-bottomLeft, .ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-bottomRight, .ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-bottomLeft, .ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}

.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-topLeft, .ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}

.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-bottomLeft, .ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}

.ant-picker-dropdown-range {
  padding: 7.54247px 0;
}

.ant-picker-dropdown-range-hidden {
  display: none;
}

.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 4px;
}

.ant-picker-ranges {
  text-align: left;
  margin-bottom: 0;
  padding: 4px 12px;
  line-height: 34px;
  list-style: none;
  overflow: hidden;
}

.ant-picker-ranges > li {
  display: inline-block;
}

.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: #1890ff;
  cursor: pointer;
  background: #e6f7ff;
  border-color: #91d5ff;
}

.ant-picker-ranges .ant-picker-ok {
  float: right;
  margin-left: 8px;
}

.ant-picker-range-wrapper {
  display: flex;
}

.ant-picker-range-arrow {
  z-index: 1;
  pointer-events: none;
  border-radius: 0 0 2px;
  width: 11.3137px;
  height: 11.3137px;
  margin-left: 16.5px;
  transition: left .3s ease-out;
  position: absolute;
  box-shadow: 2px 2px 6px -2px #0000001a;
}

.ant-picker-range-arrow:before {
  content: "";
  clip-path: inset(33%);
  -webkit-clip-path: path("M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z");
  clip-path: path("M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z");
  background: #fff -10px -10px no-repeat;
  width: 33.9411px;
  height: 33.9411px;
  position: absolute;
  top: -11.3137px;
  left: -11.3137px;
}

.ant-picker-panel-container {
  vertical-align: top;
  background: #fff;
  border-radius: 2px;
  transition: margin .3s;
  overflow: hidden;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
}

.ant-picker-panel-container .ant-picker-panels {
  direction: ltr;
  flex-wrap: nowrap;
  display: inline-flex;
}

.ant-picker-panel-container .ant-picker-panel {
  vertical-align: top;
  background: none;
  border-width: 0 0 1px;
  border-radius: 0;
}

.ant-picker-panel-container .ant-picker-panel .ant-picker-content, .ant-picker-panel-container .ant-picker-panel table {
  text-align: center;
}

.ant-picker-panel-container .ant-picker-panel-focused {
  border-color: #f0f0f0;
}

.ant-picker-compact-item:not(.ant-picker-compact-last-item):not(.ant-picker-compact-item-rtl) {
  margin-right: -1px;
}

.ant-picker-compact-item:not(.ant-picker-compact-last-item).ant-picker-compact-item-rtl {
  margin-left: -1px;
}

.ant-picker-compact-item:hover, .ant-picker-compact-item:focus, .ant-picker-compact-item:active, .ant-picker-compact-item.ant-picker-focused {
  z-index: 2;
}

.ant-picker-compact-item[disabled] {
  z-index: 0;
}

.ant-picker-compact-item:not(.ant-picker-compact-first-item):not(.ant-picker-compact-last-item).ant-picker {
  border-radius: 0;
}

.ant-picker-compact-item.ant-picker.ant-picker-compact-first-item:not(.ant-picker-compact-last-item):not(.ant-picker-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-picker-compact-item.ant-picker.ant-picker-compact-last-item:not(.ant-picker-compact-first-item):not(.ant-picker-compact-item-rtl), .ant-picker-compact-item.ant-picker.ant-picker-compact-item-rtl.ant-picker-compact-first-item:not(.ant-picker-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-picker-compact-item.ant-picker.ant-picker-compact-item-rtl.ant-picker-compact-last-item:not(.ant-picker-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-picker-panel {
  text-align: center;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  outline: none;
  flex-direction: column;
  display: inline-flex;
}

.ant-picker-panel-focused {
  border-color: #1890ff;
}

.ant-picker-decade-panel, .ant-picker-year-panel, .ant-picker-quarter-panel, .ant-picker-month-panel, .ant-picker-week-panel, .ant-picker-date-panel, .ant-picker-time-panel {
  flex-direction: column;
  width: 280px;
  display: flex;
}

.ant-picker-header {
  color: #000000d9;
  border-bottom: 1px solid #f0f0f0;
  padding: 0 8px;
  display: flex;
}

.ant-picker-header > * {
  flex: none;
}

.ant-picker-header button {
  color: #00000040;
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
  line-height: 40px;
  transition: color .3s;
}

.ant-picker-header > button {
  min-width: 1.6em;
  font-size: 14px;
}

.ant-picker-header > button:hover {
  color: #000000d9;
}

.ant-picker-header-view {
  flex: auto;
  font-weight: 500;
  line-height: 40px;
}

.ant-picker-header-view button {
  color: inherit;
  font-weight: inherit;
}

.ant-picker-header-view button:not(:first-child) {
  margin-left: 8px;
}

.ant-picker-header-view button:hover {
  color: #1890ff;
}

.ant-picker-prev-icon, .ant-picker-next-icon, .ant-picker-super-prev-icon, .ant-picker-super-next-icon {
  width: 7px;
  height: 7px;
  display: inline-block;
  position: relative;
}

.ant-picker-prev-icon:before, .ant-picker-next-icon:before, .ant-picker-super-prev-icon:before, .ant-picker-super-next-icon:before {
  content: "";
  border: 0 solid;
  border-width: 1.5px 0 0 1.5px;
  width: 7px;
  height: 7px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.ant-picker-super-prev-icon:after, .ant-picker-super-next-icon:after {
  content: "";
  border: 0 solid;
  border-width: 1.5px 0 0 1.5px;
  width: 7px;
  height: 7px;
  display: inline-block;
  position: absolute;
  top: 4px;
  left: 4px;
}

.ant-picker-prev-icon, .ant-picker-super-prev-icon {
  transform: rotate(-45deg);
}

.ant-picker-next-icon, .ant-picker-super-next-icon {
  transform: rotate(135deg);
}

.ant-picker-content {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
}

.ant-picker-content th, .ant-picker-content td {
  min-width: 24px;
  font-weight: 400;
  position: relative;
}

.ant-picker-content th {
  color: #000000d9;
  height: 30px;
  line-height: 30px;
}

.ant-picker-cell {
  color: #00000040;
  cursor: pointer;
  padding: 3px 0;
}

.ant-picker-cell-in-view {
  color: #000000d9;
}

.ant-picker-cell:before {
  z-index: 1;
  content: "";
  height: 24px;
  transition: all .3s;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: #f5f5f5;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
  z-index: 1;
  content: "";
  border: 1px solid #1890ff;
  border-radius: 2px;
  position: absolute;
  inset: 0;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range {
  position: relative;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range:before {
  background: #e6f7ff;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #1890ff;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):before {
  background: #e6f7ff;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:before {
  left: 50%;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:before {
  right: 50%;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single:after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover:after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover:after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single:after, .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range):after {
  z-index: 0;
  content: "";
  border-top: 1px dashed #7ec1ff;
  border-bottom: 1px dashed #7ec1ff;
  height: 24px;
  transition: all .3s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.ant-picker-cell-range-hover-start:after, .ant-picker-cell-range-hover-end:after, .ant-picker-cell-range-hover:after {
  left: 2px;
  right: 0;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover:before, .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover:before, .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover:before, .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start:before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end:before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start:before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end:before {
  background: #cbe6ff;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 2px 0 0 2px;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 0 2px 2px 0;
}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner:after, .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner:after {
  z-index: -1;
  content: "";
  background: #cbe6ff;
  transition: all .3s;
  position: absolute;
  top: 0;
  bottom: 0;
}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner:after {
  left: 0;
  right: -6px;
}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner:after {
  left: -6px;
  right: 0;
}

.ant-picker-cell-range-hover.ant-picker-cell-range-start:after {
  right: 50%;
}

.ant-picker-cell-range-hover.ant-picker-cell-range-end:after {
  left: 50%;
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child:after, tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child:after, .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range:after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range):after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:after {
  border-left: 1px dashed #7ec1ff;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  left: 6px;
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child:after, tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child:after, .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range:after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range):after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:after {
  border-right: 1px dashed #7ec1ff;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  right: 6px;
}

.ant-picker-cell-disabled {
  color: #00000040;
  pointer-events: none;
}

.ant-picker-cell-disabled .ant-picker-cell-inner {
  background: none;
}

.ant-picker-cell-disabled:before {
  background: #0000000a;
}

.ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner:before {
  border-color: #00000040;
}

.ant-picker-decade-panel .ant-picker-content, .ant-picker-year-panel .ant-picker-content, .ant-picker-quarter-panel .ant-picker-content, .ant-picker-month-panel .ant-picker-content {
  height: 264px;
}

.ant-picker-decade-panel .ant-picker-cell-inner, .ant-picker-year-panel .ant-picker-cell-inner, .ant-picker-quarter-panel .ant-picker-cell-inner, .ant-picker-month-panel .ant-picker-cell-inner {
  padding: 0 8px;
}

.ant-picker-quarter-panel .ant-picker-content {
  height: 56px;
}

.ant-picker-footer {
  text-align: center;
  border-bottom: 1px solid #0000;
  width: min-content;
  min-width: 100%;
  line-height: 38px;
}

.ant-picker-panel .ant-picker-footer {
  border-top: 1px solid #f0f0f0;
}

.ant-picker-footer-extra {
  text-align: left;
  padding: 0 12px;
  line-height: 38px;
}

.ant-picker-footer-extra:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
}

.ant-picker-now {
  text-align: left;
}

.ant-picker-today-btn {
  color: #1890ff;
}

.ant-picker-today-btn:hover {
  color: #40a9ff;
}

.ant-picker-today-btn:active {
  color: #096dd9;
}

.ant-picker-today-btn.ant-picker-today-btn-disabled {
  color: #00000040;
  cursor: not-allowed;
}

.ant-picker-decade-panel .ant-picker-cell-inner {
  padding: 0 4px;
}

.ant-picker-decade-panel .ant-picker-cell:before {
  display: none;
}

.ant-picker-year-panel .ant-picker-body, .ant-picker-quarter-panel .ant-picker-body, .ant-picker-month-panel .ant-picker-body {
  padding: 0 8px;
}

.ant-picker-year-panel .ant-picker-cell-inner, .ant-picker-quarter-panel .ant-picker-cell-inner, .ant-picker-month-panel .ant-picker-cell-inner {
  width: 60px;
}

.ant-picker-year-panel .ant-picker-cell-range-hover-start:after, .ant-picker-quarter-panel .ant-picker-cell-range-hover-start:after, .ant-picker-month-panel .ant-picker-cell-range-hover-start:after {
  border-left: 1px dashed #7ec1ff;
  border-radius: 2px 0 0 2px;
  left: 14px;
}

.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start:after, .ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start:after, .ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start:after, .ant-picker-year-panel .ant-picker-cell-range-hover-end:after, .ant-picker-quarter-panel .ant-picker-cell-range-hover-end:after, .ant-picker-month-panel .ant-picker-cell-range-hover-end:after {
  border-right: 1px dashed #7ec1ff;
  border-radius: 0 2px 2px 0;
  right: 14px;
}

.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end:after, .ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end:after, .ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end:after {
  border-left: 1px dashed #7ec1ff;
  border-radius: 2px 0 0 2px;
  left: 14px;
}

.ant-picker-week-panel .ant-picker-body {
  padding: 8px 12px;
}

.ant-picker-week-panel .ant-picker-cell:hover .ant-picker-cell-inner, .ant-picker-week-panel .ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner {
  background: none !important;
}

.ant-picker-week-panel-row td {
  transition: background .3s;
}

.ant-picker-week-panel-row:hover td {
  background: #f5f5f5;
}

.ant-picker-week-panel-row-selected td, .ant-picker-week-panel-row-selected:hover td {
  background: #1890ff;
}

.ant-picker-week-panel-row-selected td.ant-picker-cell-week, .ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
  color: #ffffff80;
}

.ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner:before, .ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner:before {
  border-color: #fff;
}

.ant-picker-week-panel-row-selected td .ant-picker-cell-inner, .ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
  color: #fff;
}

.ant-picker-date-panel .ant-picker-body {
  padding: 8px 12px;
}

.ant-picker-date-panel .ant-picker-content {
  width: 252px;
}

.ant-picker-date-panel .ant-picker-content th {
  width: 36px;
}

.ant-picker-datetime-panel {
  display: flex;
}

.ant-picker-datetime-panel .ant-picker-time-panel {
  border-left: 1px solid #f0f0f0;
}

.ant-picker-datetime-panel .ant-picker-date-panel, .ant-picker-datetime-panel .ant-picker-time-panel {
  transition: opacity .3s;
}

.ant-picker-datetime-panel-active .ant-picker-date-panel, .ant-picker-datetime-panel-active .ant-picker-time-panel {
  opacity: .3;
}

.ant-picker-datetime-panel-active .ant-picker-date-panel-active, .ant-picker-datetime-panel-active .ant-picker-time-panel-active {
  opacity: 1;
}

.ant-picker-time-panel {
  width: auto;
  min-width: auto;
}

.ant-picker-time-panel .ant-picker-content {
  flex: auto;
  height: 224px;
  display: flex;
}

.ant-picker-time-panel-column {
  text-align: left;
  flex: 1 0 auto;
  width: 56px;
  margin: 0;
  padding: 0;
  list-style: none;
  transition: background .3s;
  overflow-y: hidden;
}

.ant-picker-time-panel-column:after {
  content: "";
  height: 196px;
  display: block;
}

.ant-picker-datetime-panel .ant-picker-time-panel-column:after {
  height: 198px;
}

.ant-picker-time-panel-column:not(:first-child) {
  border-left: 1px solid #f0f0f0;
}

.ant-picker-time-panel-column-active {
  background: #e6f7ff33;
}

.ant-picker-time-panel-column:hover {
  overflow-y: auto;
}

.ant-picker-time-panel-column > li {
  margin: 0;
  padding: 0;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  color: #000000d9;
  cursor: pointer;
  border-radius: 0;
  width: 100%;
  height: 28px;
  margin: 0;
  padding: 0 0 0 14px;
  line-height: 28px;
  transition: background .3s;
  display: block;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #f5f5f5;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e6f7ff;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
  color: #00000040;
  cursor: not-allowed;
  background: none;
}

:root .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell, :root .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell {
  padding: 21px 0;
}

_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell {
  padding: 21px 0;
}

_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell {
  padding: 21px 0;
}

.ant-picker-rtl {
  direction: rtl;
}

.ant-picker-rtl .ant-picker-suffix {
  margin-left: 0;
  margin-right: 4px;
}

.ant-picker-rtl .ant-picker-clear {
  left: 0;
  right: auto;
}

.ant-picker-rtl .ant-picker-separator {
  transform: rotate(180deg);
}

.ant-picker-panel-rtl .ant-picker-header-view button:not(:first-child) {
  margin-left: 0;
  margin-right: 8px;
}

.ant-picker-rtl.ant-picker-range .ant-picker-clear {
  left: 11px;
  right: auto;
}

.ant-picker-rtl.ant-picker-range .ant-picker-active-bar {
  margin-left: 0;
  margin-right: 11px;
}

.ant-picker-rtl.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-right: 7px;
}

.ant-picker-dropdown-rtl .ant-picker-ranges {
  text-align: right;
}

.ant-picker-dropdown-rtl .ant-picker-ranges .ant-picker-ok {
  float: left;
  margin-left: 0;
  margin-right: 8px;
}

.ant-picker-panel-rtl {
  direction: rtl;
}

.ant-picker-panel-rtl .ant-picker-prev-icon, .ant-picker-panel-rtl .ant-picker-super-prev-icon {
  transform: rotate(135deg);
}

.ant-picker-panel-rtl .ant-picker-next-icon, .ant-picker-panel-rtl .ant-picker-super-next-icon {
  transform: rotate(-45deg);
}

.ant-picker-cell .ant-picker-cell-inner {
  z-index: 2;
  border-radius: 2px;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  transition: background .3s, border .3s;
  display: inline-block;
  position: relative;
}

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start:before {
  left: 0;
  right: 50%;
}

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end:before {
  left: 50%;
  right: 0;
}

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-end:before {
  left: 50%;
  right: 50%;
}

.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner:after {
  left: -6px;
  right: 0;
}

.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner:after {
  left: 0;
  right: -6px;
}

.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-start:after {
  left: 50%;
  right: 0;
}

.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-end:after {
  left: 0;
  right: 50%;
}

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 0 2px 2px 0;
}

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 2px 0 0 2px;
}

.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):first-child:after, .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range:after, .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range):after, .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:after {
  border-left: none;
  border-right: 1px dashed #7ec1ff;
  border-radius: 0 2px 2px 0;
  left: 0;
  right: 6px;
}

.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):last-child:after, .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range:after, .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range):after, .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:after {
  border-left: 1px dashed #7ec1ff;
  border-right: none;
  border-radius: 2px 0 0 2px;
  left: 6px;
  right: 0;
}

.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child:after, .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child:after, .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover):after, .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-end.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover):after, .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-start.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover):after, .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-start:last-child:after, .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-end:first-child:after {
  border-left: 1px dashed #7ec1ff;
  border-right: 1px dashed #7ec1ff;
  border-radius: 2px;
  left: 6px;
  right: 6px;
}

.ant-picker-dropdown-rtl .ant-picker-footer-extra {
  text-align: right;
  direction: rtl;
}

.ant-picker-panel-rtl .ant-picker-time-panel {
  direction: ltr;
}

.ant-tag {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  white-space: nowrap;
  opacity: 1;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  height: auto;
  margin: 0 8px 0 0;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  list-style: none;
  transition: all .3s;
  display: inline-block;
}

.ant-tag, .ant-tag a, .ant-tag a:hover {
  color: #000000d9;
}

.ant-tag > a:first-child:last-child {
  margin: 0 -8px;
  padding: 0 8px;
  display: inline-block;
}

.ant-tag-close-icon {
  color: #00000073;
  cursor: pointer;
  margin-left: 3px;
  font-size: 10px;
  transition: all .3s;
}

.ant-tag-close-icon:hover {
  color: #000000d9;
}

.ant-tag-has-color {
  border-color: #0000;
}

.ant-tag-has-color, .ant-tag-has-color a, .ant-tag-has-color a:hover, .ant-tag-has-color .anticon-close, .ant-tag-has-color .anticon-close:hover {
  color: #fff;
}

.ant-tag-checkable {
  cursor: pointer;
  background-color: #0000;
  border-color: #0000;
}

.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #1890ff;
}

.ant-tag-checkable:active, .ant-tag-checkable-checked {
  color: #fff;
}

.ant-tag-checkable-checked {
  background-color: #1890ff;
}

.ant-tag-checkable:active {
  background-color: #096dd9;
}

.ant-tag-hidden {
  display: none;
}

.ant-tag-pink {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}

.ant-tag-pink-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}

.ant-tag-magenta {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}

.ant-tag-magenta-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}

.ant-tag-red {
  color: #cf1322;
  background: #fff1f0;
  border-color: #ffa39e;
}

.ant-tag-red-inverse {
  color: #fff;
  background: #f5222d;
  border-color: #f5222d;
}

.ant-tag-volcano {
  color: #d4380d;
  background: #fff2e8;
  border-color: #ffbb96;
}

.ant-tag-volcano-inverse {
  color: #fff;
  background: #fa541c;
  border-color: #fa541c;
}

.ant-tag-orange {
  color: #d46b08;
  background: #fff7e6;
  border-color: #ffd591;
}

.ant-tag-orange-inverse {
  color: #fff;
  background: #fa8c16;
  border-color: #fa8c16;
}

.ant-tag-yellow {
  color: #d4b106;
  background: #feffe6;
  border-color: #fffb8f;
}

.ant-tag-yellow-inverse {
  color: #fff;
  background: #fadb14;
  border-color: #fadb14;
}

.ant-tag-gold {
  color: #d48806;
  background: #fffbe6;
  border-color: #ffe58f;
}

.ant-tag-gold-inverse {
  color: #fff;
  background: #faad14;
  border-color: #faad14;
}

.ant-tag-cyan {
  color: #08979c;
  background: #e6fffb;
  border-color: #87e8de;
}

.ant-tag-cyan-inverse {
  color: #fff;
  background: #13c2c2;
  border-color: #13c2c2;
}

.ant-tag-lime {
  color: #7cb305;
  background: #fcffe6;
  border-color: #eaff8f;
}

.ant-tag-lime-inverse {
  color: #fff;
  background: #a0d911;
  border-color: #a0d911;
}

.ant-tag-green {
  color: #389e0d;
  background: #f6ffed;
  border-color: #b7eb8f;
}

.ant-tag-green-inverse {
  color: #fff;
  background: #52c41a;
  border-color: #52c41a;
}

.ant-tag-blue {
  color: #096dd9;
  background: #e6f7ff;
  border-color: #91d5ff;
}

.ant-tag-blue-inverse {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}

.ant-tag-geekblue {
  color: #1d39c4;
  background: #f0f5ff;
  border-color: #adc6ff;
}

.ant-tag-geekblue-inverse {
  color: #fff;
  background: #2f54eb;
  border-color: #2f54eb;
}

.ant-tag-purple {
  color: #531dab;
  background: #f9f0ff;
  border-color: #d3adf7;
}

.ant-tag-purple-inverse {
  color: #fff;
  background: #722ed1;
  border-color: #722ed1;
}

.ant-tag-success {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}

.ant-tag-processing {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}

.ant-tag-error {
  color: #ff4d4f;
  background: #fff2f0;
  border-color: #ffccc7;
}

.ant-tag-warning {
  color: #faad14;
  background: #fffbe6;
  border-color: #ffe58f;
}

.ant-tag > .anticon + span, .ant-tag > span + .anticon {
  margin-left: 7px;
}

.ant-tag.ant-tag-rtl {
  text-align: right;
  direction: rtl;
  margin-left: 8px;
  margin-right: 0;
}

.ant-tag-rtl .ant-tag-close-icon {
  margin-left: 0;
  margin-right: 3px;
}

.ant-tag-rtl.ant-tag > .anticon + span, .ant-tag-rtl.ant-tag > span + .anticon {
  margin-left: 0;
  margin-right: 7px;
}

.ant-radio-group {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 1.5715;
  list-style: none;
  display: inline-block;
}

.ant-radio-group .ant-badge-count {
  z-index: 1;
}

.ant-radio-group > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
  border-left: none;
}

.ant-radio-wrapper {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  cursor: pointer;
  align-items: baseline;
  margin: 0 8px 0 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: inline-flex;
  position: relative;
}

.ant-radio-wrapper-disabled {
  cursor: not-allowed;
}

.ant-radio-wrapper:after {
  content: " ";
  width: 0;
  display: inline-block;
  overflow: hidden;
}

.ant-radio-wrapper.ant-radio-wrapper-in-form-item input[type="radio"] {
  width: 14px;
  height: 14px;
}

.ant-radio {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  cursor: pointer;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: inline-block;
  position: relative;
  top: .2em;
}

.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
  border-color: #1890ff;
}

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px #1890ff1f;
}

.ant-radio-checked:after {
  visibility: hidden;
  content: "";
  border: 1px solid #1890ff;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: .36s ease-in-out both antRadioEffect;
  position: absolute;
  top: 0;
  left: 0;
}

.ant-radio:hover:after, .ant-radio-wrapper:hover .ant-radio:after {
  visibility: visible;
}

.ant-radio-inner {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  transition: all .3s;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.ant-radio-inner:after {
  opacity: 0;
  content: " ";
  background-color: #1890ff;
  border-top: 0;
  border-left: 0;
  border-radius: 16px;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
  transition: all .3s cubic-bezier(.78, .14, .15, .86);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(0);
}

.ant-radio-input {
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  inset: 0;
}

.ant-radio.ant-radio-disabled .ant-radio-inner {
  border-color: #d9d9d9;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #1890ff;
}

.ant-radio-checked .ant-radio-inner:after {
  opacity: 1;
  transition: all .3s cubic-bezier(.78, .14, .15, .86);
  transform: scale(.5);
}

.ant-radio-disabled {
  cursor: not-allowed;
}

.ant-radio-disabled .ant-radio-inner {
  cursor: not-allowed;
  background-color: #f5f5f5;
}

.ant-radio-disabled .ant-radio-inner:after {
  background-color: #0003;
}

.ant-radio-disabled .ant-radio-input {
  cursor: not-allowed;
}

.ant-radio-disabled + span {
  color: #00000040;
  cursor: not-allowed;
}

span.ant-radio + * {
  padding-left: 8px;
  padding-right: 8px;
}

.ant-radio-button-wrapper {
  color: #000000d9;
  cursor: pointer;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-width: 1.02px 1px 1px 0;
  height: 32px;
  margin: 0;
  padding: 0 15px;
  font-size: 14px;
  line-height: 30px;
  transition: color .3s, background .3s, border-color .3s, box-shadow .3s;
  display: inline-block;
  position: relative;
}

.ant-radio-button-wrapper a {
  color: #000000d9;
}

.ant-radio-button-wrapper > .ant-radio-button {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ant-radio-group-large .ant-radio-button-wrapper {
  height: 40px;
  font-size: 16px;
  line-height: 38px;
}

.ant-radio-group-small .ant-radio-button-wrapper {
  height: 24px;
  padding: 0 7px;
  line-height: 22px;
}

.ant-radio-button-wrapper:not(:first-child):before {
  box-sizing: content-box;
  content: "";
  background-color: #d9d9d9;
  width: 1px;
  height: 100%;
  padding: 1px 0;
  transition: background-color .3s;
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
}

.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 2px 2px 0;
}

.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 2px;
}

.ant-radio-button-wrapper:hover {
  color: #1890ff;
  position: relative;
}

.ant-radio-button-wrapper:focus-within {
  box-shadow: 0 0 0 3px #1890ff1f;
}

.ant-radio-button-wrapper .ant-radio-inner, .ant-radio-button-wrapper input[type="checkbox"], .ant-radio-button-wrapper input[type="radio"] {
  opacity: 0;
  pointer-events: none;
  width: 0;
  height: 0;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #1890ff;
  background: #fff;
  border-color: #1890ff;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
  background-color: #1890ff;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #1890ff;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #40a9ff;
  border-color: #40a9ff;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover:before {
  background-color: #40a9ff;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #096dd9;
  border-color: #096dd9;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active:before {
  background-color: #096dd9;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px #1890ff1f;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #40a9ff;
  border-color: #40a9ff;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #fff;
  background: #096dd9;
  border-color: #096dd9;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px #1890ff1f;
}

.ant-radio-button-wrapper-disabled {
  color: #00000040;
  cursor: not-allowed;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-radio-button-wrapper-disabled:first-child, .ant-radio-button-wrapper-disabled:hover {
  color: #00000040;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-radio-button-wrapper-disabled:first-child {
  border-left-color: #d9d9d9;
}

.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: #00000040;
  box-shadow: none;
  background-color: #e6e6e6;
  border-color: #d9d9d9;
}

@keyframes antRadioEffect {
  0% {
    opacity: .5;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1.6);
  }
}

.ant-radio-group.ant-radio-group-rtl {
  direction: rtl;
}

.ant-radio-wrapper.ant-radio-wrapper-rtl {
  direction: rtl;
  margin-left: 8px;
  margin-right: 0;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl {
  border-left-width: 1px;
  border-right-width: 0;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:not(:first-child):before {
  left: 0;
  right: -1px;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
  border-right: 1px solid #d9d9d9;
  border-radius: 0 2px 2px 0;
}

.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
  border-right-color: #40a9ff;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:last-child {
  border-radius: 2px 0 0 2px;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
  border-right-color: #d9d9d9;
}

.ant-card {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  background: #fff;
  border-radius: 2px;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: relative;
}

.ant-card-rtl {
  direction: rtl;
}

.ant-card-hoverable {
  cursor: pointer;
  transition: box-shadow .3s, border-color .3s;
}

.ant-card-hoverable:hover {
  border-color: #0000;
  box-shadow: 0 1px 2px -2px #00000029, 0 3px 6px #0000001f, 0 5px 12px 4px #00000017;
}

.ant-card-bordered {
  border: 1px solid #f0f0f0;
}

.ant-card-head {
  color: #000000d9;
  background: none;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  font-size: 16px;
  font-weight: 500;
}

.ant-card-head:before {
  content: "";
  display: table;
}

.ant-card-head:after {
  clear: both;
  content: "";
  display: table;
}

.ant-card-head-wrapper {
  align-items: center;
  display: flex;
}

.ant-card-head-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  padding: 16px 0;
  display: inline-block;
  overflow: hidden;
}

.ant-card-head-title > .ant-typography, .ant-card-head-title > .ant-typography-edit-content {
  margin-top: 0;
  margin-bottom: 0;
  left: 0;
}

.ant-card-head .ant-tabs-top {
  clear: both;
  color: #000000d9;
  margin-bottom: -17px;
  font-size: 14px;
  font-weight: normal;
}

.ant-card-head .ant-tabs-top-bar {
  border-bottom: 1px solid #f0f0f0;
}

.ant-card-extra {
  color: #000000d9;
  margin-left: auto;
  padding: 16px 0;
  font-size: 14px;
  font-weight: normal;
}

.ant-card-rtl .ant-card-extra {
  margin-left: 0;
  margin-right: auto;
}

.ant-card-body {
  padding: 24px;
}

.ant-card-body:before {
  content: "";
  display: table;
}

.ant-card-body:after {
  clear: both;
  content: "";
  display: table;
}

.ant-card-contain-grid .ant-card-body {
  flex-wrap: wrap;
  display: flex;
}

.ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
  margin: -1px 0 0 -1px;
  padding: 0;
}

.ant-card-grid {
  border: 0;
  border-radius: 0;
  width: 33.33%;
  padding: 24px;
  transition: all .3s;
  box-shadow: 1px 0 #f0f0f0, 0 1px #f0f0f0, 1px 1px #f0f0f0, inset 1px 0 #f0f0f0, inset 0 1px #f0f0f0;
}

.ant-card-grid-hoverable:hover {
  z-index: 1;
  position: relative;
  box-shadow: 0 1px 2px -2px #00000029, 0 3px 6px #0000001f, 0 5px 12px 4px #00000017;
}

.ant-card-contain-tabs > .ant-card-head .ant-card-head-title {
  min-height: 32px;
  padding-bottom: 0;
}

.ant-card-contain-tabs > .ant-card-head .ant-card-extra {
  padding-bottom: 0;
}

.ant-card-bordered .ant-card-cover {
  margin-top: -1px;
  margin-left: -1px;
  margin-right: -1px;
}

.ant-card-cover > * {
  width: 100%;
  display: block;
}

.ant-card-cover img {
  border-radius: 2px 2px 0 0;
}

.ant-card-actions {
  background: #fff;
  border-top: 1px solid #f0f0f0;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.ant-card-actions:before {
  content: "";
  display: table;
}

.ant-card-actions:after {
  clear: both;
  content: "";
  display: table;
}

.ant-card-actions > li {
  color: #00000073;
  text-align: center;
  margin: 12px 0;
}

.ant-card-actions > li > span {
  cursor: pointer;
  min-width: 32px;
  font-size: 14px;
  line-height: 1.5715;
  display: block;
  position: relative;
}

.ant-card-actions > li > span:hover {
  color: #1890ff;
  transition: color .3s;
}

.ant-card-actions > li > span a:not(.ant-btn), .ant-card-actions > li > span > .anticon {
  color: #00000073;
  width: 100%;
  line-height: 22px;
  transition: color .3s;
  display: inline-block;
}

.ant-card-actions > li > span a:not(.ant-btn):hover, .ant-card-actions > li > span > .anticon:hover {
  color: #1890ff;
}

.ant-card-actions > li > span > .anticon {
  font-size: 16px;
  line-height: 22px;
}

.ant-card-actions > li:not(:last-child) {
  border-right: 1px solid #f0f0f0;
}

.ant-card-rtl .ant-card-actions > li:not(:last-child) {
  border-left: 1px solid #f0f0f0;
  border-right: none;
}

.ant-card-type-inner .ant-card-head {
  background: #fafafa;
  padding: 0 24px;
}

.ant-card-type-inner .ant-card-head-title {
  padding: 12px 0;
  font-size: 14px;
}

.ant-card-type-inner .ant-card-body {
  padding: 16px 24px;
}

.ant-card-type-inner .ant-card-extra {
  padding: 13.5px 0;
}

.ant-card-meta {
  margin: -4px 0;
  display: flex;
}

.ant-card-meta:before {
  content: "";
  display: table;
}

.ant-card-meta:after {
  clear: both;
  content: "";
  display: table;
}

.ant-card-meta-avatar {
  padding-right: 16px;
}

.ant-card-rtl .ant-card-meta-avatar {
  padding-left: 16px;
  padding-right: 0;
}

.ant-card-meta-detail {
  flex: 1;
  overflow: hidden;
}

.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 8px;
}

.ant-card-meta-title {
  color: #000000d9;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
}

.ant-card-meta-description {
  color: #00000073;
}

.ant-card-loading {
  overflow: hidden;
}

.ant-card-loading .ant-card-body {
  -webkit-user-select: none;
  user-select: none;
}

.ant-card-small > .ant-card-head {
  min-height: 36px;
  padding: 0 12px;
  font-size: 14px;
}

.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  padding: 8px 0;
}

.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
  padding: 8px 0;
  font-size: 14px;
}

.ant-card-small > .ant-card-body {
  padding: 12px;
}

.ant-skeleton {
  width: 100%;
  display: table;
}

.ant-skeleton-header {
  vertical-align: top;
  padding-right: 16px;
  display: table-cell;
}

.ant-skeleton-header .ant-skeleton-avatar {
  vertical-align: top;
  background: #bebebe33;
  width: 32px;
  height: 32px;
  line-height: 32px;
  display: inline-block;
}

.ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}

.ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}

.ant-skeleton-header .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}

.ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}

.ant-skeleton-content {
  vertical-align: top;
  width: 100%;
  display: table-cell;
}

.ant-skeleton-content .ant-skeleton-title {
  background: #bebebe33;
  border-radius: 2px;
  width: 100%;
  height: 16px;
}

.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 24px;
}

.ant-skeleton-content .ant-skeleton-paragraph {
  padding: 0;
}

.ant-skeleton-content .ant-skeleton-paragraph > li {
  background: #bebebe33;
  border-radius: 2px;
  width: 100%;
  height: 16px;
  list-style: none;
}

.ant-skeleton-content .ant-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 61%;
}

.ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 16px;
}

.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
  margin-top: 12px;
}

.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 28px;
}

.ant-skeleton-round .ant-skeleton-content .ant-skeleton-title, .ant-skeleton-round .ant-skeleton-content .ant-skeleton-paragraph > li {
  border-radius: 100px;
}

.ant-skeleton-active .ant-skeleton-title, .ant-skeleton-active .ant-skeleton-paragraph > li, .ant-skeleton-active .ant-skeleton-avatar, .ant-skeleton-active .ant-skeleton-button, .ant-skeleton-active .ant-skeleton-input, .ant-skeleton-active .ant-skeleton-image {
  z-index: 0;
  background: none;
  position: relative;
  overflow: hidden;
}

.ant-skeleton-active .ant-skeleton-title:after, .ant-skeleton-active .ant-skeleton-paragraph > li:after, .ant-skeleton-active .ant-skeleton-avatar:after, .ant-skeleton-active .ant-skeleton-button:after, .ant-skeleton-active .ant-skeleton-input:after, .ant-skeleton-active .ant-skeleton-image:after {
  content: "";
  background: linear-gradient(90deg, #bebebe33 25%, #8181813d 37%, #bebebe33 63%);
  animation: 1.4s infinite ant-skeleton-loading;
  position: absolute;
  inset: 0 -150%;
}

.ant-skeleton.ant-skeleton-block, .ant-skeleton.ant-skeleton-block .ant-skeleton-button, .ant-skeleton.ant-skeleton-block .ant-skeleton-input {
  width: 100%;
}

.ant-skeleton-element {
  width: auto;
  display: inline-block;
}

.ant-skeleton-element .ant-skeleton-button {
  vertical-align: top;
  background: #bebebe33;
  border-radius: 2px;
  width: 64px;
  min-width: 64px;
  height: 32px;
  line-height: 32px;
  display: inline-block;
}

.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-square {
  width: 32px;
  min-width: 32px;
}

.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-circle {
  border-radius: 50%;
  width: 32px;
  min-width: 32px;
}

.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-round {
  border-radius: 32px;
}

.ant-skeleton-element .ant-skeleton-button-lg {
  width: 80px;
  min-width: 80px;
  height: 40px;
  line-height: 40px;
}

.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-square {
  width: 40px;
  min-width: 40px;
}

.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-circle {
  border-radius: 50%;
  width: 40px;
  min-width: 40px;
}

.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-round {
  border-radius: 40px;
}

.ant-skeleton-element .ant-skeleton-button-sm {
  width: 48px;
  min-width: 48px;
  height: 24px;
  line-height: 24px;
}

.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-square {
  width: 24px;
  min-width: 24px;
}

.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-circle {
  border-radius: 50%;
  width: 24px;
  min-width: 24px;
}

.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-round {
  border-radius: 24px;
}

.ant-skeleton-element .ant-skeleton-avatar {
  vertical-align: top;
  background: #bebebe33;
  width: 32px;
  height: 32px;
  line-height: 32px;
  display: inline-block;
}

.ant-skeleton-element .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}

.ant-skeleton-element .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.ant-skeleton-element .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}

.ant-skeleton-element .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}

.ant-skeleton-element .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}

.ant-skeleton-element .ant-skeleton-input {
  vertical-align: top;
  background: #bebebe33;
  width: 160px;
  min-width: 160px;
  height: 32px;
  line-height: 32px;
  display: inline-block;
}

.ant-skeleton-element .ant-skeleton-input-lg {
  width: 200px;
  min-width: 200px;
  height: 40px;
  line-height: 40px;
}

.ant-skeleton-element .ant-skeleton-input-sm {
  width: 120px;
  min-width: 120px;
  height: 24px;
  line-height: 24px;
}

.ant-skeleton-element .ant-skeleton-image {
  vertical-align: top;
  background: #bebebe33;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 96px;
  line-height: 96px;
  display: flex;
}

.ant-skeleton-element .ant-skeleton-image.ant-skeleton-image-circle {
  border-radius: 50%;
}

.ant-skeleton-element .ant-skeleton-image-path {
  fill: #bfbfbf;
}

.ant-skeleton-element .ant-skeleton-image-svg {
  width: 48px;
  max-width: 192px;
  height: 48px;
  max-height: 192px;
  line-height: 48px;
}

.ant-skeleton-element .ant-skeleton-image-svg.ant-skeleton-image-circle {
  border-radius: 50%;
}

@keyframes ant-skeleton-loading {
  0% {
    transform: translateX(-37.5%);
  }

  100% {
    transform: translateX(37.5%);
  }
}

.ant-skeleton-rtl {
  direction: rtl;
}

.ant-skeleton-rtl .ant-skeleton-header {
  padding-left: 16px;
  padding-right: 0;
}

.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title, .ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li, .ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  animation-name: ant-skeleton-loading-rtl;
}

@keyframes ant-skeleton-loading-rtl {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 100%;
  }
}

.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 0;
  font-size: 14px;
}

.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 16px 0;
  font-size: 16px;
}

.ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 6px 16px;
}

.ant-tabs-card.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 7px 16px 6px;
}

.ant-tabs-rtl {
  direction: rtl;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab {
  margin: 0 0 0 32px;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab:last-of-type {
  margin-left: 0;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .anticon {
  margin-left: 12px;
  margin-right: 0;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove {
  margin-left: -4px;
  margin-right: 8px;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0;
}

.ant-tabs-rtl.ant-tabs-left > .ant-tabs-nav {
  order: 1;
}

.ant-tabs-rtl.ant-tabs-left > .ant-tabs-content-holder, .ant-tabs-rtl.ant-tabs-right > .ant-tabs-nav {
  order: 0;
}

.ant-tabs-rtl.ant-tabs-right > .ant-tabs-content-holder {
  order: 1;
}

.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-add, .ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-add, .ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-add, .ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-add {
  margin-left: 0;
  margin-right: 2px;
}

.ant-tabs-dropdown-rtl {
  direction: rtl;
}

.ant-tabs-dropdown-rtl .ant-tabs-dropdown-menu-item {
  text-align: right;
}

.ant-tabs-top, .ant-tabs-bottom {
  flex-direction: column;
}

.ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 16px;
}

.ant-tabs-top > .ant-tabs-nav:before, .ant-tabs-bottom > .ant-tabs-nav:before, .ant-tabs-top > div > .ant-tabs-nav:before, .ant-tabs-bottom > div > .ant-tabs-nav:before {
  content: "";
  border-bottom: 1px solid #f0f0f0;
  position: absolute;
  left: 0;
  right: 0;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 2px;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar-animated, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar-animated, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: width .3s, left .3s, right .3s;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap:after, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap:after, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap:after, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap:after {
  width: 30px;
  top: 0;
  bottom: 0;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap:before {
  left: 0;
  box-shadow: inset 10px 0 8px -8px #00000014;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap:after, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap:after, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap:after, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap:after {
  right: 0;
  box-shadow: inset -10px 0 8px -8px #00000014;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left:before, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left:before, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left:before, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left:before, .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right:after, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right:after, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right:after, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right:after {
  opacity: 1;
}

.ant-tabs-top > .ant-tabs-nav:before, .ant-tabs-top > div > .ant-tabs-nav:before, .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
  bottom: 0;
}

.ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav {
  order: 1;
  margin-top: 16px;
  margin-bottom: 0;
}

.ant-tabs-bottom > .ant-tabs-nav:before, .ant-tabs-bottom > div > .ant-tabs-nav:before, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  top: 0;
}

.ant-tabs-bottom > .ant-tabs-content-holder, .ant-tabs-bottom > div > .ant-tabs-content-holder {
  order: 0;
}

.ant-tabs-left > .ant-tabs-nav, .ant-tabs-right > .ant-tabs-nav, .ant-tabs-left > div > .ant-tabs-nav, .ant-tabs-right > div > .ant-tabs-nav {
  flex-direction: column;
  min-width: 50px;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-right > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  text-align: center;
  padding: 8px 24px;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin: 16px 0 0;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  flex-direction: column;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap:after, .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap:after, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap:after, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap:after {
  height: 30px;
  left: 0;
  right: 0;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap:before {
  top: 0;
  box-shadow: inset 0 10px 8px -8px #00000014;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap:after, .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap:after, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap:after, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap:after {
  bottom: 0;
  box-shadow: inset 0 -10px 8px -8px #00000014;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top:before, .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top:before, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top:before, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top:before, .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom:after, .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom:after, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom:after, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom:after {
  opacity: 1;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  width: 2px;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar-animated, .ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar-animated, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar-animated, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: height .3s, top .3s;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-operations, .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-operations, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-operations, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-operations {
  flex-direction: column;
  flex: 1 0 auto;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar {
  right: 0;
}

.ant-tabs-left > .ant-tabs-content-holder, .ant-tabs-left > div > .ant-tabs-content-holder {
  border-left: 1px solid #f0f0f0;
  margin-left: -1px;
}

.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane, .ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-left: 24px;
}

.ant-tabs-right > .ant-tabs-nav, .ant-tabs-right > div > .ant-tabs-nav {
  order: 1;
}

.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  left: 0;
}

.ant-tabs-right > .ant-tabs-content-holder, .ant-tabs-right > div > .ant-tabs-content-holder {
  border-right: 1px solid #f0f0f0;
  order: 0;
  margin-right: -1px;
}

.ant-tabs-right > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane, .ant-tabs-right > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-right: 24px;
}

.ant-tabs-dropdown {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  z-index: 1050;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: block;
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.ant-tabs-dropdown-hidden {
  display: none;
}

.ant-tabs-dropdown-menu {
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  max-height: 200px;
  margin: 0;
  padding: 4px 0;
  list-style-type: none;
  overflow: hidden auto;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
}

.ant-tabs-dropdown-menu-item {
  color: #000000d9;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  align-items: center;
  min-width: 120px;
  margin: 0;
  padding: 5px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  transition: all .3s;
  display: flex;
  overflow: hidden;
}

.ant-tabs-dropdown-menu-item > span {
  white-space: nowrap;
  flex: 1;
}

.ant-tabs-dropdown-menu-item-remove {
  color: #00000073;
  cursor: pointer;
  background: none;
  border: 0;
  flex: none;
  margin-left: 12px;
  font-size: 12px;
}

.ant-tabs-dropdown-menu-item-remove:hover {
  color: #40a9ff;
}

.ant-tabs-dropdown-menu-item:hover {
  background: #f5f5f5;
}

.ant-tabs-dropdown-menu-item-disabled, .ant-tabs-dropdown-menu-item-disabled:hover {
  color: #00000040;
  cursor: not-allowed;
  background: none;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  background: #fafafa;
  border: 1px solid #f0f0f0;
  margin: 0;
  padding: 8px 16px;
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: #1890ff;
  background: #fff;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-ink-bar {
  visibility: hidden;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 2px;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 2px 2px 0 0;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: #fff;
}

.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 0 2px 2px;
}

.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-top-color: #fff;
}

.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-top: 2px;
}

.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 2px 0 0 2px;
}

.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-right-color: #fff;
}

.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 2px 2px 0;
}

.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-left-color: #fff;
}

.ant-tabs {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: flex;
}

.ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
  flex: none;
  align-items: center;
  display: flex;
  position: relative;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  white-space: nowrap;
  flex: auto;
  align-self: stretch;
  display: flex;
  position: relative;
  overflow: hidden;
  transform: translate(0);
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap:after, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap:after {
  z-index: 1;
  opacity: 0;
  content: "";
  pointer-events: none;
  transition: opacity .3s;
  position: absolute;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  transition: transform .3s;
  display: flex;
  position: relative;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
  align-self: stretch;
  display: flex;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
  background: none;
  border: 0;
  padding: 8px 16px;
  position: relative;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more:after, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more:after {
  content: "";
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add {
  cursor: pointer;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
  outline: none;
  min-width: 40px;
  margin-left: 2px;
  padding: 0 8px;
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
  color: #40a9ff;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active, .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus {
  color: #096dd9;
}

.ant-tabs-extra-content {
  flex: none;
}

.ant-tabs-centered > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*="ant-tabs-nav-wrap-ping"]), .ant-tabs-centered > div > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*="ant-tabs-nav-wrap-ping"]) {
  justify-content: center;
}

.ant-tabs-ink-bar {
  pointer-events: none;
  background: #1890ff;
  position: absolute;
}

.ant-tabs-tab {
  cursor: pointer;
  background: none;
  border: 0;
  outline: none;
  align-items: center;
  padding: 12px 0;
  font-size: 14px;
  display: inline-flex;
  position: relative;
}

.ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active {
  color: #096dd9;
}

.ant-tabs-tab-btn {
  outline: none;
  transition: all .3s;
}

.ant-tabs-tab-remove {
  color: #00000073;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  flex: none;
  margin-left: 8px;
  margin-right: -4px;
  font-size: 12px;
  transition: all .3s;
}

.ant-tabs-tab-remove:hover {
  color: #000000d9;
}

.ant-tabs-tab:hover {
  color: #40a9ff;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1890ff;
  text-shadow: 0 0 .25px;
}

.ant-tabs-tab.ant-tabs-tab-disabled {
  color: #00000040;
  cursor: not-allowed;
}

.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:focus, .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:focus, .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:active, .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:active {
  color: #00000040;
}

.ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0;
}

.ant-tabs-tab .anticon {
  margin-right: 12px;
}

.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 32px;
}

.ant-tabs-content {
  width: 100%;
  position: relative;
}

.ant-tabs-content-holder {
  flex: auto;
  min-width: 0;
  min-height: 0;
}

.ant-tabs-tabpane {
  outline: none;
}

.ant-tabs-tabpane-hidden {
  display: none;
}

.ant-tabs-switch-appear, .ant-tabs-switch-enter {
  transition: none;
}

.ant-tabs-switch-appear-start, .ant-tabs-switch-enter-start {
  opacity: 0;
}

.ant-tabs-switch-appear-active, .ant-tabs-switch-enter-active {
  opacity: 1;
  transition: opacity .3s;
}

.ant-tabs-switch-leave {
  transition: none;
  position: absolute;
  inset: 0;
}

.ant-tabs-switch-leave-start {
  opacity: 1;
}

.ant-tabs-switch-leave-active {
  opacity: 0;
  transition: opacity .3s;
}

.ant-carousel {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
}

.ant-carousel .slick-slider {
  box-sizing: border-box;
  touch-action: pan-y;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  display: block;
  position: relative;
}

.ant-carousel .slick-list {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.ant-carousel .slick-list:focus {
  outline: none;
}

.ant-carousel .slick-list.dragging {
  cursor: pointer;
}

.ant-carousel .slick-list .slick-slide {
  pointer-events: none;
}

.ant-carousel .slick-list .slick-slide input.ant-radio-input, .ant-carousel .slick-list .slick-slide input.ant-checkbox-input {
  visibility: hidden;
}

.ant-carousel .slick-list .slick-slide.slick-active {
  pointer-events: auto;
}

.ant-carousel .slick-list .slick-slide.slick-active input.ant-radio-input, .ant-carousel .slick-list .slick-slide.slick-active input.ant-checkbox-input {
  visibility: visible;
}

.ant-carousel .slick-list .slick-slide > div > div {
  vertical-align: bottom;
}

.ant-carousel .slick-slider .slick-track, .ant-carousel .slick-slider .slick-list {
  touch-action: pan-y;
  transform: translate3d(0, 0, 0);
}

.ant-carousel .slick-track {
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.ant-carousel .slick-track:before, .ant-carousel .slick-track:after {
  content: "";
  display: table;
}

.ant-carousel .slick-track:after {
  clear: both;
}

.slick-loading .ant-carousel .slick-track {
  visibility: hidden;
}

.ant-carousel .slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

.ant-carousel .slick-slide img {
  display: block;
}

.ant-carousel .slick-slide.slick-loading img {
  display: none;
}

.ant-carousel .slick-slide.dragging img {
  pointer-events: none;
}

.ant-carousel .slick-initialized .slick-slide {
  display: block;
}

.ant-carousel .slick-loading .slick-slide {
  visibility: hidden;
}

.ant-carousel .slick-vertical .slick-slide {
  height: auto;
  display: block;
}

.ant-carousel .slick-arrow.slick-hidden {
  display: none;
}

.ant-carousel .slick-prev, .ant-carousel .slick-next {
  color: #0000;
  cursor: pointer;
  background: none;
  border: 0;
  outline: none;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  font-size: 0;
  line-height: 0;
  display: block;
  position: absolute;
  top: 50%;
}

.ant-carousel .slick-prev:hover, .ant-carousel .slick-next:hover, .ant-carousel .slick-prev:focus, .ant-carousel .slick-next:focus {
  color: #0000;
  background: none;
  outline: none;
}

.ant-carousel .slick-prev:hover:before, .ant-carousel .slick-next:hover:before, .ant-carousel .slick-prev:focus:before, .ant-carousel .slick-next:focus:before {
  opacity: 1;
}

.ant-carousel .slick-prev.slick-disabled:before, .ant-carousel .slick-next.slick-disabled:before {
  opacity: .25;
}

.ant-carousel .slick-prev {
  left: -25px;
}

.ant-carousel .slick-prev:before {
  content: "←";
}

.ant-carousel .slick-next {
  right: -25px;
}

.ant-carousel .slick-next:before {
  content: "→";
}

.ant-carousel .slick-dots {
  z-index: 15;
  justify-content: center;
  margin-bottom: 0;
  margin-left: 15%;
  margin-right: 15%;
  padding-left: 0;
  list-style: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex !important;
}

.ant-carousel .slick-dots-bottom {
  bottom: 12px;
}

.ant-carousel .slick-dots-top {
  top: 12px;
  bottom: auto;
}

.ant-carousel .slick-dots li {
  box-sizing: content-box;
  text-align: center;
  text-indent: -999px;
  vertical-align: top;
  flex: 0 auto;
  width: 16px;
  height: 3px;
  margin: 0 4px;
  padding: 0;
  transition: all .5s;
  display: inline-block;
  position: relative;
}

.ant-carousel .slick-dots li button {
  color: #0000;
  cursor: pointer;
  opacity: .3;
  background: #fff;
  border: 0;
  border-radius: 1px;
  outline: none;
  width: 100%;
  height: 3px;
  padding: 0;
  font-size: 0;
  transition: all .5s;
  display: block;
  position: relative;
}

.ant-carousel .slick-dots li button:hover, .ant-carousel .slick-dots li button:focus {
  opacity: .75;
}

.ant-carousel .slick-dots li button:after {
  content: "";
  position: absolute;
  inset: -4px;
}

.ant-carousel .slick-dots li.slick-active {
  width: 24px;
}

.ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  background: #fff;
}

.ant-carousel .slick-dots li.slick-active:hover, .ant-carousel .slick-dots li.slick-active:focus {
  opacity: 1;
}

.ant-carousel-vertical .slick-dots {
  flex-direction: column;
  width: 3px;
  height: auto;
  margin: 0;
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
}

.ant-carousel-vertical .slick-dots-left {
  left: 12px;
  right: auto;
}

.ant-carousel-vertical .slick-dots-right {
  left: auto;
  right: 12px;
}

.ant-carousel-vertical .slick-dots li {
  vertical-align: baseline;
  width: 3px;
  height: 16px;
  margin: 4px 0;
}

.ant-carousel-vertical .slick-dots li button {
  width: 3px;
  height: 16px;
}

.ant-carousel-vertical .slick-dots li.slick-active, .ant-carousel-vertical .slick-dots li.slick-active button {
  width: 3px;
  height: 24px;
}

.ant-carousel-rtl {
  direction: rtl;
}

.ant-carousel-rtl .ant-carousel .slick-track {
  left: auto;
  right: 0;
}

.ant-carousel-rtl .ant-carousel .slick-prev {
  left: auto;
  right: -25px;
}

.ant-carousel-rtl .ant-carousel .slick-prev:before {
  content: "→";
}

.ant-carousel-rtl .ant-carousel .slick-next {
  left: -25px;
  right: auto;
}

.ant-carousel-rtl .ant-carousel .slick-next:before {
  content: "←";
}

.ant-carousel-rtl.ant-carousel .slick-dots {
  flex-direction: row-reverse;
}

.ant-carousel-rtl.ant-carousel-vertical .slick-dots {
  flex-direction: column;
}

@keyframes antCheckboxEffect {
  0% {
    opacity: .5;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1.6);
  }
}

.ant-cascader-checkbox {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1;
  list-style: none;
  position: relative;
  top: .2em;
}

.ant-cascader-checkbox-wrapper:hover .ant-cascader-checkbox-inner, .ant-cascader-checkbox:hover .ant-cascader-checkbox-inner, .ant-cascader-checkbox-input:focus + .ant-cascader-checkbox-inner {
  border-color: #1890ff;
}

.ant-cascader-checkbox-checked:after {
  visibility: hidden;
  content: "";
  border: 1px solid #1890ff;
  border-radius: 2px;
  width: 100%;
  height: 100%;
  animation: .36s ease-in-out backwards antCheckboxEffect;
  position: absolute;
  top: 0;
  left: 0;
}

.ant-cascader-checkbox:hover:after, .ant-cascader-checkbox-wrapper:hover .ant-cascader-checkbox:after {
  visibility: visible;
}

.ant-cascader-checkbox-inner {
  border-collapse: separate;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  transition: all .3s;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.ant-cascader-checkbox-inner:after {
  opacity: 0;
  content: " ";
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  width: 5.71429px;
  height: 9.14286px;
  transition: all .1s cubic-bezier(.71, -.46, .88, .6), opacity .1s;
  display: table;
  position: absolute;
  top: 50%;
  left: 21.5%;
  transform: rotate(45deg)scale(0)translate(-50%, -50%);
}

.ant-cascader-checkbox-input {
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner:after {
  opacity: 1;
  content: " ";
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transition: all .2s cubic-bezier(.12, .4, .29, 1.46) .1s;
  display: table;
  position: absolute;
  transform: rotate(45deg)scale(1)translate(-50%, -50%);
}

.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}

.ant-cascader-checkbox-disabled {
  cursor: not-allowed;
}

.ant-cascader-checkbox-disabled.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner:after {
  border-color: #00000040;
  animation-name: none;
}

.ant-cascader-checkbox-disabled .ant-cascader-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}

.ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}

.ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner:after {
  border-collapse: separate;
  border-color: #f5f5f5;
  animation-name: none;
}

.ant-cascader-checkbox-disabled + span {
  color: #00000040;
  cursor: not-allowed;
}

.ant-cascader-checkbox-disabled:hover:after, .ant-cascader-checkbox-wrapper:hover .ant-cascader-checkbox-disabled:after {
  visibility: hidden;
}

.ant-cascader-checkbox-wrapper {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  font-size: 14px;
  line-height: 1.5715;
  line-height: unset;
  cursor: pointer;
  align-items: baseline;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-flex;
}

.ant-cascader-checkbox-wrapper:after {
  content: " ";
  width: 0;
  display: inline-block;
  overflow: hidden;
}

.ant-cascader-checkbox-wrapper.ant-cascader-checkbox-wrapper-disabled {
  cursor: not-allowed;
}

.ant-cascader-checkbox-wrapper + .ant-cascader-checkbox-wrapper {
  margin-left: 8px;
}

.ant-cascader-checkbox-wrapper.ant-cascader-checkbox-wrapper-in-form-item input[type="checkbox"] {
  width: 14px;
  height: 14px;
}

.ant-cascader-checkbox + span {
  padding-left: 8px;
  padding-right: 8px;
}

.ant-cascader-checkbox-group {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: inline-block;
}

.ant-cascader-checkbox-group-item {
  margin-right: 8px;
}

.ant-cascader-checkbox-group-item:last-child {
  margin-right: 0;
}

.ant-cascader-checkbox-group-item + .ant-cascader-checkbox-group-item {
  margin-left: 0;
}

.ant-cascader-checkbox-indeterminate .ant-cascader-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}

.ant-cascader-checkbox-indeterminate .ant-cascader-checkbox-inner:after {
  opacity: 1;
  content: " ";
  background-color: #1890ff;
  border: 0;
  width: 8px;
  height: 8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(1);
}

.ant-cascader-checkbox-indeterminate.ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner:after {
  background-color: #00000040;
  border-color: #00000040;
}

.ant-cascader {
  width: 184px;
}

.ant-cascader-checkbox {
  margin-right: 8px;
  top: 0;
}

.ant-cascader-menus {
  flex-wrap: nowrap;
  align-items: flex-start;
  display: flex;
}

.ant-cascader-menus.ant-cascader-menu-empty .ant-cascader-menu {
  width: 100%;
  height: auto;
}

.ant-cascader-menu {
  vertical-align: top;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  border-right: 1px solid #f0f0f0;
  flex-grow: 1;
  min-width: 111px;
  height: 180px;
  margin: -4px 0;
  padding: 4px 0;
  list-style: none;
  overflow: auto;
}

.ant-cascader-menu-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  flex-wrap: nowrap;
  align-items: center;
  padding: 5px 12px;
  line-height: 22px;
  transition: all .3s;
  display: flex;
  overflow: hidden;
}

.ant-cascader-menu-item:hover {
  background: #f5f5f5;
}

.ant-cascader-menu-item-disabled {
  color: #00000040;
  cursor: not-allowed;
}

.ant-cascader-menu-item-disabled:hover {
  background: none;
}

.ant-cascader-menu-empty .ant-cascader-menu-item {
  color: #00000040;
  cursor: default;
  pointer-events: none;
}

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled), .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  background-color: #e6f7ff;
  font-weight: 600;
}

.ant-cascader-menu-item-content {
  flex: auto;
}

.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon, .ant-cascader-menu-item-loading-icon {
  color: #00000073;
  margin-left: 4px;
  font-size: 10px;
}

.ant-cascader-menu-item-disabled.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon, .ant-cascader-menu-item-disabled.ant-cascader-menu-item-loading-icon {
  color: #00000040;
}

.ant-cascader-menu-item-keyword {
  color: #ff4d4f;
}

.ant-cascader-compact-item:not(.ant-cascader-compact-last-item):not(.ant-cascader-compact-item-rtl) {
  margin-right: -1px;
}

.ant-cascader-compact-item:not(.ant-cascader-compact-last-item).ant-cascader-compact-item-rtl {
  margin-left: -1px;
}

.ant-cascader-compact-item:hover, .ant-cascader-compact-item:focus, .ant-cascader-compact-item:active {
  z-index: 2;
}

.ant-cascader-compact-item[disabled] {
  z-index: 0;
}

.ant-cascader-compact-item:not(.ant-cascader-compact-first-item):not(.ant-cascader-compact-last-item).ant-cascader {
  border-radius: 0;
}

.ant-cascader-compact-item.ant-cascader.ant-cascader-compact-first-item:not(.ant-cascader-compact-last-item):not(.ant-cascader-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-cascader-compact-item.ant-cascader.ant-cascader-compact-last-item:not(.ant-cascader-compact-first-item):not(.ant-cascader-compact-item-rtl), .ant-cascader-compact-item.ant-cascader.ant-cascader-compact-item-rtl.ant-cascader-compact-first-item:not(.ant-cascader-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-cascader-compact-item.ant-cascader.ant-cascader-compact-item-rtl.ant-cascader-compact-last-item:not(.ant-cascader-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-cascader-rtl .ant-cascader-menu-item-expand-icon, .ant-cascader-rtl .ant-cascader-menu-item-loading-icon {
  margin-left: 0;
  margin-right: 4px;
}

.ant-cascader-rtl .ant-cascader-checkbox {
  margin-left: 8px;
  margin-right: 0;
  top: 0;
}

.ant-checkbox {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1;
  list-style: none;
  position: relative;
  top: .2em;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #1890ff;
}

.ant-checkbox-checked:after {
  visibility: hidden;
  content: "";
  border: 1px solid #1890ff;
  border-radius: 2px;
  width: 100%;
  height: 100%;
  animation: .36s ease-in-out backwards antCheckboxEffect;
  position: absolute;
  top: 0;
  left: 0;
}

.ant-checkbox:hover:after, .ant-checkbox-wrapper:hover .ant-checkbox:after {
  visibility: visible;
}

.ant-checkbox-inner {
  border-collapse: separate;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  transition: all .3s;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.ant-checkbox-inner:after {
  opacity: 0;
  content: " ";
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  width: 5.71429px;
  height: 9.14286px;
  transition: all .1s cubic-bezier(.71, -.46, .88, .6), opacity .1s;
  display: table;
  position: absolute;
  top: 50%;
  left: 21.5%;
  transform: rotate(45deg)scale(0)translate(-50%, -50%);
}

.ant-checkbox-input {
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.ant-checkbox-checked .ant-checkbox-inner:after {
  opacity: 1;
  content: " ";
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transition: all .2s cubic-bezier(.12, .4, .29, 1.46) .1s;
  display: table;
  position: absolute;
  transform: rotate(45deg)scale(1)translate(-50%, -50%);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}

.ant-checkbox-disabled {
  cursor: not-allowed;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: #00000040;
  animation-name: none;
}

.ant-checkbox-disabled .ant-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}

.ant-checkbox-disabled .ant-checkbox-inner:after {
  border-collapse: separate;
  border-color: #f5f5f5;
  animation-name: none;
}

.ant-checkbox-disabled + span {
  color: #00000040;
  cursor: not-allowed;
}

.ant-checkbox-disabled:hover:after, .ant-checkbox-wrapper:hover .ant-checkbox-disabled:after {
  visibility: hidden;
}

.ant-checkbox-wrapper {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  font-size: 14px;
  line-height: 1.5715;
  line-height: unset;
  cursor: pointer;
  align-items: baseline;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-flex;
}

.ant-checkbox-wrapper:after {
  content: " ";
  width: 0;
  display: inline-block;
  overflow: hidden;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
  cursor: not-allowed;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 8px;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item input[type="checkbox"] {
  width: 14px;
  height: 14px;
}

.ant-checkbox + span {
  padding-left: 8px;
  padding-right: 8px;
}

.ant-checkbox-group {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: inline-block;
}

.ant-checkbox-group-item {
  margin-right: 8px;
}

.ant-checkbox-group-item:last-child {
  margin-right: 0;
}

.ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 0;
}

.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  opacity: 1;
  content: " ";
  background-color: #1890ff;
  border: 0;
  width: 8px;
  height: 8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(1);
}

.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner:after {
  background-color: #00000040;
  border-color: #00000040;
}

.ant-checkbox-rtl {
  direction: rtl;
}

.ant-checkbox-group-rtl .ant-checkbox-group-item {
  margin-left: 8px;
  margin-right: 0;
}

.ant-checkbox-group-rtl .ant-checkbox-group-item:last-child {
  margin-left: 0 !important;
}

.ant-checkbox-group-rtl .ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 8px;
}

.ant-row {
  flex-flow: wrap;
  min-width: 0;
  display: flex;
}

.ant-row:before, .ant-row:after {
  display: flex;
}

.ant-row-no-wrap {
  flex-wrap: nowrap;
}

.ant-row-start {
  justify-content: flex-start;
}

.ant-row-center {
  justify-content: center;
}

.ant-row-end {
  justify-content: flex-end;
}

.ant-row-space-between {
  justify-content: space-between;
}

.ant-row-space-around {
  justify-content: space-around;
}

.ant-row-space-evenly {
  justify-content: space-evenly;
}

.ant-row-top {
  align-items: flex-start;
}

.ant-row-middle {
  align-items: center;
}

.ant-row-bottom {
  align-items: flex-end;
}

.ant-col {
  max-width: 100%;
  min-height: 1px;
  position: relative;
}

.ant-col-24 {
  flex: 0 0 100%;
  max-width: 100%;
  display: block;
}

.ant-col-push-24 {
  left: 100%;
}

.ant-col-pull-24 {
  right: 100%;
}

.ant-col-offset-24 {
  margin-left: 100%;
}

.ant-col-order-24 {
  order: 24;
}

.ant-col-23 {
  flex: 0 0 95.8333%;
  max-width: 95.8333%;
  display: block;
}

.ant-col-push-23 {
  left: 95.8333%;
}

.ant-col-pull-23 {
  right: 95.8333%;
}

.ant-col-offset-23 {
  margin-left: 95.8333%;
}

.ant-col-order-23 {
  order: 23;
}

.ant-col-22 {
  flex: 0 0 91.6667%;
  max-width: 91.6667%;
  display: block;
}

.ant-col-push-22 {
  left: 91.6667%;
}

.ant-col-pull-22 {
  right: 91.6667%;
}

.ant-col-offset-22 {
  margin-left: 91.6667%;
}

.ant-col-order-22 {
  order: 22;
}

.ant-col-21 {
  flex: 0 0 87.5%;
  max-width: 87.5%;
  display: block;
}

.ant-col-push-21 {
  left: 87.5%;
}

.ant-col-pull-21 {
  right: 87.5%;
}

.ant-col-offset-21 {
  margin-left: 87.5%;
}

.ant-col-order-21 {
  order: 21;
}

.ant-col-20 {
  flex: 0 0 83.3333%;
  max-width: 83.3333%;
  display: block;
}

.ant-col-push-20 {
  left: 83.3333%;
}

.ant-col-pull-20 {
  right: 83.3333%;
}

.ant-col-offset-20 {
  margin-left: 83.3333%;
}

.ant-col-order-20 {
  order: 20;
}

.ant-col-19 {
  flex: 0 0 79.1667%;
  max-width: 79.1667%;
  display: block;
}

.ant-col-push-19 {
  left: 79.1667%;
}

.ant-col-pull-19 {
  right: 79.1667%;
}

.ant-col-offset-19 {
  margin-left: 79.1667%;
}

.ant-col-order-19 {
  order: 19;
}

.ant-col-18 {
  flex: 0 0 75%;
  max-width: 75%;
  display: block;
}

.ant-col-push-18 {
  left: 75%;
}

.ant-col-pull-18 {
  right: 75%;
}

.ant-col-offset-18 {
  margin-left: 75%;
}

.ant-col-order-18 {
  order: 18;
}

.ant-col-17 {
  flex: 0 0 70.8333%;
  max-width: 70.8333%;
  display: block;
}

.ant-col-push-17 {
  left: 70.8333%;
}

.ant-col-pull-17 {
  right: 70.8333%;
}

.ant-col-offset-17 {
  margin-left: 70.8333%;
}

.ant-col-order-17 {
  order: 17;
}

.ant-col-16 {
  flex: 0 0 66.6667%;
  max-width: 66.6667%;
  display: block;
}

.ant-col-push-16 {
  left: 66.6667%;
}

.ant-col-pull-16 {
  right: 66.6667%;
}

.ant-col-offset-16 {
  margin-left: 66.6667%;
}

.ant-col-order-16 {
  order: 16;
}

.ant-col-15 {
  flex: 0 0 62.5%;
  max-width: 62.5%;
  display: block;
}

.ant-col-push-15 {
  left: 62.5%;
}

.ant-col-pull-15 {
  right: 62.5%;
}

.ant-col-offset-15 {
  margin-left: 62.5%;
}

.ant-col-order-15 {
  order: 15;
}

.ant-col-14 {
  flex: 0 0 58.3333%;
  max-width: 58.3333%;
  display: block;
}

.ant-col-push-14 {
  left: 58.3333%;
}

.ant-col-pull-14 {
  right: 58.3333%;
}

.ant-col-offset-14 {
  margin-left: 58.3333%;
}

.ant-col-order-14 {
  order: 14;
}

.ant-col-13 {
  flex: 0 0 54.1667%;
  max-width: 54.1667%;
  display: block;
}

.ant-col-push-13 {
  left: 54.1667%;
}

.ant-col-pull-13 {
  right: 54.1667%;
}

.ant-col-offset-13 {
  margin-left: 54.1667%;
}

.ant-col-order-13 {
  order: 13;
}

.ant-col-12 {
  flex: 0 0 50%;
  max-width: 50%;
  display: block;
}

.ant-col-push-12 {
  left: 50%;
}

.ant-col-pull-12 {
  right: 50%;
}

.ant-col-offset-12 {
  margin-left: 50%;
}

.ant-col-order-12 {
  order: 12;
}

.ant-col-11 {
  flex: 0 0 45.8333%;
  max-width: 45.8333%;
  display: block;
}

.ant-col-push-11 {
  left: 45.8333%;
}

.ant-col-pull-11 {
  right: 45.8333%;
}

.ant-col-offset-11 {
  margin-left: 45.8333%;
}

.ant-col-order-11 {
  order: 11;
}

.ant-col-10 {
  flex: 0 0 41.6667%;
  max-width: 41.6667%;
  display: block;
}

.ant-col-push-10 {
  left: 41.6667%;
}

.ant-col-pull-10 {
  right: 41.6667%;
}

.ant-col-offset-10 {
  margin-left: 41.6667%;
}

.ant-col-order-10 {
  order: 10;
}

.ant-col-9 {
  flex: 0 0 37.5%;
  max-width: 37.5%;
  display: block;
}

.ant-col-push-9 {
  left: 37.5%;
}

.ant-col-pull-9 {
  right: 37.5%;
}

.ant-col-offset-9 {
  margin-left: 37.5%;
}

.ant-col-order-9 {
  order: 9;
}

.ant-col-8 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  display: block;
}

.ant-col-push-8 {
  left: 33.3333%;
}

.ant-col-pull-8 {
  right: 33.3333%;
}

.ant-col-offset-8 {
  margin-left: 33.3333%;
}

.ant-col-order-8 {
  order: 8;
}

.ant-col-7 {
  flex: 0 0 29.1667%;
  max-width: 29.1667%;
  display: block;
}

.ant-col-push-7 {
  left: 29.1667%;
}

.ant-col-pull-7 {
  right: 29.1667%;
}

.ant-col-offset-7 {
  margin-left: 29.1667%;
}

.ant-col-order-7 {
  order: 7;
}

.ant-col-6 {
  flex: 0 0 25%;
  max-width: 25%;
  display: block;
}

.ant-col-push-6 {
  left: 25%;
}

.ant-col-pull-6 {
  right: 25%;
}

.ant-col-offset-6 {
  margin-left: 25%;
}

.ant-col-order-6 {
  order: 6;
}

.ant-col-5 {
  flex: 0 0 20.8333%;
  max-width: 20.8333%;
  display: block;
}

.ant-col-push-5 {
  left: 20.8333%;
}

.ant-col-pull-5 {
  right: 20.8333%;
}

.ant-col-offset-5 {
  margin-left: 20.8333%;
}

.ant-col-order-5 {
  order: 5;
}

.ant-col-4 {
  flex: 0 0 16.6667%;
  max-width: 16.6667%;
  display: block;
}

.ant-col-push-4 {
  left: 16.6667%;
}

.ant-col-pull-4 {
  right: 16.6667%;
}

.ant-col-offset-4 {
  margin-left: 16.6667%;
}

.ant-col-order-4 {
  order: 4;
}

.ant-col-3 {
  flex: 0 0 12.5%;
  max-width: 12.5%;
  display: block;
}

.ant-col-push-3 {
  left: 12.5%;
}

.ant-col-pull-3 {
  right: 12.5%;
}

.ant-col-offset-3 {
  margin-left: 12.5%;
}

.ant-col-order-3 {
  order: 3;
}

.ant-col-2 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
  display: block;
}

.ant-col-push-2 {
  left: 8.33333%;
}

.ant-col-pull-2 {
  right: 8.33333%;
}

.ant-col-offset-2 {
  margin-left: 8.33333%;
}

.ant-col-order-2 {
  order: 2;
}

.ant-col-1 {
  flex: 0 0 4.16667%;
  max-width: 4.16667%;
  display: block;
}

.ant-col-push-1 {
  left: 4.16667%;
}

.ant-col-pull-1 {
  right: 4.16667%;
}

.ant-col-offset-1 {
  margin-left: 4.16667%;
}

.ant-col-order-1 {
  order: 1;
}

.ant-col-0 {
  display: none;
}

.ant-col-offset-0 {
  margin-left: 0;
}

.ant-col-order-0 {
  order: 0;
}

.ant-col-offset-0.ant-col-rtl {
  margin-right: 0;
}

.ant-col-push-1.ant-col-rtl {
  left: auto;
  right: 4.16667%;
}

.ant-col-pull-1.ant-col-rtl {
  left: 4.16667%;
  right: auto;
}

.ant-col-offset-1.ant-col-rtl {
  margin-left: 0;
  margin-right: 4.16667%;
}

.ant-col-push-2.ant-col-rtl {
  left: auto;
  right: 8.33333%;
}

.ant-col-pull-2.ant-col-rtl {
  left: 8.33333%;
  right: auto;
}

.ant-col-offset-2.ant-col-rtl {
  margin-left: 0;
  margin-right: 8.33333%;
}

.ant-col-push-3.ant-col-rtl {
  left: auto;
  right: 12.5%;
}

.ant-col-pull-3.ant-col-rtl {
  left: 12.5%;
  right: auto;
}

.ant-col-offset-3.ant-col-rtl {
  margin-left: 0;
  margin-right: 12.5%;
}

.ant-col-push-4.ant-col-rtl {
  left: auto;
  right: 16.6667%;
}

.ant-col-pull-4.ant-col-rtl {
  left: 16.6667%;
  right: auto;
}

.ant-col-offset-4.ant-col-rtl {
  margin-left: 0;
  margin-right: 16.6667%;
}

.ant-col-push-5.ant-col-rtl {
  left: auto;
  right: 20.8333%;
}

.ant-col-pull-5.ant-col-rtl {
  left: 20.8333%;
  right: auto;
}

.ant-col-offset-5.ant-col-rtl {
  margin-left: 0;
  margin-right: 20.8333%;
}

.ant-col-push-6.ant-col-rtl {
  left: auto;
  right: 25%;
}

.ant-col-pull-6.ant-col-rtl {
  left: 25%;
  right: auto;
}

.ant-col-offset-6.ant-col-rtl {
  margin-left: 0;
  margin-right: 25%;
}

.ant-col-push-7.ant-col-rtl {
  left: auto;
  right: 29.1667%;
}

.ant-col-pull-7.ant-col-rtl {
  left: 29.1667%;
  right: auto;
}

.ant-col-offset-7.ant-col-rtl {
  margin-left: 0;
  margin-right: 29.1667%;
}

.ant-col-push-8.ant-col-rtl {
  left: auto;
  right: 33.3333%;
}

.ant-col-pull-8.ant-col-rtl {
  left: 33.3333%;
  right: auto;
}

.ant-col-offset-8.ant-col-rtl {
  margin-left: 0;
  margin-right: 33.3333%;
}

.ant-col-push-9.ant-col-rtl {
  left: auto;
  right: 37.5%;
}

.ant-col-pull-9.ant-col-rtl {
  left: 37.5%;
  right: auto;
}

.ant-col-offset-9.ant-col-rtl {
  margin-left: 0;
  margin-right: 37.5%;
}

.ant-col-push-10.ant-col-rtl {
  left: auto;
  right: 41.6667%;
}

.ant-col-pull-10.ant-col-rtl {
  left: 41.6667%;
  right: auto;
}

.ant-col-offset-10.ant-col-rtl {
  margin-left: 0;
  margin-right: 41.6667%;
}

.ant-col-push-11.ant-col-rtl {
  left: auto;
  right: 45.8333%;
}

.ant-col-pull-11.ant-col-rtl {
  left: 45.8333%;
  right: auto;
}

.ant-col-offset-11.ant-col-rtl {
  margin-left: 0;
  margin-right: 45.8333%;
}

.ant-col-push-12.ant-col-rtl {
  left: auto;
  right: 50%;
}

.ant-col-pull-12.ant-col-rtl {
  left: 50%;
  right: auto;
}

.ant-col-offset-12.ant-col-rtl {
  margin-left: 0;
  margin-right: 50%;
}

.ant-col-push-13.ant-col-rtl {
  left: auto;
  right: 54.1667%;
}

.ant-col-pull-13.ant-col-rtl {
  left: 54.1667%;
  right: auto;
}

.ant-col-offset-13.ant-col-rtl {
  margin-left: 0;
  margin-right: 54.1667%;
}

.ant-col-push-14.ant-col-rtl {
  left: auto;
  right: 58.3333%;
}

.ant-col-pull-14.ant-col-rtl {
  left: 58.3333%;
  right: auto;
}

.ant-col-offset-14.ant-col-rtl {
  margin-left: 0;
  margin-right: 58.3333%;
}

.ant-col-push-15.ant-col-rtl {
  left: auto;
  right: 62.5%;
}

.ant-col-pull-15.ant-col-rtl {
  left: 62.5%;
  right: auto;
}

.ant-col-offset-15.ant-col-rtl {
  margin-left: 0;
  margin-right: 62.5%;
}

.ant-col-push-16.ant-col-rtl {
  left: auto;
  right: 66.6667%;
}

.ant-col-pull-16.ant-col-rtl {
  left: 66.6667%;
  right: auto;
}

.ant-col-offset-16.ant-col-rtl {
  margin-left: 0;
  margin-right: 66.6667%;
}

.ant-col-push-17.ant-col-rtl {
  left: auto;
  right: 70.8333%;
}

.ant-col-pull-17.ant-col-rtl {
  left: 70.8333%;
  right: auto;
}

.ant-col-offset-17.ant-col-rtl {
  margin-left: 0;
  margin-right: 70.8333%;
}

.ant-col-push-18.ant-col-rtl {
  left: auto;
  right: 75%;
}

.ant-col-pull-18.ant-col-rtl {
  left: 75%;
  right: auto;
}

.ant-col-offset-18.ant-col-rtl {
  margin-left: 0;
  margin-right: 75%;
}

.ant-col-push-19.ant-col-rtl {
  left: auto;
  right: 79.1667%;
}

.ant-col-pull-19.ant-col-rtl {
  left: 79.1667%;
  right: auto;
}

.ant-col-offset-19.ant-col-rtl {
  margin-left: 0;
  margin-right: 79.1667%;
}

.ant-col-push-20.ant-col-rtl {
  left: auto;
  right: 83.3333%;
}

.ant-col-pull-20.ant-col-rtl {
  left: 83.3333%;
  right: auto;
}

.ant-col-offset-20.ant-col-rtl {
  margin-left: 0;
  margin-right: 83.3333%;
}

.ant-col-push-21.ant-col-rtl {
  left: auto;
  right: 87.5%;
}

.ant-col-pull-21.ant-col-rtl {
  left: 87.5%;
  right: auto;
}

.ant-col-offset-21.ant-col-rtl {
  margin-left: 0;
  margin-right: 87.5%;
}

.ant-col-push-22.ant-col-rtl {
  left: auto;
  right: 91.6667%;
}

.ant-col-pull-22.ant-col-rtl {
  left: 91.6667%;
  right: auto;
}

.ant-col-offset-22.ant-col-rtl {
  margin-left: 0;
  margin-right: 91.6667%;
}

.ant-col-push-23.ant-col-rtl {
  left: auto;
  right: 95.8333%;
}

.ant-col-pull-23.ant-col-rtl {
  left: 95.8333%;
  right: auto;
}

.ant-col-offset-23.ant-col-rtl {
  margin-left: 0;
  margin-right: 95.8333%;
}

.ant-col-push-24.ant-col-rtl {
  left: auto;
  right: 100%;
}

.ant-col-pull-24.ant-col-rtl {
  left: 100%;
  right: auto;
}

.ant-col-offset-24.ant-col-rtl {
  margin-left: 0;
  margin-right: 100%;
}

.ant-col-xs-24 {
  flex: 0 0 100%;
  max-width: 100%;
  display: block;
}

.ant-col-xs-push-24 {
  left: 100%;
}

.ant-col-xs-pull-24 {
  right: 100%;
}

.ant-col-xs-offset-24 {
  margin-left: 100%;
}

.ant-col-xs-order-24 {
  order: 24;
}

.ant-col-xs-23 {
  flex: 0 0 95.8333%;
  max-width: 95.8333%;
  display: block;
}

.ant-col-xs-push-23 {
  left: 95.8333%;
}

.ant-col-xs-pull-23 {
  right: 95.8333%;
}

.ant-col-xs-offset-23 {
  margin-left: 95.8333%;
}

.ant-col-xs-order-23 {
  order: 23;
}

.ant-col-xs-22 {
  flex: 0 0 91.6667%;
  max-width: 91.6667%;
  display: block;
}

.ant-col-xs-push-22 {
  left: 91.6667%;
}

.ant-col-xs-pull-22 {
  right: 91.6667%;
}

.ant-col-xs-offset-22 {
  margin-left: 91.6667%;
}

.ant-col-xs-order-22 {
  order: 22;
}

.ant-col-xs-21 {
  flex: 0 0 87.5%;
  max-width: 87.5%;
  display: block;
}

.ant-col-xs-push-21 {
  left: 87.5%;
}

.ant-col-xs-pull-21 {
  right: 87.5%;
}

.ant-col-xs-offset-21 {
  margin-left: 87.5%;
}

.ant-col-xs-order-21 {
  order: 21;
}

.ant-col-xs-20 {
  flex: 0 0 83.3333%;
  max-width: 83.3333%;
  display: block;
}

.ant-col-xs-push-20 {
  left: 83.3333%;
}

.ant-col-xs-pull-20 {
  right: 83.3333%;
}

.ant-col-xs-offset-20 {
  margin-left: 83.3333%;
}

.ant-col-xs-order-20 {
  order: 20;
}

.ant-col-xs-19 {
  flex: 0 0 79.1667%;
  max-width: 79.1667%;
  display: block;
}

.ant-col-xs-push-19 {
  left: 79.1667%;
}

.ant-col-xs-pull-19 {
  right: 79.1667%;
}

.ant-col-xs-offset-19 {
  margin-left: 79.1667%;
}

.ant-col-xs-order-19 {
  order: 19;
}

.ant-col-xs-18 {
  flex: 0 0 75%;
  max-width: 75%;
  display: block;
}

.ant-col-xs-push-18 {
  left: 75%;
}

.ant-col-xs-pull-18 {
  right: 75%;
}

.ant-col-xs-offset-18 {
  margin-left: 75%;
}

.ant-col-xs-order-18 {
  order: 18;
}

.ant-col-xs-17 {
  flex: 0 0 70.8333%;
  max-width: 70.8333%;
  display: block;
}

.ant-col-xs-push-17 {
  left: 70.8333%;
}

.ant-col-xs-pull-17 {
  right: 70.8333%;
}

.ant-col-xs-offset-17 {
  margin-left: 70.8333%;
}

.ant-col-xs-order-17 {
  order: 17;
}

.ant-col-xs-16 {
  flex: 0 0 66.6667%;
  max-width: 66.6667%;
  display: block;
}

.ant-col-xs-push-16 {
  left: 66.6667%;
}

.ant-col-xs-pull-16 {
  right: 66.6667%;
}

.ant-col-xs-offset-16 {
  margin-left: 66.6667%;
}

.ant-col-xs-order-16 {
  order: 16;
}

.ant-col-xs-15 {
  flex: 0 0 62.5%;
  max-width: 62.5%;
  display: block;
}

.ant-col-xs-push-15 {
  left: 62.5%;
}

.ant-col-xs-pull-15 {
  right: 62.5%;
}

.ant-col-xs-offset-15 {
  margin-left: 62.5%;
}

.ant-col-xs-order-15 {
  order: 15;
}

.ant-col-xs-14 {
  flex: 0 0 58.3333%;
  max-width: 58.3333%;
  display: block;
}

.ant-col-xs-push-14 {
  left: 58.3333%;
}

.ant-col-xs-pull-14 {
  right: 58.3333%;
}

.ant-col-xs-offset-14 {
  margin-left: 58.3333%;
}

.ant-col-xs-order-14 {
  order: 14;
}

.ant-col-xs-13 {
  flex: 0 0 54.1667%;
  max-width: 54.1667%;
  display: block;
}

.ant-col-xs-push-13 {
  left: 54.1667%;
}

.ant-col-xs-pull-13 {
  right: 54.1667%;
}

.ant-col-xs-offset-13 {
  margin-left: 54.1667%;
}

.ant-col-xs-order-13 {
  order: 13;
}

.ant-col-xs-12 {
  flex: 0 0 50%;
  max-width: 50%;
  display: block;
}

.ant-col-xs-push-12 {
  left: 50%;
}

.ant-col-xs-pull-12 {
  right: 50%;
}

.ant-col-xs-offset-12 {
  margin-left: 50%;
}

.ant-col-xs-order-12 {
  order: 12;
}

.ant-col-xs-11 {
  flex: 0 0 45.8333%;
  max-width: 45.8333%;
  display: block;
}

.ant-col-xs-push-11 {
  left: 45.8333%;
}

.ant-col-xs-pull-11 {
  right: 45.8333%;
}

.ant-col-xs-offset-11 {
  margin-left: 45.8333%;
}

.ant-col-xs-order-11 {
  order: 11;
}

.ant-col-xs-10 {
  flex: 0 0 41.6667%;
  max-width: 41.6667%;
  display: block;
}

.ant-col-xs-push-10 {
  left: 41.6667%;
}

.ant-col-xs-pull-10 {
  right: 41.6667%;
}

.ant-col-xs-offset-10 {
  margin-left: 41.6667%;
}

.ant-col-xs-order-10 {
  order: 10;
}

.ant-col-xs-9 {
  flex: 0 0 37.5%;
  max-width: 37.5%;
  display: block;
}

.ant-col-xs-push-9 {
  left: 37.5%;
}

.ant-col-xs-pull-9 {
  right: 37.5%;
}

.ant-col-xs-offset-9 {
  margin-left: 37.5%;
}

.ant-col-xs-order-9 {
  order: 9;
}

.ant-col-xs-8 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  display: block;
}

.ant-col-xs-push-8 {
  left: 33.3333%;
}

.ant-col-xs-pull-8 {
  right: 33.3333%;
}

.ant-col-xs-offset-8 {
  margin-left: 33.3333%;
}

.ant-col-xs-order-8 {
  order: 8;
}

.ant-col-xs-7 {
  flex: 0 0 29.1667%;
  max-width: 29.1667%;
  display: block;
}

.ant-col-xs-push-7 {
  left: 29.1667%;
}

.ant-col-xs-pull-7 {
  right: 29.1667%;
}

.ant-col-xs-offset-7 {
  margin-left: 29.1667%;
}

.ant-col-xs-order-7 {
  order: 7;
}

.ant-col-xs-6 {
  flex: 0 0 25%;
  max-width: 25%;
  display: block;
}

.ant-col-xs-push-6 {
  left: 25%;
}

.ant-col-xs-pull-6 {
  right: 25%;
}

.ant-col-xs-offset-6 {
  margin-left: 25%;
}

.ant-col-xs-order-6 {
  order: 6;
}

.ant-col-xs-5 {
  flex: 0 0 20.8333%;
  max-width: 20.8333%;
  display: block;
}

.ant-col-xs-push-5 {
  left: 20.8333%;
}

.ant-col-xs-pull-5 {
  right: 20.8333%;
}

.ant-col-xs-offset-5 {
  margin-left: 20.8333%;
}

.ant-col-xs-order-5 {
  order: 5;
}

.ant-col-xs-4 {
  flex: 0 0 16.6667%;
  max-width: 16.6667%;
  display: block;
}

.ant-col-xs-push-4 {
  left: 16.6667%;
}

.ant-col-xs-pull-4 {
  right: 16.6667%;
}

.ant-col-xs-offset-4 {
  margin-left: 16.6667%;
}

.ant-col-xs-order-4 {
  order: 4;
}

.ant-col-xs-3 {
  flex: 0 0 12.5%;
  max-width: 12.5%;
  display: block;
}

.ant-col-xs-push-3 {
  left: 12.5%;
}

.ant-col-xs-pull-3 {
  right: 12.5%;
}

.ant-col-xs-offset-3 {
  margin-left: 12.5%;
}

.ant-col-xs-order-3 {
  order: 3;
}

.ant-col-xs-2 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
  display: block;
}

.ant-col-xs-push-2 {
  left: 8.33333%;
}

.ant-col-xs-pull-2 {
  right: 8.33333%;
}

.ant-col-xs-offset-2 {
  margin-left: 8.33333%;
}

.ant-col-xs-order-2 {
  order: 2;
}

.ant-col-xs-1 {
  flex: 0 0 4.16667%;
  max-width: 4.16667%;
  display: block;
}

.ant-col-xs-push-1 {
  left: 4.16667%;
}

.ant-col-xs-pull-1 {
  right: 4.16667%;
}

.ant-col-xs-offset-1 {
  margin-left: 4.16667%;
}

.ant-col-xs-order-1 {
  order: 1;
}

.ant-col-xs-0 {
  display: none;
}

.ant-col-push-0 {
  left: auto;
}

.ant-col-pull-0 {
  right: auto;
}

.ant-col-xs-push-0 {
  left: auto;
}

.ant-col-xs-pull-0 {
  right: auto;
}

.ant-col-xs-offset-0 {
  margin-left: 0;
}

.ant-col-xs-order-0 {
  order: 0;
}

.ant-col-push-0.ant-col-rtl {
  right: auto;
}

.ant-col-pull-0.ant-col-rtl {
  left: auto;
}

.ant-col-xs-push-0.ant-col-rtl {
  right: auto;
}

.ant-col-xs-pull-0.ant-col-rtl {
  left: auto;
}

.ant-col-xs-offset-0.ant-col-rtl {
  margin-right: 0;
}

.ant-col-xs-push-1.ant-col-rtl {
  left: auto;
  right: 4.16667%;
}

.ant-col-xs-pull-1.ant-col-rtl {
  left: 4.16667%;
  right: auto;
}

.ant-col-xs-offset-1.ant-col-rtl {
  margin-left: 0;
  margin-right: 4.16667%;
}

.ant-col-xs-push-2.ant-col-rtl {
  left: auto;
  right: 8.33333%;
}

.ant-col-xs-pull-2.ant-col-rtl {
  left: 8.33333%;
  right: auto;
}

.ant-col-xs-offset-2.ant-col-rtl {
  margin-left: 0;
  margin-right: 8.33333%;
}

.ant-col-xs-push-3.ant-col-rtl {
  left: auto;
  right: 12.5%;
}

.ant-col-xs-pull-3.ant-col-rtl {
  left: 12.5%;
  right: auto;
}

.ant-col-xs-offset-3.ant-col-rtl {
  margin-left: 0;
  margin-right: 12.5%;
}

.ant-col-xs-push-4.ant-col-rtl {
  left: auto;
  right: 16.6667%;
}

.ant-col-xs-pull-4.ant-col-rtl {
  left: 16.6667%;
  right: auto;
}

.ant-col-xs-offset-4.ant-col-rtl {
  margin-left: 0;
  margin-right: 16.6667%;
}

.ant-col-xs-push-5.ant-col-rtl {
  left: auto;
  right: 20.8333%;
}

.ant-col-xs-pull-5.ant-col-rtl {
  left: 20.8333%;
  right: auto;
}

.ant-col-xs-offset-5.ant-col-rtl {
  margin-left: 0;
  margin-right: 20.8333%;
}

.ant-col-xs-push-6.ant-col-rtl {
  left: auto;
  right: 25%;
}

.ant-col-xs-pull-6.ant-col-rtl {
  left: 25%;
  right: auto;
}

.ant-col-xs-offset-6.ant-col-rtl {
  margin-left: 0;
  margin-right: 25%;
}

.ant-col-xs-push-7.ant-col-rtl {
  left: auto;
  right: 29.1667%;
}

.ant-col-xs-pull-7.ant-col-rtl {
  left: 29.1667%;
  right: auto;
}

.ant-col-xs-offset-7.ant-col-rtl {
  margin-left: 0;
  margin-right: 29.1667%;
}

.ant-col-xs-push-8.ant-col-rtl {
  left: auto;
  right: 33.3333%;
}

.ant-col-xs-pull-8.ant-col-rtl {
  left: 33.3333%;
  right: auto;
}

.ant-col-xs-offset-8.ant-col-rtl {
  margin-left: 0;
  margin-right: 33.3333%;
}

.ant-col-xs-push-9.ant-col-rtl {
  left: auto;
  right: 37.5%;
}

.ant-col-xs-pull-9.ant-col-rtl {
  left: 37.5%;
  right: auto;
}

.ant-col-xs-offset-9.ant-col-rtl {
  margin-left: 0;
  margin-right: 37.5%;
}

.ant-col-xs-push-10.ant-col-rtl {
  left: auto;
  right: 41.6667%;
}

.ant-col-xs-pull-10.ant-col-rtl {
  left: 41.6667%;
  right: auto;
}

.ant-col-xs-offset-10.ant-col-rtl {
  margin-left: 0;
  margin-right: 41.6667%;
}

.ant-col-xs-push-11.ant-col-rtl {
  left: auto;
  right: 45.8333%;
}

.ant-col-xs-pull-11.ant-col-rtl {
  left: 45.8333%;
  right: auto;
}

.ant-col-xs-offset-11.ant-col-rtl {
  margin-left: 0;
  margin-right: 45.8333%;
}

.ant-col-xs-push-12.ant-col-rtl {
  left: auto;
  right: 50%;
}

.ant-col-xs-pull-12.ant-col-rtl {
  left: 50%;
  right: auto;
}

.ant-col-xs-offset-12.ant-col-rtl {
  margin-left: 0;
  margin-right: 50%;
}

.ant-col-xs-push-13.ant-col-rtl {
  left: auto;
  right: 54.1667%;
}

.ant-col-xs-pull-13.ant-col-rtl {
  left: 54.1667%;
  right: auto;
}

.ant-col-xs-offset-13.ant-col-rtl {
  margin-left: 0;
  margin-right: 54.1667%;
}

.ant-col-xs-push-14.ant-col-rtl {
  left: auto;
  right: 58.3333%;
}

.ant-col-xs-pull-14.ant-col-rtl {
  left: 58.3333%;
  right: auto;
}

.ant-col-xs-offset-14.ant-col-rtl {
  margin-left: 0;
  margin-right: 58.3333%;
}

.ant-col-xs-push-15.ant-col-rtl {
  left: auto;
  right: 62.5%;
}

.ant-col-xs-pull-15.ant-col-rtl {
  left: 62.5%;
  right: auto;
}

.ant-col-xs-offset-15.ant-col-rtl {
  margin-left: 0;
  margin-right: 62.5%;
}

.ant-col-xs-push-16.ant-col-rtl {
  left: auto;
  right: 66.6667%;
}

.ant-col-xs-pull-16.ant-col-rtl {
  left: 66.6667%;
  right: auto;
}

.ant-col-xs-offset-16.ant-col-rtl {
  margin-left: 0;
  margin-right: 66.6667%;
}

.ant-col-xs-push-17.ant-col-rtl {
  left: auto;
  right: 70.8333%;
}

.ant-col-xs-pull-17.ant-col-rtl {
  left: 70.8333%;
  right: auto;
}

.ant-col-xs-offset-17.ant-col-rtl {
  margin-left: 0;
  margin-right: 70.8333%;
}

.ant-col-xs-push-18.ant-col-rtl {
  left: auto;
  right: 75%;
}

.ant-col-xs-pull-18.ant-col-rtl {
  left: 75%;
  right: auto;
}

.ant-col-xs-offset-18.ant-col-rtl {
  margin-left: 0;
  margin-right: 75%;
}

.ant-col-xs-push-19.ant-col-rtl {
  left: auto;
  right: 79.1667%;
}

.ant-col-xs-pull-19.ant-col-rtl {
  left: 79.1667%;
  right: auto;
}

.ant-col-xs-offset-19.ant-col-rtl {
  margin-left: 0;
  margin-right: 79.1667%;
}

.ant-col-xs-push-20.ant-col-rtl {
  left: auto;
  right: 83.3333%;
}

.ant-col-xs-pull-20.ant-col-rtl {
  left: 83.3333%;
  right: auto;
}

.ant-col-xs-offset-20.ant-col-rtl {
  margin-left: 0;
  margin-right: 83.3333%;
}

.ant-col-xs-push-21.ant-col-rtl {
  left: auto;
  right: 87.5%;
}

.ant-col-xs-pull-21.ant-col-rtl {
  left: 87.5%;
  right: auto;
}

.ant-col-xs-offset-21.ant-col-rtl {
  margin-left: 0;
  margin-right: 87.5%;
}

.ant-col-xs-push-22.ant-col-rtl {
  left: auto;
  right: 91.6667%;
}

.ant-col-xs-pull-22.ant-col-rtl {
  left: 91.6667%;
  right: auto;
}

.ant-col-xs-offset-22.ant-col-rtl {
  margin-left: 0;
  margin-right: 91.6667%;
}

.ant-col-xs-push-23.ant-col-rtl {
  left: auto;
  right: 95.8333%;
}

.ant-col-xs-pull-23.ant-col-rtl {
  left: 95.8333%;
  right: auto;
}

.ant-col-xs-offset-23.ant-col-rtl {
  margin-left: 0;
  margin-right: 95.8333%;
}

.ant-col-xs-push-24.ant-col-rtl {
  left: auto;
  right: 100%;
}

.ant-col-xs-pull-24.ant-col-rtl {
  left: 100%;
  right: auto;
}

.ant-col-xs-offset-24.ant-col-rtl {
  margin-left: 0;
  margin-right: 100%;
}

@media (width >= 576px) {
  .ant-col-sm-24 {
    flex: 0 0 100%;
    max-width: 100%;
    display: block;
  }

  .ant-col-sm-push-24 {
    left: 100%;
  }

  .ant-col-sm-pull-24 {
    right: 100%;
  }

  .ant-col-sm-offset-24 {
    margin-left: 100%;
  }

  .ant-col-sm-order-24 {
    order: 24;
  }

  .ant-col-sm-23 {
    flex: 0 0 95.8333%;
    max-width: 95.8333%;
    display: block;
  }

  .ant-col-sm-push-23 {
    left: 95.8333%;
  }

  .ant-col-sm-pull-23 {
    right: 95.8333%;
  }

  .ant-col-sm-offset-23 {
    margin-left: 95.8333%;
  }

  .ant-col-sm-order-23 {
    order: 23;
  }

  .ant-col-sm-22 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
    display: block;
  }

  .ant-col-sm-push-22 {
    left: 91.6667%;
  }

  .ant-col-sm-pull-22 {
    right: 91.6667%;
  }

  .ant-col-sm-offset-22 {
    margin-left: 91.6667%;
  }

  .ant-col-sm-order-22 {
    order: 22;
  }

  .ant-col-sm-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
    display: block;
  }

  .ant-col-sm-push-21 {
    left: 87.5%;
  }

  .ant-col-sm-pull-21 {
    right: 87.5%;
  }

  .ant-col-sm-offset-21 {
    margin-left: 87.5%;
  }

  .ant-col-sm-order-21 {
    order: 21;
  }

  .ant-col-sm-20 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
    display: block;
  }

  .ant-col-sm-push-20 {
    left: 83.3333%;
  }

  .ant-col-sm-pull-20 {
    right: 83.3333%;
  }

  .ant-col-sm-offset-20 {
    margin-left: 83.3333%;
  }

  .ant-col-sm-order-20 {
    order: 20;
  }

  .ant-col-sm-19 {
    flex: 0 0 79.1667%;
    max-width: 79.1667%;
    display: block;
  }

  .ant-col-sm-push-19 {
    left: 79.1667%;
  }

  .ant-col-sm-pull-19 {
    right: 79.1667%;
  }

  .ant-col-sm-offset-19 {
    margin-left: 79.1667%;
  }

  .ant-col-sm-order-19 {
    order: 19;
  }

  .ant-col-sm-18 {
    flex: 0 0 75%;
    max-width: 75%;
    display: block;
  }

  .ant-col-sm-push-18 {
    left: 75%;
  }

  .ant-col-sm-pull-18 {
    right: 75%;
  }

  .ant-col-sm-offset-18 {
    margin-left: 75%;
  }

  .ant-col-sm-order-18 {
    order: 18;
  }

  .ant-col-sm-17 {
    flex: 0 0 70.8333%;
    max-width: 70.8333%;
    display: block;
  }

  .ant-col-sm-push-17 {
    left: 70.8333%;
  }

  .ant-col-sm-pull-17 {
    right: 70.8333%;
  }

  .ant-col-sm-offset-17 {
    margin-left: 70.8333%;
  }

  .ant-col-sm-order-17 {
    order: 17;
  }

  .ant-col-sm-16 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
    display: block;
  }

  .ant-col-sm-push-16 {
    left: 66.6667%;
  }

  .ant-col-sm-pull-16 {
    right: 66.6667%;
  }

  .ant-col-sm-offset-16 {
    margin-left: 66.6667%;
  }

  .ant-col-sm-order-16 {
    order: 16;
  }

  .ant-col-sm-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
    display: block;
  }

  .ant-col-sm-push-15 {
    left: 62.5%;
  }

  .ant-col-sm-pull-15 {
    right: 62.5%;
  }

  .ant-col-sm-offset-15 {
    margin-left: 62.5%;
  }

  .ant-col-sm-order-15 {
    order: 15;
  }

  .ant-col-sm-14 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
    display: block;
  }

  .ant-col-sm-push-14 {
    left: 58.3333%;
  }

  .ant-col-sm-pull-14 {
    right: 58.3333%;
  }

  .ant-col-sm-offset-14 {
    margin-left: 58.3333%;
  }

  .ant-col-sm-order-14 {
    order: 14;
  }

  .ant-col-sm-13 {
    flex: 0 0 54.1667%;
    max-width: 54.1667%;
    display: block;
  }

  .ant-col-sm-push-13 {
    left: 54.1667%;
  }

  .ant-col-sm-pull-13 {
    right: 54.1667%;
  }

  .ant-col-sm-offset-13 {
    margin-left: 54.1667%;
  }

  .ant-col-sm-order-13 {
    order: 13;
  }

  .ant-col-sm-12 {
    flex: 0 0 50%;
    max-width: 50%;
    display: block;
  }

  .ant-col-sm-push-12 {
    left: 50%;
  }

  .ant-col-sm-pull-12 {
    right: 50%;
  }

  .ant-col-sm-offset-12 {
    margin-left: 50%;
  }

  .ant-col-sm-order-12 {
    order: 12;
  }

  .ant-col-sm-11 {
    flex: 0 0 45.8333%;
    max-width: 45.8333%;
    display: block;
  }

  .ant-col-sm-push-11 {
    left: 45.8333%;
  }

  .ant-col-sm-pull-11 {
    right: 45.8333%;
  }

  .ant-col-sm-offset-11 {
    margin-left: 45.8333%;
  }

  .ant-col-sm-order-11 {
    order: 11;
  }

  .ant-col-sm-10 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
    display: block;
  }

  .ant-col-sm-push-10 {
    left: 41.6667%;
  }

  .ant-col-sm-pull-10 {
    right: 41.6667%;
  }

  .ant-col-sm-offset-10 {
    margin-left: 41.6667%;
  }

  .ant-col-sm-order-10 {
    order: 10;
  }

  .ant-col-sm-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
    display: block;
  }

  .ant-col-sm-push-9 {
    left: 37.5%;
  }

  .ant-col-sm-pull-9 {
    right: 37.5%;
  }

  .ant-col-sm-offset-9 {
    margin-left: 37.5%;
  }

  .ant-col-sm-order-9 {
    order: 9;
  }

  .ant-col-sm-8 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    display: block;
  }

  .ant-col-sm-push-8 {
    left: 33.3333%;
  }

  .ant-col-sm-pull-8 {
    right: 33.3333%;
  }

  .ant-col-sm-offset-8 {
    margin-left: 33.3333%;
  }

  .ant-col-sm-order-8 {
    order: 8;
  }

  .ant-col-sm-7 {
    flex: 0 0 29.1667%;
    max-width: 29.1667%;
    display: block;
  }

  .ant-col-sm-push-7 {
    left: 29.1667%;
  }

  .ant-col-sm-pull-7 {
    right: 29.1667%;
  }

  .ant-col-sm-offset-7 {
    margin-left: 29.1667%;
  }

  .ant-col-sm-order-7 {
    order: 7;
  }

  .ant-col-sm-6 {
    flex: 0 0 25%;
    max-width: 25%;
    display: block;
  }

  .ant-col-sm-push-6 {
    left: 25%;
  }

  .ant-col-sm-pull-6 {
    right: 25%;
  }

  .ant-col-sm-offset-6 {
    margin-left: 25%;
  }

  .ant-col-sm-order-6 {
    order: 6;
  }

  .ant-col-sm-5 {
    flex: 0 0 20.8333%;
    max-width: 20.8333%;
    display: block;
  }

  .ant-col-sm-push-5 {
    left: 20.8333%;
  }

  .ant-col-sm-pull-5 {
    right: 20.8333%;
  }

  .ant-col-sm-offset-5 {
    margin-left: 20.8333%;
  }

  .ant-col-sm-order-5 {
    order: 5;
  }

  .ant-col-sm-4 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
    display: block;
  }

  .ant-col-sm-push-4 {
    left: 16.6667%;
  }

  .ant-col-sm-pull-4 {
    right: 16.6667%;
  }

  .ant-col-sm-offset-4 {
    margin-left: 16.6667%;
  }

  .ant-col-sm-order-4 {
    order: 4;
  }

  .ant-col-sm-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
    display: block;
  }

  .ant-col-sm-push-3 {
    left: 12.5%;
  }

  .ant-col-sm-pull-3 {
    right: 12.5%;
  }

  .ant-col-sm-offset-3 {
    margin-left: 12.5%;
  }

  .ant-col-sm-order-3 {
    order: 3;
  }

  .ant-col-sm-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
    display: block;
  }

  .ant-col-sm-push-2 {
    left: 8.33333%;
  }

  .ant-col-sm-pull-2 {
    right: 8.33333%;
  }

  .ant-col-sm-offset-2 {
    margin-left: 8.33333%;
  }

  .ant-col-sm-order-2 {
    order: 2;
  }

  .ant-col-sm-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%;
    display: block;
  }

  .ant-col-sm-push-1 {
    left: 4.16667%;
  }

  .ant-col-sm-pull-1 {
    right: 4.16667%;
  }

  .ant-col-sm-offset-1 {
    margin-left: 4.16667%;
  }

  .ant-col-sm-order-1 {
    order: 1;
  }

  .ant-col-sm-0 {
    display: none;
  }

  .ant-col-push-0 {
    left: auto;
  }

  .ant-col-pull-0 {
    right: auto;
  }

  .ant-col-sm-push-0 {
    left: auto;
  }

  .ant-col-sm-pull-0 {
    right: auto;
  }

  .ant-col-sm-offset-0 {
    margin-left: 0;
  }

  .ant-col-sm-order-0 {
    order: 0;
  }

  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-sm-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-sm-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-sm-offset-0.ant-col-rtl {
    margin-right: 0;
  }

  .ant-col-sm-push-1.ant-col-rtl {
    left: auto;
    right: 4.16667%;
  }

  .ant-col-sm-pull-1.ant-col-rtl {
    left: 4.16667%;
    right: auto;
  }

  .ant-col-sm-offset-1.ant-col-rtl {
    margin-left: 0;
    margin-right: 4.16667%;
  }

  .ant-col-sm-push-2.ant-col-rtl {
    left: auto;
    right: 8.33333%;
  }

  .ant-col-sm-pull-2.ant-col-rtl {
    left: 8.33333%;
    right: auto;
  }

  .ant-col-sm-offset-2.ant-col-rtl {
    margin-left: 0;
    margin-right: 8.33333%;
  }

  .ant-col-sm-push-3.ant-col-rtl {
    left: auto;
    right: 12.5%;
  }

  .ant-col-sm-pull-3.ant-col-rtl {
    left: 12.5%;
    right: auto;
  }

  .ant-col-sm-offset-3.ant-col-rtl {
    margin-left: 0;
    margin-right: 12.5%;
  }

  .ant-col-sm-push-4.ant-col-rtl {
    left: auto;
    right: 16.6667%;
  }

  .ant-col-sm-pull-4.ant-col-rtl {
    left: 16.6667%;
    right: auto;
  }

  .ant-col-sm-offset-4.ant-col-rtl {
    margin-left: 0;
    margin-right: 16.6667%;
  }

  .ant-col-sm-push-5.ant-col-rtl {
    left: auto;
    right: 20.8333%;
  }

  .ant-col-sm-pull-5.ant-col-rtl {
    left: 20.8333%;
    right: auto;
  }

  .ant-col-sm-offset-5.ant-col-rtl {
    margin-left: 0;
    margin-right: 20.8333%;
  }

  .ant-col-sm-push-6.ant-col-rtl {
    left: auto;
    right: 25%;
  }

  .ant-col-sm-pull-6.ant-col-rtl {
    left: 25%;
    right: auto;
  }

  .ant-col-sm-offset-6.ant-col-rtl {
    margin-left: 0;
    margin-right: 25%;
  }

  .ant-col-sm-push-7.ant-col-rtl {
    left: auto;
    right: 29.1667%;
  }

  .ant-col-sm-pull-7.ant-col-rtl {
    left: 29.1667%;
    right: auto;
  }

  .ant-col-sm-offset-7.ant-col-rtl {
    margin-left: 0;
    margin-right: 29.1667%;
  }

  .ant-col-sm-push-8.ant-col-rtl {
    left: auto;
    right: 33.3333%;
  }

  .ant-col-sm-pull-8.ant-col-rtl {
    left: 33.3333%;
    right: auto;
  }

  .ant-col-sm-offset-8.ant-col-rtl {
    margin-left: 0;
    margin-right: 33.3333%;
  }

  .ant-col-sm-push-9.ant-col-rtl {
    left: auto;
    right: 37.5%;
  }

  .ant-col-sm-pull-9.ant-col-rtl {
    left: 37.5%;
    right: auto;
  }

  .ant-col-sm-offset-9.ant-col-rtl {
    margin-left: 0;
    margin-right: 37.5%;
  }

  .ant-col-sm-push-10.ant-col-rtl {
    left: auto;
    right: 41.6667%;
  }

  .ant-col-sm-pull-10.ant-col-rtl {
    left: 41.6667%;
    right: auto;
  }

  .ant-col-sm-offset-10.ant-col-rtl {
    margin-left: 0;
    margin-right: 41.6667%;
  }

  .ant-col-sm-push-11.ant-col-rtl {
    left: auto;
    right: 45.8333%;
  }

  .ant-col-sm-pull-11.ant-col-rtl {
    left: 45.8333%;
    right: auto;
  }

  .ant-col-sm-offset-11.ant-col-rtl {
    margin-left: 0;
    margin-right: 45.8333%;
  }

  .ant-col-sm-push-12.ant-col-rtl {
    left: auto;
    right: 50%;
  }

  .ant-col-sm-pull-12.ant-col-rtl {
    left: 50%;
    right: auto;
  }

  .ant-col-sm-offset-12.ant-col-rtl {
    margin-left: 0;
    margin-right: 50%;
  }

  .ant-col-sm-push-13.ant-col-rtl {
    left: auto;
    right: 54.1667%;
  }

  .ant-col-sm-pull-13.ant-col-rtl {
    left: 54.1667%;
    right: auto;
  }

  .ant-col-sm-offset-13.ant-col-rtl {
    margin-left: 0;
    margin-right: 54.1667%;
  }

  .ant-col-sm-push-14.ant-col-rtl {
    left: auto;
    right: 58.3333%;
  }

  .ant-col-sm-pull-14.ant-col-rtl {
    left: 58.3333%;
    right: auto;
  }

  .ant-col-sm-offset-14.ant-col-rtl {
    margin-left: 0;
    margin-right: 58.3333%;
  }

  .ant-col-sm-push-15.ant-col-rtl {
    left: auto;
    right: 62.5%;
  }

  .ant-col-sm-pull-15.ant-col-rtl {
    left: 62.5%;
    right: auto;
  }

  .ant-col-sm-offset-15.ant-col-rtl {
    margin-left: 0;
    margin-right: 62.5%;
  }

  .ant-col-sm-push-16.ant-col-rtl {
    left: auto;
    right: 66.6667%;
  }

  .ant-col-sm-pull-16.ant-col-rtl {
    left: 66.6667%;
    right: auto;
  }

  .ant-col-sm-offset-16.ant-col-rtl {
    margin-left: 0;
    margin-right: 66.6667%;
  }

  .ant-col-sm-push-17.ant-col-rtl {
    left: auto;
    right: 70.8333%;
  }

  .ant-col-sm-pull-17.ant-col-rtl {
    left: 70.8333%;
    right: auto;
  }

  .ant-col-sm-offset-17.ant-col-rtl {
    margin-left: 0;
    margin-right: 70.8333%;
  }

  .ant-col-sm-push-18.ant-col-rtl {
    left: auto;
    right: 75%;
  }

  .ant-col-sm-pull-18.ant-col-rtl {
    left: 75%;
    right: auto;
  }

  .ant-col-sm-offset-18.ant-col-rtl {
    margin-left: 0;
    margin-right: 75%;
  }

  .ant-col-sm-push-19.ant-col-rtl {
    left: auto;
    right: 79.1667%;
  }

  .ant-col-sm-pull-19.ant-col-rtl {
    left: 79.1667%;
    right: auto;
  }

  .ant-col-sm-offset-19.ant-col-rtl {
    margin-left: 0;
    margin-right: 79.1667%;
  }

  .ant-col-sm-push-20.ant-col-rtl {
    left: auto;
    right: 83.3333%;
  }

  .ant-col-sm-pull-20.ant-col-rtl {
    left: 83.3333%;
    right: auto;
  }

  .ant-col-sm-offset-20.ant-col-rtl {
    margin-left: 0;
    margin-right: 83.3333%;
  }

  .ant-col-sm-push-21.ant-col-rtl {
    left: auto;
    right: 87.5%;
  }

  .ant-col-sm-pull-21.ant-col-rtl {
    left: 87.5%;
    right: auto;
  }

  .ant-col-sm-offset-21.ant-col-rtl {
    margin-left: 0;
    margin-right: 87.5%;
  }

  .ant-col-sm-push-22.ant-col-rtl {
    left: auto;
    right: 91.6667%;
  }

  .ant-col-sm-pull-22.ant-col-rtl {
    left: 91.6667%;
    right: auto;
  }

  .ant-col-sm-offset-22.ant-col-rtl {
    margin-left: 0;
    margin-right: 91.6667%;
  }

  .ant-col-sm-push-23.ant-col-rtl {
    left: auto;
    right: 95.8333%;
  }

  .ant-col-sm-pull-23.ant-col-rtl {
    left: 95.8333%;
    right: auto;
  }

  .ant-col-sm-offset-23.ant-col-rtl {
    margin-left: 0;
    margin-right: 95.8333%;
  }

  .ant-col-sm-push-24.ant-col-rtl {
    left: auto;
    right: 100%;
  }

  .ant-col-sm-pull-24.ant-col-rtl {
    left: 100%;
    right: auto;
  }

  .ant-col-sm-offset-24.ant-col-rtl {
    margin-left: 0;
    margin-right: 100%;
  }
}

@media (width >= 768px) {
  .ant-col-md-24 {
    flex: 0 0 100%;
    max-width: 100%;
    display: block;
  }

  .ant-col-md-push-24 {
    left: 100%;
  }

  .ant-col-md-pull-24 {
    right: 100%;
  }

  .ant-col-md-offset-24 {
    margin-left: 100%;
  }

  .ant-col-md-order-24 {
    order: 24;
  }

  .ant-col-md-23 {
    flex: 0 0 95.8333%;
    max-width: 95.8333%;
    display: block;
  }

  .ant-col-md-push-23 {
    left: 95.8333%;
  }

  .ant-col-md-pull-23 {
    right: 95.8333%;
  }

  .ant-col-md-offset-23 {
    margin-left: 95.8333%;
  }

  .ant-col-md-order-23 {
    order: 23;
  }

  .ant-col-md-22 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
    display: block;
  }

  .ant-col-md-push-22 {
    left: 91.6667%;
  }

  .ant-col-md-pull-22 {
    right: 91.6667%;
  }

  .ant-col-md-offset-22 {
    margin-left: 91.6667%;
  }

  .ant-col-md-order-22 {
    order: 22;
  }

  .ant-col-md-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
    display: block;
  }

  .ant-col-md-push-21 {
    left: 87.5%;
  }

  .ant-col-md-pull-21 {
    right: 87.5%;
  }

  .ant-col-md-offset-21 {
    margin-left: 87.5%;
  }

  .ant-col-md-order-21 {
    order: 21;
  }

  .ant-col-md-20 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
    display: block;
  }

  .ant-col-md-push-20 {
    left: 83.3333%;
  }

  .ant-col-md-pull-20 {
    right: 83.3333%;
  }

  .ant-col-md-offset-20 {
    margin-left: 83.3333%;
  }

  .ant-col-md-order-20 {
    order: 20;
  }

  .ant-col-md-19 {
    flex: 0 0 79.1667%;
    max-width: 79.1667%;
    display: block;
  }

  .ant-col-md-push-19 {
    left: 79.1667%;
  }

  .ant-col-md-pull-19 {
    right: 79.1667%;
  }

  .ant-col-md-offset-19 {
    margin-left: 79.1667%;
  }

  .ant-col-md-order-19 {
    order: 19;
  }

  .ant-col-md-18 {
    flex: 0 0 75%;
    max-width: 75%;
    display: block;
  }

  .ant-col-md-push-18 {
    left: 75%;
  }

  .ant-col-md-pull-18 {
    right: 75%;
  }

  .ant-col-md-offset-18 {
    margin-left: 75%;
  }

  .ant-col-md-order-18 {
    order: 18;
  }

  .ant-col-md-17 {
    flex: 0 0 70.8333%;
    max-width: 70.8333%;
    display: block;
  }

  .ant-col-md-push-17 {
    left: 70.8333%;
  }

  .ant-col-md-pull-17 {
    right: 70.8333%;
  }

  .ant-col-md-offset-17 {
    margin-left: 70.8333%;
  }

  .ant-col-md-order-17 {
    order: 17;
  }

  .ant-col-md-16 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
    display: block;
  }

  .ant-col-md-push-16 {
    left: 66.6667%;
  }

  .ant-col-md-pull-16 {
    right: 66.6667%;
  }

  .ant-col-md-offset-16 {
    margin-left: 66.6667%;
  }

  .ant-col-md-order-16 {
    order: 16;
  }

  .ant-col-md-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
    display: block;
  }

  .ant-col-md-push-15 {
    left: 62.5%;
  }

  .ant-col-md-pull-15 {
    right: 62.5%;
  }

  .ant-col-md-offset-15 {
    margin-left: 62.5%;
  }

  .ant-col-md-order-15 {
    order: 15;
  }

  .ant-col-md-14 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
    display: block;
  }

  .ant-col-md-push-14 {
    left: 58.3333%;
  }

  .ant-col-md-pull-14 {
    right: 58.3333%;
  }

  .ant-col-md-offset-14 {
    margin-left: 58.3333%;
  }

  .ant-col-md-order-14 {
    order: 14;
  }

  .ant-col-md-13 {
    flex: 0 0 54.1667%;
    max-width: 54.1667%;
    display: block;
  }

  .ant-col-md-push-13 {
    left: 54.1667%;
  }

  .ant-col-md-pull-13 {
    right: 54.1667%;
  }

  .ant-col-md-offset-13 {
    margin-left: 54.1667%;
  }

  .ant-col-md-order-13 {
    order: 13;
  }

  .ant-col-md-12 {
    flex: 0 0 50%;
    max-width: 50%;
    display: block;
  }

  .ant-col-md-push-12 {
    left: 50%;
  }

  .ant-col-md-pull-12 {
    right: 50%;
  }

  .ant-col-md-offset-12 {
    margin-left: 50%;
  }

  .ant-col-md-order-12 {
    order: 12;
  }

  .ant-col-md-11 {
    flex: 0 0 45.8333%;
    max-width: 45.8333%;
    display: block;
  }

  .ant-col-md-push-11 {
    left: 45.8333%;
  }

  .ant-col-md-pull-11 {
    right: 45.8333%;
  }

  .ant-col-md-offset-11 {
    margin-left: 45.8333%;
  }

  .ant-col-md-order-11 {
    order: 11;
  }

  .ant-col-md-10 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
    display: block;
  }

  .ant-col-md-push-10 {
    left: 41.6667%;
  }

  .ant-col-md-pull-10 {
    right: 41.6667%;
  }

  .ant-col-md-offset-10 {
    margin-left: 41.6667%;
  }

  .ant-col-md-order-10 {
    order: 10;
  }

  .ant-col-md-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
    display: block;
  }

  .ant-col-md-push-9 {
    left: 37.5%;
  }

  .ant-col-md-pull-9 {
    right: 37.5%;
  }

  .ant-col-md-offset-9 {
    margin-left: 37.5%;
  }

  .ant-col-md-order-9 {
    order: 9;
  }

  .ant-col-md-8 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    display: block;
  }

  .ant-col-md-push-8 {
    left: 33.3333%;
  }

  .ant-col-md-pull-8 {
    right: 33.3333%;
  }

  .ant-col-md-offset-8 {
    margin-left: 33.3333%;
  }

  .ant-col-md-order-8 {
    order: 8;
  }

  .ant-col-md-7 {
    flex: 0 0 29.1667%;
    max-width: 29.1667%;
    display: block;
  }

  .ant-col-md-push-7 {
    left: 29.1667%;
  }

  .ant-col-md-pull-7 {
    right: 29.1667%;
  }

  .ant-col-md-offset-7 {
    margin-left: 29.1667%;
  }

  .ant-col-md-order-7 {
    order: 7;
  }

  .ant-col-md-6 {
    flex: 0 0 25%;
    max-width: 25%;
    display: block;
  }

  .ant-col-md-push-6 {
    left: 25%;
  }

  .ant-col-md-pull-6 {
    right: 25%;
  }

  .ant-col-md-offset-6 {
    margin-left: 25%;
  }

  .ant-col-md-order-6 {
    order: 6;
  }

  .ant-col-md-5 {
    flex: 0 0 20.8333%;
    max-width: 20.8333%;
    display: block;
  }

  .ant-col-md-push-5 {
    left: 20.8333%;
  }

  .ant-col-md-pull-5 {
    right: 20.8333%;
  }

  .ant-col-md-offset-5 {
    margin-left: 20.8333%;
  }

  .ant-col-md-order-5 {
    order: 5;
  }

  .ant-col-md-4 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
    display: block;
  }

  .ant-col-md-push-4 {
    left: 16.6667%;
  }

  .ant-col-md-pull-4 {
    right: 16.6667%;
  }

  .ant-col-md-offset-4 {
    margin-left: 16.6667%;
  }

  .ant-col-md-order-4 {
    order: 4;
  }

  .ant-col-md-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
    display: block;
  }

  .ant-col-md-push-3 {
    left: 12.5%;
  }

  .ant-col-md-pull-3 {
    right: 12.5%;
  }

  .ant-col-md-offset-3 {
    margin-left: 12.5%;
  }

  .ant-col-md-order-3 {
    order: 3;
  }

  .ant-col-md-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
    display: block;
  }

  .ant-col-md-push-2 {
    left: 8.33333%;
  }

  .ant-col-md-pull-2 {
    right: 8.33333%;
  }

  .ant-col-md-offset-2 {
    margin-left: 8.33333%;
  }

  .ant-col-md-order-2 {
    order: 2;
  }

  .ant-col-md-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%;
    display: block;
  }

  .ant-col-md-push-1 {
    left: 4.16667%;
  }

  .ant-col-md-pull-1 {
    right: 4.16667%;
  }

  .ant-col-md-offset-1 {
    margin-left: 4.16667%;
  }

  .ant-col-md-order-1 {
    order: 1;
  }

  .ant-col-md-0 {
    display: none;
  }

  .ant-col-push-0 {
    left: auto;
  }

  .ant-col-pull-0 {
    right: auto;
  }

  .ant-col-md-push-0 {
    left: auto;
  }

  .ant-col-md-pull-0 {
    right: auto;
  }

  .ant-col-md-offset-0 {
    margin-left: 0;
  }

  .ant-col-md-order-0 {
    order: 0;
  }

  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-md-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-md-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-md-offset-0.ant-col-rtl {
    margin-right: 0;
  }

  .ant-col-md-push-1.ant-col-rtl {
    left: auto;
    right: 4.16667%;
  }

  .ant-col-md-pull-1.ant-col-rtl {
    left: 4.16667%;
    right: auto;
  }

  .ant-col-md-offset-1.ant-col-rtl {
    margin-left: 0;
    margin-right: 4.16667%;
  }

  .ant-col-md-push-2.ant-col-rtl {
    left: auto;
    right: 8.33333%;
  }

  .ant-col-md-pull-2.ant-col-rtl {
    left: 8.33333%;
    right: auto;
  }

  .ant-col-md-offset-2.ant-col-rtl {
    margin-left: 0;
    margin-right: 8.33333%;
  }

  .ant-col-md-push-3.ant-col-rtl {
    left: auto;
    right: 12.5%;
  }

  .ant-col-md-pull-3.ant-col-rtl {
    left: 12.5%;
    right: auto;
  }

  .ant-col-md-offset-3.ant-col-rtl {
    margin-left: 0;
    margin-right: 12.5%;
  }

  .ant-col-md-push-4.ant-col-rtl {
    left: auto;
    right: 16.6667%;
  }

  .ant-col-md-pull-4.ant-col-rtl {
    left: 16.6667%;
    right: auto;
  }

  .ant-col-md-offset-4.ant-col-rtl {
    margin-left: 0;
    margin-right: 16.6667%;
  }

  .ant-col-md-push-5.ant-col-rtl {
    left: auto;
    right: 20.8333%;
  }

  .ant-col-md-pull-5.ant-col-rtl {
    left: 20.8333%;
    right: auto;
  }

  .ant-col-md-offset-5.ant-col-rtl {
    margin-left: 0;
    margin-right: 20.8333%;
  }

  .ant-col-md-push-6.ant-col-rtl {
    left: auto;
    right: 25%;
  }

  .ant-col-md-pull-6.ant-col-rtl {
    left: 25%;
    right: auto;
  }

  .ant-col-md-offset-6.ant-col-rtl {
    margin-left: 0;
    margin-right: 25%;
  }

  .ant-col-md-push-7.ant-col-rtl {
    left: auto;
    right: 29.1667%;
  }

  .ant-col-md-pull-7.ant-col-rtl {
    left: 29.1667%;
    right: auto;
  }

  .ant-col-md-offset-7.ant-col-rtl {
    margin-left: 0;
    margin-right: 29.1667%;
  }

  .ant-col-md-push-8.ant-col-rtl {
    left: auto;
    right: 33.3333%;
  }

  .ant-col-md-pull-8.ant-col-rtl {
    left: 33.3333%;
    right: auto;
  }

  .ant-col-md-offset-8.ant-col-rtl {
    margin-left: 0;
    margin-right: 33.3333%;
  }

  .ant-col-md-push-9.ant-col-rtl {
    left: auto;
    right: 37.5%;
  }

  .ant-col-md-pull-9.ant-col-rtl {
    left: 37.5%;
    right: auto;
  }

  .ant-col-md-offset-9.ant-col-rtl {
    margin-left: 0;
    margin-right: 37.5%;
  }

  .ant-col-md-push-10.ant-col-rtl {
    left: auto;
    right: 41.6667%;
  }

  .ant-col-md-pull-10.ant-col-rtl {
    left: 41.6667%;
    right: auto;
  }

  .ant-col-md-offset-10.ant-col-rtl {
    margin-left: 0;
    margin-right: 41.6667%;
  }

  .ant-col-md-push-11.ant-col-rtl {
    left: auto;
    right: 45.8333%;
  }

  .ant-col-md-pull-11.ant-col-rtl {
    left: 45.8333%;
    right: auto;
  }

  .ant-col-md-offset-11.ant-col-rtl {
    margin-left: 0;
    margin-right: 45.8333%;
  }

  .ant-col-md-push-12.ant-col-rtl {
    left: auto;
    right: 50%;
  }

  .ant-col-md-pull-12.ant-col-rtl {
    left: 50%;
    right: auto;
  }

  .ant-col-md-offset-12.ant-col-rtl {
    margin-left: 0;
    margin-right: 50%;
  }

  .ant-col-md-push-13.ant-col-rtl {
    left: auto;
    right: 54.1667%;
  }

  .ant-col-md-pull-13.ant-col-rtl {
    left: 54.1667%;
    right: auto;
  }

  .ant-col-md-offset-13.ant-col-rtl {
    margin-left: 0;
    margin-right: 54.1667%;
  }

  .ant-col-md-push-14.ant-col-rtl {
    left: auto;
    right: 58.3333%;
  }

  .ant-col-md-pull-14.ant-col-rtl {
    left: 58.3333%;
    right: auto;
  }

  .ant-col-md-offset-14.ant-col-rtl {
    margin-left: 0;
    margin-right: 58.3333%;
  }

  .ant-col-md-push-15.ant-col-rtl {
    left: auto;
    right: 62.5%;
  }

  .ant-col-md-pull-15.ant-col-rtl {
    left: 62.5%;
    right: auto;
  }

  .ant-col-md-offset-15.ant-col-rtl {
    margin-left: 0;
    margin-right: 62.5%;
  }

  .ant-col-md-push-16.ant-col-rtl {
    left: auto;
    right: 66.6667%;
  }

  .ant-col-md-pull-16.ant-col-rtl {
    left: 66.6667%;
    right: auto;
  }

  .ant-col-md-offset-16.ant-col-rtl {
    margin-left: 0;
    margin-right: 66.6667%;
  }

  .ant-col-md-push-17.ant-col-rtl {
    left: auto;
    right: 70.8333%;
  }

  .ant-col-md-pull-17.ant-col-rtl {
    left: 70.8333%;
    right: auto;
  }

  .ant-col-md-offset-17.ant-col-rtl {
    margin-left: 0;
    margin-right: 70.8333%;
  }

  .ant-col-md-push-18.ant-col-rtl {
    left: auto;
    right: 75%;
  }

  .ant-col-md-pull-18.ant-col-rtl {
    left: 75%;
    right: auto;
  }

  .ant-col-md-offset-18.ant-col-rtl {
    margin-left: 0;
    margin-right: 75%;
  }

  .ant-col-md-push-19.ant-col-rtl {
    left: auto;
    right: 79.1667%;
  }

  .ant-col-md-pull-19.ant-col-rtl {
    left: 79.1667%;
    right: auto;
  }

  .ant-col-md-offset-19.ant-col-rtl {
    margin-left: 0;
    margin-right: 79.1667%;
  }

  .ant-col-md-push-20.ant-col-rtl {
    left: auto;
    right: 83.3333%;
  }

  .ant-col-md-pull-20.ant-col-rtl {
    left: 83.3333%;
    right: auto;
  }

  .ant-col-md-offset-20.ant-col-rtl {
    margin-left: 0;
    margin-right: 83.3333%;
  }

  .ant-col-md-push-21.ant-col-rtl {
    left: auto;
    right: 87.5%;
  }

  .ant-col-md-pull-21.ant-col-rtl {
    left: 87.5%;
    right: auto;
  }

  .ant-col-md-offset-21.ant-col-rtl {
    margin-left: 0;
    margin-right: 87.5%;
  }

  .ant-col-md-push-22.ant-col-rtl {
    left: auto;
    right: 91.6667%;
  }

  .ant-col-md-pull-22.ant-col-rtl {
    left: 91.6667%;
    right: auto;
  }

  .ant-col-md-offset-22.ant-col-rtl {
    margin-left: 0;
    margin-right: 91.6667%;
  }

  .ant-col-md-push-23.ant-col-rtl {
    left: auto;
    right: 95.8333%;
  }

  .ant-col-md-pull-23.ant-col-rtl {
    left: 95.8333%;
    right: auto;
  }

  .ant-col-md-offset-23.ant-col-rtl {
    margin-left: 0;
    margin-right: 95.8333%;
  }

  .ant-col-md-push-24.ant-col-rtl {
    left: auto;
    right: 100%;
  }

  .ant-col-md-pull-24.ant-col-rtl {
    left: 100%;
    right: auto;
  }

  .ant-col-md-offset-24.ant-col-rtl {
    margin-left: 0;
    margin-right: 100%;
  }
}

@media (width >= 992px) {
  .ant-col-lg-24 {
    flex: 0 0 100%;
    max-width: 100%;
    display: block;
  }

  .ant-col-lg-push-24 {
    left: 100%;
  }

  .ant-col-lg-pull-24 {
    right: 100%;
  }

  .ant-col-lg-offset-24 {
    margin-left: 100%;
  }

  .ant-col-lg-order-24 {
    order: 24;
  }

  .ant-col-lg-23 {
    flex: 0 0 95.8333%;
    max-width: 95.8333%;
    display: block;
  }

  .ant-col-lg-push-23 {
    left: 95.8333%;
  }

  .ant-col-lg-pull-23 {
    right: 95.8333%;
  }

  .ant-col-lg-offset-23 {
    margin-left: 95.8333%;
  }

  .ant-col-lg-order-23 {
    order: 23;
  }

  .ant-col-lg-22 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
    display: block;
  }

  .ant-col-lg-push-22 {
    left: 91.6667%;
  }

  .ant-col-lg-pull-22 {
    right: 91.6667%;
  }

  .ant-col-lg-offset-22 {
    margin-left: 91.6667%;
  }

  .ant-col-lg-order-22 {
    order: 22;
  }

  .ant-col-lg-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
    display: block;
  }

  .ant-col-lg-push-21 {
    left: 87.5%;
  }

  .ant-col-lg-pull-21 {
    right: 87.5%;
  }

  .ant-col-lg-offset-21 {
    margin-left: 87.5%;
  }

  .ant-col-lg-order-21 {
    order: 21;
  }

  .ant-col-lg-20 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
    display: block;
  }

  .ant-col-lg-push-20 {
    left: 83.3333%;
  }

  .ant-col-lg-pull-20 {
    right: 83.3333%;
  }

  .ant-col-lg-offset-20 {
    margin-left: 83.3333%;
  }

  .ant-col-lg-order-20 {
    order: 20;
  }

  .ant-col-lg-19 {
    flex: 0 0 79.1667%;
    max-width: 79.1667%;
    display: block;
  }

  .ant-col-lg-push-19 {
    left: 79.1667%;
  }

  .ant-col-lg-pull-19 {
    right: 79.1667%;
  }

  .ant-col-lg-offset-19 {
    margin-left: 79.1667%;
  }

  .ant-col-lg-order-19 {
    order: 19;
  }

  .ant-col-lg-18 {
    flex: 0 0 75%;
    max-width: 75%;
    display: block;
  }

  .ant-col-lg-push-18 {
    left: 75%;
  }

  .ant-col-lg-pull-18 {
    right: 75%;
  }

  .ant-col-lg-offset-18 {
    margin-left: 75%;
  }

  .ant-col-lg-order-18 {
    order: 18;
  }

  .ant-col-lg-17 {
    flex: 0 0 70.8333%;
    max-width: 70.8333%;
    display: block;
  }

  .ant-col-lg-push-17 {
    left: 70.8333%;
  }

  .ant-col-lg-pull-17 {
    right: 70.8333%;
  }

  .ant-col-lg-offset-17 {
    margin-left: 70.8333%;
  }

  .ant-col-lg-order-17 {
    order: 17;
  }

  .ant-col-lg-16 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
    display: block;
  }

  .ant-col-lg-push-16 {
    left: 66.6667%;
  }

  .ant-col-lg-pull-16 {
    right: 66.6667%;
  }

  .ant-col-lg-offset-16 {
    margin-left: 66.6667%;
  }

  .ant-col-lg-order-16 {
    order: 16;
  }

  .ant-col-lg-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
    display: block;
  }

  .ant-col-lg-push-15 {
    left: 62.5%;
  }

  .ant-col-lg-pull-15 {
    right: 62.5%;
  }

  .ant-col-lg-offset-15 {
    margin-left: 62.5%;
  }

  .ant-col-lg-order-15 {
    order: 15;
  }

  .ant-col-lg-14 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
    display: block;
  }

  .ant-col-lg-push-14 {
    left: 58.3333%;
  }

  .ant-col-lg-pull-14 {
    right: 58.3333%;
  }

  .ant-col-lg-offset-14 {
    margin-left: 58.3333%;
  }

  .ant-col-lg-order-14 {
    order: 14;
  }

  .ant-col-lg-13 {
    flex: 0 0 54.1667%;
    max-width: 54.1667%;
    display: block;
  }

  .ant-col-lg-push-13 {
    left: 54.1667%;
  }

  .ant-col-lg-pull-13 {
    right: 54.1667%;
  }

  .ant-col-lg-offset-13 {
    margin-left: 54.1667%;
  }

  .ant-col-lg-order-13 {
    order: 13;
  }

  .ant-col-lg-12 {
    flex: 0 0 50%;
    max-width: 50%;
    display: block;
  }

  .ant-col-lg-push-12 {
    left: 50%;
  }

  .ant-col-lg-pull-12 {
    right: 50%;
  }

  .ant-col-lg-offset-12 {
    margin-left: 50%;
  }

  .ant-col-lg-order-12 {
    order: 12;
  }

  .ant-col-lg-11 {
    flex: 0 0 45.8333%;
    max-width: 45.8333%;
    display: block;
  }

  .ant-col-lg-push-11 {
    left: 45.8333%;
  }

  .ant-col-lg-pull-11 {
    right: 45.8333%;
  }

  .ant-col-lg-offset-11 {
    margin-left: 45.8333%;
  }

  .ant-col-lg-order-11 {
    order: 11;
  }

  .ant-col-lg-10 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
    display: block;
  }

  .ant-col-lg-push-10 {
    left: 41.6667%;
  }

  .ant-col-lg-pull-10 {
    right: 41.6667%;
  }

  .ant-col-lg-offset-10 {
    margin-left: 41.6667%;
  }

  .ant-col-lg-order-10 {
    order: 10;
  }

  .ant-col-lg-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
    display: block;
  }

  .ant-col-lg-push-9 {
    left: 37.5%;
  }

  .ant-col-lg-pull-9 {
    right: 37.5%;
  }

  .ant-col-lg-offset-9 {
    margin-left: 37.5%;
  }

  .ant-col-lg-order-9 {
    order: 9;
  }

  .ant-col-lg-8 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    display: block;
  }

  .ant-col-lg-push-8 {
    left: 33.3333%;
  }

  .ant-col-lg-pull-8 {
    right: 33.3333%;
  }

  .ant-col-lg-offset-8 {
    margin-left: 33.3333%;
  }

  .ant-col-lg-order-8 {
    order: 8;
  }

  .ant-col-lg-7 {
    flex: 0 0 29.1667%;
    max-width: 29.1667%;
    display: block;
  }

  .ant-col-lg-push-7 {
    left: 29.1667%;
  }

  .ant-col-lg-pull-7 {
    right: 29.1667%;
  }

  .ant-col-lg-offset-7 {
    margin-left: 29.1667%;
  }

  .ant-col-lg-order-7 {
    order: 7;
  }

  .ant-col-lg-6 {
    flex: 0 0 25%;
    max-width: 25%;
    display: block;
  }

  .ant-col-lg-push-6 {
    left: 25%;
  }

  .ant-col-lg-pull-6 {
    right: 25%;
  }

  .ant-col-lg-offset-6 {
    margin-left: 25%;
  }

  .ant-col-lg-order-6 {
    order: 6;
  }

  .ant-col-lg-5 {
    flex: 0 0 20.8333%;
    max-width: 20.8333%;
    display: block;
  }

  .ant-col-lg-push-5 {
    left: 20.8333%;
  }

  .ant-col-lg-pull-5 {
    right: 20.8333%;
  }

  .ant-col-lg-offset-5 {
    margin-left: 20.8333%;
  }

  .ant-col-lg-order-5 {
    order: 5;
  }

  .ant-col-lg-4 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
    display: block;
  }

  .ant-col-lg-push-4 {
    left: 16.6667%;
  }

  .ant-col-lg-pull-4 {
    right: 16.6667%;
  }

  .ant-col-lg-offset-4 {
    margin-left: 16.6667%;
  }

  .ant-col-lg-order-4 {
    order: 4;
  }

  .ant-col-lg-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
    display: block;
  }

  .ant-col-lg-push-3 {
    left: 12.5%;
  }

  .ant-col-lg-pull-3 {
    right: 12.5%;
  }

  .ant-col-lg-offset-3 {
    margin-left: 12.5%;
  }

  .ant-col-lg-order-3 {
    order: 3;
  }

  .ant-col-lg-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
    display: block;
  }

  .ant-col-lg-push-2 {
    left: 8.33333%;
  }

  .ant-col-lg-pull-2 {
    right: 8.33333%;
  }

  .ant-col-lg-offset-2 {
    margin-left: 8.33333%;
  }

  .ant-col-lg-order-2 {
    order: 2;
  }

  .ant-col-lg-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%;
    display: block;
  }

  .ant-col-lg-push-1 {
    left: 4.16667%;
  }

  .ant-col-lg-pull-1 {
    right: 4.16667%;
  }

  .ant-col-lg-offset-1 {
    margin-left: 4.16667%;
  }

  .ant-col-lg-order-1 {
    order: 1;
  }

  .ant-col-lg-0 {
    display: none;
  }

  .ant-col-push-0 {
    left: auto;
  }

  .ant-col-pull-0 {
    right: auto;
  }

  .ant-col-lg-push-0 {
    left: auto;
  }

  .ant-col-lg-pull-0 {
    right: auto;
  }

  .ant-col-lg-offset-0 {
    margin-left: 0;
  }

  .ant-col-lg-order-0 {
    order: 0;
  }

  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-lg-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-lg-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-lg-offset-0.ant-col-rtl {
    margin-right: 0;
  }

  .ant-col-lg-push-1.ant-col-rtl {
    left: auto;
    right: 4.16667%;
  }

  .ant-col-lg-pull-1.ant-col-rtl {
    left: 4.16667%;
    right: auto;
  }

  .ant-col-lg-offset-1.ant-col-rtl {
    margin-left: 0;
    margin-right: 4.16667%;
  }

  .ant-col-lg-push-2.ant-col-rtl {
    left: auto;
    right: 8.33333%;
  }

  .ant-col-lg-pull-2.ant-col-rtl {
    left: 8.33333%;
    right: auto;
  }

  .ant-col-lg-offset-2.ant-col-rtl {
    margin-left: 0;
    margin-right: 8.33333%;
  }

  .ant-col-lg-push-3.ant-col-rtl {
    left: auto;
    right: 12.5%;
  }

  .ant-col-lg-pull-3.ant-col-rtl {
    left: 12.5%;
    right: auto;
  }

  .ant-col-lg-offset-3.ant-col-rtl {
    margin-left: 0;
    margin-right: 12.5%;
  }

  .ant-col-lg-push-4.ant-col-rtl {
    left: auto;
    right: 16.6667%;
  }

  .ant-col-lg-pull-4.ant-col-rtl {
    left: 16.6667%;
    right: auto;
  }

  .ant-col-lg-offset-4.ant-col-rtl {
    margin-left: 0;
    margin-right: 16.6667%;
  }

  .ant-col-lg-push-5.ant-col-rtl {
    left: auto;
    right: 20.8333%;
  }

  .ant-col-lg-pull-5.ant-col-rtl {
    left: 20.8333%;
    right: auto;
  }

  .ant-col-lg-offset-5.ant-col-rtl {
    margin-left: 0;
    margin-right: 20.8333%;
  }

  .ant-col-lg-push-6.ant-col-rtl {
    left: auto;
    right: 25%;
  }

  .ant-col-lg-pull-6.ant-col-rtl {
    left: 25%;
    right: auto;
  }

  .ant-col-lg-offset-6.ant-col-rtl {
    margin-left: 0;
    margin-right: 25%;
  }

  .ant-col-lg-push-7.ant-col-rtl {
    left: auto;
    right: 29.1667%;
  }

  .ant-col-lg-pull-7.ant-col-rtl {
    left: 29.1667%;
    right: auto;
  }

  .ant-col-lg-offset-7.ant-col-rtl {
    margin-left: 0;
    margin-right: 29.1667%;
  }

  .ant-col-lg-push-8.ant-col-rtl {
    left: auto;
    right: 33.3333%;
  }

  .ant-col-lg-pull-8.ant-col-rtl {
    left: 33.3333%;
    right: auto;
  }

  .ant-col-lg-offset-8.ant-col-rtl {
    margin-left: 0;
    margin-right: 33.3333%;
  }

  .ant-col-lg-push-9.ant-col-rtl {
    left: auto;
    right: 37.5%;
  }

  .ant-col-lg-pull-9.ant-col-rtl {
    left: 37.5%;
    right: auto;
  }

  .ant-col-lg-offset-9.ant-col-rtl {
    margin-left: 0;
    margin-right: 37.5%;
  }

  .ant-col-lg-push-10.ant-col-rtl {
    left: auto;
    right: 41.6667%;
  }

  .ant-col-lg-pull-10.ant-col-rtl {
    left: 41.6667%;
    right: auto;
  }

  .ant-col-lg-offset-10.ant-col-rtl {
    margin-left: 0;
    margin-right: 41.6667%;
  }

  .ant-col-lg-push-11.ant-col-rtl {
    left: auto;
    right: 45.8333%;
  }

  .ant-col-lg-pull-11.ant-col-rtl {
    left: 45.8333%;
    right: auto;
  }

  .ant-col-lg-offset-11.ant-col-rtl {
    margin-left: 0;
    margin-right: 45.8333%;
  }

  .ant-col-lg-push-12.ant-col-rtl {
    left: auto;
    right: 50%;
  }

  .ant-col-lg-pull-12.ant-col-rtl {
    left: 50%;
    right: auto;
  }

  .ant-col-lg-offset-12.ant-col-rtl {
    margin-left: 0;
    margin-right: 50%;
  }

  .ant-col-lg-push-13.ant-col-rtl {
    left: auto;
    right: 54.1667%;
  }

  .ant-col-lg-pull-13.ant-col-rtl {
    left: 54.1667%;
    right: auto;
  }

  .ant-col-lg-offset-13.ant-col-rtl {
    margin-left: 0;
    margin-right: 54.1667%;
  }

  .ant-col-lg-push-14.ant-col-rtl {
    left: auto;
    right: 58.3333%;
  }

  .ant-col-lg-pull-14.ant-col-rtl {
    left: 58.3333%;
    right: auto;
  }

  .ant-col-lg-offset-14.ant-col-rtl {
    margin-left: 0;
    margin-right: 58.3333%;
  }

  .ant-col-lg-push-15.ant-col-rtl {
    left: auto;
    right: 62.5%;
  }

  .ant-col-lg-pull-15.ant-col-rtl {
    left: 62.5%;
    right: auto;
  }

  .ant-col-lg-offset-15.ant-col-rtl {
    margin-left: 0;
    margin-right: 62.5%;
  }

  .ant-col-lg-push-16.ant-col-rtl {
    left: auto;
    right: 66.6667%;
  }

  .ant-col-lg-pull-16.ant-col-rtl {
    left: 66.6667%;
    right: auto;
  }

  .ant-col-lg-offset-16.ant-col-rtl {
    margin-left: 0;
    margin-right: 66.6667%;
  }

  .ant-col-lg-push-17.ant-col-rtl {
    left: auto;
    right: 70.8333%;
  }

  .ant-col-lg-pull-17.ant-col-rtl {
    left: 70.8333%;
    right: auto;
  }

  .ant-col-lg-offset-17.ant-col-rtl {
    margin-left: 0;
    margin-right: 70.8333%;
  }

  .ant-col-lg-push-18.ant-col-rtl {
    left: auto;
    right: 75%;
  }

  .ant-col-lg-pull-18.ant-col-rtl {
    left: 75%;
    right: auto;
  }

  .ant-col-lg-offset-18.ant-col-rtl {
    margin-left: 0;
    margin-right: 75%;
  }

  .ant-col-lg-push-19.ant-col-rtl {
    left: auto;
    right: 79.1667%;
  }

  .ant-col-lg-pull-19.ant-col-rtl {
    left: 79.1667%;
    right: auto;
  }

  .ant-col-lg-offset-19.ant-col-rtl {
    margin-left: 0;
    margin-right: 79.1667%;
  }

  .ant-col-lg-push-20.ant-col-rtl {
    left: auto;
    right: 83.3333%;
  }

  .ant-col-lg-pull-20.ant-col-rtl {
    left: 83.3333%;
    right: auto;
  }

  .ant-col-lg-offset-20.ant-col-rtl {
    margin-left: 0;
    margin-right: 83.3333%;
  }

  .ant-col-lg-push-21.ant-col-rtl {
    left: auto;
    right: 87.5%;
  }

  .ant-col-lg-pull-21.ant-col-rtl {
    left: 87.5%;
    right: auto;
  }

  .ant-col-lg-offset-21.ant-col-rtl {
    margin-left: 0;
    margin-right: 87.5%;
  }

  .ant-col-lg-push-22.ant-col-rtl {
    left: auto;
    right: 91.6667%;
  }

  .ant-col-lg-pull-22.ant-col-rtl {
    left: 91.6667%;
    right: auto;
  }

  .ant-col-lg-offset-22.ant-col-rtl {
    margin-left: 0;
    margin-right: 91.6667%;
  }

  .ant-col-lg-push-23.ant-col-rtl {
    left: auto;
    right: 95.8333%;
  }

  .ant-col-lg-pull-23.ant-col-rtl {
    left: 95.8333%;
    right: auto;
  }

  .ant-col-lg-offset-23.ant-col-rtl {
    margin-left: 0;
    margin-right: 95.8333%;
  }

  .ant-col-lg-push-24.ant-col-rtl {
    left: auto;
    right: 100%;
  }

  .ant-col-lg-pull-24.ant-col-rtl {
    left: 100%;
    right: auto;
  }

  .ant-col-lg-offset-24.ant-col-rtl {
    margin-left: 0;
    margin-right: 100%;
  }
}

@media (width >= 1200px) {
  .ant-col-xl-24 {
    flex: 0 0 100%;
    max-width: 100%;
    display: block;
  }

  .ant-col-xl-push-24 {
    left: 100%;
  }

  .ant-col-xl-pull-24 {
    right: 100%;
  }

  .ant-col-xl-offset-24 {
    margin-left: 100%;
  }

  .ant-col-xl-order-24 {
    order: 24;
  }

  .ant-col-xl-23 {
    flex: 0 0 95.8333%;
    max-width: 95.8333%;
    display: block;
  }

  .ant-col-xl-push-23 {
    left: 95.8333%;
  }

  .ant-col-xl-pull-23 {
    right: 95.8333%;
  }

  .ant-col-xl-offset-23 {
    margin-left: 95.8333%;
  }

  .ant-col-xl-order-23 {
    order: 23;
  }

  .ant-col-xl-22 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
    display: block;
  }

  .ant-col-xl-push-22 {
    left: 91.6667%;
  }

  .ant-col-xl-pull-22 {
    right: 91.6667%;
  }

  .ant-col-xl-offset-22 {
    margin-left: 91.6667%;
  }

  .ant-col-xl-order-22 {
    order: 22;
  }

  .ant-col-xl-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
    display: block;
  }

  .ant-col-xl-push-21 {
    left: 87.5%;
  }

  .ant-col-xl-pull-21 {
    right: 87.5%;
  }

  .ant-col-xl-offset-21 {
    margin-left: 87.5%;
  }

  .ant-col-xl-order-21 {
    order: 21;
  }

  .ant-col-xl-20 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
    display: block;
  }

  .ant-col-xl-push-20 {
    left: 83.3333%;
  }

  .ant-col-xl-pull-20 {
    right: 83.3333%;
  }

  .ant-col-xl-offset-20 {
    margin-left: 83.3333%;
  }

  .ant-col-xl-order-20 {
    order: 20;
  }

  .ant-col-xl-19 {
    flex: 0 0 79.1667%;
    max-width: 79.1667%;
    display: block;
  }

  .ant-col-xl-push-19 {
    left: 79.1667%;
  }

  .ant-col-xl-pull-19 {
    right: 79.1667%;
  }

  .ant-col-xl-offset-19 {
    margin-left: 79.1667%;
  }

  .ant-col-xl-order-19 {
    order: 19;
  }

  .ant-col-xl-18 {
    flex: 0 0 75%;
    max-width: 75%;
    display: block;
  }

  .ant-col-xl-push-18 {
    left: 75%;
  }

  .ant-col-xl-pull-18 {
    right: 75%;
  }

  .ant-col-xl-offset-18 {
    margin-left: 75%;
  }

  .ant-col-xl-order-18 {
    order: 18;
  }

  .ant-col-xl-17 {
    flex: 0 0 70.8333%;
    max-width: 70.8333%;
    display: block;
  }

  .ant-col-xl-push-17 {
    left: 70.8333%;
  }

  .ant-col-xl-pull-17 {
    right: 70.8333%;
  }

  .ant-col-xl-offset-17 {
    margin-left: 70.8333%;
  }

  .ant-col-xl-order-17 {
    order: 17;
  }

  .ant-col-xl-16 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
    display: block;
  }

  .ant-col-xl-push-16 {
    left: 66.6667%;
  }

  .ant-col-xl-pull-16 {
    right: 66.6667%;
  }

  .ant-col-xl-offset-16 {
    margin-left: 66.6667%;
  }

  .ant-col-xl-order-16 {
    order: 16;
  }

  .ant-col-xl-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
    display: block;
  }

  .ant-col-xl-push-15 {
    left: 62.5%;
  }

  .ant-col-xl-pull-15 {
    right: 62.5%;
  }

  .ant-col-xl-offset-15 {
    margin-left: 62.5%;
  }

  .ant-col-xl-order-15 {
    order: 15;
  }

  .ant-col-xl-14 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
    display: block;
  }

  .ant-col-xl-push-14 {
    left: 58.3333%;
  }

  .ant-col-xl-pull-14 {
    right: 58.3333%;
  }

  .ant-col-xl-offset-14 {
    margin-left: 58.3333%;
  }

  .ant-col-xl-order-14 {
    order: 14;
  }

  .ant-col-xl-13 {
    flex: 0 0 54.1667%;
    max-width: 54.1667%;
    display: block;
  }

  .ant-col-xl-push-13 {
    left: 54.1667%;
  }

  .ant-col-xl-pull-13 {
    right: 54.1667%;
  }

  .ant-col-xl-offset-13 {
    margin-left: 54.1667%;
  }

  .ant-col-xl-order-13 {
    order: 13;
  }

  .ant-col-xl-12 {
    flex: 0 0 50%;
    max-width: 50%;
    display: block;
  }

  .ant-col-xl-push-12 {
    left: 50%;
  }

  .ant-col-xl-pull-12 {
    right: 50%;
  }

  .ant-col-xl-offset-12 {
    margin-left: 50%;
  }

  .ant-col-xl-order-12 {
    order: 12;
  }

  .ant-col-xl-11 {
    flex: 0 0 45.8333%;
    max-width: 45.8333%;
    display: block;
  }

  .ant-col-xl-push-11 {
    left: 45.8333%;
  }

  .ant-col-xl-pull-11 {
    right: 45.8333%;
  }

  .ant-col-xl-offset-11 {
    margin-left: 45.8333%;
  }

  .ant-col-xl-order-11 {
    order: 11;
  }

  .ant-col-xl-10 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
    display: block;
  }

  .ant-col-xl-push-10 {
    left: 41.6667%;
  }

  .ant-col-xl-pull-10 {
    right: 41.6667%;
  }

  .ant-col-xl-offset-10 {
    margin-left: 41.6667%;
  }

  .ant-col-xl-order-10 {
    order: 10;
  }

  .ant-col-xl-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
    display: block;
  }

  .ant-col-xl-push-9 {
    left: 37.5%;
  }

  .ant-col-xl-pull-9 {
    right: 37.5%;
  }

  .ant-col-xl-offset-9 {
    margin-left: 37.5%;
  }

  .ant-col-xl-order-9 {
    order: 9;
  }

  .ant-col-xl-8 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    display: block;
  }

  .ant-col-xl-push-8 {
    left: 33.3333%;
  }

  .ant-col-xl-pull-8 {
    right: 33.3333%;
  }

  .ant-col-xl-offset-8 {
    margin-left: 33.3333%;
  }

  .ant-col-xl-order-8 {
    order: 8;
  }

  .ant-col-xl-7 {
    flex: 0 0 29.1667%;
    max-width: 29.1667%;
    display: block;
  }

  .ant-col-xl-push-7 {
    left: 29.1667%;
  }

  .ant-col-xl-pull-7 {
    right: 29.1667%;
  }

  .ant-col-xl-offset-7 {
    margin-left: 29.1667%;
  }

  .ant-col-xl-order-7 {
    order: 7;
  }

  .ant-col-xl-6 {
    flex: 0 0 25%;
    max-width: 25%;
    display: block;
  }

  .ant-col-xl-push-6 {
    left: 25%;
  }

  .ant-col-xl-pull-6 {
    right: 25%;
  }

  .ant-col-xl-offset-6 {
    margin-left: 25%;
  }

  .ant-col-xl-order-6 {
    order: 6;
  }

  .ant-col-xl-5 {
    flex: 0 0 20.8333%;
    max-width: 20.8333%;
    display: block;
  }

  .ant-col-xl-push-5 {
    left: 20.8333%;
  }

  .ant-col-xl-pull-5 {
    right: 20.8333%;
  }

  .ant-col-xl-offset-5 {
    margin-left: 20.8333%;
  }

  .ant-col-xl-order-5 {
    order: 5;
  }

  .ant-col-xl-4 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
    display: block;
  }

  .ant-col-xl-push-4 {
    left: 16.6667%;
  }

  .ant-col-xl-pull-4 {
    right: 16.6667%;
  }

  .ant-col-xl-offset-4 {
    margin-left: 16.6667%;
  }

  .ant-col-xl-order-4 {
    order: 4;
  }

  .ant-col-xl-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
    display: block;
  }

  .ant-col-xl-push-3 {
    left: 12.5%;
  }

  .ant-col-xl-pull-3 {
    right: 12.5%;
  }

  .ant-col-xl-offset-3 {
    margin-left: 12.5%;
  }

  .ant-col-xl-order-3 {
    order: 3;
  }

  .ant-col-xl-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
    display: block;
  }

  .ant-col-xl-push-2 {
    left: 8.33333%;
  }

  .ant-col-xl-pull-2 {
    right: 8.33333%;
  }

  .ant-col-xl-offset-2 {
    margin-left: 8.33333%;
  }

  .ant-col-xl-order-2 {
    order: 2;
  }

  .ant-col-xl-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%;
    display: block;
  }

  .ant-col-xl-push-1 {
    left: 4.16667%;
  }

  .ant-col-xl-pull-1 {
    right: 4.16667%;
  }

  .ant-col-xl-offset-1 {
    margin-left: 4.16667%;
  }

  .ant-col-xl-order-1 {
    order: 1;
  }

  .ant-col-xl-0 {
    display: none;
  }

  .ant-col-push-0 {
    left: auto;
  }

  .ant-col-pull-0 {
    right: auto;
  }

  .ant-col-xl-push-0 {
    left: auto;
  }

  .ant-col-xl-pull-0 {
    right: auto;
  }

  .ant-col-xl-offset-0 {
    margin-left: 0;
  }

  .ant-col-xl-order-0 {
    order: 0;
  }

  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-xl-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-xl-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-xl-offset-0.ant-col-rtl {
    margin-right: 0;
  }

  .ant-col-xl-push-1.ant-col-rtl {
    left: auto;
    right: 4.16667%;
  }

  .ant-col-xl-pull-1.ant-col-rtl {
    left: 4.16667%;
    right: auto;
  }

  .ant-col-xl-offset-1.ant-col-rtl {
    margin-left: 0;
    margin-right: 4.16667%;
  }

  .ant-col-xl-push-2.ant-col-rtl {
    left: auto;
    right: 8.33333%;
  }

  .ant-col-xl-pull-2.ant-col-rtl {
    left: 8.33333%;
    right: auto;
  }

  .ant-col-xl-offset-2.ant-col-rtl {
    margin-left: 0;
    margin-right: 8.33333%;
  }

  .ant-col-xl-push-3.ant-col-rtl {
    left: auto;
    right: 12.5%;
  }

  .ant-col-xl-pull-3.ant-col-rtl {
    left: 12.5%;
    right: auto;
  }

  .ant-col-xl-offset-3.ant-col-rtl {
    margin-left: 0;
    margin-right: 12.5%;
  }

  .ant-col-xl-push-4.ant-col-rtl {
    left: auto;
    right: 16.6667%;
  }

  .ant-col-xl-pull-4.ant-col-rtl {
    left: 16.6667%;
    right: auto;
  }

  .ant-col-xl-offset-4.ant-col-rtl {
    margin-left: 0;
    margin-right: 16.6667%;
  }

  .ant-col-xl-push-5.ant-col-rtl {
    left: auto;
    right: 20.8333%;
  }

  .ant-col-xl-pull-5.ant-col-rtl {
    left: 20.8333%;
    right: auto;
  }

  .ant-col-xl-offset-5.ant-col-rtl {
    margin-left: 0;
    margin-right: 20.8333%;
  }

  .ant-col-xl-push-6.ant-col-rtl {
    left: auto;
    right: 25%;
  }

  .ant-col-xl-pull-6.ant-col-rtl {
    left: 25%;
    right: auto;
  }

  .ant-col-xl-offset-6.ant-col-rtl {
    margin-left: 0;
    margin-right: 25%;
  }

  .ant-col-xl-push-7.ant-col-rtl {
    left: auto;
    right: 29.1667%;
  }

  .ant-col-xl-pull-7.ant-col-rtl {
    left: 29.1667%;
    right: auto;
  }

  .ant-col-xl-offset-7.ant-col-rtl {
    margin-left: 0;
    margin-right: 29.1667%;
  }

  .ant-col-xl-push-8.ant-col-rtl {
    left: auto;
    right: 33.3333%;
  }

  .ant-col-xl-pull-8.ant-col-rtl {
    left: 33.3333%;
    right: auto;
  }

  .ant-col-xl-offset-8.ant-col-rtl {
    margin-left: 0;
    margin-right: 33.3333%;
  }

  .ant-col-xl-push-9.ant-col-rtl {
    left: auto;
    right: 37.5%;
  }

  .ant-col-xl-pull-9.ant-col-rtl {
    left: 37.5%;
    right: auto;
  }

  .ant-col-xl-offset-9.ant-col-rtl {
    margin-left: 0;
    margin-right: 37.5%;
  }

  .ant-col-xl-push-10.ant-col-rtl {
    left: auto;
    right: 41.6667%;
  }

  .ant-col-xl-pull-10.ant-col-rtl {
    left: 41.6667%;
    right: auto;
  }

  .ant-col-xl-offset-10.ant-col-rtl {
    margin-left: 0;
    margin-right: 41.6667%;
  }

  .ant-col-xl-push-11.ant-col-rtl {
    left: auto;
    right: 45.8333%;
  }

  .ant-col-xl-pull-11.ant-col-rtl {
    left: 45.8333%;
    right: auto;
  }

  .ant-col-xl-offset-11.ant-col-rtl {
    margin-left: 0;
    margin-right: 45.8333%;
  }

  .ant-col-xl-push-12.ant-col-rtl {
    left: auto;
    right: 50%;
  }

  .ant-col-xl-pull-12.ant-col-rtl {
    left: 50%;
    right: auto;
  }

  .ant-col-xl-offset-12.ant-col-rtl {
    margin-left: 0;
    margin-right: 50%;
  }

  .ant-col-xl-push-13.ant-col-rtl {
    left: auto;
    right: 54.1667%;
  }

  .ant-col-xl-pull-13.ant-col-rtl {
    left: 54.1667%;
    right: auto;
  }

  .ant-col-xl-offset-13.ant-col-rtl {
    margin-left: 0;
    margin-right: 54.1667%;
  }

  .ant-col-xl-push-14.ant-col-rtl {
    left: auto;
    right: 58.3333%;
  }

  .ant-col-xl-pull-14.ant-col-rtl {
    left: 58.3333%;
    right: auto;
  }

  .ant-col-xl-offset-14.ant-col-rtl {
    margin-left: 0;
    margin-right: 58.3333%;
  }

  .ant-col-xl-push-15.ant-col-rtl {
    left: auto;
    right: 62.5%;
  }

  .ant-col-xl-pull-15.ant-col-rtl {
    left: 62.5%;
    right: auto;
  }

  .ant-col-xl-offset-15.ant-col-rtl {
    margin-left: 0;
    margin-right: 62.5%;
  }

  .ant-col-xl-push-16.ant-col-rtl {
    left: auto;
    right: 66.6667%;
  }

  .ant-col-xl-pull-16.ant-col-rtl {
    left: 66.6667%;
    right: auto;
  }

  .ant-col-xl-offset-16.ant-col-rtl {
    margin-left: 0;
    margin-right: 66.6667%;
  }

  .ant-col-xl-push-17.ant-col-rtl {
    left: auto;
    right: 70.8333%;
  }

  .ant-col-xl-pull-17.ant-col-rtl {
    left: 70.8333%;
    right: auto;
  }

  .ant-col-xl-offset-17.ant-col-rtl {
    margin-left: 0;
    margin-right: 70.8333%;
  }

  .ant-col-xl-push-18.ant-col-rtl {
    left: auto;
    right: 75%;
  }

  .ant-col-xl-pull-18.ant-col-rtl {
    left: 75%;
    right: auto;
  }

  .ant-col-xl-offset-18.ant-col-rtl {
    margin-left: 0;
    margin-right: 75%;
  }

  .ant-col-xl-push-19.ant-col-rtl {
    left: auto;
    right: 79.1667%;
  }

  .ant-col-xl-pull-19.ant-col-rtl {
    left: 79.1667%;
    right: auto;
  }

  .ant-col-xl-offset-19.ant-col-rtl {
    margin-left: 0;
    margin-right: 79.1667%;
  }

  .ant-col-xl-push-20.ant-col-rtl {
    left: auto;
    right: 83.3333%;
  }

  .ant-col-xl-pull-20.ant-col-rtl {
    left: 83.3333%;
    right: auto;
  }

  .ant-col-xl-offset-20.ant-col-rtl {
    margin-left: 0;
    margin-right: 83.3333%;
  }

  .ant-col-xl-push-21.ant-col-rtl {
    left: auto;
    right: 87.5%;
  }

  .ant-col-xl-pull-21.ant-col-rtl {
    left: 87.5%;
    right: auto;
  }

  .ant-col-xl-offset-21.ant-col-rtl {
    margin-left: 0;
    margin-right: 87.5%;
  }

  .ant-col-xl-push-22.ant-col-rtl {
    left: auto;
    right: 91.6667%;
  }

  .ant-col-xl-pull-22.ant-col-rtl {
    left: 91.6667%;
    right: auto;
  }

  .ant-col-xl-offset-22.ant-col-rtl {
    margin-left: 0;
    margin-right: 91.6667%;
  }

  .ant-col-xl-push-23.ant-col-rtl {
    left: auto;
    right: 95.8333%;
  }

  .ant-col-xl-pull-23.ant-col-rtl {
    left: 95.8333%;
    right: auto;
  }

  .ant-col-xl-offset-23.ant-col-rtl {
    margin-left: 0;
    margin-right: 95.8333%;
  }

  .ant-col-xl-push-24.ant-col-rtl {
    left: auto;
    right: 100%;
  }

  .ant-col-xl-pull-24.ant-col-rtl {
    left: 100%;
    right: auto;
  }

  .ant-col-xl-offset-24.ant-col-rtl {
    margin-left: 0;
    margin-right: 100%;
  }
}

@media (width >= 1600px) {
  .ant-col-xxl-24 {
    flex: 0 0 100%;
    max-width: 100%;
    display: block;
  }

  .ant-col-xxl-push-24 {
    left: 100%;
  }

  .ant-col-xxl-pull-24 {
    right: 100%;
  }

  .ant-col-xxl-offset-24 {
    margin-left: 100%;
  }

  .ant-col-xxl-order-24 {
    order: 24;
  }

  .ant-col-xxl-23 {
    flex: 0 0 95.8333%;
    max-width: 95.8333%;
    display: block;
  }

  .ant-col-xxl-push-23 {
    left: 95.8333%;
  }

  .ant-col-xxl-pull-23 {
    right: 95.8333%;
  }

  .ant-col-xxl-offset-23 {
    margin-left: 95.8333%;
  }

  .ant-col-xxl-order-23 {
    order: 23;
  }

  .ant-col-xxl-22 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
    display: block;
  }

  .ant-col-xxl-push-22 {
    left: 91.6667%;
  }

  .ant-col-xxl-pull-22 {
    right: 91.6667%;
  }

  .ant-col-xxl-offset-22 {
    margin-left: 91.6667%;
  }

  .ant-col-xxl-order-22 {
    order: 22;
  }

  .ant-col-xxl-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
    display: block;
  }

  .ant-col-xxl-push-21 {
    left: 87.5%;
  }

  .ant-col-xxl-pull-21 {
    right: 87.5%;
  }

  .ant-col-xxl-offset-21 {
    margin-left: 87.5%;
  }

  .ant-col-xxl-order-21 {
    order: 21;
  }

  .ant-col-xxl-20 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
    display: block;
  }

  .ant-col-xxl-push-20 {
    left: 83.3333%;
  }

  .ant-col-xxl-pull-20 {
    right: 83.3333%;
  }

  .ant-col-xxl-offset-20 {
    margin-left: 83.3333%;
  }

  .ant-col-xxl-order-20 {
    order: 20;
  }

  .ant-col-xxl-19 {
    flex: 0 0 79.1667%;
    max-width: 79.1667%;
    display: block;
  }

  .ant-col-xxl-push-19 {
    left: 79.1667%;
  }

  .ant-col-xxl-pull-19 {
    right: 79.1667%;
  }

  .ant-col-xxl-offset-19 {
    margin-left: 79.1667%;
  }

  .ant-col-xxl-order-19 {
    order: 19;
  }

  .ant-col-xxl-18 {
    flex: 0 0 75%;
    max-width: 75%;
    display: block;
  }

  .ant-col-xxl-push-18 {
    left: 75%;
  }

  .ant-col-xxl-pull-18 {
    right: 75%;
  }

  .ant-col-xxl-offset-18 {
    margin-left: 75%;
  }

  .ant-col-xxl-order-18 {
    order: 18;
  }

  .ant-col-xxl-17 {
    flex: 0 0 70.8333%;
    max-width: 70.8333%;
    display: block;
  }

  .ant-col-xxl-push-17 {
    left: 70.8333%;
  }

  .ant-col-xxl-pull-17 {
    right: 70.8333%;
  }

  .ant-col-xxl-offset-17 {
    margin-left: 70.8333%;
  }

  .ant-col-xxl-order-17 {
    order: 17;
  }

  .ant-col-xxl-16 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
    display: block;
  }

  .ant-col-xxl-push-16 {
    left: 66.6667%;
  }

  .ant-col-xxl-pull-16 {
    right: 66.6667%;
  }

  .ant-col-xxl-offset-16 {
    margin-left: 66.6667%;
  }

  .ant-col-xxl-order-16 {
    order: 16;
  }

  .ant-col-xxl-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
    display: block;
  }

  .ant-col-xxl-push-15 {
    left: 62.5%;
  }

  .ant-col-xxl-pull-15 {
    right: 62.5%;
  }

  .ant-col-xxl-offset-15 {
    margin-left: 62.5%;
  }

  .ant-col-xxl-order-15 {
    order: 15;
  }

  .ant-col-xxl-14 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
    display: block;
  }

  .ant-col-xxl-push-14 {
    left: 58.3333%;
  }

  .ant-col-xxl-pull-14 {
    right: 58.3333%;
  }

  .ant-col-xxl-offset-14 {
    margin-left: 58.3333%;
  }

  .ant-col-xxl-order-14 {
    order: 14;
  }

  .ant-col-xxl-13 {
    flex: 0 0 54.1667%;
    max-width: 54.1667%;
    display: block;
  }

  .ant-col-xxl-push-13 {
    left: 54.1667%;
  }

  .ant-col-xxl-pull-13 {
    right: 54.1667%;
  }

  .ant-col-xxl-offset-13 {
    margin-left: 54.1667%;
  }

  .ant-col-xxl-order-13 {
    order: 13;
  }

  .ant-col-xxl-12 {
    flex: 0 0 50%;
    max-width: 50%;
    display: block;
  }

  .ant-col-xxl-push-12 {
    left: 50%;
  }

  .ant-col-xxl-pull-12 {
    right: 50%;
  }

  .ant-col-xxl-offset-12 {
    margin-left: 50%;
  }

  .ant-col-xxl-order-12 {
    order: 12;
  }

  .ant-col-xxl-11 {
    flex: 0 0 45.8333%;
    max-width: 45.8333%;
    display: block;
  }

  .ant-col-xxl-push-11 {
    left: 45.8333%;
  }

  .ant-col-xxl-pull-11 {
    right: 45.8333%;
  }

  .ant-col-xxl-offset-11 {
    margin-left: 45.8333%;
  }

  .ant-col-xxl-order-11 {
    order: 11;
  }

  .ant-col-xxl-10 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
    display: block;
  }

  .ant-col-xxl-push-10 {
    left: 41.6667%;
  }

  .ant-col-xxl-pull-10 {
    right: 41.6667%;
  }

  .ant-col-xxl-offset-10 {
    margin-left: 41.6667%;
  }

  .ant-col-xxl-order-10 {
    order: 10;
  }

  .ant-col-xxl-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
    display: block;
  }

  .ant-col-xxl-push-9 {
    left: 37.5%;
  }

  .ant-col-xxl-pull-9 {
    right: 37.5%;
  }

  .ant-col-xxl-offset-9 {
    margin-left: 37.5%;
  }

  .ant-col-xxl-order-9 {
    order: 9;
  }

  .ant-col-xxl-8 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    display: block;
  }

  .ant-col-xxl-push-8 {
    left: 33.3333%;
  }

  .ant-col-xxl-pull-8 {
    right: 33.3333%;
  }

  .ant-col-xxl-offset-8 {
    margin-left: 33.3333%;
  }

  .ant-col-xxl-order-8 {
    order: 8;
  }

  .ant-col-xxl-7 {
    flex: 0 0 29.1667%;
    max-width: 29.1667%;
    display: block;
  }

  .ant-col-xxl-push-7 {
    left: 29.1667%;
  }

  .ant-col-xxl-pull-7 {
    right: 29.1667%;
  }

  .ant-col-xxl-offset-7 {
    margin-left: 29.1667%;
  }

  .ant-col-xxl-order-7 {
    order: 7;
  }

  .ant-col-xxl-6 {
    flex: 0 0 25%;
    max-width: 25%;
    display: block;
  }

  .ant-col-xxl-push-6 {
    left: 25%;
  }

  .ant-col-xxl-pull-6 {
    right: 25%;
  }

  .ant-col-xxl-offset-6 {
    margin-left: 25%;
  }

  .ant-col-xxl-order-6 {
    order: 6;
  }

  .ant-col-xxl-5 {
    flex: 0 0 20.8333%;
    max-width: 20.8333%;
    display: block;
  }

  .ant-col-xxl-push-5 {
    left: 20.8333%;
  }

  .ant-col-xxl-pull-5 {
    right: 20.8333%;
  }

  .ant-col-xxl-offset-5 {
    margin-left: 20.8333%;
  }

  .ant-col-xxl-order-5 {
    order: 5;
  }

  .ant-col-xxl-4 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
    display: block;
  }

  .ant-col-xxl-push-4 {
    left: 16.6667%;
  }

  .ant-col-xxl-pull-4 {
    right: 16.6667%;
  }

  .ant-col-xxl-offset-4 {
    margin-left: 16.6667%;
  }

  .ant-col-xxl-order-4 {
    order: 4;
  }

  .ant-col-xxl-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
    display: block;
  }

  .ant-col-xxl-push-3 {
    left: 12.5%;
  }

  .ant-col-xxl-pull-3 {
    right: 12.5%;
  }

  .ant-col-xxl-offset-3 {
    margin-left: 12.5%;
  }

  .ant-col-xxl-order-3 {
    order: 3;
  }

  .ant-col-xxl-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
    display: block;
  }

  .ant-col-xxl-push-2 {
    left: 8.33333%;
  }

  .ant-col-xxl-pull-2 {
    right: 8.33333%;
  }

  .ant-col-xxl-offset-2 {
    margin-left: 8.33333%;
  }

  .ant-col-xxl-order-2 {
    order: 2;
  }

  .ant-col-xxl-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%;
    display: block;
  }

  .ant-col-xxl-push-1 {
    left: 4.16667%;
  }

  .ant-col-xxl-pull-1 {
    right: 4.16667%;
  }

  .ant-col-xxl-offset-1 {
    margin-left: 4.16667%;
  }

  .ant-col-xxl-order-1 {
    order: 1;
  }

  .ant-col-xxl-0 {
    display: none;
  }

  .ant-col-push-0 {
    left: auto;
  }

  .ant-col-pull-0 {
    right: auto;
  }

  .ant-col-xxl-push-0 {
    left: auto;
  }

  .ant-col-xxl-pull-0 {
    right: auto;
  }

  .ant-col-xxl-offset-0 {
    margin-left: 0;
  }

  .ant-col-xxl-order-0 {
    order: 0;
  }

  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-xxl-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-xxl-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-xxl-offset-0.ant-col-rtl {
    margin-right: 0;
  }

  .ant-col-xxl-push-1.ant-col-rtl {
    left: auto;
    right: 4.16667%;
  }

  .ant-col-xxl-pull-1.ant-col-rtl {
    left: 4.16667%;
    right: auto;
  }

  .ant-col-xxl-offset-1.ant-col-rtl {
    margin-left: 0;
    margin-right: 4.16667%;
  }

  .ant-col-xxl-push-2.ant-col-rtl {
    left: auto;
    right: 8.33333%;
  }

  .ant-col-xxl-pull-2.ant-col-rtl {
    left: 8.33333%;
    right: auto;
  }

  .ant-col-xxl-offset-2.ant-col-rtl {
    margin-left: 0;
    margin-right: 8.33333%;
  }

  .ant-col-xxl-push-3.ant-col-rtl {
    left: auto;
    right: 12.5%;
  }

  .ant-col-xxl-pull-3.ant-col-rtl {
    left: 12.5%;
    right: auto;
  }

  .ant-col-xxl-offset-3.ant-col-rtl {
    margin-left: 0;
    margin-right: 12.5%;
  }

  .ant-col-xxl-push-4.ant-col-rtl {
    left: auto;
    right: 16.6667%;
  }

  .ant-col-xxl-pull-4.ant-col-rtl {
    left: 16.6667%;
    right: auto;
  }

  .ant-col-xxl-offset-4.ant-col-rtl {
    margin-left: 0;
    margin-right: 16.6667%;
  }

  .ant-col-xxl-push-5.ant-col-rtl {
    left: auto;
    right: 20.8333%;
  }

  .ant-col-xxl-pull-5.ant-col-rtl {
    left: 20.8333%;
    right: auto;
  }

  .ant-col-xxl-offset-5.ant-col-rtl {
    margin-left: 0;
    margin-right: 20.8333%;
  }

  .ant-col-xxl-push-6.ant-col-rtl {
    left: auto;
    right: 25%;
  }

  .ant-col-xxl-pull-6.ant-col-rtl {
    left: 25%;
    right: auto;
  }

  .ant-col-xxl-offset-6.ant-col-rtl {
    margin-left: 0;
    margin-right: 25%;
  }

  .ant-col-xxl-push-7.ant-col-rtl {
    left: auto;
    right: 29.1667%;
  }

  .ant-col-xxl-pull-7.ant-col-rtl {
    left: 29.1667%;
    right: auto;
  }

  .ant-col-xxl-offset-7.ant-col-rtl {
    margin-left: 0;
    margin-right: 29.1667%;
  }

  .ant-col-xxl-push-8.ant-col-rtl {
    left: auto;
    right: 33.3333%;
  }

  .ant-col-xxl-pull-8.ant-col-rtl {
    left: 33.3333%;
    right: auto;
  }

  .ant-col-xxl-offset-8.ant-col-rtl {
    margin-left: 0;
    margin-right: 33.3333%;
  }

  .ant-col-xxl-push-9.ant-col-rtl {
    left: auto;
    right: 37.5%;
  }

  .ant-col-xxl-pull-9.ant-col-rtl {
    left: 37.5%;
    right: auto;
  }

  .ant-col-xxl-offset-9.ant-col-rtl {
    margin-left: 0;
    margin-right: 37.5%;
  }

  .ant-col-xxl-push-10.ant-col-rtl {
    left: auto;
    right: 41.6667%;
  }

  .ant-col-xxl-pull-10.ant-col-rtl {
    left: 41.6667%;
    right: auto;
  }

  .ant-col-xxl-offset-10.ant-col-rtl {
    margin-left: 0;
    margin-right: 41.6667%;
  }

  .ant-col-xxl-push-11.ant-col-rtl {
    left: auto;
    right: 45.8333%;
  }

  .ant-col-xxl-pull-11.ant-col-rtl {
    left: 45.8333%;
    right: auto;
  }

  .ant-col-xxl-offset-11.ant-col-rtl {
    margin-left: 0;
    margin-right: 45.8333%;
  }

  .ant-col-xxl-push-12.ant-col-rtl {
    left: auto;
    right: 50%;
  }

  .ant-col-xxl-pull-12.ant-col-rtl {
    left: 50%;
    right: auto;
  }

  .ant-col-xxl-offset-12.ant-col-rtl {
    margin-left: 0;
    margin-right: 50%;
  }

  .ant-col-xxl-push-13.ant-col-rtl {
    left: auto;
    right: 54.1667%;
  }

  .ant-col-xxl-pull-13.ant-col-rtl {
    left: 54.1667%;
    right: auto;
  }

  .ant-col-xxl-offset-13.ant-col-rtl {
    margin-left: 0;
    margin-right: 54.1667%;
  }

  .ant-col-xxl-push-14.ant-col-rtl {
    left: auto;
    right: 58.3333%;
  }

  .ant-col-xxl-pull-14.ant-col-rtl {
    left: 58.3333%;
    right: auto;
  }

  .ant-col-xxl-offset-14.ant-col-rtl {
    margin-left: 0;
    margin-right: 58.3333%;
  }

  .ant-col-xxl-push-15.ant-col-rtl {
    left: auto;
    right: 62.5%;
  }

  .ant-col-xxl-pull-15.ant-col-rtl {
    left: 62.5%;
    right: auto;
  }

  .ant-col-xxl-offset-15.ant-col-rtl {
    margin-left: 0;
    margin-right: 62.5%;
  }

  .ant-col-xxl-push-16.ant-col-rtl {
    left: auto;
    right: 66.6667%;
  }

  .ant-col-xxl-pull-16.ant-col-rtl {
    left: 66.6667%;
    right: auto;
  }

  .ant-col-xxl-offset-16.ant-col-rtl {
    margin-left: 0;
    margin-right: 66.6667%;
  }

  .ant-col-xxl-push-17.ant-col-rtl {
    left: auto;
    right: 70.8333%;
  }

  .ant-col-xxl-pull-17.ant-col-rtl {
    left: 70.8333%;
    right: auto;
  }

  .ant-col-xxl-offset-17.ant-col-rtl {
    margin-left: 0;
    margin-right: 70.8333%;
  }

  .ant-col-xxl-push-18.ant-col-rtl {
    left: auto;
    right: 75%;
  }

  .ant-col-xxl-pull-18.ant-col-rtl {
    left: 75%;
    right: auto;
  }

  .ant-col-xxl-offset-18.ant-col-rtl {
    margin-left: 0;
    margin-right: 75%;
  }

  .ant-col-xxl-push-19.ant-col-rtl {
    left: auto;
    right: 79.1667%;
  }

  .ant-col-xxl-pull-19.ant-col-rtl {
    left: 79.1667%;
    right: auto;
  }

  .ant-col-xxl-offset-19.ant-col-rtl {
    margin-left: 0;
    margin-right: 79.1667%;
  }

  .ant-col-xxl-push-20.ant-col-rtl {
    left: auto;
    right: 83.3333%;
  }

  .ant-col-xxl-pull-20.ant-col-rtl {
    left: 83.3333%;
    right: auto;
  }

  .ant-col-xxl-offset-20.ant-col-rtl {
    margin-left: 0;
    margin-right: 83.3333%;
  }

  .ant-col-xxl-push-21.ant-col-rtl {
    left: auto;
    right: 87.5%;
  }

  .ant-col-xxl-pull-21.ant-col-rtl {
    left: 87.5%;
    right: auto;
  }

  .ant-col-xxl-offset-21.ant-col-rtl {
    margin-left: 0;
    margin-right: 87.5%;
  }

  .ant-col-xxl-push-22.ant-col-rtl {
    left: auto;
    right: 91.6667%;
  }

  .ant-col-xxl-pull-22.ant-col-rtl {
    left: 91.6667%;
    right: auto;
  }

  .ant-col-xxl-offset-22.ant-col-rtl {
    margin-left: 0;
    margin-right: 91.6667%;
  }

  .ant-col-xxl-push-23.ant-col-rtl {
    left: auto;
    right: 95.8333%;
  }

  .ant-col-xxl-pull-23.ant-col-rtl {
    left: 95.8333%;
    right: auto;
  }

  .ant-col-xxl-offset-23.ant-col-rtl {
    margin-left: 0;
    margin-right: 95.8333%;
  }

  .ant-col-xxl-push-24.ant-col-rtl {
    left: auto;
    right: 100%;
  }

  .ant-col-xxl-pull-24.ant-col-rtl {
    left: 100%;
    right: auto;
  }

  .ant-col-xxl-offset-24.ant-col-rtl {
    margin-left: 0;
    margin-right: 100%;
  }
}

.ant-row-rtl {
  direction: rtl;
}

.ant-collapse {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  border-radius: 2px;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
}

.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}

.ant-collapse > .ant-collapse-item:last-child, .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 2px 2px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #000000d9;
  cursor: pointer;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 12px 16px;
  line-height: 1.5715;
  transition: all .3s, visibility;
  display: flex;
  position: relative;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  vertical-align: -1px;
  margin-right: 12px;
  font-size: 12px;
  display: inline-block;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transition: transform .24s;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
  flex: auto;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  margin-left: auto;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
  outline: none;
}

.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only {
  cursor: default;
}

.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text {
  cursor: pointer;
  flex: none;
}

.ant-collapse > .ant-collapse-item .ant-collapse-icon-collapsible-only {
  cursor: default;
}

.ant-collapse > .ant-collapse-item .ant-collapse-icon-collapsible-only .ant-collapse-expand-icon {
  cursor: pointer;
}

.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-left: 12px;
}

.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 40px 12px 16px;
  position: relative;
}

.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin: 0;
  position: absolute;
  top: 50%;
  left: auto;
  right: 16px;
  transform: translateY(-50%);
}

.ant-collapse-content {
  color: #000000d9;
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
}

.ant-collapse-content-hidden {
  display: none;
}

.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 2px 2px;
}

.ant-collapse-borderless {
  background-color: #fafafa;
  border: 0;
}

.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}

.ant-collapse-borderless > .ant-collapse-item:last-child, .ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
}

.ant-collapse-borderless > .ant-collapse-item:last-child {
  border-bottom: 0;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: #0000;
  border-top: 0;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 4px;
}

.ant-collapse-ghost {
  background-color: #0000;
  border: 0;
}

.ant-collapse-ghost > .ant-collapse-item {
  border-bottom: 0;
}

.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
  background-color: #0000;
  border-top: 0;
}

.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 12px;
  padding-bottom: 12px;
}

.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header, .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: #00000040;
  cursor: not-allowed;
}

.ant-collapse-rtl {
  direction: rtl;
}

.ant-collapse-rtl.ant-collapse.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 16px 12px 40px;
  position: relative;
}

.ant-collapse-rtl.ant-collapse.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 16px;
  right: auto;
  transform: translateY(-50%);
}

.ant-collapse-rtl .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 40px 12px 16px;
}

.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin-left: 12px;
  margin-right: 0;
}

.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transform: rotate(180deg);
}

.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  margin-left: 0;
  margin-right: auto;
}

.ant-collapse-rtl.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-left: 0;
  padding-right: 12px;
}

.ant-comment {
  background-color: inherit;
  position: relative;
}

.ant-comment-inner {
  padding: 16px 0;
  display: flex;
}

.ant-comment-avatar {
  cursor: pointer;
  flex-shrink: 0;
  margin-right: 12px;
  position: relative;
}

.ant-comment-avatar img {
  border-radius: 50%;
  width: 32px;
  height: 32px;
}

.ant-comment-content {
  word-wrap: break-word;
  flex: auto;
  min-width: 1px;
  font-size: 14px;
  position: relative;
}

.ant-comment-content-author {
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 4px;
  font-size: 14px;
  display: flex;
}

.ant-comment-content-author > a, .ant-comment-content-author > span {
  padding-right: 8px;
  font-size: 12px;
  line-height: 18px;
}

.ant-comment-content-author-name {
  color: #00000073;
  font-size: 14px;
  transition: color .3s;
}

.ant-comment-content-author-name > *, .ant-comment-content-author-name > :hover {
  color: #00000073;
}

.ant-comment-content-author-time {
  color: #ccc;
  white-space: nowrap;
  cursor: auto;
}

.ant-comment-content-detail p {
  margin-bottom: inherit;
  white-space: pre-wrap;
}

.ant-comment-actions {
  margin-top: 12px;
  margin-bottom: inherit;
  padding-left: 0;
}

.ant-comment-actions > li {
  color: #00000073;
  display: inline-block;
}

.ant-comment-actions > li > span {
  color: #00000073;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  margin-right: 10px;
  font-size: 12px;
  transition: color .3s;
}

.ant-comment-actions > li > span:hover {
  color: #595959;
}

.ant-comment-nested {
  margin-left: 44px;
}

.ant-comment-rtl {
  direction: rtl;
}

.ant-comment-rtl .ant-comment-avatar {
  margin-left: 12px;
  margin-right: 0;
}

.ant-comment-rtl .ant-comment-content-author > a, .ant-comment-rtl .ant-comment-content-author > span {
  padding-left: 8px;
  padding-right: 0;
}

.ant-comment-rtl .ant-comment-actions {
  padding-right: 0;
}

.ant-comment-rtl .ant-comment-actions > li > span {
  margin-left: 10px;
  margin-right: 0;
}

.ant-comment-rtl .ant-comment-nested {
  margin-left: 0;
  margin-right: 44px;
}

.ant-descriptions-header {
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.ant-descriptions-title {
  color: #000000d9;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: auto;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5715;
  overflow: hidden;
}

.ant-descriptions-extra {
  color: #000000d9;
  margin-left: auto;
  font-size: 14px;
}

.ant-descriptions-view {
  border-radius: 2px;
  width: 100%;
}

.ant-descriptions-view table {
  table-layout: fixed;
  width: 100%;
}

.ant-descriptions-row > th, .ant-descriptions-row > td {
  padding-bottom: 16px;
}

.ant-descriptions-row:last-child {
  border-bottom: none;
}

.ant-descriptions-item-label {
  color: #000000d9;
  text-align: start;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5715;
}

.ant-descriptions-item-label:after {
  content: ":";
  margin: 0 8px 0 2px;
  position: relative;
  top: -.5px;
}

.ant-descriptions-item-label.ant-descriptions-item-no-colon:after {
  content: " ";
}

.ant-descriptions-item-no-label:after {
  content: "";
  margin: 0;
}

.ant-descriptions-item-content {
  color: #000000d9;
  word-break: break-word;
  overflow-wrap: break-word;
  flex: 1;
  font-size: 14px;
  line-height: 1.5715;
  display: table-cell;
}

.ant-descriptions-item {
  vertical-align: top;
  padding-bottom: 0;
}

.ant-descriptions-item-container {
  display: flex;
}

.ant-descriptions-item-container .ant-descriptions-item-label, .ant-descriptions-item-container .ant-descriptions-item-content {
  align-items: baseline;
  display: inline-flex;
}

.ant-descriptions-middle .ant-descriptions-row > th, .ant-descriptions-middle .ant-descriptions-row > td {
  padding-bottom: 12px;
}

.ant-descriptions-small .ant-descriptions-row > th, .ant-descriptions-small .ant-descriptions-row > td {
  padding-bottom: 8px;
}

.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #f0f0f0;
}

.ant-descriptions-bordered .ant-descriptions-view > table {
  table-layout: auto;
  border-collapse: collapse;
}

.ant-descriptions-bordered .ant-descriptions-item-label, .ant-descriptions-bordered .ant-descriptions-item-content {
  border-right: 1px solid #f0f0f0;
  padding: 16px 24px;
}

.ant-descriptions-bordered .ant-descriptions-item-label:last-child, .ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  border-right: none;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #fafafa;
}

.ant-descriptions-bordered .ant-descriptions-item-label:after {
  display: none;
}

.ant-descriptions-bordered .ant-descriptions-row {
  border-bottom: 1px solid #f0f0f0;
}

.ant-descriptions-bordered .ant-descriptions-row:last-child {
  border-bottom: none;
}

.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label, .ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content {
  padding: 12px 24px;
}

.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label, .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
  padding: 8px 16px;
}

.ant-descriptions-rtl {
  direction: rtl;
}

.ant-descriptions-rtl .ant-descriptions-item-label:after {
  margin: 0 2px 0 8px;
}

.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label, .ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content {
  border-left: 1px solid #f0f0f0;
  border-right: none;
}

.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label:last-child, .ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  border-left: none;
}

.ant-divider {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  border-top: 1px solid #0000000f;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
}

.ant-divider-vertical {
  vertical-align: middle;
  border-top: 0;
  border-left: 1px solid #0000000f;
  height: .9em;
  margin: 0 8px;
  display: inline-block;
  position: relative;
  top: -.06em;
}

.ant-divider-horizontal {
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 24px 0;
  display: flex;
}

.ant-divider-horizontal.ant-divider-with-text {
  color: #000000d9;
  white-space: nowrap;
  text-align: center;
  border-top: 0 #0000000f;
  align-items: center;
  margin: 16px 0;
  font-size: 16px;
  font-weight: 500;
  display: flex;
}

.ant-divider-horizontal.ant-divider-with-text:before, .ant-divider-horizontal.ant-divider-with-text:after {
  border-top: 1px solid #0000;
  border-top-color: inherit;
  content: "";
  border-bottom: 0;
  width: 50%;
  position: relative;
  transform: translateY(50%);
}

.ant-divider-horizontal.ant-divider-with-text-left:before {
  width: 5%;
}

.ant-divider-horizontal.ant-divider-with-text-left:after, .ant-divider-horizontal.ant-divider-with-text-right:before {
  width: 95%;
}

.ant-divider-horizontal.ant-divider-with-text-right:after {
  width: 5%;
}

.ant-divider-inner-text {
  padding: 0 1em;
  display: inline-block;
}

.ant-divider-dashed {
  background: none;
  border: 0 dashed #0000000f;
  border-top-width: 1px;
}

.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed:before, .ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed:after {
  border-style: dashed none none;
}

.ant-divider-vertical.ant-divider-dashed {
  border-width: 0 0 0 1px;
}

.ant-divider-plain.ant-divider-with-text {
  color: #000000d9;
  font-size: 14px;
  font-weight: normal;
}

.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-no-default-orientation-margin-left:before {
  width: 0;
}

.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-no-default-orientation-margin-left:after {
  width: 100%;
}

.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-no-default-orientation-margin-left .ant-divider-inner-text {
  padding-left: 0;
}

.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-no-default-orientation-margin-right:before {
  width: 100%;
}

.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-no-default-orientation-margin-right:after {
  width: 0;
}

.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-no-default-orientation-margin-right .ant-divider-inner-text {
  padding-right: 0;
}

.ant-divider-rtl {
  direction: rtl;
}

.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left:before {
  width: 95%;
}

.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left:after, .ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right:before {
  width: 5%;
}

.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right:after {
  width: 95%;
}

.ant-drawer {
  z-index: 1000;
  pointer-events: none;
  position: fixed;
  inset: 0;
}

.ant-drawer-inline {
  position: absolute;
}

.ant-drawer-mask {
  z-index: 1000;
  pointer-events: auto;
  background: #00000073;
  position: absolute;
  inset: 0;
}

.ant-drawer-content-wrapper {
  z-index: 1000;
  transition: all .3s;
  position: absolute;
}

.ant-drawer-content-wrapper-hidden {
  display: none;
}

.ant-drawer-left > .ant-drawer-content-wrapper {
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 6px 0 16px -8px #00000014, 9px 0 28px #0000000d, 12px 0 48px 16px #00000008;
}

.ant-drawer-right > .ant-drawer-content-wrapper {
  top: 0;
  bottom: 0;
  right: 0;
  box-shadow: -6px 0 16px -8px #00000014, -9px 0 28px #0000000d, -12px 0 48px 16px #00000008;
}

.ant-drawer-top > .ant-drawer-content-wrapper {
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 6px 16px -8px #00000014, 0 9px 28px #0000000d, 0 12px 48px 16px #00000008;
}

.ant-drawer-bottom > .ant-drawer-content-wrapper {
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 -6px 16px -8px #00000014, 0 -9px 28px #0000000d, 0 -12px 48px 16px #00000008;
}

.ant-drawer-content {
  pointer-events: auto;
  background: #fff;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.ant-drawer-wrapper-body {
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
}

.ant-drawer-header {
  border-bottom: 1px solid #f0f0f0;
  flex: 0;
  align-items: center;
  padding: 16px 24px;
  font-size: 16px;
  line-height: 22px;
  display: flex;
}

.ant-drawer-header-title {
  flex: 1;
  align-items: center;
  min-width: 0;
  min-height: 0;
  display: flex;
}

.ant-drawer-extra {
  flex: none;
}

.ant-drawer-close {
  color: #00000073;
  text-align: center;
  text-transform: none;
  cursor: pointer;
  text-rendering: auto;
  background: none;
  border: 0;
  outline: 0;
  margin-right: 12px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  transition: color .3s;
  display: inline-block;
}

.ant-drawer-close:focus, .ant-drawer-close:hover {
  color: #000000bf;
  text-decoration: none;
}

.ant-drawer-title {
  color: #000000d9;
  flex: 1;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.ant-drawer-body {
  flex: 1;
  min-width: 0;
  min-height: 0;
  padding: 24px;
  overflow: auto;
}

.ant-drawer-footer {
  border-top: 1px solid #f0f0f0;
  flex-shrink: 0;
  padding: 10px 16px;
}

.panel-motion-enter-start, .panel-motion-appear-start, .panel-motion-leave-start {
  transition: none;
}

.panel-motion-enter-active, .panel-motion-appear-active, .panel-motion-leave-active, .ant-drawer-mask-motion-enter-active, .ant-drawer-mask-motion-appear-active, .ant-drawer-mask-motion-leave-active {
  transition: all .3s;
}

.ant-drawer-mask-motion-enter, .ant-drawer-mask-motion-appear {
  opacity: 0;
}

.ant-drawer-mask-motion-enter-active, .ant-drawer-mask-motion-appear-active, .ant-drawer-mask-motion-leave {
  opacity: 1;
}

.ant-drawer-mask-motion-leave-active {
  opacity: 0;
}

.ant-drawer-panel-motion-left-enter-start, .ant-drawer-panel-motion-left-appear-start, .ant-drawer-panel-motion-left-leave-start {
  transition: none;
}

.ant-drawer-panel-motion-left-enter-active, .ant-drawer-panel-motion-left-appear-active, .ant-drawer-panel-motion-left-leave-active {
  transition: all .3s;
}

.ant-drawer-panel-motion-left-enter-start, .ant-drawer-panel-motion-left-appear-start {
  transform: translateX(-100%) !important;
}

.ant-drawer-panel-motion-left-enter-active, .ant-drawer-panel-motion-left-appear-active, .ant-drawer-panel-motion-left-leave {
  transform: translateX(0);
}

.ant-drawer-panel-motion-left-leave-active {
  transform: translateX(-100%);
}

.ant-drawer-panel-motion-right-enter-start, .ant-drawer-panel-motion-right-appear-start, .ant-drawer-panel-motion-right-leave-start {
  transition: none;
}

.ant-drawer-panel-motion-right-enter-active, .ant-drawer-panel-motion-right-appear-active, .ant-drawer-panel-motion-right-leave-active {
  transition: all .3s;
}

.ant-drawer-panel-motion-right-enter-start, .ant-drawer-panel-motion-right-appear-start {
  transform: translateX(100%) !important;
}

.ant-drawer-panel-motion-right-enter-active, .ant-drawer-panel-motion-right-appear-active, .ant-drawer-panel-motion-right-leave {
  transform: translateX(0);
}

.ant-drawer-panel-motion-right-leave-active {
  transform: translateX(100%);
}

.ant-drawer-panel-motion-top-enter-start, .ant-drawer-panel-motion-top-appear-start, .ant-drawer-panel-motion-top-leave-start {
  transition: none;
}

.ant-drawer-panel-motion-top-enter-active, .ant-drawer-panel-motion-top-appear-active, .ant-drawer-panel-motion-top-leave-active {
  transition: all .3s;
}

.ant-drawer-panel-motion-top-enter-start, .ant-drawer-panel-motion-top-appear-start {
  transform: translateY(-100%) !important;
}

.ant-drawer-panel-motion-top-enter-active, .ant-drawer-panel-motion-top-appear-active, .ant-drawer-panel-motion-top-leave {
  transform: translateY(0);
}

.ant-drawer-panel-motion-top-leave-active {
  transform: translateY(-100%);
}

.ant-drawer-panel-motion-bottom-enter-start, .ant-drawer-panel-motion-bottom-appear-start, .ant-drawer-panel-motion-bottom-leave-start {
  transition: none;
}

.ant-drawer-panel-motion-bottom-enter-active, .ant-drawer-panel-motion-bottom-appear-active, .ant-drawer-panel-motion-bottom-leave-active {
  transition: all .3s;
}

.ant-drawer-panel-motion-bottom-enter-start, .ant-drawer-panel-motion-bottom-appear-start {
  transform: translateY(100%) !important;
}

.ant-drawer-panel-motion-bottom-enter-active, .ant-drawer-panel-motion-bottom-appear-active, .ant-drawer-panel-motion-bottom-leave {
  transform: translateY(0);
}

.ant-drawer-panel-motion-bottom-leave-active {
  transform: translateY(100%);
}

.ant-drawer-rtl {
  direction: rtl;
}

.ant-drawer-rtl .ant-drawer-close {
  margin-left: 12px;
  margin-right: 0;
}

.ant-form-item .ant-input-number + .ant-form-text {
  margin-left: 8px;
}

.ant-form-inline {
  flex-wrap: wrap;
  display: flex;
}

.ant-form-inline .ant-form-item {
  flex-wrap: nowrap;
  flex: none;
  margin-bottom: 0;
  margin-right: 16px;
}

.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 24px;
}

.ant-form-inline .ant-form-item > .ant-form-item-label, .ant-form-inline .ant-form-item > .ant-form-item-control {
  vertical-align: top;
  display: inline-block;
}

.ant-form-inline .ant-form-item > .ant-form-item-label {
  flex: none;
}

.ant-form-inline .ant-form-item .ant-form-text, .ant-form-inline .ant-form-item .ant-form-item-has-feedback {
  display: inline-block;
}

.ant-form-horizontal .ant-form-item-label {
  flex-grow: 0;
}

.ant-form-horizontal .ant-form-item-control {
  flex: 1 1 0;
  min-width: 0;
}

.ant-form-horizontal .ant-form-item-label[class$="-24"] + .ant-form-item-control, .ant-form-horizontal .ant-form-item-label[class*="-24 "] + .ant-form-item-control {
  min-width: unset;
}

.ant-form-vertical .ant-form-item-row {
  flex-direction: column;
}

.ant-form-vertical .ant-form-item-label > label {
  height: auto;
}

.ant-form-vertical .ant-form-item .ant-form-item-control {
  width: 100%;
}

.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
  white-space: initial;
  text-align: left;
  padding: 0 0 8px;
  line-height: 1.5715;
}

.ant-form-vertical .ant-form-item-label > label, .ant-col-24.ant-form-item-label > label, .ant-col-xl-24.ant-form-item-label > label {
  margin: 0;
}

.ant-form-vertical .ant-form-item-label > label:after, .ant-col-24.ant-form-item-label > label:after, .ant-col-xl-24.ant-form-item-label > label:after {
  display: none;
}

.ant-form-rtl.ant-form-vertical .ant-form-item-label, .ant-form-rtl.ant-col-24.ant-form-item-label, .ant-form-rtl.ant-col-xl-24.ant-form-item-label {
  text-align: right;
}

@media (width <= 575px) {
  .ant-form-item .ant-form-item-label {
    white-space: initial;
    text-align: left;
    padding: 0 0 8px;
    line-height: 1.5715;
  }

  .ant-form-item .ant-form-item-label > label {
    margin: 0;
  }

  .ant-form-item .ant-form-item-label > label:after {
    display: none;
  }

  .ant-form-rtl.ant-form-item .ant-form-item-label {
    text-align: right;
  }

  .ant-form .ant-form-item {
    flex-wrap: wrap;
  }

  .ant-form .ant-form-item .ant-form-item-label, .ant-form .ant-form-item .ant-form-item-control {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ant-col-xs-24.ant-form-item-label {
    white-space: initial;
    text-align: left;
    padding: 0 0 8px;
    line-height: 1.5715;
  }

  .ant-col-xs-24.ant-form-item-label > label {
    margin: 0;
  }

  .ant-col-xs-24.ant-form-item-label > label:after {
    display: none;
  }

  .ant-form-rtl.ant-col-xs-24.ant-form-item-label {
    text-align: right;
  }
}

@media (width <= 767px) {
  .ant-col-sm-24.ant-form-item-label {
    white-space: initial;
    text-align: left;
    padding: 0 0 8px;
    line-height: 1.5715;
  }

  .ant-col-sm-24.ant-form-item-label > label {
    margin: 0;
  }

  .ant-col-sm-24.ant-form-item-label > label:after {
    display: none;
  }

  .ant-form-rtl.ant-col-sm-24.ant-form-item-label {
    text-align: right;
  }
}

@media (width <= 991px) {
  .ant-col-md-24.ant-form-item-label {
    white-space: initial;
    text-align: left;
    padding: 0 0 8px;
    line-height: 1.5715;
  }

  .ant-col-md-24.ant-form-item-label > label {
    margin: 0;
  }

  .ant-col-md-24.ant-form-item-label > label:after {
    display: none;
  }

  .ant-form-rtl.ant-col-md-24.ant-form-item-label {
    text-align: right;
  }
}

@media (width <= 1199px) {
  .ant-col-lg-24.ant-form-item-label {
    white-space: initial;
    text-align: left;
    padding: 0 0 8px;
    line-height: 1.5715;
  }

  .ant-col-lg-24.ant-form-item-label > label {
    margin: 0;
  }

  .ant-col-lg-24.ant-form-item-label > label:after {
    display: none;
  }

  .ant-form-rtl.ant-col-lg-24.ant-form-item-label {
    text-align: right;
  }
}

@media (width <= 1599px) {
  .ant-col-xl-24.ant-form-item-label {
    white-space: initial;
    text-align: left;
    padding: 0 0 8px;
    line-height: 1.5715;
  }

  .ant-col-xl-24.ant-form-item-label > label {
    margin: 0;
  }

  .ant-col-xl-24.ant-form-item-label > label:after {
    display: none;
  }

  .ant-form-rtl.ant-col-xl-24.ant-form-item-label {
    text-align: right;
  }
}

.ant-form-item-explain-error {
  color: #ff4d4f;
}

.ant-form-item-explain-warning {
  color: #faad14;
}

.ant-form-item-has-feedback .ant-switch {
  margin: 2px 0 4px;
}

.ant-form-item-has-warning .ant-form-item-split {
  color: #faad14;
}

.ant-form-item-has-error .ant-form-item-split {
  color: #ff4d4f;
}

.ant-form {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
}

.ant-form legend {
  color: #00000073;
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #d9d9d9;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  display: block;
}

.ant-form label {
  font-size: 14px;
}

.ant-form input[type="search"] {
  box-sizing: border-box;
}

.ant-form input[type="radio"], .ant-form input[type="checkbox"] {
  line-height: normal;
}

.ant-form input[type="file"] {
  display: block;
}

.ant-form input[type="range"] {
  width: 100%;
  display: block;
}

.ant-form select[multiple], .ant-form select[size] {
  height: auto;
}

.ant-form input[type="file"]:focus, .ant-form input[type="radio"]:focus, .ant-form input[type="checkbox"]:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.ant-form output {
  color: #000000d9;
  padding-top: 15px;
  font-size: 14px;
  line-height: 1.5715;
  display: block;
}

.ant-form .ant-form-text {
  padding-right: 8px;
  display: inline-block;
}

.ant-form-small .ant-form-item-label > label {
  height: 24px;
}

.ant-form-small .ant-form-item-control-input {
  min-height: 24px;
}

.ant-form-large .ant-form-item-label > label {
  height: 40px;
}

.ant-form-large .ant-form-item-control-input {
  min-height: 40px;
}

.ant-form-item {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  vertical-align: top;
  margin: 0 0 24px;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
}

.ant-form-item-with-help {
  transition: none;
}

.ant-form-item-hidden, .ant-form-item-hidden.ant-row {
  display: none;
}

.ant-form-item-label {
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
  flex-grow: 0;
  display: inline-block;
  overflow: hidden;
}

.ant-form-item-label-left {
  text-align: left;
}

.ant-form-item-label-wrap {
  overflow: unset;
  white-space: unset;
  line-height: 1.3215em;
}

.ant-form-item-label > label {
  color: #000000d9;
  align-items: center;
  max-width: 100%;
  height: 32px;
  font-size: 14px;
  display: inline-flex;
  position: relative;
}

.ant-form-item-label > label > .anticon {
  vertical-align: top;
  font-size: 14px;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  color: #ff4d4f;
  content: "*";
  margin-right: 4px;
  font-family: SimSun, sans-serif;
  font-size: 14px;
  line-height: 1;
  display: inline-block;
}

.ant-form-hide-required-mark .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  display: none;
}

.ant-form-item-label > label .ant-form-item-optional {
  color: #00000073;
  margin-left: 4px;
  display: inline-block;
}

.ant-form-hide-required-mark .ant-form-item-label > label .ant-form-item-optional {
  display: none;
}

.ant-form-item-label > label .ant-form-item-tooltip {
  color: #00000073;
  cursor: help;
  -ms-writing-mode: lr-tb;
  writing-mode: horizontal-tb;
  margin-inline-start: 4px;
}

.ant-form-item-label > label:after {
  content: ":";
  margin: 0 8px 0 2px;
  position: relative;
  top: -.5px;
}

.ant-form-item-label > label.ant-form-item-no-colon:after {
  content: " ";
}

.ant-form-item-control {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.ant-form-item-control:first-child:not([class^="ant-col-"]):not([class*=" ant-col-"]) {
  width: 100%;
}

.ant-form-item-control-input {
  align-items: center;
  min-height: 32px;
  display: flex;
  position: relative;
}

.ant-form-item-control-input-content {
  flex: auto;
  max-width: 100%;
}

.ant-form-item-explain, .ant-form-item-extra {
  clear: both;
  color: #00000073;
  font-size: 14px;
  line-height: 1.5715;
  transition: color .3s cubic-bezier(.215, .61, .355, 1);
}

.ant-form-item-explain-connected {
  width: 100%;
}

.ant-form-item-extra {
  min-height: 24px;
}

.ant-form-item-with-help .ant-form-item-explain {
  opacity: 1;
  height: auto;
}

.ant-form-item-feedback-icon {
  text-align: center;
  visibility: visible;
  pointer-events: none;
  font-size: 14px;
  animation: .3s cubic-bezier(.12, .4, .29, 1.46) zoomIn;
}

.ant-form-item-feedback-icon-success {
  color: #52c41a;
}

.ant-form-item-feedback-icon-error {
  color: #ff4d4f;
}

.ant-form-item-feedback-icon-warning {
  color: #faad14;
}

.ant-form-item-feedback-icon-validating {
  color: #1890ff;
}

.ant-show-help {
  transition: opacity .3s cubic-bezier(.645, .045, .355, 1);
}

.ant-show-help-appear, .ant-show-help-enter {
  opacity: 0;
}

.ant-show-help-appear-active, .ant-show-help-enter-active, .ant-show-help-leave {
  opacity: 1;
}

.ant-show-help-leave-active {
  opacity: 0;
}

.ant-show-help-item {
  overflow: hidden;
  transition: height .3s cubic-bezier(.645, .045, .355, 1), opacity .3s cubic-bezier(.645, .045, .355, 1), transform .3s cubic-bezier(.645, .045, .355, 1) !important;
}

.ant-show-help-item-appear, .ant-show-help-item-enter {
  opacity: 0;
  transform: translateY(-5px);
}

.ant-show-help-item-appear-active, .ant-show-help-item-enter-active {
  opacity: 1;
  transform: translateY(0);
}

.ant-show-help-item-leave {
  transition: height .2s cubic-bezier(.645, .045, .355, 1), opacity .2s cubic-bezier(.645, .045, .355, 1), transform .2s cubic-bezier(.645, .045, .355, 1) !important;
}

.ant-show-help-item-leave-active {
  transform: translateY(-5px);
}

@keyframes diffZoomIn1 {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes diffZoomIn2 {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes diffZoomIn3 {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.ant-form-rtl {
  direction: rtl;
}

.ant-form-rtl .ant-form-item-label {
  text-align: left;
}

.ant-form-rtl .ant-form-item-label > label.ant-form-item-required:before {
  margin-left: 4px;
  margin-right: 0;
}

.ant-form-rtl .ant-form-item-label > label:after {
  margin: 0 2px 0 8px;
}

.ant-form-rtl .ant-form-item-label > label .ant-form-item-optional {
  margin-left: 0;
  margin-right: 4px;
}

.ant-col-rtl .ant-form-item-control:first-child {
  width: 100%;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input {
  padding-left: 24px;
  padding-right: 11px;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-left: 18px;
  padding-right: 11px;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input, .ant-form-rtl .ant-form-item-has-feedback .ant-input-number-affix-wrapper .ant-input-number {
  padding: 0;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  left: 28px;
  right: auto;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input-number {
  padding-left: 18px;
}

.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-arrow, .ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-clear, .ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow, .ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear, .ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-arrow, .ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-clear {
  left: 32px;
  right: auto;
}

.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value, .ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value, .ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-left: 42px;
  padding-right: 0;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-arrow {
  margin-left: 19px;
  margin-right: 0;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-clear {
  left: 32px;
  right: auto;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-picker, .ant-form-rtl .ant-form-item-has-feedback .ant-picker-large {
  padding-left: 29.2px;
  padding-right: 11px;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-picker-small {
  padding-left: 25.2px;
  padding-right: 7px;
}

.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon, .ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon, .ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon, .ant-form-rtl .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
  left: 0;
  right: auto;
}

.ant-form-rtl.ant-form-inline .ant-form-item {
  margin-left: 16px;
  margin-right: 0;
}

.ant-image {
  display: inline-block;
  position: relative;
}

.ant-image-img {
  vertical-align: middle;
  width: 100%;
  height: auto;
}

.ant-image-img-placeholder {
  background-color: #f5f5f5;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQuNSAyLjVoLTEzQS41LjUgMCAwIDAgMSAzdjEwYS41LjUgMCAwIDAgLjUuNWgxM2EuNS41IDAgMCAwIC41LS41VjNhLjUuNSAwIDAgMC0uNS0uNXpNNS4yODEgNC43NWExIDEgMCAwIDEgMCAyIDEgMSAwIDAgMSAwLTJ6bTguMDMgNi44M2EuMTI3LjEyNyAwIDAgMS0uMDgxLjAzSDIuNzY5YS4xMjUuMTI1IDAgMCAxLS4wOTYtLjIwN2wyLjY2MS0zLjE1NmEuMTI2LjEyNiAwIDAgMSAuMTc3LS4wMTZsLjAxNi4wMTZMNy4wOCAxMC4wOWwyLjQ3LTIuOTNhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTUuMDE2IDMuNTg4IDQuMjQ0YS4xMjcuMTI3IDAgMCAxLS4wMi4xNzV6IiBmaWxsPSIjOEM4QzhDIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30%;
}

.ant-image-mask {
  color: #fff;
  cursor: pointer;
  opacity: 0;
  background: #00000080;
  justify-content: center;
  align-items: center;
  transition: opacity .3s;
  display: flex;
  position: absolute;
  inset: 0;
}

.ant-image-mask-info {
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 4px;
  overflow: hidden;
}

.ant-image-mask-info .anticon {
  margin-inline-end: 4px;
}

.ant-image-mask:hover {
  opacity: 1;
}

.ant-image-placeholder {
  position: absolute;
  inset: 0;
}

.ant-image-preview {
  pointer-events: none;
  text-align: center;
  height: 100%;
}

.ant-image-preview.ant-zoom-enter, .ant-image-preview.ant-zoom-appear {
  opacity: 0;
  -webkit-user-select: none;
  user-select: none;
  animation-duration: .3s;
  transform: none;
}

.ant-image-preview-mask {
  z-index: 1000;
  background-color: #00000073;
  height: 100%;
  position: fixed;
  inset: 0;
}

.ant-image-preview-mask-hidden {
  display: none;
}

.ant-image-preview-wrap {
  outline: 0;
  position: fixed;
  inset: 0;
  overflow: auto;
}

.ant-image-preview-body {
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.ant-image-preview-img {
  vertical-align: middle;
  cursor: grab;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: auto;
  max-width: 100%;
  max-height: 100%;
  transition: transform .3s cubic-bezier(.215, .61, .355, 1);
  transform: scale3d(1, 1, 1);
}

.ant-image-preview-img-wrapper {
  transition: transform .3s cubic-bezier(.215, .61, .355, 1);
  position: absolute;
  inset: 0;
}

.ant-image-preview-img-wrapper:before {
  content: "";
  width: 1px;
  height: 50%;
  margin-right: -1px;
  display: inline-block;
}

.ant-image-preview-moving .ant-image-preview-img {
  cursor: grabbing;
}

.ant-image-preview-moving .ant-image-preview-img-wrapper {
  transition-duration: 0s;
}

.ant-image-preview-wrap {
  z-index: 1080;
}

.ant-image-preview-operations-wrapper {
  z-index: 1081;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
}

.ant-image-preview-operations {
  box-sizing: border-box;
  color: #ffffffd9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  pointer-events: auto;
  background: #0000001a;
  flex-direction: row-reverse;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: flex;
}

.ant-image-preview-operations-operation {
  cursor: pointer;
  margin-left: 12px;
  padding: 12px;
  transition: all .3s;
}

.ant-image-preview-operations-operation:hover {
  background: #0003;
}

.ant-image-preview-operations-operation-disabled {
  color: #ffffff40;
  pointer-events: none;
}

.ant-image-preview-operations-operation:last-of-type {
  margin-left: 0;
}

.ant-image-preview-operations-progress {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.ant-image-preview-operations-icon {
  font-size: 18px;
}

.ant-image-preview-switch-left, .ant-image-preview-switch-right {
  z-index: 1081;
  color: #ffffffd9;
  cursor: pointer;
  pointer-events: auto;
  background: #0000001a;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  transition: all .3s;
  display: flex;
  position: fixed;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}

.ant-image-preview-switch-left:hover, .ant-image-preview-switch-right:hover {
  background: #0003;
}

.ant-image-preview-switch-left-disabled, .ant-image-preview-switch-right-disabled, .ant-image-preview-switch-left-disabled:hover, .ant-image-preview-switch-right-disabled:hover {
  color: #ffffff40;
  cursor: not-allowed;
  background: #0000001a;
}

.ant-image-preview-switch-left-disabled > .anticon, .ant-image-preview-switch-right-disabled > .anticon, .ant-image-preview-switch-left-disabled:hover > .anticon, .ant-image-preview-switch-right-disabled:hover > .anticon {
  cursor: not-allowed;
}

.ant-image-preview-switch-left > .anticon, .ant-image-preview-switch-right > .anticon {
  font-size: 18px;
}

.ant-image-preview-switch-left {
  left: 8px;
}

.ant-image-preview-switch-right {
  right: 8px;
}

.ant-input-number-affix-wrapper {
  color: #000000d9;
  padding: 0;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 90px;
  min-width: 0;
  padding-inline-start: 11px;
  font-size: 14px;
  line-height: 1.5715;
  transition: all .3s;
  display: inline-flex;
  position: relative;
}

.ant-input-number-affix-wrapper::-moz-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input-number-affix-wrapper:-ms-input-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input-number-affix-wrapper::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input-number-affix-wrapper:-moz-placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input-number-affix-wrapper:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-input-number-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input-number-affix-wrapper:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}

.ant-input-number-affix-wrapper:focus, .ant-input-number-affix-wrapper-focused {
  border-color: #40a9ff;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #1890ff33;
}

.ant-input-number-affix-wrapper-disabled {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-input-number-affix-wrapper-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-input-number-affix-wrapper[disabled] {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-input-number-affix-wrapper[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-input-number-affix-wrapper-borderless, .ant-input-number-affix-wrapper-borderless:hover, .ant-input-number-affix-wrapper-borderless:focus, .ant-input-number-affix-wrapper-borderless-focused, .ant-input-number-affix-wrapper-borderless-disabled, .ant-input-number-affix-wrapper-borderless[disabled] {
  box-shadow: none;
  background-color: #0000;
  border: none;
}

textarea.ant-input-number-affix-wrapper {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  transition: all .3s, height;
}

.ant-input-number-affix-wrapper-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-input-number-affix-wrapper-sm {
  padding: 0 7px;
}

.ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled):hover {
  z-index: 1;
  border-color: #40a9ff;
  border-right-width: 1px;
}

.ant-input-number-affix-wrapper-focused, .ant-input-number-affix-wrapper:focus {
  z-index: 1;
}

.ant-input-number-affix-wrapper-disabled .ant-input-number[disabled] {
  background: none;
}

.ant-input-number-affix-wrapper > div.ant-input-number {
  border: none;
  outline: none;
  width: 100%;
}

.ant-input-number-affix-wrapper > div.ant-input-number.ant-input-number-focused {
  box-shadow: none !important;
}

.ant-input-number-affix-wrapper input.ant-input-number-input {
  padding: 0;
}

.ant-input-number-affix-wrapper:before {
  visibility: hidden;
  content: " ";
  width: 0;
  display: inline-block;
}

.ant-input-number-affix-wrapper .ant-input-number-handler-wrap {
  z-index: 2;
}

.ant-input-number-prefix, .ant-input-number-suffix {
  pointer-events: none;
  flex: none;
  align-items: center;
  display: flex;
}

.ant-input-number-prefix {
  margin-inline-end: 4px;
}

.ant-input-number-suffix {
  z-index: 1;
  height: 100%;
  margin-left: 4px;
  margin-right: 11px;
  position: absolute;
  top: 0;
  right: 0;
}

.ant-input-number-group-wrapper .ant-input-number-affix-wrapper {
  width: 100%;
}

.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number, .ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #ff4d4f;
}

.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus, .ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ff7875;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #ff4d4f33;
}

.ant-input-number-status-error .ant-input-number-prefix {
  color: #ff4d4f;
}

.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number, .ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #faad14;
}

.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus, .ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ffc53d;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #faad1433;
}

.ant-input-number-status-warning .ant-input-number-prefix {
  color: #faad14;
}

.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper, .ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #ff4d4f;
}

.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus, .ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ff7875;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #ff4d4f33;
}

.ant-input-number-affix-wrapper-status-error .ant-input-number-prefix {
  color: #ff4d4f;
}

.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper, .ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}

.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus, .ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ffc53d;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #faad1433;
}

.ant-input-number-affix-wrapper-status-warning .ant-input-number-prefix {
  color: #faad14;
}

.ant-input-number-group-wrapper-status-error .ant-input-number-group-addon {
  color: #ff4d4f;
  border-color: #ff4d4f;
}

.ant-input-number-group-wrapper-status-warning .ant-input-number-group-addon {
  color: #faad14;
  border-color: #faad14;
}

.ant-input-number {
  box-sizing: border-box;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  color: #000000d9;
  padding: 4px 11px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 90px;
  min-width: 0;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  transition: all .3s;
  display: inline-block;
  position: relative;
}

.ant-input-number::-moz-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input-number:-ms-input-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input-number::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input-number:-moz-placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input-number:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-input-number:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input-number:focus, .ant-input-number-focused {
  border-color: #40a9ff;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #1890ff33;
}

.ant-input-number[disabled] {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-input-number[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-input-number-borderless, .ant-input-number-borderless:hover, .ant-input-number-borderless:focus, .ant-input-number-borderless-focused, .ant-input-number-borderless-disabled, .ant-input-number-borderless[disabled] {
  box-shadow: none;
  background-color: #0000;
  border: none;
}

textarea.ant-input-number {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  transition: all .3s, height;
}

.ant-input-number-group {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: table;
  position: relative;
}

.ant-input-number-group[class*="col-"] {
  float: none;
  padding-left: 0;
  padding-right: 0;
}

.ant-input-number-group > [class*="col-"] {
  padding-right: 8px;
}

.ant-input-number-group > [class*="col-"]:last-child {
  padding-right: 0;
}

.ant-input-number-group-addon, .ant-input-number-group-wrap, .ant-input-number-group > .ant-input-number {
  display: table-cell;
}

.ant-input-number-group-addon:not(:first-child):not(:last-child), .ant-input-number-group-wrap:not(:first-child):not(:last-child), .ant-input-number-group > .ant-input-number:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.ant-input-number-group-addon, .ant-input-number-group-wrap {
  white-space: nowrap;
  vertical-align: middle;
  width: 1px;
}

.ant-input-number-group-wrap > * {
  display: block !important;
}

.ant-input-number-group .ant-input-number {
  float: left;
  text-align: inherit;
  width: 100%;
  margin-bottom: 0;
}

.ant-input-number-group .ant-input-number:focus, .ant-input-number-group .ant-input-number:hover {
  z-index: 1;
  border-right-width: 1px;
}

.ant-input-search-with-button .ant-input-number-group .ant-input-number:hover {
  z-index: 0;
}

.ant-input-number-group-addon {
  color: #000000d9;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 0 11px;
  font-size: 14px;
  font-weight: normal;
  transition: all .3s;
  position: relative;
}

.ant-input-number-group-addon .ant-select {
  margin: -5px -11px;
}

.ant-input-number-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  box-shadow: none;
  border: 1px solid #0000;
}

.ant-input-number-group-addon .ant-select-open .ant-select-selector, .ant-input-number-group-addon .ant-select-focused .ant-select-selector {
  color: #1890ff;
}

.ant-input-number-group-addon .ant-cascader-picker {
  background-color: #0000;
  margin: -9px -12px;
}

.ant-input-number-group-addon .ant-cascader-picker .ant-cascader-input {
  text-align: left;
  box-shadow: none;
  border: 0;
}

.ant-input-number-group > .ant-input-number:first-child, .ant-input-number-group-addon:first-child, .ant-input-number-group > .ant-input-number:first-child .ant-select .ant-select-selector, .ant-input-number-group-addon:first-child .ant-select .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-number-group > .ant-input-number-affix-wrapper:not(:first-child) .ant-input-number {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-number-group > .ant-input-number-affix-wrapper:not(:last-child) .ant-input-number {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-number-group-addon:first-child {
  border-right: 0;
}

.ant-input-number-group-addon:last-child {
  border-left: 0;
}

.ant-input-number-group > .ant-input-number:last-child, .ant-input-number-group-addon:last-child, .ant-input-number-group > .ant-input-number:last-child .ant-select .ant-select-selector, .ant-input-number-group-addon:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-number-group-lg .ant-input-number, .ant-input-number-group-lg > .ant-input-number-group-addon {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-input-number-group-sm .ant-input-number, .ant-input-number-group-sm > .ant-input-number-group-addon {
  padding: 0 7px;
}

.ant-input-number-group-lg .ant-select-single .ant-select-selector {
  height: 40px;
}

.ant-input-number-group-sm .ant-select-single .ant-select-selector {
  height: 24px;
}

.ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-search .ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child) {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.ant-input-number-group .ant-input-number-affix-wrapper:not(:first-child), .ant-input-search .ant-input-number-group .ant-input-number-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-number-group.ant-input-number-group-compact {
  display: block;
}

.ant-input-number-group.ant-input-number-group-compact:before {
  content: "";
  display: table;
}

.ant-input-number-group.ant-input-number-group-compact:after {
  clear: both;
  content: "";
  display: table;
}

.ant-input-number-group.ant-input-number-group-compact-addon:not(:first-child):not(:last-child), .ant-input-number-group.ant-input-number-group-compact-wrap:not(:first-child):not(:last-child), .ant-input-number-group.ant-input-number-group-compact > .ant-input-number:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}

.ant-input-number-group.ant-input-number-group-compact-addon:not(:first-child):not(:last-child):hover, .ant-input-number-group.ant-input-number-group-compact-wrap:not(:first-child):not(:last-child):hover, .ant-input-number-group.ant-input-number-group-compact > .ant-input-number:not(:first-child):not(:last-child):hover, .ant-input-number-group.ant-input-number-group-compact-addon:not(:first-child):not(:last-child):focus, .ant-input-number-group.ant-input-number-group-compact-wrap:not(:first-child):not(:last-child):focus, .ant-input-number-group.ant-input-number-group-compact > .ant-input-number:not(:first-child):not(:last-child):focus {
  z-index: 1;
}

.ant-input-number-group.ant-input-number-group-compact > * {
  float: none;
  vertical-align: top;
  border-radius: 0;
  display: inline-block;
}

.ant-input-number-group.ant-input-number-group-compact > .ant-input-number-affix-wrapper, .ant-input-number-group.ant-input-number-group-compact > .ant-input-number-number-affix-wrapper, .ant-input-number-group.ant-input-number-group-compact > .ant-picker-range {
  display: inline-flex;
}

.ant-input-number-group.ant-input-number-group-compact > :not(:last-child) {
  border-right-width: 1px;
  margin-right: -1px;
}

.ant-input-number-group.ant-input-number-group-compact .ant-input-number {
  float: none;
}

.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector, .ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input, .ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker .ant-input, .ant-input-number-group.ant-input-number-group-compact > .ant-input-group-wrapper .ant-input {
  border-right-width: 1px;
  border-radius: 0;
}

.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector:hover, .ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input:hover, .ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker .ant-input:hover, .ant-input-number-group.ant-input-number-group-compact > .ant-input-group-wrapper .ant-input:hover, .ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector:focus, .ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input:focus, .ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker .ant-input:focus, .ant-input-number-group.ant-input-number-group-compact > .ant-input-group-wrapper .ant-input:focus, .ant-input-number-group.ant-input-number-group-compact > .ant-select-focused, .ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-arrow {
  z-index: 1;
}

.ant-input-number-group.ant-input-number-group-compact > :first-child, .ant-input-number-group.ant-input-number-group-compact > .ant-select:first-child > .ant-select-selector, .ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete:first-child .ant-input, .ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.ant-input-number-group.ant-input-number-group-compact > :last-child, .ant-input-number-group.ant-input-number-group-compact > .ant-select:last-child > .ant-select-selector, .ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:last-child .ant-input, .ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-right-width: 1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}

.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper {
  margin-left: -1px;
}

.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper, .ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
  border-radius: 0;
}

.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 2px 0 0 2px;
}

.ant-input-number-group > .ant-input-number-rtl:first-child {
  border-radius: 0 2px 2px 0;
}

.ant-input-number-group > .ant-input-number-rtl:last-child {
  border-radius: 2px 0 0 2px;
}

.ant-input-number-group-rtl .ant-input-number-group-addon:first-child {
  border-left: 0;
  border-right: 1px solid #d9d9d9;
  border-radius: 0 2px 2px 0;
}

.ant-input-number-group-rtl .ant-input-number-group-addon:last-child {
  border-left: 1px solid #d9d9d9;
  border-right: 0;
  border-radius: 2px 0 0 2px;
}

.ant-input-number-group-wrapper {
  text-align: start;
  vertical-align: top;
  display: inline-block;
}

.ant-input-number-handler {
  color: #00000073;
  text-align: center;
  border-left: 1px solid #d9d9d9;
  width: 100%;
  height: 50%;
  font-weight: bold;
  line-height: 0;
  transition: all .1s linear;
  display: block;
  position: relative;
  overflow: hidden;
}

.ant-input-number-handler:active {
  background: #f4f4f4;
}

.ant-input-number-handler:hover .ant-input-number-handler-up-inner, .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #40a9ff;
}

.ant-input-number-handler-up-inner, .ant-input-number-handler-down-inner {
  color: #00000073;
  text-align: center;
  text-transform: none;
  vertical-align: -.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  width: 12px;
  height: 12px;
  font-style: normal;
  line-height: 12px;
  transition: all .1s linear;
  display: inline-flex;
  position: absolute;
  right: 4px;
}

.ant-input-number-handler-up-inner > *, .ant-input-number-handler-down-inner > * {
  line-height: 1;
}

.ant-input-number-handler-up-inner svg, .ant-input-number-handler-down-inner svg {
  display: inline-block;
}

.ant-input-number-handler-up-inner:before, .ant-input-number-handler-down-inner:before {
  display: none;
}

.ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon, .ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon, .ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon, .ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon {
  display: block;
}

.ant-input-number:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}

.ant-input-number:hover + .ant-form-item-children-icon {
  opacity: 0;
  transition: opacity .24s linear .24s;
}

.ant-input-number-focused {
  border-color: #40a9ff;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #1890ff33;
}

.ant-input-number-disabled {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-input-number-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-input-number-disabled .ant-input-number-input {
  cursor: not-allowed;
}

.ant-input-number-disabled .ant-input-number-handler-wrap, .ant-input-number-readonly .ant-input-number-handler-wrap {
  display: none;
}

.ant-input-number-input {
  text-align: left;
  background-color: #0000;
  border: 0;
  border-radius: 2px;
  outline: 0;
  width: 100%;
  height: 30px;
  padding: 0 11px;
  transition: all .3s linear;
  appearance: textfield !important;
}

.ant-input-number-input::-moz-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input-number-input:-ms-input-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input-number-input::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input-number-input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input-number-input:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-input-number-input:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input-number-input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.ant-input-number-input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

.ant-input-number-lg {
  padding: 0;
  font-size: 16px;
}

.ant-input-number-lg input {
  height: 38px;
}

.ant-input-number-sm {
  padding: 0;
}

.ant-input-number-sm input {
  height: 22px;
  padding: 0 7px;
}

.ant-input-number-handler-wrap {
  opacity: 0;
  background: #fff;
  border-radius: 0 2px 2px 0;
  width: 22px;
  height: 100%;
  transition: opacity .24s linear .1s;
  position: absolute;
  top: 0;
  right: 0;
}

.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner, .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  justify-content: center;
  align-items: center;
  min-width: auto;
  margin-right: 0;
  font-size: 7px;
  display: flex;
}

.ant-input-number-borderless .ant-input-number-handler-wrap {
  border-left-width: 0;
}

.ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: 40%;
}

.ant-input-number:hover .ant-input-number-handler-wrap, .ant-input-number-focused .ant-input-number-handler-wrap {
  opacity: 1;
}

.ant-input-number-handler-up {
  cursor: pointer;
  border-top-right-radius: 2px;
}

.ant-input-number-handler-up-inner {
  text-align: center;
  margin-top: -5px;
  top: 50%;
}

.ant-input-number-handler-up:hover {
  height: 60% !important;
}

.ant-input-number-handler-down {
  cursor: pointer;
  border-top: 1px solid #d9d9d9;
  border-bottom-right-radius: 2px;
  top: 0;
}

.ant-input-number-handler-down-inner {
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
}

.ant-input-number-handler-down:hover {
  height: 60% !important;
}

.ant-input-number-borderless .ant-input-number-handler-down {
  border-top-width: 0;
}

.ant-input-number:hover:not(.ant-input-number-borderless) .ant-input-number-handler-down, .ant-input-number-focused:not(.ant-input-number-borderless) .ant-input-number-handler-down {
  border-top: 1px solid #d9d9d9;
}

.ant-input-number-handler-up-disabled, .ant-input-number-handler-down-disabled {
  cursor: not-allowed;
}

.ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner, .ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
  color: #00000040;
}

.ant-input-number-borderless {
  box-shadow: none;
}

.ant-input-number-out-of-range input {
  color: #ff4d4f;
}

.ant-input-number-compact-item:not(.ant-input-number-compact-last-item):not(.ant-input-number-compact-item-rtl) {
  margin-right: -1px;
}

.ant-input-number-compact-item:not(.ant-input-number-compact-last-item).ant-input-number-compact-item-rtl {
  margin-left: -1px;
}

.ant-input-number-compact-item:hover, .ant-input-number-compact-item:focus, .ant-input-number-compact-item:active, .ant-input-number-compact-item.ant-input-number-focused {
  z-index: 2;
}

.ant-input-number-compact-item[disabled] {
  z-index: 0;
}

.ant-input-number-compact-item:not(.ant-input-number-compact-first-item):not(.ant-input-number-compact-last-item).ant-input-number {
  border-radius: 0;
}

.ant-input-number-compact-item.ant-input-number.ant-input-number-compact-first-item:not(.ant-input-number-compact-last-item):not(.ant-input-number-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-number-compact-item.ant-input-number.ant-input-number-compact-last-item:not(.ant-input-number-compact-first-item):not(.ant-input-number-compact-item-rtl), .ant-input-number-compact-item.ant-input-number.ant-input-number-compact-item-rtl.ant-input-number-compact-first-item:not(.ant-input-number-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-number-compact-item.ant-input-number.ant-input-number-compact-item-rtl.ant-input-number-compact-last-item:not(.ant-input-number-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-number-rtl {
  direction: rtl;
}

.ant-input-number-rtl .ant-input-number-handler {
  border-left: 0;
  border-right: 1px solid #d9d9d9;
}

.ant-input-number-rtl .ant-input-number-handler-wrap {
  left: 0;
  right: auto;
}

.ant-input-number-rtl.ant-input-number-borderless .ant-input-number-handler-wrap {
  border-right-width: 0;
}

.ant-input-number-rtl .ant-input-number-handler-up {
  border-top-right-radius: 0;
}

.ant-input-number-rtl .ant-input-number-handler-down {
  border-bottom-right-radius: 0;
}

.ant-input-number-rtl .ant-input-number-input {
  text-align: right;
  direction: ltr;
}

.ant-input-affix-wrapper {
  color: #000000d9;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.5715;
  transition: all .3s;
  display: inline-flex;
  position: relative;
}

.ant-input-affix-wrapper::-moz-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input-affix-wrapper:-ms-input-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input-affix-wrapper::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input-affix-wrapper:-moz-placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input-affix-wrapper:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-input-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input-affix-wrapper:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}

.ant-input-rtl .ant-input-affix-wrapper:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  border-color: #40a9ff;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #1890ff33;
}

.ant-input-rtl .ant-input-affix-wrapper:focus, .ant-input-rtl .ant-input-affix-wrapper-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-input-affix-wrapper-disabled {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-input-affix-wrapper-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-input-affix-wrapper[disabled] {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-input-affix-wrapper[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-input-affix-wrapper-borderless, .ant-input-affix-wrapper-borderless:hover, .ant-input-affix-wrapper-borderless:focus, .ant-input-affix-wrapper-borderless-focused, .ant-input-affix-wrapper-borderless-disabled, .ant-input-affix-wrapper-borderless[disabled] {
  box-shadow: none;
  background-color: #0000;
  border: none;
}

textarea.ant-input-affix-wrapper {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  transition: all .3s, height;
}

.ant-input-affix-wrapper-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-input-affix-wrapper-sm {
  padding: 0 7px;
}

.ant-input-affix-wrapper-rtl {
  direction: rtl;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  z-index: 1;
  border-color: #40a9ff;
  border-right-width: 1px;
}

.ant-input-rtl .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-input-search-with-button .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  z-index: 0;
}

.ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus {
  z-index: 1;
}

.ant-input-affix-wrapper-disabled .ant-input[disabled] {
  background: #fff0;
}

.ant-input-affix-wrapper > .ant-input {
  font-size: inherit;
  border: none;
  outline: none;
}

.ant-input-affix-wrapper > .ant-input:focus {
  box-shadow: none !important;
}

.ant-input-affix-wrapper > .ant-input:not(textarea) {
  padding: 0;
}

.ant-input-affix-wrapper:before {
  visibility: hidden;
  content: " ";
  width: 0;
  display: inline-block;
}

.ant-input-prefix, .ant-input-suffix {
  flex: none;
  align-items: center;
  display: flex;
}

.ant-input-prefix > :not(:last-child), .ant-input-suffix > :not(:last-child) {
  margin-right: 8px;
}

.ant-input-show-count-suffix {
  color: #00000073;
}

.ant-input-show-count-has-suffix {
  margin-right: 2px;
}

.ant-input-prefix {
  margin-right: 4px;
}

.ant-input-suffix {
  margin-left: 4px;
}

.anticon.ant-input-clear-icon, .ant-input-clear-icon {
  color: #00000040;
  vertical-align: -1px;
  cursor: pointer;
  margin: 0;
  font-size: 12px;
  transition: color .3s;
}

.anticon.ant-input-clear-icon:hover, .ant-input-clear-icon:hover {
  color: #00000073;
}

.anticon.ant-input-clear-icon:active, .ant-input-clear-icon:active {
  color: #000000d9;
}

.anticon.ant-input-clear-icon-hidden, .ant-input-clear-icon-hidden {
  visibility: hidden;
}

.anticon.ant-input-clear-icon-has-suffix, .ant-input-clear-icon-has-suffix {
  margin: 0 4px;
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn {
  padding: 0;
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
  z-index: 1;
  position: absolute;
  top: 8px;
  right: 8px;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #ff4d4f;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus, .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ff7875;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #ff4d4f33;
}

.ant-input-status-error .ant-input-prefix {
  color: #ff4d4f;
}

.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #faad14;
}

.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus, .ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ffc53d;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #faad1433;
}

.ant-input-status-warning .ant-input-prefix {
  color: #faad14;
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #ff4d4f;
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ff7875;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #ff4d4f33;
}

.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #ff4d4f;
}

.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}

.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #faad1433;
}

.ant-input-affix-wrapper-status-warning .ant-input-prefix {
  color: #faad14;
}

.ant-input-textarea-status-error.ant-input-textarea-has-feedback .ant-input, .ant-input-textarea-status-warning.ant-input-textarea-has-feedback .ant-input, .ant-input-textarea-status-success.ant-input-textarea-has-feedback .ant-input, .ant-input-textarea-status-validating.ant-input-textarea-has-feedback .ant-input {
  padding-right: 24px;
}

.ant-input-group-wrapper-status-error .ant-input-group-addon {
  color: #ff4d4f;
  border-color: #ff4d4f;
}

.ant-input-group-wrapper-status-warning .ant-input-group-addon {
  color: #faad14;
  border-color: #faad14;
}

.ant-input {
  box-sizing: border-box;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  color: #000000d9;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 100%;
  min-width: 0;
  margin: 0;
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  transition: all .3s;
  display: inline-block;
  position: relative;
}

.ant-input::-moz-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input:-ms-input-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-input:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}

.ant-input-rtl .ant-input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-input:focus, .ant-input-focused {
  border-color: #40a9ff;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #1890ff33;
}

.ant-input-rtl .ant-input:focus, .ant-input-rtl .ant-input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-input-disabled {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-input[disabled] {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-input-borderless, .ant-input-borderless:hover, .ant-input-borderless:focus, .ant-input-borderless-focused, .ant-input-borderless-disabled, .ant-input-borderless[disabled] {
  box-shadow: none;
  background-color: #0000;
  border: none;
}

textarea.ant-input {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  transition: all .3s, height;
}

.ant-input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-input-sm {
  padding: 0 7px;
}

.ant-input-rtl {
  direction: rtl;
}

.ant-input-group {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: table;
  position: relative;
}

.ant-input-group[class*="col-"] {
  float: none;
  padding-left: 0;
  padding-right: 0;
}

.ant-input-group > [class*="col-"] {
  padding-right: 8px;
}

.ant-input-group > [class*="col-"]:last-child {
  padding-right: 0;
}

.ant-input-group-addon, .ant-input-group-wrap, .ant-input-group > .ant-input {
  display: table-cell;
}

.ant-input-group-addon:not(:first-child):not(:last-child), .ant-input-group-wrap:not(:first-child):not(:last-child), .ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.ant-input-group-addon, .ant-input-group-wrap {
  white-space: nowrap;
  vertical-align: middle;
  width: 1px;
}

.ant-input-group-wrap > * {
  display: block !important;
}

.ant-input-group .ant-input {
  float: left;
  text-align: inherit;
  width: 100%;
  margin-bottom: 0;
}

.ant-input-group .ant-input:focus, .ant-input-group .ant-input:hover {
  z-index: 1;
  border-right-width: 1px;
}

.ant-input-search-with-button .ant-input-group .ant-input:hover {
  z-index: 0;
}

.ant-input-group-addon {
  color: #000000d9;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 0 11px;
  font-size: 14px;
  font-weight: normal;
  transition: all .3s;
  position: relative;
}

.ant-input-group-addon .ant-select {
  margin: -5px -11px;
}

.ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  box-shadow: none;
  border: 1px solid #0000;
}

.ant-input-group-addon .ant-select-open .ant-select-selector, .ant-input-group-addon .ant-select-focused .ant-select-selector {
  color: #1890ff;
}

.ant-input-group-addon .ant-cascader-picker {
  background-color: #0000;
  margin: -9px -12px;
}

.ant-input-group-addon .ant-cascader-picker .ant-cascader-input {
  text-align: left;
  box-shadow: none;
  border: 0;
}

.ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child, .ant-input-group > .ant-input:first-child .ant-select .ant-select-selector, .ant-input-group-addon:first-child .ant-select .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-group-addon:first-child {
  border-right: 0;
}

.ant-input-group-addon:last-child {
  border-left: 0;
}

.ant-input-group > .ant-input:last-child, .ant-input-group-addon:last-child, .ant-input-group > .ant-input:last-child .ant-select .ant-select-selector, .ant-input-group-addon:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-group-lg .ant-input, .ant-input-group-lg > .ant-input-group-addon {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-input-group-sm .ant-input, .ant-input-group-sm > .ant-input-group-addon {
  padding: 0 7px;
}

.ant-input-group-lg .ant-select-single .ant-select-selector {
  height: 40px;
}

.ant-input-group-sm .ant-select-single .ant-select-selector {
  height: 24px;
}

.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.ant-input-group .ant-input-affix-wrapper:not(:first-child), .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-group.ant-input-group-compact {
  display: block;
}

.ant-input-group.ant-input-group-compact:before {
  content: "";
  display: table;
}

.ant-input-group.ant-input-group-compact:after {
  clear: both;
  content: "";
  display: table;
}

.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child), .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child), .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}

.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover, .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover, .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover, .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus, .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus, .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}

.ant-input-group.ant-input-group-compact > * {
  float: none;
  vertical-align: top;
  border-radius: 0;
  display: inline-block;
}

.ant-input-group.ant-input-group-compact > .ant-input-affix-wrapper, .ant-input-group.ant-input-group-compact > .ant-input-number-affix-wrapper, .ant-input-group.ant-input-group-compact > .ant-picker-range {
  display: inline-flex;
}

.ant-input-group.ant-input-group-compact > :not(:last-child) {
  border-right-width: 1px;
  margin-right: -1px;
}

.ant-input-group.ant-input-group-compact .ant-input {
  float: none;
}

.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector, .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input, .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input, .ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
  border-right-width: 1px;
  border-radius: 0;
}

.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:hover, .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover, .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover, .ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:hover, .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:focus, .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus, .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus, .ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:focus, .ant-input-group.ant-input-group-compact > .ant-select-focused, .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-arrow {
  z-index: 1;
}

.ant-input-group.ant-input-group-compact > :first-child, .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector, .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input, .ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.ant-input-group.ant-input-group-compact > :last-child, .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector, .ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input, .ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-right-width: 1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper {
  margin-left: -1px;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper, .ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
  border-radius: 0;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 2px 0 0 2px;
}

.ant-input-group > .ant-input-rtl:first-child, .ant-input-group-rtl .ant-input-group-addon:first-child {
  border-radius: 0 2px 2px 0;
}

.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-left: 0;
  border-right: 1px solid #d9d9d9;
}

.ant-input-group-rtl .ant-input-group-addon:last-child {
  border-left: 1px solid #d9d9d9;
  border-right: 0;
  border-radius: 2px 0 0 2px;
}

.ant-input-group-rtl.ant-input-group > .ant-input:last-child, .ant-input-group-rtl.ant-input-group-addon:last-child, .ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-radius: 2px 0 0 2px;
}

.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 0 2px 2px 0;
}

.ant-input-group-rtl.ant-input-group.ant-input-group-compact > :not(:last-child) {
  border-left-width: 1px;
  margin-left: -1px;
  margin-right: 0;
}

.ant-input-group-rtl.ant-input-group.ant-input-group-compact > :first-child, .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector, .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input, .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-radius: 0 2px 2px 0;
}

.ant-input-group-rtl.ant-input-group.ant-input-group-compact > :last-child, .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector, .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input, .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input, .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-left-width: 1px;
  border-radius: 2px 0 0 2px;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl + .ant-input-group-wrapper-rtl {
  margin-left: 0;
  margin-right: -1px;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 0 2px 2px 0;
}

.ant-input-group-wrapper {
  text-align: start;
  vertical-align: top;
  width: 100%;
  display: inline-block;
}

.ant-input-password-icon.anticon {
  color: #00000073;
  cursor: pointer;
  transition: all .3s;
}

.ant-input-password-icon.anticon:hover {
  color: #000000d9;
}

.ant-input[type="color"] {
  height: 32px;
}

.ant-input[type="color"].ant-input-lg {
  height: 40px;
}

.ant-input[type="color"].ant-input-sm {
  height: 24px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.ant-input-textarea-show-count > .ant-input {
  height: 100%;
}

.ant-input-textarea-show-count:after {
  float: right;
  color: #00000073;
  white-space: nowrap;
  content: attr(data-count);
  pointer-events: none;
}

.ant-input-textarea-show-count.ant-input-textarea-in-form-item:after {
  margin-bottom: -22px;
}

.ant-input-textarea-suffix {
  z-index: 1;
  align-items: center;
  margin: auto;
  display: inline-flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 11px;
}

.ant-input-compact-item:not(.ant-input-compact-last-item):not(.ant-input-compact-item-rtl) {
  margin-right: -1px;
}

.ant-input-compact-item:not(.ant-input-compact-last-item).ant-input-compact-item-rtl {
  margin-left: -1px;
}

.ant-input-compact-item:hover, .ant-input-compact-item:focus, .ant-input-compact-item:active {
  z-index: 2;
}

.ant-input-compact-item[disabled] {
  z-index: 0;
}

.ant-input-compact-item:not(.ant-input-compact-first-item):not(.ant-input-compact-last-item).ant-input {
  border-radius: 0;
}

.ant-input-compact-item.ant-input.ant-input-compact-first-item:not(.ant-input-compact-last-item):not(.ant-input-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-compact-item.ant-input.ant-input-compact-last-item:not(.ant-input-compact-first-item):not(.ant-input-compact-item-rtl), .ant-input-compact-item.ant-input.ant-input-compact-item-rtl.ant-input-compact-first-item:not(.ant-input-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-compact-item.ant-input.ant-input-compact-item-rtl.ant-input-compact-last-item:not(.ant-input-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-search .ant-input:hover, .ant-input-search .ant-input:focus {
  border-color: #40a9ff;
}

.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary), .ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: #40a9ff;
}

.ant-input-search .ant-input-affix-wrapper {
  border-radius: 0;
}

.ant-input-search .ant-input-lg {
  line-height: 1.5713;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  border: 0;
  padding: 0;
  left: -1px;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  border-radius: 0 2px 2px 0;
  padding-top: 0;
  padding-bottom: 0;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
  color: #00000073;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary).ant-btn-loading:before {
  inset: 0;
}

.ant-input-search-button {
  height: 32px;
}

.ant-input-search-button:hover, .ant-input-search-button:focus {
  z-index: 1;
}

.ant-input-search-large .ant-input-search-button {
  height: 40px;
}

.ant-input-search-small .ant-input-search-button {
  height: 24px;
}

.ant-input-search.ant-input-compact-item:not(.ant-input-compact-item-rtl):not(.ant-input-compact-last-item) .ant-input-group-addon .ant-input-search-button {
  border-radius: 0;
  margin-right: -1px;
}

.ant-input-search.ant-input-compact-item:not(.ant-input-compact-first-item) .ant-input, .ant-input-search.ant-input-compact-item:not(.ant-input-compact-first-item) .ant-input-affix-wrapper {
  border-radius: 0;
}

.ant-input-search.ant-input-compact-item > .ant-input-group-addon .ant-input-search-button:hover, .ant-input-search.ant-input-compact-item > .ant-input:hover, .ant-input-search.ant-input-compact-item .ant-input-affix-wrapper:hover, .ant-input-search.ant-input-compact-item > .ant-input-group-addon .ant-input-search-button:focus, .ant-input-search.ant-input-compact-item > .ant-input:focus, .ant-input-search.ant-input-compact-item .ant-input-affix-wrapper:focus, .ant-input-search.ant-input-compact-item > .ant-input-group-addon .ant-input-search-button:active, .ant-input-search.ant-input-compact-item > .ant-input:active, .ant-input-search.ant-input-compact-item .ant-input-affix-wrapper:active, .ant-input-search.ant-input-compact-item > .ant-input-affix-wrapper-focused {
  z-index: 2;
}

.ant-input-search.ant-input-compact-item-rtl:not(.ant-input-compact-last-item) .ant-input-group-addon:last-child .ant-input-search-button {
  border-radius: 0;
  margin-left: -1px;
}

.ant-input-group-wrapper-rtl, .ant-input-group-rtl {
  direction: rtl;
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
  border: none;
  outline: none;
}

.ant-input-affix-wrapper-rtl .ant-input-prefix {
  margin: 0 0 0 4px;
}

.ant-input-affix-wrapper-rtl .ant-input-suffix {
  margin: 0 4px 0 0;
}

.ant-input-textarea-rtl {
  direction: rtl;
}

.ant-input-textarea-rtl.ant-input-textarea-show-count:after {
  text-align: left;
}

.ant-input-affix-wrapper-rtl .ant-input-clear-icon-has-suffix {
  margin-left: 4px;
  margin-right: 0;
}

.ant-input-affix-wrapper-rtl .ant-input-clear-icon {
  left: 8px;
  right: auto;
}

.ant-input-search-rtl {
  direction: rtl;
}

.ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary), .ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: #d9d9d9;
}

.ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary):hover, .ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary):hover {
  border-left-color: #40a9ff;
}

.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper:hover, .ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper-focused {
  border-right-color: #40a9ff;
}

.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon:last-child {
  left: auto;
  right: -1px;
}

.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  border-radius: 2px 0 0 2px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-input {
    height: 32px;
  }

  .ant-input-lg {
    height: 40px;
  }

  .ant-input-sm {
    height: 24px;
  }

  .ant-input-affix-wrapper > input.ant-input {
    height: auto;
  }
}

.ant-layout {
  background: #f0f2f5;
  flex-direction: column;
  flex: auto;
  min-height: 0;
  display: flex;
}

.ant-layout, .ant-layout * {
  box-sizing: border-box;
}

.ant-layout.ant-layout-has-sider {
  flex-direction: row;
}

.ant-layout.ant-layout-has-sider > .ant-layout, .ant-layout.ant-layout-has-sider > .ant-layout-content {
  width: 0;
}

.ant-layout-header, .ant-layout-footer {
  flex: none;
}

.ant-layout-header {
  color: #000000d9;
  background: #001529;
  height: 64px;
  padding: 0 50px;
  line-height: 64px;
}

.ant-layout-footer {
  color: #000000d9;
  background: #f0f2f5;
  padding: 24px 50px;
  font-size: 14px;
}

.ant-layout-content {
  flex: auto;
  min-height: 0;
}

.ant-layout-sider {
  background: #001529;
  min-width: 0;
  transition: all .2s;
  position: relative;
}

.ant-layout-sider-children {
  height: 100%;
  margin-top: -.1px;
  padding-top: .1px;
}

.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  width: auto;
}

.ant-layout-sider-has-trigger {
  padding-bottom: 48px;
}

.ant-layout-sider-right {
  order: 1;
}

.ant-layout-sider-trigger {
  z-index: 1;
  color: #fff;
  text-align: center;
  cursor: pointer;
  background: #002140;
  height: 48px;
  line-height: 48px;
  transition: all .2s;
  position: fixed;
  bottom: 0;
}

.ant-layout-sider-zero-width > * {
  overflow: hidden;
}

.ant-layout-sider-zero-width-trigger {
  z-index: 1;
  color: #fff;
  text-align: center;
  cursor: pointer;
  background: #001529;
  border-radius: 0 2px 2px 0;
  width: 36px;
  height: 42px;
  font-size: 18px;
  line-height: 42px;
  transition: background .3s;
  position: absolute;
  top: 64px;
  right: -36px;
}

.ant-layout-sider-zero-width-trigger:after {
  content: "";
  background: none;
  transition: all .3s;
  position: absolute;
  inset: 0;
}

.ant-layout-sider-zero-width-trigger:hover:after {
  background: #ffffff1a;
}

.ant-layout-sider-zero-width-trigger-right {
  border-radius: 2px 0 0 2px;
  left: -36px;
}

.ant-layout-sider-light {
  background: #fff;
}

.ant-layout-sider-light .ant-layout-sider-trigger, .ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: #000000d9;
  background: #fff;
}

.ant-layout-rtl {
  direction: rtl;
}

.ant-list {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: relative;
}

.ant-list * {
  outline: none;
}

.ant-list-pagination {
  text-align: right;
  margin-top: 24px;
}

.ant-list-pagination .ant-pagination-options {
  text-align: left;
}

.ant-list-more {
  text-align: center;
  margin-top: 12px;
}

.ant-list-more button {
  padding-left: 32px;
  padding-right: 32px;
}

.ant-list-spin {
  text-align: center;
  min-height: 40px;
}

.ant-list-empty-text {
  color: #00000040;
  text-align: center;
  padding: 16px;
  font-size: 14px;
}

.ant-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ant-list-item {
  color: #000000d9;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  display: flex;
}

.ant-list-item-meta {
  flex: 1;
  align-items: flex-start;
  max-width: 100%;
  display: flex;
}

.ant-list-item-meta-avatar {
  margin-right: 16px;
}

.ant-list-item-meta-content {
  color: #000000d9;
  flex: 1 0;
  width: 0;
}

.ant-list-item-meta-title {
  color: #000000d9;
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 1.5715;
}

.ant-list-item-meta-title > a {
  color: #000000d9;
  transition: all .3s;
}

.ant-list-item-meta-title > a:hover {
  color: #1890ff;
}

.ant-list-item-meta-description {
  color: #00000073;
  font-size: 14px;
  line-height: 1.5715;
}

.ant-list-item-action {
  flex: none;
  margin-left: 48px;
  padding: 0;
  font-size: 0;
  list-style: none;
}

.ant-list-item-action > li {
  color: #00000073;
  text-align: center;
  padding: 0 8px;
  font-size: 14px;
  line-height: 1.5715;
  display: inline-block;
  position: relative;
}

.ant-list-item-action > li:first-child {
  padding-left: 0;
}

.ant-list-item-action-split {
  background-color: #f0f0f0;
  width: 1px;
  height: 14px;
  margin-top: -7px;
  position: absolute;
  top: 50%;
  right: 0;
}

.ant-list-header, .ant-list-footer {
  background: none;
  padding-top: 12px;
  padding-bottom: 12px;
}

.ant-list-empty {
  color: #00000073;
  text-align: center;
  padding: 16px 0;
  font-size: 12px;
}

.ant-list-split .ant-list-item {
  border-bottom: 1px solid #f0f0f0;
}

.ant-list-split .ant-list-item:last-child {
  border-bottom: none;
}

.ant-list-split .ant-list-header {
  border-bottom: 1px solid #f0f0f0;
}

.ant-list-split.ant-list-empty .ant-list-footer {
  border-top: 1px solid #f0f0f0;
}

.ant-list-loading .ant-list-spin-nested-loading {
  min-height: 32px;
}

.ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
  border-bottom: 1px solid #f0f0f0;
}

.ant-list-lg .ant-list-item {
  padding: 16px 24px;
}

.ant-list-sm .ant-list-item {
  padding: 8px 16px;
}

.ant-list-vertical .ant-list-item {
  align-items: initial;
}

.ant-list-vertical .ant-list-item-main {
  flex: 1;
  display: block;
}

.ant-list-vertical .ant-list-item-extra {
  margin-left: 40px;
}

.ant-list-vertical .ant-list-item-meta {
  margin-bottom: 16px;
}

.ant-list-vertical .ant-list-item-meta-title {
  color: #000000d9;
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 24px;
}

.ant-list-vertical .ant-list-item-action {
  margin-top: 16px;
  margin-left: auto;
}

.ant-list-vertical .ant-list-item-action > li {
  padding: 0 16px;
}

.ant-list-vertical .ant-list-item-action > li:first-child {
  padding-left: 0;
}

.ant-list-grid .ant-col > .ant-list-item {
  border-bottom: none;
  max-width: 100%;
  margin-bottom: 16px;
  padding-top: 0;
  padding-bottom: 0;
  display: block;
}

.ant-list-item-no-flex {
  display: block;
}

.ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: right;
}

.ant-list-bordered {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.ant-list-bordered .ant-list-header, .ant-list-bordered .ant-list-footer, .ant-list-bordered .ant-list-item {
  padding-left: 24px;
  padding-right: 24px;
}

.ant-list-bordered .ant-list-pagination {
  margin: 16px 24px;
}

.ant-list-bordered.ant-list-sm .ant-list-item, .ant-list-bordered.ant-list-sm .ant-list-header, .ant-list-bordered.ant-list-sm .ant-list-footer {
  padding: 8px 16px;
}

.ant-list-bordered.ant-list-lg .ant-list-item, .ant-list-bordered.ant-list-lg .ant-list-header, .ant-list-bordered.ant-list-lg .ant-list-footer {
  padding: 16px 24px;
}

@media screen and (width <= 768px) {
  .ant-list-item-action, .ant-list-vertical .ant-list-item-extra {
    margin-left: 24px;
  }
}

@media screen and (width <= 576px) {
  .ant-list-item {
    flex-wrap: wrap;
  }

  .ant-list-item-action {
    margin-left: 12px;
  }

  .ant-list-vertical .ant-list-item {
    flex-wrap: wrap-reverse;
  }

  .ant-list-vertical .ant-list-item-main {
    min-width: 220px;
  }

  .ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}

.ant-list-rtl {
  text-align: right;
  direction: rtl;
}

.ant-list-rtl .ReactVirtualized__List .ant-list-item {
  direction: rtl;
}

.ant-list-rtl .ant-list-pagination {
  text-align: left;
}

.ant-list-rtl .ant-list-item-meta-avatar {
  margin-left: 16px;
  margin-right: 0;
}

.ant-list-rtl .ant-list-item-action {
  margin-left: 0;
  margin-right: 48px;
}

.ant-list.ant-list-rtl .ant-list-item-action > li:first-child {
  padding-left: 16px;
  padding-right: 0;
}

.ant-list-rtl .ant-list-item-action-split {
  left: 0;
  right: auto;
}

.ant-list-rtl.ant-list-vertical .ant-list-item-extra {
  margin-left: 0;
  margin-right: 40px;
}

.ant-list-rtl.ant-list-vertical .ant-list-item-action {
  margin-right: auto;
}

.ant-list-rtl .ant-list-vertical .ant-list-item-action > li:first-child {
  padding-left: 16px;
  padding-right: 0;
}

.ant-list-rtl .ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: left;
}

@media screen and (width <= 768px) {
  .ant-list-rtl .ant-list-item-action, .ant-list-rtl .ant-list-vertical .ant-list-item-extra {
    margin-left: 0;
    margin-right: 24px;
  }
}

@media screen and (width <= 576px) {
  .ant-list-rtl .ant-list-item-action {
    margin-left: 0;
    margin-right: 22px;
  }

  .ant-list-rtl.ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}

.ant-pagination {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
}

.ant-pagination ul, .ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ant-pagination:after {
  clear: both;
  visibility: hidden;
  content: " ";
  height: 0;
  display: block;
  overflow: hidden;
}

.ant-pagination-total-text {
  vertical-align: middle;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  display: inline-block;
}

.ant-pagination-item {
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: 0;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 30px;
  list-style: none;
  display: inline-block;
}

.ant-pagination-item a {
  color: #000000d9;
  padding: 0 6px;
  transition: none;
  display: block;
}

.ant-pagination-item a:hover {
  text-decoration: none;
}

.ant-pagination-item:hover {
  border-color: #1890ff;
  transition: all .3s;
}

.ant-pagination-item:hover a {
  color: #1890ff;
}

.ant-pagination-item:focus-visible {
  border-color: #1890ff;
  transition: all .3s;
}

.ant-pagination-item:focus-visible a {
  color: #1890ff;
}

.ant-pagination-item-active {
  background: #fff;
  border-color: #1890ff;
  font-weight: 500;
}

.ant-pagination-item-active a {
  color: #1890ff;
}

.ant-pagination-item-active:hover, .ant-pagination-item-active:focus-visible {
  border-color: #40a9ff;
}

.ant-pagination-item-active:hover a, .ant-pagination-item-active:focus-visible a {
  color: #40a9ff;
}

.ant-pagination-jump-prev, .ant-pagination-jump-next {
  outline: 0;
}

.ant-pagination-jump-prev .ant-pagination-item-container, .ant-pagination-jump-next .ant-pagination-item-container {
  position: relative;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #1890ff;
  letter-spacing: -1px;
  opacity: 0;
  font-size: 12px;
  transition: all .2s;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
  margin: auto;
  inset: 0;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: #00000040;
  letter-spacing: 2px;
  text-align: center;
  text-indent: .13em;
  opacity: 1;
  margin: auto;
  font-family: Arial, Helvetica, sans-serif;
  transition: all .2s;
  display: block;
  position: absolute;
  inset: 0;
}

.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon, .ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 1;
}

.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis, .ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0;
}

.ant-pagination-jump-prev:focus-visible .ant-pagination-item-link-icon, .ant-pagination-jump-next:focus-visible .ant-pagination-item-link-icon {
  opacity: 1;
}

.ant-pagination-jump-prev:focus-visible .ant-pagination-item-ellipsis, .ant-pagination-jump-next:focus-visible .ant-pagination-item-ellipsis {
  opacity: 0;
}

.ant-pagination-prev, .ant-pagination-jump-prev, .ant-pagination-jump-next {
  margin-right: 8px;
}

.ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next {
  color: #000000d9;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 2px;
  min-width: 32px;
  height: 32px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 32px;
  list-style: none;
  transition: all .3s;
  display: inline-block;
}

.ant-pagination-prev, .ant-pagination-next {
  outline: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.ant-pagination-prev button, .ant-pagination-next button {
  color: #000000d9;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.ant-pagination-prev:hover button, .ant-pagination-next:hover button {
  border-color: #40a9ff;
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  transition: all .3s;
  display: block;
}

.ant-pagination-prev:focus-visible .ant-pagination-item-link, .ant-pagination-next:focus-visible .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
  color: #1890ff;
  border-color: #1890ff;
}

.ant-pagination-disabled, .ant-pagination-disabled:hover {
  cursor: not-allowed;
}

.ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link {
  color: #00000040;
  cursor: not-allowed;
  border-color: #d9d9d9;
}

.ant-pagination-disabled:focus-visible {
  cursor: not-allowed;
}

.ant-pagination-disabled:focus-visible .ant-pagination-item-link {
  color: #00000040;
  cursor: not-allowed;
  border-color: #d9d9d9;
}

.ant-pagination-slash {
  margin: 0 10px 0 5px;
}

.ant-pagination-options {
  vertical-align: middle;
  margin-left: 16px;
  display: inline-block;
}

@media (-ms-high-contrast: none) {
  .ant-pagination-options {
    vertical-align: top;
  }

  .ant-pagination-options ::-ms-backdrop {
    vertical-align: top;
  }
}

.ant-pagination-options-size-changer.ant-select {
  width: auto;
  display: inline-block;
}

.ant-pagination-options-quick-jumper {
  vertical-align: top;
  height: 32px;
  margin-left: 8px;
  line-height: 32px;
  display: inline-block;
}

.ant-pagination-options-quick-jumper input {
  color: #000000d9;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 50px;
  min-width: 0;
  height: 32px;
  margin: 0 8px;
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.5715;
  transition: all .3s;
  display: inline-block;
  position: relative;
}

.ant-pagination-options-quick-jumper input::-moz-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-pagination-options-quick-jumper input::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-pagination-options-quick-jumper input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}

.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-pagination-options-quick-jumper input:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-pagination-options-quick-jumper input:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}

.ant-pagination-options-quick-jumper input:focus, .ant-pagination-options-quick-jumper input-focused {
  border-color: #40a9ff;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #1890ff33;
}

.ant-pagination-options-quick-jumper input-disabled {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-pagination-options-quick-jumper input[disabled] {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-pagination-options-quick-jumper input-borderless, .ant-pagination-options-quick-jumper input-borderless:hover, .ant-pagination-options-quick-jumper input-borderless:focus, .ant-pagination-options-quick-jumper input-borderless-focused, .ant-pagination-options-quick-jumper input-borderless-disabled, .ant-pagination-options-quick-jumper input-borderless[disabled] {
  box-shadow: none;
  background-color: #0000;
  border: none;
}

textarea.ant-pagination-options-quick-jumper input {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  transition: all .3s, height;
}

.ant-pagination-options-quick-jumper input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-pagination-options-quick-jumper input-sm {
  padding: 0 7px;
}

.ant-pagination-simple .ant-pagination-prev, .ant-pagination-simple .ant-pagination-next {
  vertical-align: top;
  height: 24px;
  line-height: 24px;
}

.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
  background-color: #0000;
  border: 0;
  height: 24px;
}

.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link:after, .ant-pagination-simple .ant-pagination-next .ant-pagination-item-link:after {
  height: 24px;
  line-height: 24px;
}

.ant-pagination-simple .ant-pagination-simple-pager {
  height: 24px;
  margin-right: 8px;
  display: inline-block;
}

.ant-pagination-simple .ant-pagination-simple-pager input {
  box-sizing: border-box;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  transition: border-color .3s;
}

.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: #1890ff;
}

.ant-pagination-simple .ant-pagination-simple-pager input:focus {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px #1890ff33;
}

.ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
  color: #00000040;
  cursor: not-allowed;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-pagination.ant-pagination-mini .ant-pagination-total-text, .ant-pagination.ant-pagination-mini .ant-pagination-simple-pager {
  height: 24px;
  line-height: 24px;
}

.ant-pagination.ant-pagination-mini .ant-pagination-item {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
}

.ant-pagination.ant-pagination-mini .ant-pagination-item:not(.ant-pagination-item-active) {
  background: none;
  border-color: #0000;
}

.ant-pagination.ant-pagination-mini .ant-pagination-prev, .ant-pagination.ant-pagination-mini .ant-pagination-next {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
}

.ant-pagination.ant-pagination-mini .ant-pagination-prev .ant-pagination-item-link, .ant-pagination.ant-pagination-mini .ant-pagination-next .ant-pagination-item-link {
  background: none;
  border-color: #0000;
}

.ant-pagination.ant-pagination-mini .ant-pagination-prev .ant-pagination-item-link:after, .ant-pagination.ant-pagination-mini .ant-pagination-next .ant-pagination-item-link:after {
  height: 24px;
  line-height: 24px;
}

.ant-pagination.ant-pagination-mini .ant-pagination-jump-prev, .ant-pagination.ant-pagination-mini .ant-pagination-jump-next {
  height: 24px;
  margin-right: 0;
  line-height: 24px;
}

.ant-pagination.ant-pagination-mini .ant-pagination-options {
  margin-left: 2px;
}

.ant-pagination.ant-pagination-mini .ant-pagination-options-size-changer {
  top: 0;
}

.ant-pagination.ant-pagination-mini .ant-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px;
}

.ant-pagination.ant-pagination-mini .ant-pagination-options-quick-jumper input {
  width: 44px;
  height: 24px;
  padding: 0 7px;
}

.ant-pagination.ant-pagination-disabled {
  cursor: not-allowed;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item {
  cursor: not-allowed;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item a {
  color: #00000040;
  cursor: not-allowed;
  background: none;
  border: none;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
  background: #e6e6e6;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
  color: #00000040;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  color: #00000040;
  cursor: not-allowed;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-pagination-simple.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  background: none;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item-link-icon {
  opacity: 0;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item-ellipsis {
  opacity: 1;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
  color: #00000040;
}

@media only screen and (width <= 992px) {
  .ant-pagination-item-after-jump-prev, .ant-pagination-item-before-jump-next {
    display: none;
  }
}

@media only screen and (width <= 576px) {
  .ant-pagination-options {
    display: none;
  }
}

.ant-pagination-rtl .ant-pagination-total-text, .ant-pagination-rtl .ant-pagination-item, .ant-pagination-rtl .ant-pagination-prev, .ant-pagination-rtl .ant-pagination-jump-prev, .ant-pagination-rtl .ant-pagination-jump-next {
  margin-left: 8px;
  margin-right: 0;
}

.ant-pagination-rtl .ant-pagination-slash {
  margin: 0 5px 0 10px;
}

.ant-pagination-rtl .ant-pagination-options {
  margin-left: 0;
  margin-right: 16px;
}

.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-size-changer.ant-select {
  margin-left: 8px;
  margin-right: 0;
}

.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-quick-jumper {
  margin-left: 0;
}

.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager, .ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager input {
  margin-left: 8px;
  margin-right: 0;
}

.ant-pagination-rtl.ant-pagination.mini .ant-pagination-options {
  margin-left: 0;
  margin-right: 2px;
}

.ant-spin {
  box-sizing: border-box;
  color: #1890ff;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 1.5715;
  list-style: none;
  transition: transform .3s cubic-bezier(.78, .14, .15, .86);
  display: none;
  position: absolute;
}

.ant-spin-spinning {
  opacity: 1;
  display: inline-block;
  position: static;
}

.ant-spin-nested-loading {
  position: relative;
}

.ant-spin-nested-loading > div > .ant-spin {
  z-index: 4;
  width: 100%;
  height: 100%;
  max-height: 400px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  margin: -10px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  text-shadow: 0 1px 2px #fff;
  width: 100%;
  padding-top: 5px;
  font-size: 14px;
  position: absolute;
  top: 50%;
}

.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
  margin-top: -20px;
}

.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -7px;
}

.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
  padding-top: 2px;
}

.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
  margin-top: -17px;
}

.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: -16px;
}

.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  padding-top: 11px;
}

.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -26px;
}

.ant-spin-container {
  transition: opacity .3s;
  position: relative;
}

.ant-spin-container:after {
  z-index: 10;
  display: none \9 ;
  opacity: 0;
  content: "";
  pointer-events: none;
  background: #fff;
  width: 100%;
  height: 100%;
  transition: all .3s;
  position: absolute;
  inset: 0;
}

.ant-spin-blur {
  clear: both;
  opacity: .5;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}

.ant-spin-blur:after {
  opacity: .4;
  pointer-events: auto;
}

.ant-spin-tip {
  color: #00000073;
}

.ant-spin-dot {
  width: 1em;
  height: 1em;
  font-size: 20px;
  display: inline-block;
  position: relative;
}

.ant-spin-dot-item {
  transform-origin: 50%;
  opacity: .3;
  background-color: #1890ff;
  border-radius: 100%;
  width: 9px;
  height: 9px;
  animation: 1s linear infinite alternate antSpinMove;
  display: block;
  position: absolute;
  transform: scale(.75);
}

.ant-spin-dot-item:first-child {
  top: 0;
  left: 0;
}

.ant-spin-dot-item:nth-child(2) {
  animation-delay: .4s;
  top: 0;
  right: 0;
}

.ant-spin-dot-item:nth-child(3) {
  animation-delay: .8s;
  bottom: 0;
  right: 0;
}

.ant-spin-dot-item:nth-child(4) {
  animation-delay: 1.2s;
  bottom: 0;
  left: 0;
}

.ant-spin-dot-spin {
  animation: 1.2s linear infinite antRotate;
  transform: rotate(0);
}

.ant-spin-sm .ant-spin-dot {
  font-size: 14px;
}

.ant-spin-sm .ant-spin-dot i {
  width: 6px;
  height: 6px;
}

.ant-spin-lg .ant-spin-dot {
  font-size: 32px;
}

.ant-spin-lg .ant-spin-dot i {
  width: 14px;
  height: 14px;
}

.ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ant-spin-blur {
    opacity: .5;
    background: #fff;
  }
}

@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}

@keyframes antRotate {
  to {
    transform: rotate(360deg);
  }
}

.ant-spin-rtl {
  direction: rtl;
}

.ant-spin-rtl .ant-spin-dot-spin {
  animation-name: antRotateRtl;
  transform: rotate(-45deg);
}

@keyframes antRotateRtl {
  to {
    transform: rotate(-405deg);
  }
}

.ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions, .ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:hover {
  background: #fff;
  border-color: #ff4d4f;
}

.ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:focus, .ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions-focused {
  border-color: #ff7875;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #ff4d4f33;
}

.ant-mentions-status-error .ant-input-prefix {
  color: #ff4d4f;
}

.ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions, .ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:hover {
  background: #fff;
  border-color: #faad14;
}

.ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:focus, .ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions-focused {
  border-color: #ffc53d;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #faad1433;
}

.ant-mentions-status-warning .ant-input-prefix {
  color: #faad14;
}

.ant-mentions {
  box-sizing: border-box;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  color: #000000d9;
  white-space: pre-wrap;
  vertical-align: bottom;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 100%;
  min-width: 0;
  height: auto;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  transition: all .3s;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.ant-mentions::-moz-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-mentions:-ms-input-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-mentions::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-mentions:-moz-placeholder-shown {
  text-overflow: ellipsis;
}

.ant-mentions:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-mentions:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-mentions:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}

.ant-mentions:focus, .ant-mentions-focused {
  border-color: #40a9ff;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #1890ff33;
}

.ant-mentions-disabled {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-mentions-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-mentions[disabled] {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-mentions[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-mentions-borderless, .ant-mentions-borderless:hover, .ant-mentions-borderless:focus, .ant-mentions-borderless-focused, .ant-mentions-borderless-disabled, .ant-mentions-borderless[disabled] {
  box-shadow: none;
  background-color: #0000;
  border: none;
}

textarea.ant-mentions {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  transition: all .3s, height;
}

.ant-mentions-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-mentions-sm {
  padding: 0 7px;
}

.ant-mentions-disabled > textarea {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-mentions-disabled > textarea:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-mentions-focused {
  border-color: #40a9ff;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #1890ff33;
}

.ant-mentions > textarea, .ant-mentions-measure {
  overflow: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  font-variant: inherit;
  font-size-adjust: inherit;
  font-stretch: inherit;
  line-height: inherit;
  direction: inherit;
  letter-spacing: inherit;
  white-space: inherit;
  text-align: inherit;
  vertical-align: top;
  word-wrap: break-word;
  word-break: inherit;
  -moz-tab-size: inherit;
  -o-tab-size: inherit;
  tab-size: inherit;
  min-height: 30px;
  margin: 0;
  padding: 4px 11px;
  overflow: hidden auto;
}

.ant-mentions > textarea {
  resize: none;
  border: none;
  outline: none;
  width: 100%;
}

.ant-mentions > textarea::-moz-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-mentions > textarea:-ms-input-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-mentions > textarea::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-mentions > textarea:-moz-placeholder-shown {
  text-overflow: ellipsis;
}

.ant-mentions > textarea:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-mentions > textarea:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-mentions-measure {
  z-index: -1;
  color: #0000;
  pointer-events: none;
  position: absolute;
  inset: 0;
}

.ant-mentions-measure > span {
  min-height: 1em;
  display: inline-block;
}

.ant-mentions-dropdown {
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  z-index: 1050;
  box-sizing: border-box;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: absolute;
  top: -9999px;
  left: -9999px;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
}

.ant-mentions-dropdown-hidden {
  display: none;
}

.ant-mentions-dropdown-menu {
  outline: none;
  max-height: 250px;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  overflow: auto;
}

.ant-mentions-dropdown-menu-item {
  color: #000000d9;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  min-width: 100px;
  padding: 5px 12px;
  font-weight: normal;
  line-height: 1.5715;
  transition: background .3s;
  display: block;
  position: relative;
  overflow: hidden;
}

.ant-mentions-dropdown-menu-item:hover {
  background-color: #f5f5f5;
}

.ant-mentions-dropdown-menu-item:first-child {
  border-radius: 2px 2px 0 0;
}

.ant-mentions-dropdown-menu-item:last-child {
  border-radius: 0 0 2px 2px;
}

.ant-mentions-dropdown-menu-item-disabled {
  color: #00000040;
  cursor: not-allowed;
}

.ant-mentions-dropdown-menu-item-disabled:hover {
  color: #00000040;
  cursor: not-allowed;
  background-color: #fff;
}

.ant-mentions-dropdown-menu-item-selected {
  color: #000000d9;
  background-color: #fafafa;
  font-weight: 600;
}

.ant-mentions-dropdown-menu-item-active {
  background-color: #f5f5f5;
}

.ant-mentions-suffix {
  z-index: 1;
  align-items: center;
  margin: auto;
  display: inline-flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 11px;
}

.ant-mentions-rtl {
  direction: rtl;
}

.ant-message {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  z-index: 1010;
  pointer-events: none;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: fixed;
  top: 8px;
  left: 0;
}

.ant-message-notice {
  text-align: center;
  padding: 8px;
}

.ant-message-notice-content {
  pointer-events: all;
  background: #fff;
  border-radius: 2px;
  padding: 10px 16px;
  display: inline-block;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
}

.ant-message-success .anticon {
  color: #52c41a;
}

.ant-message-error .anticon {
  color: #ff4d4f;
}

.ant-message-warning .anticon {
  color: #faad14;
}

.ant-message-info .anticon, .ant-message-loading .anticon {
  color: #1890ff;
}

.ant-message .anticon {
  margin-right: 8px;
  font-size: 16px;
  position: relative;
  top: 1px;
}

.ant-message-notice.ant-move-up-leave.ant-move-up-leave-active {
  animation-name: MessageMoveOut;
  animation-duration: .3s;
}

@keyframes MessageMoveOut {
  0% {
    opacity: 1;
    max-height: 150px;
    padding: 8px;
  }

  100% {
    opacity: 0;
    max-height: 0;
    padding: 0;
  }
}

.ant-message-rtl, .ant-message-rtl span {
  direction: rtl;
}

.ant-message-rtl .anticon {
  margin-left: 8px;
  margin-right: 0;
}

.ant-modal {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  pointer-events: none;
  width: auto;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding: 0 0 24px;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: relative;
  top: 100px;
}

.ant-modal.ant-zoom-enter, .ant-modal.ant-zoom-appear {
  opacity: 0;
  -webkit-user-select: none;
  user-select: none;
  animation-duration: .3s;
  transform: none;
}

.ant-modal-mask {
  z-index: 1000;
  background-color: #00000073;
  height: 100%;
  position: fixed;
  inset: 0;
}

.ant-modal-mask-hidden {
  display: none;
}

.ant-modal-wrap {
  z-index: 1000;
  outline: 0;
  position: fixed;
  inset: 0;
  overflow: auto;
}

.ant-modal-title {
  color: #000000d9;
  word-wrap: break-word;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.ant-modal-content {
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  position: relative;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
}

.ant-modal-close {
  z-index: 10;
  color: #00000073;
  cursor: pointer;
  background: none;
  border: 0;
  outline: 0;
  padding: 0;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  transition: color .3s;
  position: absolute;
  top: 0;
  right: 0;
}

.ant-modal-close-x {
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  width: 54px;
  height: 54px;
  font-size: 16px;
  font-style: normal;
  line-height: 54px;
  display: block;
}

.ant-modal-close:focus, .ant-modal-close:hover {
  color: #000000bf;
  text-decoration: none;
}

.ant-modal-header {
  color: #000000d9;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
  padding: 16px 24px;
}

.ant-modal-body {
  word-wrap: break-word;
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
}

.ant-modal-footer {
  text-align: right;
  background: none;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
  padding: 10px 16px;
}

.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  margin-bottom: 0;
  margin-left: 8px;
}

.ant-modal-open {
  overflow: hidden;
}

.ant-modal-centered {
  text-align: center;
}

.ant-modal-centered:before {
  vertical-align: middle;
  content: "";
  width: 0;
  height: 100%;
  display: inline-block;
}

.ant-modal-centered .ant-modal {
  text-align: left;
  vertical-align: middle;
  padding-bottom: 0;
  display: inline-block;
  top: 0;
}

@media (width <= 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }

  .ant-modal-centered .ant-modal {
    flex: 1;
  }
}

.ant-modal-confirm .ant-modal-header {
  display: none;
}

.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}

.ant-modal-confirm-body-wrapper:before {
  content: "";
  display: table;
}

.ant-modal-confirm-body-wrapper:after {
  clear: both;
  content: "";
  display: table;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  color: #000000d9;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  display: block;
  overflow: hidden;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  color: #000000d9;
  margin-top: 8px;
  font-size: 14px;
}

.ant-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}

.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 38px;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  text-align: right;
  margin-top: 24px;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-bottom: 0;
  margin-left: 8px;
}

.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: #ff4d4f;
}

.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon, .ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #faad14;
}

.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: #1890ff;
}

.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: #52c41a;
}

.ant-modal-confirm .ant-zoom-leave .ant-modal-confirm-btns {
  pointer-events: none;
}

.ant-modal-wrap-rtl {
  direction: rtl;
}

.ant-modal-wrap-rtl .ant-modal-close {
  right: initial;
  left: 0;
}

.ant-modal-wrap-rtl .ant-modal-footer {
  text-align: left;
}

.ant-modal-wrap-rtl .ant-modal-footer .ant-btn + .ant-btn {
  margin-left: 0;
  margin-right: 8px;
}

.ant-modal-wrap-rtl .ant-modal-confirm-body {
  direction: rtl;
}

.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon {
  float: right;
  margin-left: 16px;
  margin-right: 0;
}

.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 0;
  margin-right: 38px;
}

.ant-modal-wrap-rtl .ant-modal-confirm-btns {
  text-align: left;
}

.ant-modal-wrap-rtl .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-left: 0;
  margin-right: 8px;
}

.ant-modal-wrap-rtl.ant-modal-centered .ant-modal {
  text-align: right;
}

.ant-notification {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  z-index: 1010;
  margin: 0 24px 0 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: fixed;
}

.ant-notification-close-icon {
  cursor: pointer;
  font-size: 14px;
}

.ant-notification-hook-holder {
  position: relative;
}

.ant-notification-notice {
  word-wrap: break-word;
  background: #fff;
  border-radius: 2px;
  width: 384px;
  max-width: calc(100vw - 48px);
  margin-bottom: 16px;
  margin-left: auto;
  padding: 16px 24px;
  line-height: 1.5715;
  position: relative;
  overflow: hidden;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
}

.ant-notification-top .ant-notification-notice, .ant-notification-bottom .ant-notification-notice {
  margin-left: auto;
  margin-right: auto;
}

.ant-notification-topLeft .ant-notification-notice, .ant-notification-bottomLeft .ant-notification-notice {
  margin-left: 0;
  margin-right: auto;
}

.ant-notification-notice-message {
  color: #000000d9;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
}

.ant-notification-notice-message-single-line-auto-margin {
  pointer-events: none;
  background-color: #0000;
  width: calc(264px - 100%);
  max-width: 4px;
  display: block;
}

.ant-notification-notice-message-single-line-auto-margin:before {
  content: "";
  display: block;
}

.ant-notification-notice-description {
  font-size: 14px;
}

.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 24px;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 48px;
  font-size: 16px;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 48px;
  font-size: 14px;
}

.ant-notification-notice-icon {
  margin-left: 4px;
  font-size: 24px;
  line-height: 24px;
  position: absolute;
}

.anticon.ant-notification-notice-icon-success {
  color: #52c41a;
}

.anticon.ant-notification-notice-icon-info {
  color: #1890ff;
}

.anticon.ant-notification-notice-icon-warning {
  color: #faad14;
}

.anticon.ant-notification-notice-icon-error {
  color: #ff4d4f;
}

.ant-notification-notice-close {
  color: #00000073;
  outline: none;
  position: absolute;
  top: 16px;
  right: 22px;
}

.ant-notification-notice-close:hover {
  color: #000000ab;
}

.ant-notification-notice-btn {
  float: right;
  margin-top: 16px;
}

.ant-notification .notification-fade-effect {
  animation-duration: .24s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: both;
}

.ant-notification-fade-enter, .ant-notification-fade-appear {
  opacity: 0;
  animation-duration: .24s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-notification-fade-leave {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-notification-fade-enter.ant-notification-fade-enter-active, .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationFadeIn;
  animation-play-state: running;
}

.ant-notification-fade-leave.ant-notification-fade-leave-active {
  animation-name: NotificationFadeOut;
  animation-play-state: running;
}

@keyframes NotificationFadeIn {
  0% {
    opacity: 0;
    left: 384px;
  }

  100% {
    opacity: 1;
    left: 0;
  }
}

@keyframes NotificationFadeOut {
  0% {
    opacity: 1;
    max-height: 150px;
    margin-bottom: 16px;
  }

  100% {
    opacity: 0;
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.ant-notification-rtl {
  direction: rtl;
}

.ant-notification-rtl .ant-notification-notice-closable .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-message, .ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 0;
  margin-right: 48px;
}

.ant-notification-rtl .ant-notification-notice-icon {
  margin-left: 0;
  margin-right: 4px;
}

.ant-notification-rtl .ant-notification-notice-close {
  left: 22px;
  right: auto;
}

.ant-notification-rtl .ant-notification-notice-btn {
  float: left;
}

.ant-notification-top, .ant-notification-bottom {
  margin-left: 0;
  margin-right: 0;
}

.ant-notification-top .ant-notification-fade-enter.ant-notification-fade-enter-active, .ant-notification-top .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationTopFadeIn;
}

.ant-notification-bottom .ant-notification-fade-enter.ant-notification-fade-enter-active, .ant-notification-bottom .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationBottomFadeIn;
}

.ant-notification-topLeft, .ant-notification-bottomLeft {
  margin-left: 24px;
  margin-right: 0;
}

.ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active, .ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active, .ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active, .ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationLeftFadeIn;
}

@keyframes NotificationTopFadeIn {
  0% {
    opacity: 0;
    margin-top: -100%;
  }

  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes NotificationBottomFadeIn {
  0% {
    opacity: 0;
    margin-bottom: -100%;
  }

  100% {
    opacity: 1;
    margin-bottom: 0;
  }
}

@keyframes NotificationLeftFadeIn {
  0% {
    opacity: 0;
    right: 384px;
  }

  100% {
    opacity: 1;
    right: 0;
  }
}

.ant-page-header {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  background-color: #fff;
  margin: 0;
  padding: 16px 24px;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: relative;
}

.ant-page-header-ghost {
  background-color: inherit;
}

.ant-page-header.has-breadcrumb {
  padding-top: 12px;
}

.ant-page-header.has-footer {
  padding-bottom: 0;
}

.ant-page-header-back {
  margin-right: 16px;
  font-size: 16px;
  line-height: 1;
}

.ant-page-header-back-button {
  color: #000;
  cursor: pointer;
  outline: none;
  transition: color .3s;
}

.ant-page-header-back-button:focus-visible, .ant-page-header-back-button:hover {
  color: #40a9ff;
}

.ant-page-header-back-button:active {
  color: #096dd9;
}

.ant-page-header .ant-divider-vertical {
  vertical-align: middle;
  height: 14px;
  margin: 0 12px;
}

.ant-breadcrumb + .ant-page-header-heading {
  margin-top: 8px;
}

.ant-page-header-heading {
  justify-content: space-between;
  display: flex;
}

.ant-page-header-heading-left {
  align-items: center;
  margin: 4px 0;
  display: flex;
  overflow: hidden;
}

.ant-page-header-heading-title {
  color: #000000d9;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0;
  margin-right: 12px;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  overflow: hidden;
}

.ant-page-header-heading .ant-avatar {
  margin-right: 12px;
}

.ant-page-header-heading-sub-title {
  color: #00000073;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 12px;
  font-size: 14px;
  line-height: 1.5715;
  overflow: hidden;
}

.ant-page-header-heading-extra {
  white-space: nowrap;
  margin: 4px 0;
}

.ant-page-header-heading-extra > * {
  white-space: unset;
}

.ant-page-header-content {
  padding-top: 12px;
}

.ant-page-header-footer {
  margin-top: 16px;
}

.ant-page-header-footer .ant-tabs > .ant-tabs-nav {
  margin: 0;
}

.ant-page-header-footer .ant-tabs > .ant-tabs-nav:before {
  border: none;
}

.ant-page-header-footer .ant-tabs .ant-tabs-tab {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
}

.ant-page-header-compact .ant-page-header-heading {
  flex-wrap: wrap;
}

.ant-page-header-rtl {
  direction: rtl;
}

.ant-page-header-rtl .ant-page-header-back {
  float: right;
  margin-left: 16px;
  margin-right: 0;
}

.ant-page-header-rtl .ant-page-header-heading-title, .ant-page-header-rtl .ant-page-header-heading .ant-avatar {
  margin-left: 12px;
  margin-right: 0;
}

.ant-page-header-rtl .ant-page-header-heading-sub-title {
  float: right;
  margin-left: 12px;
  margin-right: 0;
}

.ant-page-header-rtl .ant-page-header-heading-tags {
  float: right;
}

.ant-page-header-rtl .ant-page-header-heading-extra {
  float: left;
}

.ant-page-header-rtl .ant-page-header-heading-extra > * {
  margin-left: 0;
  margin-right: 12px;
}

.ant-page-header-rtl .ant-page-header-heading-extra > :first-child {
  margin-right: 0;
}

.ant-page-header-rtl .ant-page-header-footer .ant-tabs-bar .ant-tabs-nav {
  float: right;
}

.ant-popconfirm {
  z-index: 1060;
}

.ant-progress {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: inline-block;
}

.ant-progress-line {
  width: 100%;
  font-size: 14px;
  position: relative;
}

.ant-progress-steps {
  display: inline-block;
}

.ant-progress-steps-outer {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.ant-progress-steps-item {
  background: #f3f3f3;
  flex-shrink: 0;
  min-width: 2px;
  margin-right: 2px;
  transition: all .3s;
}

.ant-progress-steps-item-active {
  background: #1890ff;
}

.ant-progress-small.ant-progress-line, .ant-progress-small.ant-progress-line .ant-progress-text .anticon {
  font-size: 12px;
}

.ant-progress-outer {
  width: 100%;
  margin-right: 0;
  padding-right: 0;
  display: inline-block;
}

.ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 8px);
  padding-right: calc(2em + 8px);
}

.ant-progress-inner {
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 100px;
  width: 100%;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.ant-progress-circle-trail {
  stroke: #f5f5f5;
}

.ant-progress-circle-path {
  animation: .3s ant-progress-appear;
}

.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #1890ff;
}

.ant-progress-success-bg, .ant-progress-bg {
  background-color: #1890ff;
  border-radius: 100px;
  transition: all .4s cubic-bezier(.08, .82, .17, 1);
  position: relative;
}

.ant-progress-success-bg {
  background-color: #52c41a;
  position: absolute;
  top: 0;
  left: 0;
}

.ant-progress-text {
  color: #000000d9;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
  width: 2em;
  margin-left: 8px;
  font-size: 1em;
  line-height: 1;
  display: inline-block;
}

.ant-progress-text .anticon {
  font-size: 14px;
}

.ant-progress-status-active .ant-progress-bg:before {
  opacity: 0;
  content: "";
  background: #fff;
  border-radius: 10px;
  animation: 2.4s cubic-bezier(.23, 1, .32, 1) infinite ant-progress-active;
  position: absolute;
  inset: 0;
}

.ant-progress-status-exception .ant-progress-bg {
  background-color: #ff4d4f;
}

.ant-progress-status-exception .ant-progress-text {
  color: #ff4d4f;
}

.ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #ff4d4f;
}

.ant-progress-status-success .ant-progress-bg {
  background-color: #52c41a;
}

.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}

.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #52c41a;
}

.ant-progress-circle .ant-progress-inner {
  background-color: #0000;
  line-height: 1;
  position: relative;
}

.ant-progress-circle .ant-progress-text {
  color: #000000d9;
  white-space: normal;
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 1em;
  line-height: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ant-progress-circle .ant-progress-text .anticon {
  font-size: 1.16667em;
}

.ant-progress-circle.ant-progress-status-exception .ant-progress-text {
  color: #ff4d4f;
}

.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}

@keyframes ant-progress-active {
  0% {
    opacity: .1;
    transform: translateX(-100%)scaleX(0);
  }

  20% {
    opacity: .5;
    transform: translateX(-100%)scaleX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(0)scaleX(1);
  }
}

.ant-progress-rtl {
  direction: rtl;
}

.ant-progress-rtl.ant-progress-show-info .ant-progress-outer {
  margin-left: calc(-2em - 8px);
  margin-right: 0;
  padding-left: calc(2em + 8px);
  padding-right: 0;
}

.ant-progress-rtl .ant-progress-success-bg {
  left: auto;
  right: 0;
}

.ant-progress-rtl.ant-progress-line .ant-progress-text, .ant-progress-rtl.ant-progress-steps .ant-progress-text {
  text-align: right;
  margin-left: 0;
  margin-right: 8px;
}

.ant-rate {
  box-sizing: border-box;
  color: #fadb14;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  font-size: 20px;
  line-height: 1.5715;
  line-height: unset;
  outline: none;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
}

.ant-rate-disabled .ant-rate-star {
  cursor: default;
}

.ant-rate-disabled .ant-rate-star > div:hover {
  transform: scale(1);
}

.ant-rate-star {
  color: inherit;
  cursor: pointer;
  display: inline-block;
  position: relative;
}

.ant-rate-star:not(:last-child) {
  margin-right: 8px;
}

.ant-rate-star > div {
  transition: all .3s, outline;
}

.ant-rate-star > div:hover {
  transform: scale(1.1);
}

.ant-rate-star > div:focus {
  outline: 0;
}

.ant-rate-star > div:focus-visible {
  outline: 1px dashed #fadb14;
  transform: scale(1.1);
}

.ant-rate-star-first, .ant-rate-star-second {
  color: #f0f0f0;
  -webkit-user-select: none;
  user-select: none;
  transition: all .3s;
}

.ant-rate-star-first .anticon, .ant-rate-star-second .anticon {
  vertical-align: middle;
}

.ant-rate-star-first {
  opacity: 0;
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.ant-rate-star-half .ant-rate-star-first, .ant-rate-star-half .ant-rate-star-second {
  opacity: 1;
}

.ant-rate-star-half .ant-rate-star-first, .ant-rate-star-full .ant-rate-star-second {
  color: inherit;
}

.ant-rate-text {
  margin: 0 8px;
  font-size: 14px;
  display: inline-block;
}

.ant-rate-rtl {
  direction: rtl;
}

.ant-rate-rtl .ant-rate-star:not(:last-child) {
  margin-left: 8px;
  margin-right: 0;
}

.ant-rate-rtl .ant-rate-star-first {
  left: auto;
  right: 0;
}

.ant-result {
  padding: 48px 32px;
}

.ant-result-success .ant-result-icon > .anticon {
  color: #52c41a;
}

.ant-result-error .ant-result-icon > .anticon {
  color: #ff4d4f;
}

.ant-result-info .ant-result-icon > .anticon {
  color: #1890ff;
}

.ant-result-warning .ant-result-icon > .anticon {
  color: #faad14;
}

.ant-result-image {
  width: 250px;
  height: 295px;
  margin: auto;
}

.ant-result-icon {
  text-align: center;
  margin-bottom: 24px;
}

.ant-result-icon > .anticon {
  font-size: 72px;
}

.ant-result-title {
  color: #000000d9;
  text-align: center;
  font-size: 24px;
  line-height: 1.8;
}

.ant-result-subtitle {
  color: #00000073;
  text-align: center;
  font-size: 14px;
  line-height: 1.6;
}

.ant-result-extra {
  text-align: center;
  margin: 24px 0 0;
}

.ant-result-extra > * {
  margin-right: 8px;
}

.ant-result-extra > :last-child {
  margin-right: 0;
}

.ant-result-content {
  background-color: #fafafa;
  margin-top: 24px;
  padding: 24px 40px;
}

.ant-result-rtl {
  direction: rtl;
}

.ant-result-rtl .ant-result-extra > * {
  margin-left: 8px;
  margin-right: 0;
}

.ant-result-rtl .ant-result-extra > :last-child {
  margin-left: 0;
}

.segmented-disabled-item, .segmented-disabled-item:hover, .segmented-disabled-item:focus {
  color: #00000040;
  cursor: not-allowed;
}

.segmented-item-selected {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px #0000000d, 0 1px 4px -1px #00000012, 0 0 1px #00000014;
}

.segmented-text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
  overflow: hidden;
}

.ant-segmented {
  box-sizing: border-box;
  color: #000000a6;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  background-color: #0000000a;
  border-radius: 2px;
  margin: 0;
  padding: 2px;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
  display: inline-block;
}

.ant-segmented-group {
  place-items: stretch flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.ant-segmented.ant-segmented-block {
  display: flex;
}

.ant-segmented.ant-segmented-block .ant-segmented-item {
  flex: 1;
  min-width: 0;
}

.ant-segmented:not(.ant-segmented-disabled):hover, .ant-segmented:not(.ant-segmented-disabled):focus {
  background-color: #0000000f;
}

.ant-segmented-item {
  text-align: center;
  cursor: pointer;
  transition: color .3s cubic-bezier(.645, .045, .355, 1);
  position: relative;
}

.ant-segmented-item-selected {
  color: #262626;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px #0000000d, 0 1px 4px -1px #00000012, 0 0 1px #00000014;
}

.ant-segmented-item:hover, .ant-segmented-item:focus {
  color: #262626;
}

.ant-segmented-item-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
  min-height: 28px;
  padding: 0 11px;
  line-height: 28px;
  overflow: hidden;
}

.ant-segmented-item-icon + * {
  margin-left: 6px;
}

.ant-segmented-item-input {
  opacity: 0;
  pointer-events: none;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.ant-segmented.ant-segmented-lg .ant-segmented-item-label {
  min-height: 36px;
  padding: 0 11px;
  font-size: 16px;
  line-height: 36px;
}

.ant-segmented.ant-segmented-sm .ant-segmented-item-label {
  min-height: 20px;
  padding: 0 7px;
  line-height: 20px;
}

.ant-segmented-item-disabled, .ant-segmented-item-disabled:hover, .ant-segmented-item-disabled:focus {
  color: #00000040;
  cursor: not-allowed;
}

.ant-segmented-thumb {
  background-color: #fff;
  border-radius: 2px;
  width: 0;
  height: 100%;
  padding: 4px 0;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 2px 8px -2px #0000000d, 0 1px 4px -1px #00000012, 0 0 1px #00000014;
}

.ant-segmented-thumb-motion-appear-active {
  will-change: transform, width;
  transition: transform .3s cubic-bezier(.645, .045, .355, 1), width .3s cubic-bezier(.645, .045, .355, 1);
}

.ant-segmented.ant-segmented-rtl {
  direction: rtl;
}

.ant-segmented.ant-segmented-rtl .ant-segmented-item-icon {
  margin-left: 6px;
  margin-right: 0;
}

.ant-slider {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  cursor: pointer;
  touch-action: none;
  height: 12px;
  margin: 10px 6px;
  padding: 4px 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: relative;
}

.ant-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px;
}

.ant-slider-vertical .ant-slider-rail {
  width: 4px;
  height: 100%;
}

.ant-slider-vertical .ant-slider-track {
  width: 4px;
}

.ant-slider-vertical .ant-slider-handle {
  margin-top: -6px;
  margin-left: -5px;
}

.ant-slider-vertical .ant-slider-mark {
  width: 18px;
  height: 100%;
  top: 0;
  left: 12px;
}

.ant-slider-vertical .ant-slider-mark-text {
  white-space: nowrap;
  left: 4px;
}

.ant-slider-vertical .ant-slider-step {
  width: 4px;
  height: 100%;
}

.ant-slider-vertical .ant-slider-dot {
  margin-left: -2px;
  top: auto;
}

.ant-slider-tooltip .ant-tooltip-inner {
  min-width: unset;
}

.ant-slider-rtl.ant-slider-vertical .ant-slider-handle {
  margin-left: 0;
  margin-right: -5px;
}

.ant-slider-rtl.ant-slider-vertical .ant-slider-mark {
  left: auto;
  right: 12px;
}

.ant-slider-rtl.ant-slider-vertical .ant-slider-mark-text {
  left: auto;
  right: 4px;
}

.ant-slider-rtl.ant-slider-vertical .ant-slider-dot {
  left: auto;
  right: 2px;
}

.ant-slider-with-marks {
  margin-bottom: 28px;
}

.ant-slider-rail {
  background-color: #f5f5f5;
  border-radius: 2px;
  width: 100%;
  height: 4px;
  transition: background-color .3s;
  position: absolute;
}

.ant-slider-track {
  background-color: #91d5ff;
  border-radius: 2px;
  height: 4px;
  transition: background-color .3s;
  position: absolute;
}

.ant-slider-handle {
  box-shadow: 0;
  cursor: pointer;
  background-color: #fff;
  border: 2px solid #91d5ff;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  transition: border-color .3s, box-shadow .6s, transform .3s cubic-bezier(.18, .89, .32, 1.28);
  position: absolute;
}

.ant-slider-handle-dragging {
  z-index: 1;
}

.ant-slider-handle:focus {
  border-color: #46a6ff;
  outline: none;
  box-shadow: 0 0 0 5px #1890ff1f;
}

.ant-slider-handle.ant-tooltip-open {
  border-color: #1890ff;
}

.ant-slider-handle:after {
  content: "";
  position: absolute;
  inset: -6px;
}

.ant-slider:hover .ant-slider-rail {
  background-color: #e1e1e1;
}

.ant-slider:hover .ant-slider-track {
  background-color: #69c0ff;
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #69c0ff;
}

.ant-slider-mark {
  width: 100%;
  font-size: 14px;
  position: absolute;
  top: 14px;
  left: 0;
}

.ant-slider-mark-text {
  color: #00000073;
  text-align: center;
  word-break: keep-all;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  display: inline-block;
  position: absolute;
}

.ant-slider-mark-text-active {
  color: #000000d9;
}

.ant-slider-step {
  pointer-events: none;
  background: none;
  width: 100%;
  height: 4px;
  position: absolute;
}

.ant-slider-dot {
  cursor: pointer;
  background-color: #fff;
  border: 2px solid #f0f0f0;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  position: absolute;
  top: -2px;
}

.ant-slider-dot-active {
  border-color: #8cc8ff;
}

.ant-slider-disabled {
  cursor: not-allowed;
}

.ant-slider-disabled .ant-slider-rail {
  background-color: #f5f5f5 !important;
}

.ant-slider-disabled .ant-slider-track {
  background-color: #00000040 !important;
}

.ant-slider-disabled .ant-slider-handle, .ant-slider-disabled .ant-slider-dot {
  box-shadow: none;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #00000040 !important;
}

.ant-slider-disabled .ant-slider-mark-text, .ant-slider-disabled .ant-slider-dot {
  cursor: not-allowed !important;
}

.ant-slider-rtl {
  direction: rtl;
}

.ant-slider-rtl .ant-slider-mark {
  left: auto;
  right: 0;
}

.ant-statistic {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
}

.ant-statistic-title {
  color: #00000073;
  margin-bottom: 4px;
  font-size: 14px;
}

.ant-statistic-skeleton {
  padding-top: 16px;
}

.ant-statistic-content {
  color: #000000d9;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 24px;
}

.ant-statistic-content-value {
  direction: ltr;
  display: inline-block;
}

.ant-statistic-content-prefix, .ant-statistic-content-suffix {
  display: inline-block;
}

.ant-statistic-content-prefix {
  margin-right: 4px;
}

.ant-statistic-content-suffix {
  margin-left: 4px;
}

.ant-statistic-rtl {
  direction: rtl;
}

.ant-statistic-rtl .ant-statistic-content-prefix {
  margin-left: 4px;
  margin-right: 0;
}

.ant-statistic-rtl .ant-statistic-content-suffix {
  margin-left: 0;
  margin-right: 4px;
}

.ant-steps {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  text-align: initial;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 1.5715;
  list-style: none;
  display: flex;
}

.ant-steps-item {
  vertical-align: top;
  flex: 1;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.ant-steps-item-container {
  outline: none;
}

.ant-steps-item:last-child {
  flex: none;
}

.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail, .ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:after {
  display: none;
}

.ant-steps-item-icon, .ant-steps-item-content {
  vertical-align: top;
  display: inline-block;
}

.ant-steps-item-icon {
  text-align: center;
  border: 1px solid #00000040;
  border-radius: 32px;
  width: 32px;
  height: 32px;
  margin: 0 8px 0 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 16px;
  line-height: 32px;
  transition: background-color .3s, border-color .3s;
}

.ant-steps-item-icon .ant-steps-icon {
  color: #1890ff;
  line-height: 1;
  position: relative;
  top: -.5px;
}

.ant-steps-item-tail {
  width: 100%;
  padding: 0 10px;
  position: absolute;
  top: 12px;
  left: 0;
}

.ant-steps-item-tail:after {
  content: "";
  background: #f0f0f0;
  border-radius: 1px;
  width: 100%;
  height: 1px;
  transition: background .3s;
  display: inline-block;
}

.ant-steps-item-title {
  color: #000000d9;
  padding-right: 16px;
  font-size: 16px;
  line-height: 32px;
  display: inline-block;
  position: relative;
}

.ant-steps-item-title:after {
  content: "";
  background: #f0f0f0;
  width: 9999px;
  height: 1px;
  display: block;
  position: absolute;
  top: 16px;
  left: 100%;
}

.ant-steps-item-subtitle {
  color: #00000073;
  margin-left: 8px;
  font-size: 14px;
  font-weight: normal;
  display: inline;
}

.ant-steps-item-description {
  color: #00000073;
  font-size: 14px;
}

.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff;
  border-color: #00000040;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #00000040;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #00000040;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #00000073;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:after {
  background-color: #f0f0f0;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #00000073;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail:after {
  background-color: #f0f0f0;
}

.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #1890ff;
}

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #1890ff;
}

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #1890ff;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #000000d9;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:after {
  background-color: #f0f0f0;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #000000d9;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail:after {
  background-color: #f0f0f0;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #1890ff;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
  color: #fff;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-title {
  font-weight: 500;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #1890ff;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #1890ff;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #1890ff;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #000000d9;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:after {
  background-color: #1890ff;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #00000073;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail:after {
  background-color: #1890ff;
}

.ant-steps-item-error .ant-steps-item-icon {
  background-color: #fff;
  border-color: #ff4d4f;
}

.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
  color: #ff4d4f;
}

.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #ff4d4f;
}

.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #ff4d4f;
}

.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:after {
  background-color: #f0f0f0;
}

.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #ff4d4f;
}

.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail:after {
  background-color: #f0f0f0;
}

.ant-steps-item.ant-steps-next-error .ant-steps-item-title:after {
  background: #ff4d4f;
}

.ant-steps-item-disabled {
  cursor: not-allowed;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"] {
  cursor: pointer;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"] .ant-steps-item-title, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"] .ant-steps-item-subtitle, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"] .ant-steps-item-description, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"] .ant-steps-item-icon .ant-steps-icon {
  transition: color .3s;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-title, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-subtitle, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-description {
  color: #1890ff;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-icon {
  border-color: #1890ff;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-icon .ant-steps-icon {
  color: #1890ff;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  white-space: nowrap;
  padding-left: 16px;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-right: 0;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: none;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  white-space: normal;
  max-width: 140px;
}

.ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon {
  background: none;
  border: 0;
  height: auto;
}

.ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
  width: 32px;
  height: 32px;
  font-size: 24px;
  line-height: 32px;
  top: 0;
  left: .5px;
}

.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #1890ff;
}

.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
  background: none;
  width: auto;
}

.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 12px;
}

.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0;
}

.ant-steps-small .ant-steps-item-icon {
  text-align: center;
  border-radius: 24px;
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
  font-size: 12px;
  line-height: 24px;
}

.ant-steps-small .ant-steps-item-title {
  padding-right: 12px;
  font-size: 14px;
  line-height: 24px;
}

.ant-steps-small .ant-steps-item-title:after {
  top: 12px;
}

.ant-steps-small .ant-steps-item-description {
  color: #00000073;
  font-size: 14px;
}

.ant-steps-small .ant-steps-item-tail {
  top: 8px;
}

.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  background: none;
  border: 0;
  border-radius: 0;
}

.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 24px;
  transform: none;
}

.ant-steps-vertical {
  flex-direction: column;
  display: flex;
}

.ant-steps-vertical > .ant-steps-item {
  flex: 1 0 auto;
  padding-left: 0;
  display: block;
  overflow: visible;
}

.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: left;
  margin-right: 16px;
}

.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
  min-height: 48px;
  display: block;
  overflow: hidden;
}

.ant-steps-vertical > .ant-steps-item .ant-steps-item-title {
  line-height: 32px;
}

.ant-steps-vertical > .ant-steps-item .ant-steps-item-description {
  padding-bottom: 12px;
}

.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
  position: absolute;
  top: 0;
  left: 15px;
}

.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail:after {
  width: 1px;
  height: 100%;
}

.ant-steps-vertical > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
  display: block;
}

.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:after {
  display: none;
}

.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  padding: 30px 0 6px;
  position: absolute;
  top: 0;
  left: 11px;
}

.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
  line-height: 24px;
}

.ant-steps-label-vertical .ant-steps-item {
  overflow: visible;
}

.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 58px;
  padding: 3.5px 24px;
}

.ant-steps-label-vertical .ant-steps-item-content {
  text-align: center;
  width: 116px;
  margin-top: 8px;
  display: block;
}

.ant-steps-label-vertical .ant-steps-item-icon {
  margin-left: 42px;
  display: inline-block;
}

.ant-steps-label-vertical .ant-steps-item-title {
  padding-left: 0;
  padding-right: 0;
}

.ant-steps-label-vertical .ant-steps-item-title:after {
  display: none;
}

.ant-steps-label-vertical .ant-steps-item-subtitle {
  margin-bottom: 4px;
  margin-left: 0;
  line-height: 1.5715;
  display: block;
}

.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
  margin-left: 46px;
}

.ant-steps-dot .ant-steps-item-title, .ant-steps-dot.ant-steps-small .ant-steps-item-title {
  line-height: 1.5715;
}

.ant-steps-dot .ant-steps-item-tail, .ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  width: 100%;
  margin: 0 0 0 70px;
  padding: 0;
  top: 2px;
}

.ant-steps-dot .ant-steps-item-tail:after, .ant-steps-dot.ant-steps-small .ant-steps-item-tail:after {
  width: calc(100% - 20px);
  height: 3px;
  margin-left: 12px;
}

.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot, .ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 2px;
}

.ant-steps-dot .ant-steps-item-icon, .ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  background: none;
  border: 0;
  width: 8px;
  height: 8px;
  margin-left: 67px;
  padding-right: 0;
  line-height: 8px;
}

.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot, .ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  float: left;
  border-radius: 100px;
  width: 100%;
  height: 100%;
  transition: all .3s;
  position: relative;
}

.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot:after, .ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot:after {
  content: "";
  background: none;
  width: 60px;
  height: 32px;
  position: absolute;
  top: -12px;
  left: -26px;
}

.ant-steps-dot .ant-steps-item-content, .ant-steps-dot.ant-steps-small .ant-steps-item-content {
  width: 140px;
}

.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon, .ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
  background: none;
  width: 10px;
  height: 10px;
  line-height: 10px;
  position: relative;
  top: -1px;
}

.ant-steps-dot .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot, .ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot {
  left: 0;
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  background: none;
  margin-top: 13px;
  margin-left: 0;
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  margin: 0;
  padding: 22px 0 4px;
  top: 6.5px;
  left: -9px;
}

.ant-steps-vertical.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  margin-top: 10px;
}

.ant-steps-vertical.ant-steps-dot.ant-steps-small .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 3.5px;
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 0;
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item-content {
  width: inherit;
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon-dot {
  top: -1px;
  left: -1px;
}

.ant-steps-navigation {
  padding-top: 12px;
}

.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-left: -12px;
}

.ant-steps-navigation .ant-steps-item {
  text-align: center;
  overflow: visible;
}

.ant-steps-navigation .ant-steps-item-container {
  text-align: left;
  height: 100%;
  margin-left: -16px;
  padding-bottom: 12px;
  transition: opacity .3s;
  display: inline-block;
}

.ant-steps-navigation .ant-steps-item-container .ant-steps-item-content {
  max-width: auto;
}

.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  padding-right: 0;
  overflow: hidden;
}

.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title:after {
  display: none;
}

.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role="button"] {
  cursor: pointer;
}

.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role="button"]:hover {
  opacity: .85;
}

.ant-steps-navigation .ant-steps-item:last-child {
  flex: 1;
}

.ant-steps-navigation .ant-steps-item:last-child:after {
  display: none;
}

.ant-steps-navigation .ant-steps-item:after {
  content: "";
  border-top: 1px solid #00000040;
  border-bottom: none;
  border-left: none;
  border-right: 1px solid #00000040;
  width: 12px;
  height: 12px;
  margin-top: -14px;
  margin-left: -2px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: rotate(45deg);
}

.ant-steps-navigation .ant-steps-item:before {
  content: "";
  background-color: #1890ff;
  width: 0;
  height: 2px;
  transition: width .3s ease-out, left .3s ease-out;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 50%;
}

.ant-steps-navigation .ant-steps-item.ant-steps-item-active:before {
  width: 100%;
  left: 0;
}

.ant-steps-navigation.ant-steps-vertical > .ant-steps-item {
  margin-right: 0 !important;
}

.ant-steps-navigation.ant-steps-vertical > .ant-steps-item:before {
  display: none;
}

.ant-steps-navigation.ant-steps-vertical > .ant-steps-item.ant-steps-item-active:before {
  top: 0;
  right: 0;
  left: unset;
  width: 3px;
  height: calc(100% - 24px);
  display: block;
}

.ant-steps-navigation.ant-steps-vertical > .ant-steps-item:after {
  text-align: center;
  width: 8px;
  height: 8px;
  margin-bottom: 8px;
  display: block;
  position: relative;
  top: -2px;
  left: 50%;
  transform: rotate(135deg);
}

.ant-steps-navigation.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail, .ant-steps-navigation.ant-steps-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  visibility: hidden;
}

.ant-steps-rtl {
  direction: rtl;
}

.ant-steps.ant-steps-rtl .ant-steps-item-icon {
  margin-left: 8px;
  margin-right: 0;
}

.ant-steps-rtl .ant-steps-item-tail {
  left: auto;
  right: 0;
}

.ant-steps-rtl .ant-steps-item-title {
  padding-left: 16px;
  padding-right: 0;
}

.ant-steps-rtl .ant-steps-item-title .ant-steps-item-subtitle {
  float: left;
  margin-left: 0;
  margin-right: 8px;
}

.ant-steps-rtl .ant-steps-item-title:after {
  left: auto;
  right: 100%;
}

.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 0;
  padding-right: 16px;
}

.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-right: 0;
}

.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-left: 0;
}

.ant-steps-rtl .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  left: auto;
  right: .5px;
}

.ant-steps-rtl.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-left: 0;
  margin-right: -12px;
}

.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container {
  text-align: right;
  margin-left: 0;
  margin-right: -16px;
}

.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  padding-left: 0;
}

.ant-steps-rtl.ant-steps-navigation .ant-steps-item:after {
  margin-left: 0;
  margin-right: -2px;
  left: auto;
  right: 100%;
  transform: rotate(225deg);
}

.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 0;
  padding-right: 12px;
}

.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-right: 0;
}

.ant-steps-rtl.ant-steps-small .ant-steps-item-title {
  padding-left: 12px;
  padding-right: 0;
}

.ant-steps-rtl.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: right;
  margin-left: 16px;
  margin-right: 0;
}

.ant-steps-rtl.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  left: auto;
  right: 16px;
}

.ant-steps-rtl.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  left: auto;
  right: 12px;
}

.ant-steps-rtl.ant-steps-label-vertical .ant-steps-item-title {
  padding-left: 0;
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail, .ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  margin: 0 70px 0 0;
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail:after, .ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail:after {
  margin-left: 0;
  margin-right: 12px;
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot, .ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  left: auto;
  right: 2px;
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon, .ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  margin-left: 0;
  margin-right: 67px;
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot, .ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  float: right;
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot:after, .ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot:after {
  left: auto;
  right: -26px;
}

.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-left: 16px;
  margin-right: 0;
}

.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  left: auto;
  right: -9px;
}

.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: auto;
  right: 0;
}

.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
  left: auto;
  right: -2px;
}

.ant-steps-rtl.ant-steps-with-progress.ant-steps-vertical > .ant-steps-item {
  padding-right: 4px;
}

.ant-steps-rtl.ant-steps-with-progress.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: 19px;
}

.ant-steps-rtl.ant-steps-with-progress.ant-steps-small.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: 15px;
}

.ant-steps-rtl.ant-steps-with-progress.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item:first-child {
  padding-left: 0;
  padding-right: 4px;
}

.ant-steps-rtl.ant-steps-with-progress.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item:first-child.ant-steps-item-active {
  padding-right: 4px;
}

.ant-steps-with-progress .ant-steps-item {
  padding-top: 4px;
}

.ant-steps-with-progress .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 4px;
  left: 19px;
}

.ant-steps-with-progress.ant-steps-horizontal .ant-steps-item:first-child, .ant-steps-with-progress.ant-steps-small.ant-steps-horizontal .ant-steps-item:first-child {
  padding-bottom: 4px;
  padding-left: 4px;
}

.ant-steps-with-progress.ant-steps-small > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  left: 15px;
}

.ant-steps-with-progress.ant-steps-vertical .ant-steps-item {
  padding-left: 4px;
}

.ant-steps-with-progress.ant-steps-label-vertical .ant-steps-item .ant-steps-item-tail {
  top: 14px !important;
}

.ant-steps-with-progress .ant-steps-item-icon {
  position: relative;
}

.ant-steps-with-progress .ant-steps-item-icon .ant-progress {
  position: absolute;
  inset: -5px;
}

.ant-switch {
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  box-sizing: border-box;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: #00000040;
  border: 0;
  border-radius: 100px;
  min-width: 44px;
  height: 22px;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 22px;
  list-style: none;
  transition: all .2s;
  display: inline-block;
  position: relative;
}

.ant-switch:focus {
  outline: 0;
  box-shadow: 0 0 0 2px #0000001a;
}

.ant-switch-checked:focus {
  box-shadow: 0 0 0 2px #e6f7ff;
}

.ant-switch:focus:hover {
  box-shadow: none;
}

.ant-switch-checked {
  background-color: #1890ff;
}

.ant-switch-loading, .ant-switch-disabled {
  cursor: not-allowed;
  opacity: .4;
}

.ant-switch-loading *, .ant-switch-disabled * {
  box-shadow: none;
  cursor: not-allowed;
}

.ant-switch-inner {
  color: #fff;
  margin: 0 7px 0 25px;
  font-size: 12px;
  transition: margin .2s;
  display: block;
}

.ant-switch-checked .ant-switch-inner {
  margin: 0 25px 0 7px;
}

.ant-switch-handle {
  width: 18px;
  height: 18px;
  transition: all .2s ease-in-out;
  position: absolute;
  top: 2px;
  left: 2px;
}

.ant-switch-handle:before {
  content: "";
  background-color: #fff;
  border-radius: 9px;
  transition: all .2s ease-in-out;
  position: absolute;
  inset: 0;
  box-shadow: 0 2px 4px #00230b33;
}

.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 20px);
}

.ant-switch:not(.ant-switch-disabled):active .ant-switch-handle:before {
  left: 0;
  right: -30%;
}

.ant-switch:not(.ant-switch-disabled):active.ant-switch-checked .ant-switch-handle:before {
  left: -30%;
  right: 0;
}

.ant-switch-loading-icon.anticon {
  color: #000000a6;
  vertical-align: top;
  position: relative;
  top: 2px;
}

.ant-switch-checked .ant-switch-loading-icon {
  color: #1890ff;
}

.ant-switch-small {
  min-width: 28px;
  height: 16px;
  line-height: 16px;
}

.ant-switch-small .ant-switch-inner {
  margin: 0 5px 0 18px;
  font-size: 12px;
}

.ant-switch-small .ant-switch-handle {
  width: 12px;
  height: 12px;
}

.ant-switch-small .ant-switch-loading-icon {
  font-size: 9px;
  top: 1.5px;
}

.ant-switch-small.ant-switch-checked .ant-switch-inner {
  margin: 0 18px 0 5px;
}

.ant-switch-small.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 14px);
}

.ant-switch-rtl {
  direction: rtl;
}

.ant-switch-rtl .ant-switch-inner {
  margin: 0 25px 0 7px;
}

.ant-switch-rtl .ant-switch-handle {
  left: auto;
  right: 2px;
}

.ant-switch-rtl:not(.ant-switch-rtl-disabled):active .ant-switch-handle:before {
  left: -30%;
  right: 0;
}

.ant-switch-rtl:not(.ant-switch-rtl-disabled):active.ant-switch-checked .ant-switch-handle:before {
  left: 0;
  right: -30%;
}

.ant-switch-rtl.ant-switch-checked .ant-switch-inner {
  margin: 0 7px 0 25px;
}

.ant-switch-rtl.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 20px);
}

.ant-switch-rtl.ant-switch-small.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 14px);
}

.ant-table.ant-table-middle {
  font-size: 14px;
}

.ant-table.ant-table-middle .ant-table-title, .ant-table.ant-table-middle .ant-table-footer, .ant-table.ant-table-middle .ant-table-thead > tr > th, .ant-table.ant-table-middle .ant-table-tbody > tr > td, .ant-table.ant-table-middle tfoot > tr > th, .ant-table.ant-table-middle tfoot > tr > td {
  padding: 12px 8px;
}

.ant-table.ant-table-middle .ant-table-filter-trigger {
  margin-right: -4px;
}

.ant-table.ant-table-middle .ant-table-expanded-row-fixed {
  margin: -12px -8px;
}

.ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -12px -8px -12px 40px;
}

.ant-table.ant-table-middle .ant-table-selection-column {
  padding-inline-start: 2px;
}

.ant-table.ant-table-small {
  font-size: 14px;
}

.ant-table.ant-table-small .ant-table-title, .ant-table.ant-table-small .ant-table-footer, .ant-table.ant-table-small .ant-table-thead > tr > th, .ant-table.ant-table-small .ant-table-tbody > tr > td, .ant-table.ant-table-small tfoot > tr > th, .ant-table.ant-table-small tfoot > tr > td {
  padding: 8px;
}

.ant-table.ant-table-small .ant-table-filter-trigger {
  margin-right: -4px;
}

.ant-table.ant-table-small .ant-table-expanded-row-fixed {
  margin: -8px;
}

.ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -8px -8px -8px 40px;
}

.ant-table.ant-table-small .ant-table-selection-column {
  padding-inline-start: 2px;
}

.ant-table.ant-table-bordered > .ant-table-title {
  border: 1px solid #f0f0f0;
  border-bottom: 0;
}

.ant-table.ant-table-bordered > .ant-table-container {
  border-left: 1px solid #f0f0f0;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr:not(:last-child) > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #f0f0f0;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th:before, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th:before, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th:before, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th:before {
  background-color: #0000 !important;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > .ant-table-cell-fix-right-first:after, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first:after, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > .ant-table-cell-fix-right-first:after, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > .ant-table-cell-fix-right-first:after, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > .ant-table-cell-fix-right-first:after, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > .ant-table-cell-fix-right-first:after, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first:after, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > .ant-table-cell-fix-right-first:after, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > .ant-table-cell-fix-right-first:after, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > .ant-table-cell-fix-right-first:after, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > .ant-table-cell-fix-right-first:after, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > .ant-table-cell-fix-right-first:after {
  border-right: 1px solid #f0f0f0;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -16px -17px;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed:after, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed:after, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed:after, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td > .ant-table-expanded-row-fixed:after {
  content: "";
  border-right: 1px solid #f0f0f0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1px;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
  border-top: 1px solid #f0f0f0;
}

.ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-expanded-row > td, .ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-placeholder > td {
  border-right: 0;
}

.ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed, .ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -12px -9px;
}

.ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed, .ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -8px -9px;
}

.ant-table.ant-table-bordered > .ant-table-footer {
  border: 1px solid #f0f0f0;
  border-top: 0;
}

.ant-table-cell .ant-table-container:first-child {
  border-top: 0;
}

.ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: 0 1px 0 1px #fafafa;
}

.ant-table-wrapper {
  clear: both;
  max-width: 100%;
}

.ant-table-wrapper:before {
  content: "";
  display: table;
}

.ant-table-wrapper:after {
  clear: both;
  content: "";
  display: table;
}

.ant-table {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  background: #fff;
  border-radius: 2px;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: relative;
}

.ant-table table {
  text-align: left;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 2px 2px 0 0;
  width: 100%;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
  overflow-wrap: break-word;
  padding: 16px;
  position: relative;
}

.ant-table-cell-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
  overflow: hidden;
}

.ant-table-cell-ellipsis.ant-table-cell-fix-left-last, .ant-table-cell-ellipsis.ant-table-cell-fix-right-first {
  overflow: visible;
}

.ant-table-cell-ellipsis.ant-table-cell-fix-left-last .ant-table-cell-content, .ant-table-cell-ellipsis.ant-table-cell-fix-right-first .ant-table-cell-content {
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.ant-table-cell-ellipsis .ant-table-column-title {
  text-overflow: ellipsis;
  word-break: keep-all;
  overflow: hidden;
}

.ant-table-title {
  padding: 16px;
}

.ant-table-footer {
  color: #000000d9;
  background: #fafafa;
  padding: 16px;
}

.ant-table-thead > tr > th {
  color: #000000d9;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  font-weight: 500;
  transition: background .3s;
  position: relative;
}

.ant-table-thead > tr > th[colspan]:not([colspan="1"]) {
  text-align: center;
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
  content: "";
  background-color: #0000000f;
  width: 1px;
  height: 1.6em;
  transition: background-color .3s;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}

.ant-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f0;
  transition: background .3s;
}

.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table, .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table {
  margin: -16px -16px -16px 32px;
}

.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td, .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td {
  border-bottom: 0;
}

.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child, .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child, .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child, .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child {
  border-radius: 0;
}

.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #fafafa;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #e6f7ff;
  border-color: #00000008;
}

.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #dcf4ff;
}

.ant-table-summary {
  z-index: 2;
  background: #fff;
  position: relative;
}

div.ant-table-summary {
  box-shadow: 0 -1px #f0f0f0;
}

.ant-table-summary > tr > th, .ant-table-summary > tr > td {
  border-bottom: 1px solid #f0f0f0;
}

.ant-table-pagination.ant-pagination {
  margin: 16px 0;
}

.ant-table-pagination {
  flex-wrap: wrap;
  row-gap: 8px;
  display: flex;
}

.ant-table-pagination > * {
  flex: none;
}

.ant-table-pagination-left {
  justify-content: flex-start;
}

.ant-table-pagination-center {
  justify-content: center;
}

.ant-table-pagination-right {
  justify-content: flex-end;
}

.ant-table-thead th.ant-table-column-has-sorters {
  cursor: pointer;
  outline: none;
  transition: all .3s;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #0000000a;
}

.ant-table-thead th.ant-table-column-has-sorters:hover:before {
  background-color: #0000 !important;
}

.ant-table-thead th.ant-table-column-has-sorters:focus-visible {
  color: #1890ff;
}

.ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover, .ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-right:hover, .ant-table-thead th.ant-table-column-sort {
  background: #f5f5f5;
}

.ant-table-thead th.ant-table-column-sort:before {
  background-color: #0000 !important;
}

td.ant-table-column-sort {
  background: #fafafa;
}

.ant-table-column-title {
  z-index: 1;
  flex: 1;
  position: relative;
}

.ant-table-column-sorters {
  flex: auto;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.ant-table-column-sorters:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.ant-table-column-sorter {
  color: #bfbfbf;
  margin-left: 4px;
  font-size: 0;
  transition: color .3s;
}

.ant-table-column-sorter-inner {
  flex-direction: column;
  align-items: center;
  display: inline-flex;
}

.ant-table-column-sorter-up, .ant-table-column-sorter-down {
  font-size: 11px;
}

.ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active {
  color: #1890ff;
}

.ant-table-column-sorter-up + .ant-table-column-sorter-down {
  margin-top: -.3em;
}

.ant-table-column-sorters:hover .ant-table-column-sorter {
  color: #a6a6a6;
}

.ant-table-filter-column {
  justify-content: space-between;
  display: flex;
}

.ant-table-filter-trigger {
  color: #bfbfbf;
  cursor: pointer;
  border-radius: 2px;
  align-items: center;
  margin: -4px -8px -4px 4px;
  padding: 0 4px;
  font-size: 12px;
  transition: all .3s;
  display: flex;
  position: relative;
}

.ant-table-filter-trigger:hover {
  color: #00000073;
  background: #0000000a;
}

.ant-table-filter-trigger.active {
  color: #1890ff;
}

.ant-table-filter-dropdown {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  background-color: #fff;
  border-radius: 2px;
  min-width: 120px;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
}

.ant-table-filter-dropdown .ant-dropdown-menu {
  box-shadow: none;
  border: 0;
  max-height: 264px;
  overflow-x: hidden;
}

.ant-table-filter-dropdown .ant-dropdown-menu:empty:after {
  color: #00000040;
  text-align: center;
  content: "Not Found";
  padding: 8px 0;
  font-size: 12px;
  display: block;
}

.ant-table-filter-dropdown-tree {
  padding: 8px 8px 0;
}

.ant-table-filter-dropdown-tree .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}

.ant-table-filter-dropdown-tree .ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper, .ant-table-filter-dropdown-tree .ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper:hover {
  background-color: #bae7ff;
}

.ant-table-filter-dropdown-search {
  border-bottom: 1px solid #f0f0f0;
  padding: 8px;
}

.ant-table-filter-dropdown-search-input input {
  min-width: 140px;
}

.ant-table-filter-dropdown-search-input .anticon {
  color: #00000040;
}

.ant-table-filter-dropdown-checkall {
  width: 100%;
  margin-bottom: 4px;
  margin-left: 4px;
}

.ant-table-filter-dropdown-submenu > ul {
  max-height: calc(100vh - 130px);
  overflow: hidden auto;
}

.ant-table-filter-dropdown .ant-checkbox-wrapper + span, .ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-left: 8px;
}

.ant-table-filter-dropdown-btns {
  background-color: inherit;
  border-top: 1px solid #f0f0f0;
  justify-content: space-between;
  padding: 7px 8px;
  display: flex;
  overflow: hidden;
}

.ant-table-selection-col {
  width: 32px;
}

.ant-table-bordered .ant-table-selection-col {
  width: 50px;
}

table tr th.ant-table-selection-column, table tr td.ant-table-selection-column {
  text-align: center;
  padding-left: 8px;
  padding-right: 8px;
}

table tr th.ant-table-selection-column .ant-radio-wrapper, table tr td.ant-table-selection-column .ant-radio-wrapper {
  margin-right: 0;
}

table tr th.ant-table-selection-column.ant-table-cell-fix-left {
  z-index: 3;
}

table tr th.ant-table-selection-column:after {
  background-color: #0000 !important;
}

.ant-table-selection {
  flex-direction: column;
  display: inline-flex;
  position: relative;
}

.ant-table-selection-extra {
  z-index: 1;
  cursor: pointer;
  margin-inline-start: 100%;
  padding-inline-start: 4px;
  transition: all .3s;
  position: absolute;
  top: 0;
}

.ant-table-selection-extra .anticon {
  color: #bfbfbf;
  font-size: 10px;
}

.ant-table-selection-extra .anticon:hover {
  color: #a6a6a6;
}

.ant-table-expand-icon-col {
  width: 48px;
}

.ant-table-row-expand-icon-cell {
  text-align: center;
}

.ant-table-row-expand-icon-cell .ant-table-row-expand-icon {
  float: none;
  vertical-align: sub;
  display: inline-flex;
}

.ant-table-row-indent {
  float: left;
  height: 1px;
}

.ant-table-row-expand-icon {
  color: inherit;
  cursor: pointer;
  float: left;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  outline: none;
  width: 17px;
  height: 17px;
  padding: 0;
  line-height: 17px;
  transition: all .3s;
  position: relative;
  transform: scale(.941177);
}

.ant-table-row-expand-icon:focus-visible, .ant-table-row-expand-icon:hover {
  color: #40a9ff;
}

.ant-table-row-expand-icon:active {
  color: #096dd9;
}

.ant-table-row-expand-icon:focus, .ant-table-row-expand-icon:hover, .ant-table-row-expand-icon:active {
  border-color: currentColor;
}

.ant-table-row-expand-icon:before, .ant-table-row-expand-icon:after {
  content: "";
  background: currentColor;
  transition: transform .3s ease-out;
  position: absolute;
}

.ant-table-row-expand-icon:before {
  height: 1px;
  top: 7px;
  left: 3px;
  right: 3px;
}

.ant-table-row-expand-icon:after {
  width: 1px;
  top: 3px;
  bottom: 3px;
  left: 7px;
  transform: rotate(90deg);
}

.ant-table-row-expand-icon-collapsed:before {
  transform: rotate(-180deg);
}

.ant-table-row-expand-icon-collapsed:after {
  transform: rotate(0);
}

.ant-table-row-expand-icon-spaced {
  visibility: hidden;
  background: none;
  border: 0;
}

.ant-table-row-expand-icon-spaced:before, .ant-table-row-expand-icon-spaced:after {
  content: none;
  display: none;
}

.ant-table-row-indent + .ant-table-row-expand-icon {
  margin-top: 2.5005px;
  margin-right: 8px;
}

tr.ant-table-expanded-row > td, tr.ant-table-expanded-row:hover > td {
  background: #fbfbfb;
}

tr.ant-table-expanded-row .ant-descriptions-view {
  display: flex;
}

tr.ant-table-expanded-row .ant-descriptions-view table {
  flex: auto;
  width: auto;
}

.ant-table .ant-table-expanded-row-fixed {
  margin: -16px;
  padding: 16px;
  position: relative;
}

.ant-table-tbody > tr.ant-table-placeholder {
  text-align: center;
}

.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  color: #00000040;
}

.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: #fff;
}

.ant-table-cell-fix-left, .ant-table-cell-fix-right {
  z-index: 2;
  background: #fff;
  position: sticky !important;
}

.ant-table-cell-fix-left-first:after, .ant-table-cell-fix-left-last:after {
  content: "";
  pointer-events: none;
  width: 30px;
  transition: box-shadow .3s;
  position: absolute;
  top: 0;
  bottom: -1px;
  right: 0;
  transform: translateX(100%);
}

.ant-table-cell-fix-left-all:after {
  display: none;
}

.ant-table-cell-fix-right-first:after, .ant-table-cell-fix-right-last:after {
  content: "";
  pointer-events: none;
  width: 30px;
  transition: box-shadow .3s;
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  transform: translateX(-100%);
}

.ant-table .ant-table-container:before, .ant-table .ant-table-container:after {
  z-index: calc(calc(2 + 1)  + 1);
  content: "";
  pointer-events: none;
  width: 30px;
  transition: box-shadow .3s;
  position: absolute;
  top: 0;
  bottom: 0;
}

.ant-table .ant-table-container:before {
  left: 0;
}

.ant-table .ant-table-container:after {
  right: 0;
}

.ant-table-ping-left:not(.ant-table-has-fix-left) > .ant-table-container {
  position: relative;
}

.ant-table-ping-left:not(.ant-table-has-fix-left) > .ant-table-container:before, .ant-table-ping-left .ant-table-cell-fix-left-first:after, .ant-table-ping-left .ant-table-cell-fix-left-last:after {
  box-shadow: inset 10px 0 8px -8px #00000026;
}

.ant-table-ping-left .ant-table-cell-fix-left-last:before {
  background-color: #0000 !important;
}

.ant-table-ping-right:not(.ant-table-has-fix-right) > .ant-table-container {
  position: relative;
}

.ant-table-ping-right:not(.ant-table-has-fix-right) > .ant-table-container:after, .ant-table-ping-right .ant-table-cell-fix-right-first:after, .ant-table-ping-right .ant-table-cell-fix-right-last:after {
  box-shadow: inset -10px 0 8px -8px #00000026;
}

.ant-table-sticky-holder {
  z-index: calc(2 + 1);
  background: #fff;
  position: sticky;
}

.ant-table-sticky-scroll {
  z-index: calc(2 + 1);
  opacity: .6;
  background: #fff;
  border-top: 1px solid #f0f0f0;
  align-items: center;
  display: flex;
  position: sticky;
  bottom: 0;
}

.ant-table-sticky-scroll:hover {
  transform-origin: bottom;
}

.ant-table-sticky-scroll-bar {
  background-color: #00000059;
  border-radius: 4px;
  height: 8px;
}

.ant-table-sticky-scroll-bar:hover, .ant-table-sticky-scroll-bar-active {
  background-color: #000c;
}

@media (-ms-high-contrast: none) {
  .ant-table-ping-left .ant-table-cell-fix-left-last:after, .ant-table-ping-right .ant-table-cell-fix-right-first:after {
    box-shadow: none !important;
  }
}

.ant-table-title {
  border-radius: 2px 2px 0 0;
}

.ant-table-title + .ant-table-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.ant-table-title + .ant-table-container table, .ant-table-title + .ant-table-container table > thead > tr:first-child th:first-child, .ant-table-title + .ant-table-container table > thead > tr:first-child th:last-child {
  border-radius: 0;
}

.ant-table-container {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 2px;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 2px;
}

.ant-table-footer {
  border-radius: 0 0 2px 2px;
}

.ant-table-wrapper-rtl, .ant-table-rtl {
  direction: rtl;
}

.ant-table-wrapper-rtl .ant-table table {
  text-align: right;
}

.ant-table-wrapper-rtl .ant-table-thead > tr > th[colspan]:not([colspan="1"]) {
  text-align: center;
}

.ant-table-wrapper-rtl .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
  left: 0;
  right: auto;
}

.ant-table-wrapper-rtl .ant-table-thead > tr > th {
  text-align: right;
}

.ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table.ant-table-rtl {
  margin: -16px 33px -16px -16px;
}

.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-left {
  justify-content: flex-end;
}

.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-right {
  justify-content: flex-start;
}

.ant-table-wrapper-rtl .ant-table-column-sorter {
  margin-left: 0;
  margin-right: 4px;
}

.ant-table-wrapper-rtl .ant-table-filter-column-title {
  padding: 16px 16px 16px 2.3em;
}

.ant-table-rtl .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
  padding: 0 0 0 2.3em;
}

.ant-table-wrapper-rtl .ant-table-filter-trigger {
  margin: -4px 4px -4px -8px;
}

.ant-dropdown-rtl .ant-table-filter-dropdown .ant-checkbox-wrapper + span, .ant-dropdown-rtl .ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span, .ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown .ant-checkbox-wrapper + span, .ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-left: 0;
  padding-right: 8px;
}

.ant-table-wrapper-rtl .ant-table-selection {
  text-align: center;
}

.ant-table-wrapper-rtl .ant-table-row-indent, .ant-table-wrapper-rtl .ant-table-row-expand-icon {
  float: right;
}

.ant-table-wrapper-rtl .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-left: 8px;
  margin-right: 0;
}

.ant-table-wrapper-rtl .ant-table-row-expand-icon:after {
  transform: rotate(-90deg);
}

.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed:before {
  transform: rotate(180deg);
}

.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed:after {
  transform: rotate(0);
}

@keyframes ant-tree-node-fx-do-not-use {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ant-tree.ant-tree-directory .ant-tree-treenode {
  position: relative;
}

.ant-tree.ant-tree-directory .ant-tree-treenode:before {
  content: "";
  pointer-events: none;
  transition: background-color .3s;
  position: absolute;
  inset: 0 0 4px;
}

.ant-tree.ant-tree-directory .ant-tree-treenode:hover:before {
  background: #f5f5f5;
}

.ant-tree.ant-tree-directory .ant-tree-treenode > * {
  z-index: 1;
}

.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-switcher {
  transition: color .3s;
}

.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper {
  -webkit-user-select: none;
  user-select: none;
  border-radius: 0;
}

.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
  background: none;
}

.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
  background: none;
}

.ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover:before, .ant-tree.ant-tree-directory .ant-tree-treenode-selected:before {
  background: #1890ff;
}

.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
  color: #fff;
}

.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper {
  color: #fff;
  background: none;
}

.ant-tree-checkbox {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1;
  list-style: none;
  position: relative;
  top: .2em;
}

.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner, .ant-tree-checkbox:hover .ant-tree-checkbox-inner, .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: #1890ff;
}

.ant-tree-checkbox-checked:after {
  visibility: hidden;
  content: "";
  border: 1px solid #1890ff;
  border-radius: 2px;
  width: 100%;
  height: 100%;
  animation: .36s ease-in-out backwards antCheckboxEffect;
  position: absolute;
  top: 0;
  left: 0;
}

.ant-tree-checkbox:hover:after, .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox:after {
  visibility: visible;
}

.ant-tree-checkbox-inner {
  border-collapse: separate;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  transition: all .3s;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.ant-tree-checkbox-inner:after {
  opacity: 0;
  content: " ";
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  width: 5.71429px;
  height: 9.14286px;
  transition: all .1s cubic-bezier(.71, -.46, .88, .6), opacity .1s;
  display: table;
  position: absolute;
  top: 50%;
  left: 21.5%;
  transform: rotate(45deg)scale(0)translate(-50%, -50%);
}

.ant-tree-checkbox-input {
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner:after {
  opacity: 1;
  content: " ";
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transition: all .2s cubic-bezier(.12, .4, .29, 1.46) .1s;
  display: table;
  position: absolute;
  transform: rotate(45deg)scale(1)translate(-50%, -50%);
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}

.ant-tree-checkbox-disabled {
  cursor: not-allowed;
}

.ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner:after {
  border-color: #00000040;
  animation-name: none;
}

.ant-tree-checkbox-disabled .ant-tree-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}

.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}

.ant-tree-checkbox-disabled .ant-tree-checkbox-inner:after {
  border-collapse: separate;
  border-color: #f5f5f5;
  animation-name: none;
}

.ant-tree-checkbox-disabled + span {
  color: #00000040;
  cursor: not-allowed;
}

.ant-tree-checkbox-disabled:hover:after, .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-disabled:after {
  visibility: hidden;
}

.ant-tree-checkbox-wrapper {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  font-size: 14px;
  line-height: 1.5715;
  line-height: unset;
  cursor: pointer;
  align-items: baseline;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-flex;
}

.ant-tree-checkbox-wrapper:after {
  content: " ";
  width: 0;
  display: inline-block;
  overflow: hidden;
}

.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}

.ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
  margin-left: 8px;
}

.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-in-form-item input[type="checkbox"] {
  width: 14px;
  height: 14px;
}

.ant-tree-checkbox + span {
  padding-left: 8px;
  padding-right: 8px;
}

.ant-tree-checkbox-group {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: inline-block;
}

.ant-tree-checkbox-group-item {
  margin-right: 8px;
}

.ant-tree-checkbox-group-item:last-child {
  margin-right: 0;
}

.ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 0;
}

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after {
  opacity: 1;
  content: " ";
  background-color: #1890ff;
  border: 0;
  width: 8px;
  height: 8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(1);
}

.ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner:after {
  background-color: #00000040;
  border-color: #00000040;
}

.ant-tree {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  background: #fff;
  border-radius: 2px;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  transition: background-color .3s;
}

.ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
  background: #e6f7ff;
}

.ant-tree-list-holder-inner {
  align-items: flex-start;
}

.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner {
  align-items: stretch;
}

.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
  flex: auto;
}

.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging {
  position: relative;
}

.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging:after {
  opacity: 0;
  content: "";
  pointer-events: none;
  border: 1px solid #1890ff;
  animation: .3s forwards ant-tree-node-fx-do-not-use;
  position: absolute;
  inset: 0 0 4px;
}

.ant-tree .ant-tree-treenode {
  outline: none;
  align-items: flex-start;
  padding: 0 0 4px;
  display: flex;
}

.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
  color: #00000040;
  cursor: not-allowed;
}

.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper:hover {
  background: none;
}

.ant-tree .ant-tree-treenode-active .ant-tree-node-content-wrapper {
  background: #f5f5f5;
}

.ant-tree .ant-tree-treenode:not(.ant-tree .ant-tree-treenode-disabled).filter-node .ant-tree-title {
  color: inherit;
  font-weight: 500;
}

.ant-tree .ant-tree-treenode-draggable .ant-tree-draggable-icon {
  text-align: center;
  visibility: visible;
  opacity: .2;
  width: 24px;
  line-height: 24px;
  transition: opacity .3s;
}

.ant-tree-treenode:hover .ant-tree .ant-tree-treenode-draggable .ant-tree-draggable-icon {
  opacity: .45;
}

.ant-tree .ant-tree-treenode-draggable.ant-tree-treenode-disabled .ant-tree-draggable-icon {
  visibility: hidden;
}

.ant-tree-indent {
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  align-self: stretch;
}

.ant-tree-indent-unit {
  width: 24px;
  display: inline-block;
}

.ant-tree-draggable-icon {
  visibility: hidden;
}

.ant-tree-switcher {
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  position: relative;
}

.ant-tree-switcher .ant-tree-switcher-icon, .ant-tree-switcher .ant-select-tree-switcher-icon {
  vertical-align: baseline;
  font-size: 10px;
  display: inline-block;
}

.ant-tree-switcher .ant-tree-switcher-icon svg, .ant-tree-switcher .ant-select-tree-switcher-icon svg {
  transition: transform .3s;
}

.ant-tree-switcher-noop {
  cursor: default;
}

.ant-tree-switcher_close .ant-tree-switcher-icon svg {
  transform: rotate(-90deg);
}

.ant-tree-switcher-loading-icon {
  color: #1890ff;
}

.ant-tree-switcher-leaf-line {
  z-index: 1;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
}

.ant-tree-switcher-leaf-line:before {
  content: " ";
  border-right: 1px solid #d9d9d9;
  margin-left: -1px;
  position: absolute;
  top: 0;
  bottom: -4px;
  right: 12px;
}

.ant-tree-switcher-leaf-line:after {
  content: " ";
  border-bottom: 1px solid #d9d9d9;
  width: 10px;
  height: 14px;
  position: absolute;
}

.ant-tree-checkbox {
  top: initial;
  margin: 4px 8px 0 0;
}

.ant-tree .ant-tree-node-content-wrapper {
  z-index: auto;
  color: inherit;
  cursor: pointer;
  background: none;
  border-radius: 2px;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  line-height: 24px;
  transition: all .3s, border, line-height, box-shadow;
  position: relative;
}

.ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}

.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #bae7ff;
}

.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
  text-align: center;
  vertical-align: top;
  width: 24px;
  height: 24px;
  line-height: 24px;
  display: inline-block;
}

.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle:empty {
  display: none;
}

.ant-tree-unselectable .ant-tree-node-content-wrapper:hover {
  background-color: #0000;
}

.ant-tree-node-content-wrapper {
  -webkit-user-select: none;
  user-select: none;
  line-height: 24px;
}

.ant-tree-node-content-wrapper .ant-tree-drop-indicator {
  z-index: 1;
  pointer-events: none;
  background-color: #1890ff;
  border-radius: 1px;
  height: 2px;
  position: absolute;
}

.ant-tree-node-content-wrapper .ant-tree-drop-indicator:after {
  content: "";
  background-color: #0000;
  border: 2px solid #1890ff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  position: absolute;
  top: -3px;
  left: -6px;
}

.ant-tree .ant-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px #1890ff;
}

.ant-tree-show-line .ant-tree-indent-unit {
  height: 100%;
  position: relative;
}

.ant-tree-show-line .ant-tree-indent-unit:before {
  content: "";
  border-right: 1px solid #d9d9d9;
  position: absolute;
  top: 0;
  bottom: -4px;
  right: 12px;
}

.ant-tree-show-line .ant-tree-indent-unit-end:before {
  display: none;
}

.ant-tree-show-line .ant-tree-switcher {
  background: #fff;
}

.ant-tree-show-line .ant-tree-switcher-line-icon {
  vertical-align: -.15em;
}

.ant-tree .ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line:before {
  height: 14px !important;
  top: auto !important;
  bottom: auto !important;
}

.ant-tree-rtl {
  direction: rtl;
}

.ant-tree-rtl .ant-tree-node-content-wrapper[draggable="true"] .ant-tree-drop-indicator:after {
  right: -6px;
  left: unset;
}

.ant-tree .ant-tree-treenode-rtl {
  direction: rtl;
}

.ant-tree-rtl .ant-tree-switcher_close .ant-tree-switcher-icon svg {
  transform: rotate(90deg);
}

.ant-tree-rtl.ant-tree-show-line .ant-tree-indent-unit:before {
  border-left: 1px solid #d9d9d9;
  border-right: none;
  left: -13px;
  right: auto;
}

.ant-tree-rtl .ant-tree-checkbox, .ant-tree-select-dropdown-rtl .ant-select-tree-checkbox {
  margin: 4px 0 0 8px;
}

.ant-timeline {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
}

.ant-timeline-item {
  margin: 0;
  padding-bottom: 20px;
  font-size: 14px;
  list-style: none;
  position: relative;
}

.ant-timeline-item-tail {
  border-left: 2px solid #f0f0f0;
  height: calc(100% - 10px);
  position: absolute;
  top: 10px;
  left: 4px;
}

.ant-timeline-item-pending .ant-timeline-item-head {
  background-color: #0000;
  font-size: 12px;
}

.ant-timeline-item-pending .ant-timeline-item-tail {
  display: none;
}

.ant-timeline-item-head {
  background-color: #fff;
  border: 2px solid #0000;
  border-radius: 100px;
  width: 10px;
  height: 10px;
  position: absolute;
}

.ant-timeline-item-head-blue {
  color: #1890ff;
  border-color: #1890ff;
}

.ant-timeline-item-head-red {
  color: #ff4d4f;
  border-color: #ff4d4f;
}

.ant-timeline-item-head-green {
  color: #52c41a;
  border-color: #52c41a;
}

.ant-timeline-item-head-gray {
  color: #00000040;
  border-color: #00000040;
}

.ant-timeline-item-head-custom {
  text-align: center;
  border: 0;
  border-radius: 0;
  width: auto;
  height: auto;
  margin-top: 0;
  padding: 3px 1px;
  line-height: 1;
  position: absolute;
  top: 5.5px;
  left: 5px;
  transform: translate(-50%, -50%);
}

.ant-timeline-item-content {
  word-break: break-word;
  margin: 0 0 0 26px;
  position: relative;
  top: -7.001px;
}

.ant-timeline-item-last > .ant-timeline-item-tail {
  display: none;
}

.ant-timeline-item-last > .ant-timeline-item-content {
  min-height: 48px;
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail, .ant-timeline.ant-timeline-right .ant-timeline-item-tail, .ant-timeline.ant-timeline-label .ant-timeline-item-tail, .ant-timeline.ant-timeline-alternate .ant-timeline-item-head, .ant-timeline.ant-timeline-right .ant-timeline-item-head, .ant-timeline.ant-timeline-label .ant-timeline-item-head, .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom, .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom, .ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  left: 50%;
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-head, .ant-timeline.ant-timeline-right .ant-timeline-item-head, .ant-timeline.ant-timeline-label .ant-timeline-item-head {
  margin-left: -4px;
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom, .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom, .ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  margin-left: 1px;
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content, .ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content, .ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  text-align: left;
  width: calc(50% - 14px);
  left: calc(50% - 4px);
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content, .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content, .ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
  text-align: right;
  width: calc(50% - 12px);
  margin: 0;
}

.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail, .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head, .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  left: calc(100% - 6px);
}

.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(100% - 18px);
}

.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  border-left: 2px dotted #f0f0f0;
  height: calc(100% - 14px);
  display: block;
}

.ant-timeline.ant-timeline-reverse .ant-timeline-item-last .ant-timeline-item-tail {
  display: none;
}

.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  border-left: 2px dotted #f0f0f0;
  height: calc(100% - 15px);
  display: block;
  top: 15px;
}

.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-content {
  min-height: 48px;
}

.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  text-align: right;
  width: calc(50% - 12px);
  position: absolute;
  top: -7.001px;
}

.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
  text-align: left;
  width: calc(50% - 14px);
  left: calc(50% + 14px);
}

.ant-timeline-rtl {
  direction: rtl;
}

.ant-timeline-rtl .ant-timeline-item-tail {
  border-left: none;
  border-right: 2px solid #f0f0f0;
  left: auto;
  right: 4px;
}

.ant-timeline-rtl .ant-timeline-item-head-custom {
  left: auto;
  right: 5px;
  transform: translate(50%, -50%);
}

.ant-timeline-rtl .ant-timeline-item-content {
  margin: 0 18px 0 0;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail, .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-tail, .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-tail, .ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head, .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head, .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head, .ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom, .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom, .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  left: auto;
  right: 50%;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head, .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head, .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  margin-left: 0;
  margin-right: -4px;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom, .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom, .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  margin-left: 0;
  margin-right: 1px;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content, .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content, .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  text-align: right;
  left: auto;
  right: calc(50% - 4px);
}

.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content, .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content, .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
  text-align: left;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail, .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head, .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  left: auto;
  right: 0;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  text-align: right;
  width: 100%;
  margin-right: 18px;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail, .ant-timeline-rtl.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  border-left: none;
  border-right: 2px dotted #f0f0f0;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  text-align: left;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
  text-align: right;
  right: calc(50% + 14px);
}

.ant-transfer-customize-list .ant-transfer-list {
  flex: 50%;
  width: auto;
  height: auto;
  min-height: 200px;
}

.ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
  border: 0;
  border-radius: 0;
}

.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-selection-column {
  width: 40px;
  min-width: 40px;
}

.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  background: #fafafa;
}

.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 1px solid #f0f0f0;
}

.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-body {
  margin: 0;
}

.ant-transfer-customize-list .ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 16px 0 4px;
}

.ant-transfer-customize-list .ant-input[disabled] {
  background-color: #0000;
}

.ant-transfer-status-error .ant-transfer-list {
  border-color: #ff4d4f;
}

.ant-transfer-status-error .ant-transfer-list-search:not([disabled]) {
  border-color: #d9d9d9;
}

.ant-transfer-status-error .ant-transfer-list-search:not([disabled]):hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}

.ant-transfer-status-error .ant-transfer-list-search:not([disabled]):focus {
  border-color: #40a9ff;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #1890ff33;
}

.ant-transfer-status-warning .ant-transfer-list {
  border-color: #faad14;
}

.ant-transfer-status-warning .ant-transfer-list-search:not([disabled]) {
  border-color: #d9d9d9;
}

.ant-transfer-status-warning .ant-transfer-list-search:not([disabled]):hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}

.ant-transfer-status-warning .ant-transfer-list-search:not([disabled]):focus {
  border-color: #40a9ff;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #1890ff33;
}

.ant-transfer {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  align-items: stretch;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: flex;
  position: relative;
}

.ant-transfer-disabled .ant-transfer-list {
  background: #f5f5f5;
}

.ant-transfer-list {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  flex-direction: column;
  width: 180px;
  height: 200px;
  display: flex;
}

.ant-transfer-list-with-pagination {
  width: 250px;
  height: auto;
}

.ant-transfer-list-search .anticon-search {
  color: #00000040;
}

.ant-transfer-list-header {
  color: #000000d9;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
  flex: none;
  align-items: center;
  height: 40px;
  padding: 8px 12px 9px;
  display: flex;
}

.ant-transfer-list-header > :not(:last-child) {
  margin-right: 4px;
}

.ant-transfer-list-header > * {
  flex: none;
}

.ant-transfer-list-header-title {
  white-space: nowrap;
  text-align: right;
  text-overflow: ellipsis;
  flex: auto;
  overflow: hidden;
}

.ant-transfer-list-header-dropdown {
  cursor: pointer;
  font-size: 10px;
  transform: translateY(10%);
}

.ant-transfer-list-header-dropdown[disabled] {
  cursor: not-allowed;
}

.ant-transfer-list-body {
  flex-direction: column;
  flex: auto;
  font-size: 14px;
  display: flex;
  overflow: hidden;
}

.ant-transfer-list-body-search-wrapper {
  flex: none;
  padding: 12px;
  position: relative;
}

.ant-transfer-list-content {
  flex: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;
}

.ant-transfer-list-content-item {
  align-items: center;
  min-height: 32px;
  padding: 6px 12px;
  line-height: 20px;
  transition: all .3s;
  display: flex;
}

.ant-transfer-list-content-item > :not(:last-child) {
  margin-right: 8px;
}

.ant-transfer-list-content-item > * {
  flex: none;
}

.ant-transfer-list-content-item-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: auto;
  overflow: hidden;
}

.ant-transfer-list-content-item-remove {
  color: #d9d9d9;
  cursor: pointer;
  transition: all .3s;
  position: relative;
}

.ant-transfer-list-content-item-remove:hover {
  color: #40a9ff;
}

.ant-transfer-list-content-item-remove:after {
  content: "";
  position: absolute;
  inset: -6px -50%;
}

.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover {
  background-color: #dcf4ff;
}

.ant-transfer-list-content-show-remove .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  cursor: default;
  background: none;
}

.ant-transfer-list-content-item-checked {
  background-color: #e6f7ff;
}

.ant-transfer-list-content-item-disabled {
  color: #00000040;
  cursor: not-allowed;
}

.ant-transfer-list-pagination {
  text-align: right;
  border-top: 1px solid #f0f0f0;
  padding: 8px 0;
}

.ant-transfer-list-body-not-found {
  color: #00000040;
  text-align: center;
  flex: none;
  width: 100%;
  margin: auto 0;
}

.ant-transfer-list-footer {
  border-top: 1px solid #f0f0f0;
}

.ant-transfer-operation {
  vertical-align: middle;
  flex-direction: column;
  flex: none;
  align-self: center;
  margin: 0 8px;
  display: flex;
}

.ant-transfer-operation .ant-btn {
  display: block;
}

.ant-transfer-operation .ant-btn:first-child {
  margin-bottom: 4px;
}

.ant-transfer-operation .ant-btn .anticon {
  font-size: 12px;
}

.ant-transfer .ant-empty-image {
  max-height: -2px;
}

.ant-transfer-rtl {
  direction: rtl;
}

.ant-transfer-rtl .ant-transfer-list-search {
  padding-left: 24px;
  padding-right: 8px;
}

.ant-transfer-rtl .ant-transfer-list-search-action {
  left: 12px;
  right: auto;
}

.ant-transfer-rtl .ant-transfer-list-header > :not(:last-child) {
  margin-left: 4px;
  margin-right: 0;
}

.ant-transfer-rtl .ant-transfer-list-header {
  left: auto;
  right: 0;
}

.ant-transfer-rtl .ant-transfer-list-header-title {
  text-align: left;
}

.ant-transfer-rtl .ant-transfer-list-content-item > :not(:last-child) {
  margin-left: 8px;
  margin-right: 0;
}

.ant-transfer-rtl .ant-transfer-list-pagination {
  text-align: left;
}

.ant-transfer-rtl .ant-transfer-list-footer {
  left: auto;
  right: 0;
}

.ant-select-tree-checkbox {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1;
  list-style: none;
  position: relative;
  top: .2em;
}

.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner, .ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner, .ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
  border-color: #1890ff;
}

.ant-select-tree-checkbox-checked:after {
  visibility: hidden;
  content: "";
  border: 1px solid #1890ff;
  border-radius: 2px;
  width: 100%;
  height: 100%;
  animation: .36s ease-in-out backwards antCheckboxEffect;
  position: absolute;
  top: 0;
  left: 0;
}

.ant-select-tree-checkbox:hover:after, .ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox:after {
  visibility: visible;
}

.ant-select-tree-checkbox-inner {
  border-collapse: separate;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  transition: all .3s;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.ant-select-tree-checkbox-inner:after {
  opacity: 0;
  content: " ";
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  width: 5.71429px;
  height: 9.14286px;
  transition: all .1s cubic-bezier(.71, -.46, .88, .6), opacity .1s;
  display: table;
  position: absolute;
  top: 50%;
  left: 21.5%;
  transform: rotate(45deg)scale(0)translate(-50%, -50%);
}

.ant-select-tree-checkbox-input {
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner:after {
  opacity: 1;
  content: " ";
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transition: all .2s cubic-bezier(.12, .4, .29, 1.46) .1s;
  display: table;
  position: absolute;
  transform: rotate(45deg)scale(1)translate(-50%, -50%);
}

.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}

.ant-select-tree-checkbox-disabled {
  cursor: not-allowed;
}

.ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner:after {
  border-color: #00000040;
  animation-name: none;
}

.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}

.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}

.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner:after {
  border-collapse: separate;
  border-color: #f5f5f5;
  animation-name: none;
}

.ant-select-tree-checkbox-disabled + span {
  color: #00000040;
  cursor: not-allowed;
}

.ant-select-tree-checkbox-disabled:hover:after, .ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-disabled:after {
  visibility: hidden;
}

.ant-select-tree-checkbox-wrapper {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  font-size: 14px;
  line-height: 1.5715;
  line-height: unset;
  cursor: pointer;
  align-items: baseline;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-flex;
}

.ant-select-tree-checkbox-wrapper:after {
  content: " ";
  width: 0;
  display: inline-block;
  overflow: hidden;
}

.ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}

.ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
  margin-left: 8px;
}

.ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-in-form-item input[type="checkbox"] {
  width: 14px;
  height: 14px;
}

.ant-select-tree-checkbox + span {
  padding-left: 8px;
  padding-right: 8px;
}

.ant-select-tree-checkbox-group {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: inline-block;
}

.ant-select-tree-checkbox-group-item {
  margin-right: 8px;
}

.ant-select-tree-checkbox-group-item:last-child {
  margin-right: 0;
}

.ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 0;
}

.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}

.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner:after {
  opacity: 1;
  content: " ";
  background-color: #1890ff;
  border: 0;
  width: 8px;
  height: 8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(1);
}

.ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner:after {
  background-color: #00000040;
  border-color: #00000040;
}

.ant-tree-select-dropdown {
  padding: 8px 4px;
}

.ant-tree-select-dropdown-rtl {
  direction: rtl;
}

.ant-tree-select-dropdown .ant-select-tree {
  border-radius: 0;
}

.ant-tree-select-dropdown .ant-select-tree-list-holder-inner {
  align-items: stretch;
}

.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode .ant-select-tree-node-content-wrapper {
  flex: auto;
}

.ant-select-tree {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  background: #fff;
  border-radius: 2px;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  transition: background-color .3s;
}

.ant-select-tree-focused:not(:hover):not(.ant-select-tree-active-focused) {
  background: #e6f7ff;
}

.ant-select-tree-list-holder-inner {
  align-items: flex-start;
}

.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner {
  align-items: stretch;
}

.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-node-content-wrapper {
  flex: auto;
}

.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-treenode.dragging {
  position: relative;
}

.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-treenode.dragging:after {
  opacity: 0;
  content: "";
  pointer-events: none;
  border: 1px solid #1890ff;
  animation: .3s forwards ant-tree-node-fx-do-not-use;
  position: absolute;
  inset: 0 0 4px;
}

.ant-select-tree .ant-select-tree-treenode {
  outline: none;
  align-items: flex-start;
  padding: 0 0 4px;
  display: flex;
}

.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
  color: #00000040;
  cursor: not-allowed;
}

.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper:hover {
  background: none;
}

.ant-select-tree .ant-select-tree-treenode-active .ant-select-tree-node-content-wrapper {
  background: #f5f5f5;
}

.ant-select-tree .ant-select-tree-treenode:not(.ant-select-tree .ant-select-tree-treenode-disabled).filter-node .ant-select-tree-title {
  color: inherit;
  font-weight: 500;
}

.ant-select-tree .ant-select-tree-treenode-draggable .ant-select-tree-draggable-icon {
  text-align: center;
  visibility: visible;
  opacity: .2;
  width: 24px;
  line-height: 24px;
  transition: opacity .3s;
}

.ant-select-tree-treenode:hover .ant-select-tree .ant-select-tree-treenode-draggable .ant-select-tree-draggable-icon {
  opacity: .45;
}

.ant-select-tree .ant-select-tree-treenode-draggable.ant-select-tree-treenode-disabled .ant-select-tree-draggable-icon {
  visibility: hidden;
}

.ant-select-tree-indent {
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  align-self: stretch;
}

.ant-select-tree-indent-unit {
  width: 24px;
  display: inline-block;
}

.ant-select-tree-draggable-icon {
  visibility: hidden;
}

.ant-select-tree-switcher {
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  position: relative;
}

.ant-select-tree-switcher .ant-tree-switcher-icon, .ant-select-tree-switcher .ant-select-tree-switcher-icon {
  vertical-align: baseline;
  font-size: 10px;
  display: inline-block;
}

.ant-select-tree-switcher .ant-tree-switcher-icon svg, .ant-select-tree-switcher .ant-select-tree-switcher-icon svg {
  transition: transform .3s;
}

.ant-select-tree-switcher-noop {
  cursor: default;
}

.ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
  transform: rotate(-90deg);
}

.ant-select-tree-switcher-loading-icon {
  color: #1890ff;
}

.ant-select-tree-switcher-leaf-line {
  z-index: 1;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
}

.ant-select-tree-switcher-leaf-line:before {
  content: " ";
  border-right: 1px solid #d9d9d9;
  margin-left: -1px;
  position: absolute;
  top: 0;
  bottom: -4px;
  right: 12px;
}

.ant-select-tree-switcher-leaf-line:after {
  content: " ";
  border-bottom: 1px solid #d9d9d9;
  width: 10px;
  height: 14px;
  position: absolute;
}

.ant-select-tree-checkbox {
  top: initial;
  margin: 4px 8px 0 0;
}

.ant-select-tree .ant-select-tree-node-content-wrapper {
  z-index: auto;
  color: inherit;
  cursor: pointer;
  background: none;
  border-radius: 2px;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  line-height: 24px;
  transition: all .3s, border, line-height, box-shadow;
  position: relative;
}

.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}

.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #bae7ff;
}

.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle {
  text-align: center;
  vertical-align: top;
  width: 24px;
  height: 24px;
  line-height: 24px;
  display: inline-block;
}

.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle:empty {
  display: none;
}

.ant-select-tree-unselectable .ant-select-tree-node-content-wrapper:hover {
  background-color: #0000;
}

.ant-select-tree-node-content-wrapper {
  -webkit-user-select: none;
  user-select: none;
  line-height: 24px;
}

.ant-select-tree-node-content-wrapper .ant-tree-drop-indicator {
  z-index: 1;
  pointer-events: none;
  background-color: #1890ff;
  border-radius: 1px;
  height: 2px;
  position: absolute;
}

.ant-select-tree-node-content-wrapper .ant-tree-drop-indicator:after {
  content: "";
  background-color: #0000;
  border: 2px solid #1890ff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  position: absolute;
  top: -3px;
  left: -6px;
}

.ant-select-tree .ant-select-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px #1890ff;
}

.ant-select-tree-show-line .ant-select-tree-indent-unit {
  height: 100%;
  position: relative;
}

.ant-select-tree-show-line .ant-select-tree-indent-unit:before {
  content: "";
  border-right: 1px solid #d9d9d9;
  position: absolute;
  top: 0;
  bottom: -4px;
  right: 12px;
}

.ant-select-tree-show-line .ant-select-tree-indent-unit-end:before {
  display: none;
}

.ant-select-tree-show-line .ant-select-tree-switcher {
  background: #fff;
}

.ant-select-tree-show-line .ant-select-tree-switcher-line-icon {
  vertical-align: -.15em;
}

.ant-select-tree .ant-select-tree-treenode-leaf-last .ant-select-tree-switcher-leaf-line:before {
  height: 14px !important;
  top: auto !important;
  bottom: auto !important;
}

.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
  transform: rotate(90deg);
}

.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher-loading-icon {
  transform: scaleY(-1);
}

.ant-typography {
  color: #000000d9;
  word-break: break-word;
}

.ant-typography.ant-typography-secondary {
  color: #00000073;
}

.ant-typography.ant-typography-success {
  color: #52c41a;
}

.ant-typography.ant-typography-warning {
  color: #faad14;
}

.ant-typography.ant-typography-danger {
  color: #ff4d4f;
}

a.ant-typography.ant-typography-danger:active, a.ant-typography.ant-typography-danger:focus {
  color: #d9363e;
}

a.ant-typography.ant-typography-danger:hover {
  color: #ff7875;
}

.ant-typography.ant-typography-disabled {
  color: #00000040;
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
}

div.ant-typography, .ant-typography p {
  margin-bottom: 1em;
}

h1.ant-typography, div.ant-typography-h1, div.ant-typography-h1 > textarea, .ant-typography h1 {
  color: #000000d9;
  margin-bottom: .5em;
  font-size: 38px;
  font-weight: 600;
  line-height: 1.23;
}

h2.ant-typography, div.ant-typography-h2, div.ant-typography-h2 > textarea, .ant-typography h2 {
  color: #000000d9;
  margin-bottom: .5em;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.35;
}

h3.ant-typography, div.ant-typography-h3, div.ant-typography-h3 > textarea, .ant-typography h3 {
  color: #000000d9;
  margin-bottom: .5em;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.35;
}

h4.ant-typography, div.ant-typography-h4, div.ant-typography-h4 > textarea, .ant-typography h4 {
  color: #000000d9;
  margin-bottom: .5em;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
}

h5.ant-typography, div.ant-typography-h5, div.ant-typography-h5 > textarea, .ant-typography h5 {
  color: #000000d9;
  margin-bottom: .5em;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
}

.ant-typography + h1.ant-typography, .ant-typography + h2.ant-typography, .ant-typography + h3.ant-typography, .ant-typography + h4.ant-typography, .ant-typography + h5.ant-typography, .ant-typography div + h1, .ant-typography ul + h1, .ant-typography li + h1, .ant-typography p + h1, .ant-typography h1 + h1, .ant-typography h2 + h1, .ant-typography h3 + h1, .ant-typography h4 + h1, .ant-typography h5 + h1, .ant-typography div + h2, .ant-typography ul + h2, .ant-typography li + h2, .ant-typography p + h2, .ant-typography h1 + h2, .ant-typography h2 + h2, .ant-typography h3 + h2, .ant-typography h4 + h2, .ant-typography h5 + h2, .ant-typography div + h3, .ant-typography ul + h3, .ant-typography li + h3, .ant-typography p + h3, .ant-typography h1 + h3, .ant-typography h2 + h3, .ant-typography h3 + h3, .ant-typography h4 + h3, .ant-typography h5 + h3, .ant-typography div + h4, .ant-typography ul + h4, .ant-typography li + h4, .ant-typography p + h4, .ant-typography h1 + h4, .ant-typography h2 + h4, .ant-typography h3 + h4, .ant-typography h4 + h4, .ant-typography h5 + h4, .ant-typography div + h5, .ant-typography ul + h5, .ant-typography li + h5, .ant-typography p + h5, .ant-typography h1 + h5, .ant-typography h2 + h5, .ant-typography h3 + h5, .ant-typography h4 + h5, .ant-typography h5 + h5 {
  margin-top: 1.2em;
}

a.ant-typography-ellipsis, span.ant-typography-ellipsis {
  max-width: 100%;
  display: inline-block;
}

a.ant-typography, .ant-typography a {
  color: #1890ff;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  transition: color .3s;
}

a.ant-typography:focus-visible, .ant-typography a:focus-visible, a.ant-typography:hover, .ant-typography a:hover {
  color: #40a9ff;
}

a.ant-typography:active, .ant-typography a:active {
  color: #096dd9;
}

a.ant-typography:active, .ant-typography a:active, a.ant-typography:hover, .ant-typography a:hover {
  text-decoration: none;
}

a.ant-typography[disabled], .ant-typography a[disabled], a.ant-typography.ant-typography-disabled, .ant-typography a.ant-typography-disabled {
  color: #00000040;
  cursor: not-allowed;
}

a.ant-typography[disabled]:active, .ant-typography a[disabled]:active, a.ant-typography.ant-typography-disabled:active, .ant-typography a.ant-typography-disabled:active, a.ant-typography[disabled]:hover, .ant-typography a[disabled]:hover, a.ant-typography.ant-typography-disabled:hover, .ant-typography a.ant-typography-disabled:hover {
  color: #00000040;
}

a.ant-typography[disabled]:active, .ant-typography a[disabled]:active, a.ant-typography.ant-typography-disabled:active, .ant-typography a.ant-typography-disabled:active {
  pointer-events: none;
}

.ant-typography code {
  background: #9696961a;
  border: 1px solid #64646433;
  border-radius: 3px;
  margin: 0 .2em;
  padding: .2em .4em .1em;
  font-size: 85%;
}

.ant-typography kbd {
  background: #9696960f;
  border: 1px solid #64646433;
  border-bottom-width: 2px;
  border-radius: 3px;
  margin: 0 .2em;
  padding: .15em .4em .1em;
  font-size: 90%;
}

.ant-typography mark {
  background-color: #ffe58f;
  padding: 0;
}

.ant-typography u, .ant-typography ins {
  -webkit-text-decoration-skip: ink;
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
  text-decoration: underline;
}

.ant-typography s, .ant-typography del {
  text-decoration: line-through;
}

.ant-typography strong {
  font-weight: 600;
}

.ant-typography-expand, .ant-typography-edit, .ant-typography-copy {
  color: #1890ff;
  cursor: pointer;
  outline: none;
  margin-left: 4px;
  transition: color .3s;
}

.ant-typography-expand:focus-visible, .ant-typography-edit:focus-visible, .ant-typography-copy:focus-visible, .ant-typography-expand:hover, .ant-typography-edit:hover, .ant-typography-copy:hover {
  color: #40a9ff;
}

.ant-typography-expand:active, .ant-typography-edit:active, .ant-typography-copy:active {
  color: #096dd9;
}

.ant-typography-copy-success, .ant-typography-copy-success:hover, .ant-typography-copy-success:focus {
  color: #52c41a;
}

.ant-typography-edit-content {
  position: relative;
}

div.ant-typography-edit-content {
  margin-top: -5px;
  margin-bottom: calc(1em - 5px);
  left: -12px;
}

.ant-typography-edit-content-confirm {
  color: #00000073;
  pointer-events: none;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  position: absolute;
  bottom: 8px;
  right: 10px;
}

.ant-typography-edit-content textarea {
  height: 1em;
  -moz-transition: none;
  margin: 0 !important;
}

.ant-typography ul, .ant-typography ol {
  margin: 0 0 1em;
  padding: 0;
}

.ant-typography ul li, .ant-typography ol li {
  margin: 0 0 0 20px;
  padding: 0 0 0 4px;
}

.ant-typography ul {
  list-style-type: circle;
}

.ant-typography ul ul {
  list-style-type: disc;
}

.ant-typography ol {
  list-style-type: decimal;
}

.ant-typography pre, .ant-typography blockquote {
  margin: 1em 0;
}

.ant-typography pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  background: #9696961a;
  border: 1px solid #64646433;
  border-radius: 3px;
  padding: .4em .6em;
}

.ant-typography pre code {
  font-size: inherit;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  font-family: inherit;
  display: inline;
}

.ant-typography blockquote {
  opacity: .85;
  border-left: 4px solid #64646433;
  padding: 0 0 0 .6em;
}

.ant-typography-single-line {
  white-space: nowrap;
}

.ant-typography-ellipsis-single-line {
  text-overflow: ellipsis;
  overflow: hidden;
}

a.ant-typography-ellipsis-single-line, span.ant-typography-ellipsis-single-line {
  vertical-align: bottom;
}

.ant-typography-ellipsis-multiple-line {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.ant-typography-rtl {
  direction: rtl;
}

.ant-typography-rtl .ant-typography-expand, .ant-typography-rtl .ant-typography-edit, .ant-typography-rtl .ant-typography-copy {
  margin-left: 0;
  margin-right: 4px;
}

.ant-typography-rtl .ant-typography-expand {
  float: left;
}

div.ant-typography-edit-content.ant-typography-rtl {
  left: auto;
  right: -12px;
}

.ant-typography-rtl .ant-typography-edit-content-confirm {
  left: 10px;
  right: auto;
}

.ant-typography-rtl.ant-typography ul li, .ant-typography-rtl.ant-typography ol li {
  margin: 0 20px 0 0;
  padding: 0 4px 0 0;
}

.ant-upload {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  outline: 0;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
}

.ant-upload p {
  margin: 0;
}

.ant-upload-btn {
  outline: none;
  width: 100%;
  display: block;
}

.ant-upload input[type="file"] {
  cursor: pointer;
}

.ant-upload.ant-upload-select {
  display: inline-block;
}

.ant-upload.ant-upload-disabled {
  color: #00000040;
  cursor: not-allowed;
}

.ant-upload.ant-upload-select-picture-card {
  text-align: center;
  vertical-align: top;
  cursor: pointer;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  width: 104px;
  height: 104px;
  margin-bottom: 8px;
  margin-right: 8px;
  transition: border-color .3s;
}

.ant-upload.ant-upload-select-picture-card > .ant-upload {
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #1890ff;
}

.ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #d9d9d9;
}

.ant-upload.ant-upload-drag {
  text-align: center;
  cursor: pointer;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  width: 100%;
  height: 100%;
  transition: border-color .3s;
  position: relative;
}

.ant-upload.ant-upload-drag .ant-upload {
  padding: 16px 0;
}

.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #096dd9;
}

.ant-upload.ant-upload-drag.ant-upload-disabled {
  cursor: not-allowed;
}

.ant-upload.ant-upload-drag .ant-upload-btn {
  height: 100%;
  display: table;
}

.ant-upload.ant-upload-drag .ant-upload-drag-container {
  vertical-align: middle;
  display: table-cell;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #40a9ff;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 20px;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #40a9ff;
  font-size: 48px;
}

.ant-upload.ant-upload-drag p.ant-upload-text {
  color: #000000d9;
  margin: 0 0 4px;
  font-size: 16px;
}

.ant-upload.ant-upload-drag p.ant-upload-hint {
  color: #00000073;
  font-size: 14px;
}

.ant-upload.ant-upload-drag .anticon-plus {
  color: #00000040;
  font-size: 30px;
  transition: all .3s;
}

.ant-upload.ant-upload-drag .anticon-plus:hover, .ant-upload.ant-upload-drag:hover .anticon-plus {
  color: #00000073;
}

.ant-upload-picture-card-wrapper {
  width: 100%;
  display: inline-block;
}

.ant-upload-picture-card-wrapper:before {
  content: "";
  display: table;
}

.ant-upload-picture-card-wrapper:after {
  clear: both;
  content: "";
  display: table;
}

.ant-upload-list {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
}

.ant-upload-list:before {
  content: "";
  display: table;
}

.ant-upload-list:after {
  clear: both;
  content: "";
  display: table;
}

.ant-upload-list-item {
  height: 22.001px;
  margin-top: 8px;
  font-size: 14px;
  position: relative;
}

.ant-upload-list-item-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  padding-left: 22px;
  line-height: 1.5715;
  display: inline-block;
  overflow: hidden;
}

.ant-upload-list-item-card-actions {
  position: absolute;
  right: 0;
}

.ant-upload-list-item-card-actions-btn {
  opacity: 0;
}

.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  vertical-align: top;
  height: 22.001px;
  line-height: 1;
}

.ant-upload-list-item-card-actions.picture {
  line-height: 0;
  top: 22px;
}

.ant-upload-list-item-card-actions-btn:focus, .ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}

.ant-upload-list-item-card-actions .anticon {
  color: #00000073;
  transition: all .3s;
}

.ant-upload-list-item-card-actions:hover .anticon {
  color: #000000d9;
}

.ant-upload-list-item-info {
  height: 100%;
  transition: background-color .3s;
}

.ant-upload-list-item-info > span {
  width: 100%;
  height: 100%;
  display: block;
}

.ant-upload-list-item-info .anticon-loading .anticon, .ant-upload-list-item-info .ant-upload-text-icon .anticon {
  color: #00000073;
  font-size: 14px;
  position: absolute;
  top: 5px;
}

.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #f5f5f5;
}

.ant-upload-list-item:hover .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}

.ant-upload-list-item-error, .ant-upload-list-item-error .ant-upload-text-icon > .anticon, .ant-upload-list-item-error .ant-upload-list-item-name, .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  color: #ff4d4f;
}

.ant-upload-list-item-error .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}

.ant-upload-list-item-progress {
  width: 100%;
  padding-left: 26px;
  font-size: 14px;
  line-height: 0;
  position: absolute;
  bottom: -12px;
}

.ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  height: 66px;
  padding: 8px;
  position: relative;
}

.ant-upload-list-picture .ant-upload-list-item:hover, .ant-upload-list-picture-card .ant-upload-list-item:hover {
  background: none;
}

.ant-upload-list-picture .ant-upload-list-item-error, .ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #ff4d4f;
}

.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info, .ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: none;
}

.ant-upload-list-picture .ant-upload-list-item-uploading, .ant-upload-list-picture-card .ant-upload-list-item-uploading {
  border-style: dashed;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  text-align: center;
  opacity: .8;
  width: 48px;
  height: 48px;
  line-height: 60px;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail .anticon, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail .anticon {
  font-size: 26px;
}

.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill="#e6f7ff"], .ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill="#e6f7ff"] {
  fill: #fff2f0;
}

.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill="#1890ff"], .ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill="#1890ff"] {
  fill: #ff4d4f;
}

.ant-upload-list-picture .ant-upload-list-item-icon, .ant-upload-list-picture-card .ant-upload-list-item-icon {
  font-size: 26px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ant-upload-list-picture .ant-upload-list-item-icon .anticon, .ant-upload-list-picture-card .ant-upload-list-item-icon .anticon {
  font-size: 26px;
}

.ant-upload-list-picture .ant-upload-list-item-image, .ant-upload-list-picture-card .ant-upload-list-item-image {
  max-width: 100%;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail img, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  width: 48px;
  height: 48px;
  display: block;
  overflow: hidden;
}

.ant-upload-list-picture .ant-upload-list-item-name, .ant-upload-list-picture-card .ant-upload-list-item-name {
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  margin: 0 0 0 8px;
  padding-left: 48px;
  padding-right: 8px;
  line-height: 44px;
  transition: all .3s;
  display: inline-block;
  overflow: hidden;
}

.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name, .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
  margin-bottom: 12px;
}

.ant-upload-list-picture .ant-upload-list-item-progress, .ant-upload-list-picture-card .ant-upload-list-item-progress {
  width: calc(100% - 24px);
  margin-top: 0;
  padding-left: 56px;
  bottom: 14px;
}

.ant-upload-list-picture-card-container {
  vertical-align: top;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  display: inline-block;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  height: 100%;
  margin: 0;
}

.ant-upload-list-picture-card .ant-upload-list-item-info {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.ant-upload-list-picture-card .ant-upload-list-item-info:before {
  z-index: 1;
  opacity: 0;
  content: " ";
  background-color: #00000080;
  width: 100%;
  height: 100%;
  transition: all .3s;
  position: absolute;
}

.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info:before {
  opacity: 1;
}

.ant-upload-list-picture-card .ant-upload-list-item-actions {
  z-index: 10;
  white-space: nowrap;
  opacity: 0;
  transition: all .3s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye, .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download, .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  z-index: 10;
  color: #ffffffd9;
  cursor: pointer;
  width: 16px;
  margin: 0 4px;
  font-size: 16px;
  transition: all .3s;
}

.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover, .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover, .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
  color: #fff;
}

.ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions, .ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
  opacity: 1;
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
  position: static;
}

.ant-upload-list-picture-card .ant-upload-list-item-name {
  text-align: center;
  margin: 8px 0 0;
  padding: 0;
  line-height: 1.5715;
  display: none;
}

.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  display: block;
  position: absolute;
  bottom: 10px;
}

.ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: #fafafa;
}

.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
  height: auto;
}

.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info:before, .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye, .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete {
  display: none;
}

.ant-upload-list-picture-card .ant-upload-list-item-progress {
  width: calc(100% - 14px);
  padding-left: 0;
  bottom: 32px;
}

.ant-upload-list-text-container, .ant-upload-list-picture-container {
  transition: opacity .3s, height .3s;
}

.ant-upload-list-text-container:before, .ant-upload-list-picture-container:before {
  content: "";
  width: 0;
  height: 0;
  display: table;
}

.ant-upload-list-text-container .ant-upload-span, .ant-upload-list-picture-container .ant-upload-span {
  flex: auto;
  display: block;
}

.ant-upload-list-text .ant-upload-span, .ant-upload-list-picture .ant-upload-span {
  align-items: center;
  display: flex;
}

.ant-upload-list-text .ant-upload-span > *, .ant-upload-list-picture .ant-upload-span > * {
  flex: none;
}

.ant-upload-list-text .ant-upload-list-item-name, .ant-upload-list-picture .ant-upload-list-item-name {
  flex: auto;
  margin: 0;
  padding: 0 8px;
}

.ant-upload-list-text .ant-upload-list-item-card-actions, .ant-upload-list-picture .ant-upload-list-item-card-actions, .ant-upload-list-text .ant-upload-text-icon .anticon {
  position: static;
}

.ant-upload-list .ant-upload-animate-inline-appear, .ant-upload-list .ant-upload-animate-inline-enter, .ant-upload-list .ant-upload-animate-inline-leave {
  animation-duration: .3s;
  animation-timing-function: cubic-bezier(.78, .14, .15, .86);
  animation-fill-mode: forwards;
}

.ant-upload-list .ant-upload-animate-inline-appear, .ant-upload-list .ant-upload-animate-inline-enter {
  animation-name: uploadAnimateInlineIn;
}

.ant-upload-list .ant-upload-animate-inline-leave {
  animation-name: uploadAnimateInlineOut;
}

@keyframes uploadAnimateInlineIn {
  from {
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
  }
}

@keyframes uploadAnimateInlineOut {
  to {
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
  }
}

.ant-upload-rtl {
  direction: rtl;
}

.ant-upload-rtl.ant-upload.ant-upload-select-picture-card {
  margin-left: 8px;
  margin-right: auto;
}

.ant-upload-list-rtl {
  direction: rtl;
}

.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
  padding-left: 14px;
  padding-right: 22px;
}

.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
  padding-left: 28px;
  padding-right: 22px;
}

.ant-upload-list-rtl .ant-upload-list-item-name {
  padding-left: 0;
  padding-right: 22px;
}

.ant-upload-list-rtl .ant-upload-list-item-name-icon-count-1 {
  padding-left: 14px;
}

.ant-upload-list-rtl .ant-upload-list-item-card-actions {
  left: 0;
  right: auto;
}

.ant-upload-list-rtl .ant-upload-list-item-card-actions .anticon {
  padding-left: 5px;
  padding-right: 0;
}

.ant-upload-list-rtl .ant-upload-list-item-info {
  padding: 0 4px 0 12px;
}

.ant-upload-list-rtl .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  padding-left: 5px;
  padding-right: 0;
}

.ant-upload-list-rtl .ant-upload-list-item-progress {
  padding-left: 0;
  padding-right: 26px;
}

.ant-upload-list-picture .ant-upload-list-item-info, .ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-thumbnail, .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  left: auto;
  right: 8px;
}

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-icon, .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-icon {
  left: auto;
  right: 50%;
  transform: translate(50%, -50%);
}

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name, .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name {
  margin: 0 8px 0 0;
  padding-left: 8px;
  padding-right: 48px;
}

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1, .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
  padding-left: 18px;
  padding-right: 48px;
}

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2, .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
  padding-left: 36px;
  padding-right: 48px;
}

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-progress, .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-progress {
  padding-left: 0;
  padding-right: 0;
}

.ant-upload-list-rtl .ant-upload-list-picture-card-container {
  margin: 0 0 8px 8px;
}

.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-actions {
  left: auto;
  right: 50%;
  transform: translate(50%, -50%);
}

.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  margin: 8px 0 0;
  padding: 0;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  line-height: 1.5;
  font-family: var(--font-sans), ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

body {
  --tw-text-opacity: 1;
  color: rgb(68 68 68 / var(--tw-text-opacity));
}

h1 {
  --tw-text-opacity: 1;
  color: rgb(68 68 68 / var(--tw-text-opacity));
  margin-top: 0;
  margin-bottom: .5em;
  font-size: 2.25rem;
  font-weight: 500;
  line-height: 1.1;
}

h2 {
  --tw-text-opacity: 1;
  color: rgb(68 68 68 / var(--tw-text-opacity));
  margin-top: 0;
  margin-bottom: .5em;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.1;
}

h3 {
  --tw-text-opacity: 1;
  color: rgb(68 68 68 / var(--tw-text-opacity));
  margin-top: 0;
  margin-bottom: .5em;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5;
}

h4 {
  --tw-text-opacity: 1;
  color: rgb(68 68 68 / var(--tw-text-opacity));
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 17px;
}

a {
  --tw-text-opacity: 1;
  color: rgb(68 68 68 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  animation-duration: .3s;
}

a:visited {
  color: #444;
}

a:hover {
  --tw-text-opacity: 1;
  color: rgb(64 169 255 / var(--tw-text-opacity));
}

a:active {
  --tw-text-opacity: 1;
  color: rgb(68 68 68 / var(--tw-text-opacity));
}

p {
  --tw-text-opacity: 1;
  color: rgb(68 68 68 / var(--tw-text-opacity));
  font-size: .875rem;
  line-height: 1.25rem;
}

label {
  --tw-text-opacity: 1;
  color: rgb(68 68 68 / var(--tw-text-opacity));
  margin-top: .375rem;
  margin-bottom: .375rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

textarea {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(204 204 204 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(85 85 85 / var(--tw-text-opacity));
  --tw-shadow: inset 0 1px 1px #00000013;
  --tw-shadow-colored: inset 0 1px 1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  max-width: 100%;
  min-height: 30px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: .875rem;
  line-height: 1.25rem;
  transition: border .2s linear, box-shadow .2s linear;
}

textarea:focus {
  --tw-border-opacity: 1;
  border-color: rgb(97 143 176 / var(--tw-border-opacity));
  outline-width: 0;
}

input:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

:root {
  --background: 39 12% 98%;
  --foreground: 0, 0%, 7%;
  --card: 45 12% 95%;
  --card-foreground: 240 10% 3.9%;
  --popover: 45 12% 95%;
  --popover-foreground: 240 10% 3.9%;
  --primary: 47.9 95.8% 53.1%;
  --primary-foreground: 26 83.3% 14.1%;
  --secondary: 40, 11%, 89%;
  --secondary-foreground: 240 5.9% 10%;
  --muted: 40, 11%, 89%;
  --muted-foreground: 240 3.8% 46.1%;
  --accent: 40, 11%, 89%;
  --accent-foreground: 240 5.9% 10%;
  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 0 0% 98%;
  --border: 48, 13%, 84%;
  --input: 240 5.9% 90%;
  --ring: 240 5.9% 10%;
  --radius: .5rem;
  --sticky: #f4f3f1bf;
}

* {
  border-color: hsl(var(--border));
}

body {
  font-feature-settings: "rlig" 1, "calt" 1;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
}

@media (width >= 1400px) {
  .container {
    max-width: 1400px;
  }
}

.input-base {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(204 204 204 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(85 85 85 / var(--tw-text-opacity));
  --tw-shadow: inset 0 1px 1px #00000013;
  --tw-shadow-colored: inset 0 1px 1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 6px;
  min-height: 30px;
  max-height: 100%;
  padding-left: .375rem;
  padding-right: .375rem;
  font-size: .875rem;
  line-height: 1.25rem;
  transition: border .2s linear, box-shadow .2s linear;
}

.input-base:focus {
  --tw-border-opacity: 1;
  border-color: rgb(97 143 176 / var(--tw-border-opacity));
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.btn-success {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(76 174 76 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(92 184 92 / var(--tw-bg-opacity));
  text-align: center;
  vertical-align: middle;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: .25rem;
  padding: 6px .75rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.42857;
  text-decoration-line: none;
  display: inline-block;
}

.btn-success:focus, .btn-success:not(:disabled):hover {
  --tw-border-opacity: 1;
  border-color: rgb(57 132 57 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(68 157 68 / var(--tw-bg-opacity));
}

.btn-success:active, .btn-success.active, .open > .dropdown-toggle.btn-success, .btn-success.\!active {
  --tw-border-opacity: 1;
  border-color: rgb(57 132 57 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(68 157 68 / var(--tw-bg-opacity));
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.btn-success:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(92 184 92 / var(--tw-ring-opacity));
  --tw-ring-opacity: .5;
  outline: 2px solid #0000;
}

.btn-success:active {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.btn-success.disabled, .btn-success[disabled], fieldset[disabled] .btn-success, .btn-success.\!disabled {
  cursor: not-allowed;
  opacity: .65;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.btn-default {
  cursor: pointer;
  touch-action: manipulation;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(204 204 204 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(51 51 51 / var(--tw-text-opacity));
  border-radius: .25rem;
  flex: none;
  padding: .375rem .75rem;
  font-size: .875rem;
  line-height: 1.4;
  display: inline-block;
}

.btn-danger {
  cursor: pointer;
  touch-action: manipulation;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(212 63 58 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(217 83 79 / var(--tw-bg-opacity));
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: .25rem;
  flex: none;
  padding: .375rem .75rem;
  font-size: .875rem;
  line-height: 1.4;
  display: inline-block;
}

.plus-btn {
  cursor: pointer;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(68 68 68 / var(--tw-text-opacity));
  background-color: #ffffffe6;
  border-radius: 6px;
  padding: .375rem 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.plus-btn:hover {
  background-color: #ffffffb3;
}

.plus-btn:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.btn-sm {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(204 204 204 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(51 51 51 / var(--tw-text-opacity));
  border-radius: 6px;
  padding: 2px .625rem;
  font-size: .75rem;
  line-height: 1.9;
}

.btn-sm:hover {
  --tw-border-opacity: 1;
  border-color: rgb(173 173 173 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(224 224 224 / var(--tw-bg-opacity));
}

.delete-button, .edit-button, .lock-button, .add-button {
  white-space: nowrap;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(204 204 204 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(51 51 51 / var(--tw-text-opacity));
  padding: 2px .625rem;
  font-size: .75rem;
  line-height: 1rem;
}

.delete-button:hover, .edit-button:hover, .lock-button:hover, .add-button:hover {
  --tw-border-opacity: 1;
  border-color: rgb(173 173 173 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(224 224 224 / var(--tw-bg-opacity));
}

.btn-primary {
  cursor: pointer;
  touch-action: manipulation;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(53 126 189 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(66 139 202 / var(--tw-bg-opacity));
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: .25rem;
  flex: none;
  padding: .375rem .75rem;
  font-size: .875rem;
  line-height: 1.4;
  display: inline-block;
}

.btn-primary:hover {
  --tw-border-opacity: 1;
  border-color: rgb(40 94 142 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(48 113 169 / var(--tw-bg-opacity));
}

.btn-connect {
  cursor: pointer;
  touch-action: manipulation;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  text-align: center;
  border-radius: .25rem;
  flex: none;
  padding: .375rem .75rem;
  font-size: .875rem;
  line-height: 1.4;
  display: inline-block;
}

.btn-info {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(70 184 218 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(91 192 222 / var(--tw-bg-opacity));
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: .25rem;
  padding: .375rem .75rem;
}

.btn-info:hover {
  --tw-border-opacity: 1;
  border-color: rgb(38 154 188 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(49 176 213 / var(--tw-bg-opacity));
}

.coincraft-table {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 9px 7px 17px -4px #ccc;
  --tw-shadow-colored: 9px 7px 17px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  font-size: 13px;
  line-height: 1.25rem;
}

.coincraft-table-row {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-bottom-color: rgb(238 238 238 / var(--tw-border-opacity));
  display: flex;
}

.coincraft-table-row.header {
  --tw-bg-opacity: 1;
  background-color: rgb(255 199 0 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(85 85 85 / var(--tw-text-opacity));
  border-bottom-width: 0;
  font-weight: 700;
}

.coincraft-table-row.header .coincraft-table-cell {
  border-right-width: 0;
}

.coincraft-table-row.header .coincraft-table-cell:first-child {
  border-left-width: 0;
}

.coincraft-table-sub-section {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-color: rgb(204 204 204 / var(--tw-border-opacity));
  border-bottom-color: rgb(204 204 204 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  --tw-shadow: inset 0px 8px 12px -4px #0000001a;
  --tw-shadow-colored: inset 0px 8px 12px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.coincraft-table-sub-section .coincraft-table-row {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-bottom-color: rgb(221 221 221 / var(--tw-border-opacity));
  background-color: #0000;
}

.coincraft-table-sub-section .coincraft-table-row:last-child {
  border-bottom-width: 0;
}

.coincraft-table-cell {
  cursor: default;
  --tw-border-opacity: 1;
  border-right-width: 1px;
  border-color: rgb(238 238 238 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(119 119 119 / var(--tw-text-opacity));
  padding: .5em;
  line-height: 1.25rem;
  font-size: 13px !important;
}

.coincraft-table-cell:first-child {
  --tw-border-opacity: 1;
  border-left-width: 1px;
  border-color: rgb(221 221 221 / var(--tw-border-opacity));
}

.coincraft-table-cell:last-child {
  border-right-width: 0;
}

.coincraft-table-cell.coincraft-table-cell--duration {
  min-height: 100%;
  padding: 0 !important;
}

.coincraft-table-cell.selected {
  z-index: 5;
  outline: 4px solid #379;
}

.coincraft-table-cell.text-link, .coincraft-table-cell .text-link {
  cursor: pointer;
  text-decoration-line: underline;
}

.coincraft-table-cell.text-link:hover, .coincraft-table-cell .text-link:hover {
  --tw-text-opacity: 1;
  color: rgb(68 68 68 / var(--tw-text-opacity));
}

.coincraft-table-cell--text, .coincraft-table-cell--number, .coincraft-table-cell--percent, .coincraft-table-cell--currency, .coincraft-table-cell--textarea, .coincraft-table-cell--date, .coincraft-table-cell--durationMinutes {
  --tw-text-opacity: 1;
  color: rgb(68 68 68 / var(--tw-text-opacity));
  --tw-shadow: inset 6px 6px 13px -10px #00000038;
  --tw-shadow-colored: inset 6px 6px 13px -10px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: #00000003;
}

.coincraft-table-cell--text:focus-within, .coincraft-table-cell--number:focus-within, .coincraft-table-cell--percent:focus-within, .coincraft-table-cell--currency:focus-within, .coincraft-table-cell--textarea:focus-within, .coincraft-table-cell--date:focus-within, .coincraft-table-cell--durationMinutes:focus-within {
  z-index: 5;
  outline: 4px solid #379;
}

.coincraft-table-cell--text:hover, .coincraft-table-cell--number:hover, .coincraft-table-cell--percent:hover, .coincraft-table-cell--currency:hover, .coincraft-table-cell--textarea:hover, .coincraft-table-cell--date:hover, .coincraft-table-cell--durationMinutes:hover, .coincraft-table-cell--text .selected, .coincraft-table-cell--number .selected, .coincraft-table-cell--percent .selected, .coincraft-table-cell--currency .selected, .coincraft-table-cell--textarea .selected, .coincraft-table-cell--date .selected, .coincraft-table-cell--durationMinutes .selected {
  --tw-shadow: 1px 1px 10px #0003;
  --tw-shadow-colored: 1px 1px 10px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: #0000;
}

.coincraft-table-cell--text, .coincraft-table-cell--number, .coincraft-table-cell--percent, .coincraft-table-cell--currency, .coincraft-table-cell--durationMinutes {
  cursor: text;
  justify-content: space-between;
  align-items: center;
  padding: .25em;
  display: flex;
}

.coincraft-table-cell--text, .coincraft-table-cell--number, .coincraft-table-cell--percent, .coincraft-table-cell--currency, .coincraft-table-cell--durationMinutes .coincraft-table-cell__symbol {
  flex: none;
  padding: .25em;
}

.coincraft-table-cell__text, .coincraft-table-cell__number, .coincraft-table-cell__percent, .coincraft-table-cell__currency, .coincraft-table-cell--durationMinutes {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: #0000;
  border-width: 0;
  flex: 1;
  width: 100%;
  min-width: 0;
  height: 100%;
}

.coincraft-table-cell__text:focus, .coincraft-table-cell__number:focus, .coincraft-table-cell__percent:focus, .coincraft-table-cell__currency:focus, .coincraft-table-cell--durationMinutes:focus {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.rw-input {
  --tw-text-opacity: 1 !important;
  color: rgb(85 85 85 / var(--tw-text-opacity)) !important;
  font-size: .875rem !important;
  line-height: 1.25rem !important;
}

.coincraft-table-cell--tax-dropdown, .coincraft-table-cell--dropdown, .coincraft-table-cell--bool, .coincraft-table-cell--project, .coincraft-table-cell--phase, .coincraft-table-cell--staff, .coincraft-table-cell--role, .coincraft-table-cell--contact, .coincraft-table-cell--costCentre, .coincraft-table-cell--staffOrRole, .coincraft-table-cell--status {
  cursor: pointer;
  padding: 0;
}

.coincraft-table-cell--tax-dropdown:hover, .coincraft-table-cell--dropdown:hover, .coincraft-table-cell--bool:hover, .coincraft-table-cell--project:hover, .coincraft-table-cell--phase:hover, .coincraft-table-cell--staff:hover, .coincraft-table-cell--role:hover, .coincraft-table-cell--contact:hover, .coincraft-table-cell--costCentre:hover, .coincraft-table-cell--staffOrRole:hover, .coincraft-table-cell--status:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity));
}

.coincraft-table-cell--tax-dropdown, .coincraft-table-cell--dropdown, .coincraft-table-cell--bool, .coincraft-table-cell--project, .coincraft-table-cell--phase, .coincraft-table-cell--staff, .coincraft-table-cell--role, .coincraft-table-cell--contact, .coincraft-table-cell--costCentre, .coincraft-table-cell--staffOrRole, .coincraft-table-cell--status > button {
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  align-items: center;
  width: 100%;
  display: flex;
}

.coincraft-table-cell--btn button, .coincraft-table-cell--button button, .coincraft-table-cell__button {
  cursor: pointer;
  background-color: #0000;
  border-width: 0;
  width: 100%;
  height: 100%;
}

.coincraft-table-cell--btn button:hover, .coincraft-table-cell--button button:hover, .coincraft-table-cell__button:hover {
  background-color: #0000;
}

.coincraft-table-cell--text {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.coincraft-table-cell--text * {
  padding-left: .5em;
}

input[type="text"]:focus {
  outline-offset: 0px !important;
}

.scheduling-tool .modal-body, .info-panel .modal-body {
  padding: 0;
}

.scheduling-tool .inner-modal .modal-body {
  padding: 1rem;
}

.alert {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  border-radius: .25rem;
  margin-bottom: 1.25rem;
  padding: 1rem;
  font-size: .875rem !important;
  line-height: 1.25rem !important;
}

.calendar .calendar-item {
  z-index: 0;
  -webkit-user-select: none;
  user-select: none;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-width: 1px;
  border-color: #ffffffbf;
  border-radius: 6px;
  padding: .375rem;
  font-size: 1em;
  font-weight: 600;
  position: absolute;
  overflow: hidden;
}

.calendar .calendar-item:hover {
  z-index: 2;
}

.calendar .calendar-item.allocation, .calendar .calendar-item.allocation .time {
  --tw-bg-opacity: 1;
  background-color: rgb(102 102 102 / var(--tw-bg-opacity));
}

.calendar .calendar-item:after {
  z-index: -1;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-content: "";
  content: var(--tw-content);
  border-radius: 6px;
  display: block;
  position: absolute;
  inset: -1px;
}

.calendar .calendar-item .time {
  width: 100%;
  padding: .25rem .75rem;
  font-size: 11px;
  font-weight: 700;
  position: absolute;
  bottom: 0;
  left: 0;
}

.calendar .calendar-item.selected, .calendar .calendar-item.selected .time {
  --tw-bg-opacity: 1;
  background-color: rgb(0 193 255 / var(--tw-bg-opacity));
}

.calendar .calendar-item.selected.allocation, .calendar .calendar-item.selected.allocation .time {
  --tw-bg-opacity: 1;
  background-color: rgb(99 139 171 / var(--tw-bg-opacity));
}

.timesheet-widget__row {
  text-align: center;
  min-width: 36em;
  margin-bottom: .8em;
  font-size: 1.25em;
  line-height: 1.5;
}

@media (width >= 768px) {
  .timesheet-widget__row {
    font-size: 1.3em;
  }
}

@media (width >= 1024px) {
  .timesheet-widget__row {
    font-size: 1.4em;
  }
}

@media (width >= 1280px) {
  .timesheet-widget__row {
    font-size: 1.5em;
  }
}

.timesheet-widget__row__inner {
  text-align: left;
  width: 92%;
  display: inline-block;
}

.timesheet-widget__task-row {
  align-items: center;
  display: flex;
}

.time-entry {
  font-size: 1em;
  position: relative;
}

.timesheet-widget__phase-title {
  align-items: center;
  display: flex;
}

.timesheet-widget__left-section {
  width: 20%;
  display: inline-block;
}

@media (width >= 768px) {
  .timesheet-widget__left-section {
    width: 25%;
  }
}

@media (width >= 1024px) {
  .timesheet-widget__left-section {
    width: 30%;
  }
}

.timesheet-widget__middle-section {
  width: 75%;
  display: inline-block;
}

@media (width >= 1381px) {
  .timesheet-widget__middle-section {
    width: 70%;
  }
}

.timesheet-widget__right-section {
  width: 5%;
  display: inline-block;
}

.time-entry__note {
  padding: .25rem;
  font-size: .75em;
}

.time-entry--daily, .time-entry--daily__note {
  white-space: nowrap;
  align-items: center;
  display: flex;
}

.time-entry:hover .time-entry__input, .time-entry--daily:hover .time-entry__input, .time-entry__note-input {
  outline: 1px solid #ddd;
}

.time-entry:hover .time-entry__note {
  visibility: visible;
}

.time-entry__input {
  box-sizing: border-box;
  text-align: right;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-style: none;
  outline: 1px solid #ccc;
  width: 3.4em;
  margin: .25rem 2px;
  padding-right: .25rem;
  line-height: 1.5;
  min-height: 1.25rem !important;
  max-height: 1.25rem !important;
  font-size: .875rem !important;
  line-height: 1.25rem !important;
}

.time-entry__input:focus {
  outline-color: #ccc !important;
}

.time-entry.locked {
  cursor: not-allowed;
}

.time-entry.locked span {
  margin-right: .5em;
  font-size: .8em;
}

.time-entry__note, .timesheet-widget__task-delete, .timesheet-widget__task-edit, .timesheet-widget__task-lock {
  cursor: pointer;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(204 204 204 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(119 119 119 / var(--tw-text-opacity));
  border-radius: .25rem;
}

.time-entry__note:hover, .timesheet-widget__task-delete:hover, .timesheet-widget__task-edit:hover, .timesheet-widget__task-lock:hover {
  --tw-border-opacity: 1;
  border-color: rgb(170 170 170 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(51 51 51 / var(--tw-text-opacity));
  --tw-bg-opacity: 1 !important;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity)) !important;
}

.time-entry__note i, .timesheet-widget__task-delete i, .timesheet-widget__task-edit i, .timesheet-widget__task-lock i {
  margin: .2em;
}

.timesheet-widget__task-edit, .timesheet-widget__task-lock {
  margin-right: 1em;
}

.time-entry__note {
  font-size: .6em;
}

.timesheet-widget__task-delete, .timesheet-widget__task-edit, .timesheet-widget__task-lock {
  margin-right: .4em;
  padding: .1em;
}

.timesheet-widget__task-delete i, .timesheet-widget__task-edit i, .timesheet-widget__task-lock i {
  margin: 0;
}

.timesheet-widget__task-title {
  align-items: center;
  font-size: .7em;
  font-style: italic;
  display: inline-flex;
}

.timesheet-widget__task-row {
  --tw-border-opacity: 1;
  border-color: #0000;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: rgb(221 221 221 / var(--tw-border-opacity));
  padding: 1px;
}

.timesheet-widget__task-row:hover {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: rgb(170 170 170 / var(--tw-border-opacity));
}

.timesheet-widget__task-row:hover .timesheet-widget__task-delete {
  visibility: visible;
}

.timesheet-widget__progress-bar {
  text-align: center;
  padding: 0;
  font-size: .75em;
}

.time-entry.weekend {
  opacity: .6;
}

.time-entry.weekend:hover {
  opacity: 1;
}

.time-entry__note-input {
  resize: none;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-style: none;
  flex-grow: 1;
  width: 100%;
  min-height: 0;
  margin: 2px 1em;
  padding: .25rem;
  font-size: 1em;
  overflow: hidden;
}

.time-entry__note-input:focus {
  outline: 1px solid #ccc;
  margin: 2px .9em;
}

@media screen and (-webkit-device-pixel-ratio >= 0) {
  .time-entry__note-input:focus {
    outline: 1px solid #ccc;
    margin: 0 .83em;
    padding: .375rem !important;
  }
}

.time-entry--daily__note {
  --tw-text-opacity: 1;
  color: rgb(136 136 136 / var(--tw-text-opacity));
  flex-grow: 1;
  font-size: .7em;
  position: relative;
}

.time-entry--daily__note i {
  flex-grow: 0;
  width: 1em;
}

.time-entry__input {
  flex-grow: 0;
}

.time-entry--daily {
  width: 100%;
}

.time-entry__note-input::-webkit-input-placeholder {
  --tw-text-opacity: 1;
  color: rgb(170 170 170 / var(--tw-text-opacity));
  font-style: italic;
}

.time-entry__note-input:-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgb(170 170 170 / var(--tw-text-opacity));
  opacity: 1;
  font-style: italic;
}

.time-entry__note-input::-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgb(170 170 170 / var(--tw-text-opacity));
  opacity: 1;
  font-style: italic;
}

.time-entry__note-input:-ms-input-placeholder {
  --tw-text-opacity: 1;
  color: rgb(170 170 170 / var(--tw-text-opacity));
  font-style: italic;
}

.time-entry__date-hover {
  z-index: 2;
  --tw-border-opacity: 1;
  border-top-width: 2px;
  border-color: rgb(102 102 102 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(102 102 102 / var(--tw-text-opacity));
  opacity: 0;
  width: 4em;
  margin-top: .5em;
  padding: 1px .25rem .25rem;
  font-size: .75em;
  font-style: italic;
  display: none;
  position: absolute;
}

.time-entry:hover .time-entry__date-hover {
  visibility: visible;
  opacity: 1;
}

.time-entry__time-options-hover {
  z-index: 2;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(170 170 170 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(102 102 102 / var(--tw-text-opacity));
  opacity: 0;
  --tw-shadow: 1px 1px 4px #0003;
  --tw-shadow-colored: 1px 1px 4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  margin-top: 1em;
  margin-left: -2em;
  padding: 1px .25rem .25rem;
  font-style: italic;
  display: none;
  position: absolute;
}

.time-entry__time-options:hover .time-entry__time-options-hover {
  white-space: nowrap;
  --tw-text-opacity: 1;
  color: rgb(34 34 34 / var(--tw-text-opacity));
  opacity: 1;
  display: block;
}

.timesheet-widget__tasks-block:hover .timesheet-widget__task-row {
  opacity: .7;
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  animation-duration: .2s;
}

.timesheet-widget__task-row:hover {
  --tw-text-opacity: 1;
  color: rgb(34 34 34 / var(--tw-text-opacity));
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  animation-duration: .2s;
  opacity: 1 !important;
}

.timesheet-widget__task-row:hover input, .timesheet-widget__task-row:hover textarea {
  --tw-text-opacity: 1;
  color: rgb(34 34 34 / var(--tw-text-opacity));
}

.timesheet-widget__task-row:hover + .timesheet-widget__task-row {
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  animation-duration: .2s;
  opacity: .5 !important;
}

.timesheets__navigation {
  align-items: center;
  display: flex;
}

.timesheets__day-nav-button {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(204 204 204 / var(--tw-border-opacity));
  margin: 1px;
}

.timesheets__day-nav-button:hover {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(255 199 0 / var(--tw-border-opacity));
  margin: 0;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.timesheets__day-nav-button:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.timesheets__day-nav-button.selected, .timesheets__day-nav-button.selected:hover {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(255 199 0 / var(--tw-border-opacity));
  margin: 0;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.coincraft-table-cell .fa-caret-left {
  margin-right: 10px;
}

.invoice-page {
  box-sizing: border-box;
  white-space: pre-line;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  text-align: left;
  --tw-shadow: .25em .25em 1em #4b4b4b33;
  --tw-shadow-colored: .25em .25em 1em var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  flex-direction: column;
  width: 21cm;
  height: 29.7cm;
  margin: 1em;
  padding: 1cm;
  font-size: .75rem;
  display: inline-flex;
  overflow: hidden;
}

.invoice-page * {
  font-size: .75rem;
}

.invoice-page .inv-bold {
  --tw-text-opacity: 1;
  color: rgb(68 68 68 / var(--tw-text-opacity));
  font-weight: 600;
}

.invoice-page > .header {
  text-transform: uppercase;
  grid-template-areas: ". logo logo"
                       "project logo logo"
                       "project logo logo"
                       "client logo logo"
                       "client issue ."
                       "number due amount";
  margin-bottom: 2em;
  padding: 1em;
  display: grid;
}

.invoice-page > .header .logo {
  grid-area: logo;
  place-self: flex-start end;
  margin: 0;
}

.invoice-page > .header .project-info {
  grid-area: project;
  align-self: flex-end;
  margin-bottom: 1em;
  margin-right: 2em;
  line-height: 1.5em;
}

.invoice-page > .header .client-info {
  grid-area: client;
  align-self: flex-end;
  margin-bottom: .75em;
  margin-right: 2em;
  line-height: 1.5em;
}

.invoice-page > .header .issue-date {
  grid-area: issue;
  align-self: flex-end;
  margin-bottom: .75em;
  margin-right: 2em;
  line-height: 1.5em;
}

.invoice-page > .header .inv-number {
  grid-area: number;
}

.invoice-page > .header .due-date {
  grid-area: due;
}

.invoice-page > .header .amount-due {
  grid-area: amount;
}

.invoice-page > .header .inv-highlight {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: rgb(68 68 68 / var(--tw-border-opacity));
  text-transform: uppercase;
  padding: .75em 0;
}

.invoice-page > .header .inv-highlight:not(:last-child) {
  margin-right: 2em;
}

.invoice-page > .header .inv-highlight .label {
  --tw-text-opacity: 1;
  color: rgb(68 68 68 / var(--tw-text-opacity));
  font-size: 1.2em;
  font-weight: 600;
}

.invoice-page > .header .inv-highlight .value, .invoice-page > .header .inv-highlight .\!value {
  font-size: 2em;
}

@media print {
  .invoice-page {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    width: 100vw;
    height: 100vh;
    margin: 0;
    display: flex;
  }
}

.invoice-page .invoice-table {
  display: grid;
}

.invoice-page .invoice-table .cell {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgb(68 68 68 / var(--tw-border-opacity));
  padding: .75em;
}

.invoice-page .invoice-table .cell.last {
  border-bottom-width: 0;
}

.invoice-page .invoice-table .cell.header {
  --tw-bg-opacity: 1;
  background-color: rgb(68 68 68 / var(--tw-bg-opacity));
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-size: 1.1em;
}

.invoice-page .invoice-table .cell.right {
  text-align: right;
}

.invoice-page .invoice-table .cell.total, .invoice-page .invoice-table .cell.\!total {
  font-size: 1.1em;
}

.invoice-page .invoice-table .cell.total.label, .invoice-page .invoice-table .cell.\!total.label {
  text-transform: uppercase;
  padding-right: 3em;
}

.invoice-page .invoice-table .cell.total.label {
  grid-column: 2 / 4;
}

.invoice-page .invoice-table .cell.\!total.label {
  grid-column: 2 / 4 !important;
}

.invoice-page .invoice-table .cell.total.amount {
  grid-column: 4 / 5;
}

.invoice-page .invoice-table .cell.\!total.amount {
  grid-column: 4 / 5 !important;
}

.invoice-page .invoice-table .cell.total.last, .invoice-page .invoice-table .cell.\!total.last {
  border-bottom-width: 0;
  font-weight: 700;
}

.invoice-page .invoice-table .cell.add-item {
  border-bottom-width: 0;
}

.invoice-page .invoice-table.invoice-table-totals {
  --tw-border-opacity: 1;
  border-top-width: 2px;
  border-color: rgb(68 68 68 / var(--tw-border-opacity));
}

.invoice-page .ordering {
  font-size: 1.2em;
  position: absolute;
  top: .8em;
  left: -.7em;
}

.invoice-page .ordering .arrow {
  cursor: pointer;
  opacity: .25;
  margin: 0;
  padding: 0;
  position: absolute;
}

.invoice-page .ordering .arrow:hover {
  opacity: .8;
}

.invoice-page .ordering .arrow.up {
  top: -.35em;
}

.invoice-page .ordering .arrow.down {
  top: .35em;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.left-1 {
  left: .25rem;
}

.left-2 {
  left: .5rem;
}

.left-\[1em\] {
  left: 1em;
}

.left-\[50\%\] {
  left: 50%;
}

.right-0 {
  right: 0;
}

.right-1 {
  right: .25rem;
}

.right-1\.5 {
  right: .375rem;
}

.right-11 {
  right: 2.75rem;
}

.right-4 {
  right: 1rem;
}

.right-full {
  right: 100%;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.top-14 {
  top: 3.5rem;
}

.top-2\.5 {
  top: .625rem;
}

.top-4 {
  top: 1rem;
}

.top-\[2\.5em\] {
  top: 2.5em;
}

.top-\[50\%\] {
  top: 50%;
}

.top-\[8em\] {
  top: 8em;
}

.top-\[calc\(50\%-0\.5em\)\] {
  top: calc(50% - .5em);
}

.top-full {
  top: 100%;
}

.z-10 {
  z-index: 10;
}

.z-50 {
  z-index: 50;
}

.z-\[10003\] {
  z-index: 10003;
}

.z-\[1000\] {
  z-index: 1000;
}

.z-\[1001\] {
  z-index: 1001;
}

.m-0 {
  margin: 0;
}

.m-\[0\.5em\] {
  margin: .5em;
}

.m-px {
  margin: 1px;
}

.\!my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.-mx-1 {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-\[1em\] {
  margin-left: 1em;
  margin-right: 1em;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-\[5em\] {
  margin-top: 5em;
  margin-bottom: 5em;
}

.-ml-3 {
  margin-left: -.75rem;
}

.-mt-2 {
  margin-top: -.5rem;
}

.-mt-32 {
  margin-top: -8rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-2\.5 {
  margin-bottom: .625rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-\[0\.5em\] {
  margin-bottom: .5em;
}

.mb-\[0\.8em\] {
  margin-bottom: .8em;
}

.mb-\[1em\] {
  margin-bottom: 1em;
}

.mb-\[7px\] {
  margin-bottom: 7px;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-2\.5 {
  margin-left: .625rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-\[1em\] {
  margin-left: 1em;
}

.ml-\[2em\] {
  margin-left: 2em;
}

.ml-\[5em\] {
  margin-left: 5em;
}

.ml-\[7\.2em\] {
  margin-left: 7.2em;
}

.ml-auto {
  margin-left: auto;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-2\.5 {
  margin-right: .625rem;
}

.mr-\[0\.5em\] {
  margin-right: .5em;
}

.mr-auto {
  margin-right: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-1\.5 {
  margin-top: .375rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-3\.5 {
  margin-top: .875rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[0\.3em\] {
  margin-top: .3em;
}

.mt-\[0\.75em\] {
  margin-top: .75em;
}

.mt-\[1\.5em\] {
  margin-top: 1.5em;
}

.mt-\[1em\] {
  margin-top: 1em;
}

.mt-\[2em\] {
  margin-top: 2em;
}

.mt-\[2px\] {
  margin-top: 2px;
}

.mt-\[3em\] {
  margin-top: 3em;
}

.mt-\[4em\] {
  margin-top: 4em;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.\!table {
  display: table !important;
}

.table {
  display: table;
}

.table-cell {
  display: table-cell;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1;
}

.size-3 {
  width: .75rem;
  height: .75rem;
}

.size-3\.5 {
  width: .875rem;
  height: .875rem;
}

.size-4 {
  width: 1rem;
  height: 1rem;
}

.size-7 {
  width: 1.75rem;
  height: 1.75rem;
}

.size-8 {
  width: 2rem;
  height: 2rem;
}

.size-\[10em\] {
  width: 10em;
  height: 10em;
}

.size-\[30px\] {
  width: 30px;
  height: 30px;
}

.size-full {
  width: 100%;
  height: 100%;
}

.\!h-6 {
  height: 1.5rem !important;
}

.\!h-full {
  height: 100% !important;
}

.h-1 {
  height: .25rem;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-2 {
  height: .5rem;
}

.h-2\.5 {
  height: .625rem;
}

.h-24 {
  height: 6rem;
}

.h-28 {
  height: 7rem;
}

.h-3 {
  height: .75rem;
}

.h-3\.5 {
  height: .875rem;
}

.h-4 {
  height: 1rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-\[0\.5em\] {
  height: .5em;
}

.h-\[1px\] {
  height: 1px;
}

.h-\[22px\] {
  height: 22px;
}

.h-\[53px\] {
  height: 53px;
}

.h-\[var\(--radix-select-trigger-height\)\] {
  height: var(--radix-select-trigger-height);
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-px {
  height: 1px;
}

.h-screen {
  height: 100vh;
}

.max-h-96 {
  max-height: 24rem;
}

.max-h-\[22px\] {
  max-height: 22px;
}

.max-h-\[300px\] {
  max-height: 300px;
}

.max-h-\[50rem\] {
  max-height: 50rem;
}

.max-h-\[75\%\] {
  max-height: 75%;
}

.max-h-max {
  max-height: max-content;
}

.\!min-h-9 {
  min-height: 2.25rem !important;
}

.min-h-0 {
  min-height: 0;
}

.min-h-32 {
  min-height: 8rem;
}

.\!w-1\/2 {
  width: 50% !important;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/4 {
  width: 25%;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: .5rem;
}

.w-2\.5 {
  width: .625rem;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-3 {
  width: .75rem;
}

.w-3\.5 {
  width: .875rem;
}

.w-3\/4 {
  width: 75%;
}

.w-4 {
  width: 1rem;
}

.w-40 {
  width: 10rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-60 {
  width: 15rem;
}

.w-64 {
  width: 16rem;
}

.w-7 {
  width: 1.75rem;
}

.w-72 {
  width: 18rem;
}

.w-8 {
  width: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.w-\[0\.5em\] {
  width: .5em;
}

.w-\[1040px\] {
  width: 1040px;
}

.w-\[12\.5rem\] {
  width: 12.5rem;
}

.w-\[12em\] {
  width: 12em;
}

.w-\[150px\] {
  width: 150px;
}

.w-\[18em\] {
  width: 18em;
}

.w-\[1px\] {
  width: 1px;
}

.w-\[200px\] {
  width: 200px;
}

.w-\[20em\] {
  width: 20em;
}

.w-\[24\%\] {
  width: 24%;
}

.w-\[30\%\] {
  width: 30%;
}

.w-\[325px\] {
  width: 325px;
}

.w-\[35\%\] {
  width: 35%;
}

.w-\[350px\] {
  width: 350px;
}

.w-\[4\.5rem\] {
  width: 4.5rem;
}

.w-\[4em\] {
  width: 4em;
}

.w-\[4rem\] {
  width: 4rem;
}

.w-\[50px\] {
  width: 50px;
}

.w-\[70\%\] {
  width: 70%;
}

.w-\[9em\] {
  width: 9em;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.min-w-0 {
  min-width: 0;
}

.min-w-\[36em\] {
  min-width: 36em;
}

.min-w-\[8rem\] {
  min-width: 8rem;
}

.min-w-\[var\(--radix-select-trigger-width\)\] {
  min-width: var(--radix-select-trigger-width);
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-\[1040px\] {
  max-width: 1040px;
}

.max-w-\[60em\] {
  max-width: 60em;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-max {
  max-width: max-content;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1;
}

.flex-\[0_0_0\%\] {
  flex: 0 0;
}

.flex-auto {
  flex: auto;
}

.flex-none {
  flex: none;
}

.flex-shrink-0, .shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.grow {
  flex-grow: 1;
}

.basis-auto {
  flex-basis: auto;
}

.caption-bottom {
  caption-side: bottom;
}

.border-collapse {
  border-collapse: collapse;
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[-50\%\] {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[-50\%\] {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-text {
  cursor: text;
}

.touch-none {
  touch-action: none;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize {
  resize: both;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.content-start {
  align-content: flex-start;
}

.content-end {
  align-content: flex-end;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: .25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-2\.5 {
  gap: .625rem;
}

.gap-3\.5 {
  gap: .875rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-\[0\.5em\] {
  gap: .5em;
}

.gap-\[2px\] {
  gap: 2px;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.375rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.625rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.625rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-y-\[1em\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1em * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1em * var(--tw-space-y-reverse));
}

.self-center {
  align-self: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.\!rounded-none {
  border-radius: 0 !important;
}

.rounded {
  border-radius: .25rem;
}

.rounded-\[0\.5em\] {
  border-radius: .5em;
}

.rounded-\[6px\] {
  border-radius: 6px;
}

.rounded-\[inherit\] {
  border-radius: inherit;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: var(--radius);
}

.rounded-md {
  border-radius: calc(var(--radius)  - 2px);
}

.rounded-sm {
  border-radius: calc(var(--radius)  - 4px);
}

.rounded-l-\[0\.5em\] {
  border-top-left-radius: .5em;
  border-bottom-left-radius: .5em;
}

.rounded-bl-none {
  border-bottom-left-radius: 0;
}

.rounded-br-none {
  border-bottom-right-radius: 0;
}

.rounded-tl-none {
  border-top-left-radius: 0;
}

.rounded-tr-none {
  border-top-right-radius: 0;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-l {
  border-left-width: 1px;
}

.border-r-0 {
  border-right-width: 0;
}

.border-t {
  border-top-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.\!border-none {
  border-style: none !important;
}

.border-none {
  border-style: none;
}

.border-\[\#357ebd\] {
  --tw-border-opacity: 1;
  border-color: rgb(53 126 189 / var(--tw-border-opacity));
}

.border-\[\#666\] {
  --tw-border-opacity: 1;
  border-color: rgb(102 102 102 / var(--tw-border-opacity));
}

.border-\[\#aaa\] {
  --tw-border-opacity: 1;
  border-color: rgb(170 170 170 / var(--tw-border-opacity));
}

.border-\[\#ccc\] {
  --tw-border-opacity: 1;
  border-color: rgb(204 204 204 / var(--tw-border-opacity));
}

.border-\[\#d9d9d9\] {
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity));
}

.border-\[\#ddd\] {
  --tw-border-opacity: 1;
  border-color: rgb(221 221 221 / var(--tw-border-opacity));
}

.border-\[\#e5e5e5\] {
  --tw-border-opacity: 1;
  border-color: rgb(229 229 229 / var(--tw-border-opacity));
}

.border-\[\#eee\] {
  --tw-border-opacity: 1;
  border-color: rgb(238 238 238 / var(--tw-border-opacity));
}

.border-border {
  border-color: hsl(var(--border));
}

.border-danger {
  --tw-border-opacity: 1;
  border-color: rgb(217 83 79 / var(--tw-border-opacity));
}

.border-gold {
  --tw-border-opacity: 1;
  border-color: rgb(255 199 0 / var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-input {
  border-color: hsl(var(--input));
}

.border-primary {
  border-color: hsl(var(--primary));
}

.border-transparent {
  border-color: #0000;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-b-\[\#ccc\] {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(204 204 204 / var(--tw-border-opacity));
}

.border-b-\[\#ddd\] {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(221 221 221 / var(--tw-border-opacity));
}

.border-b-\[rgb\(255\,211\,51\)\] {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(255 211 51 / var(--tw-border-opacity));
}

.border-l-transparent {
  border-left-color: #0000;
}

.border-t-\[\#d9d9d9\] {
  --tw-border-opacity: 1;
  border-top-color: rgb(217 217 217 / var(--tw-border-opacity));
}

.border-t-\[\#ddd\] {
  --tw-border-opacity: 1;
  border-top-color: rgb(221 221 221 / var(--tw-border-opacity));
}

.border-t-\[rgb\(255\,233\,153\)\] {
  --tw-border-opacity: 1;
  border-top-color: rgb(255 233 153 / var(--tw-border-opacity));
}

.border-t-transparent {
  border-top-color: #0000;
}

.\!bg-transparent {
  background-color: #0000 !important;
}

.bg-444 {
  --tw-bg-opacity: 1;
  background-color: rgb(68 68 68 / var(--tw-bg-opacity));
}

.bg-\[\#009a81\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 154 129 / var(--tw-bg-opacity));
}

.bg-\[\#428bca\] {
  --tw-bg-opacity: 1;
  background-color: rgb(66 139 202 / var(--tw-bg-opacity));
}

.bg-\[\#444\] {
  --tw-bg-opacity: 1;
  background-color: rgb(68 68 68 / var(--tw-bg-opacity));
}

.bg-\[\#666\] {
  --tw-bg-opacity: 1;
  background-color: rgb(102 102 102 / var(--tw-bg-opacity));
}

.bg-\[\#f0f0f0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(240 240 240 / var(--tw-bg-opacity));
}

.bg-\[\#f15a29\] {
  --tw-bg-opacity: 1;
  background-color: rgb(241 90 41 / var(--tw-bg-opacity));
}

.bg-\[\#f9dd72\] {
  --tw-bg-opacity: 1;
  background-color: rgb(249 221 114 / var(--tw-bg-opacity));
}

.bg-\[\#fafafa\] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.bg-\[rgb\(255\,222\,102\)\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 222 102 / var(--tw-bg-opacity));
}

.bg-accent {
  background-color: hsl(var(--accent));
}

.bg-background {
  background-color: hsl(var(--background));
}

.bg-black\/50 {
  background-color: #00000080;
}

.bg-black\/80 {
  background-color: #000c;
}

.bg-border {
  background-color: hsl(var(--border));
}

.bg-danger {
  --tw-bg-opacity: 1;
  background-color: rgb(217 83 79 / var(--tw-bg-opacity));
}

.bg-destructive {
  background-color: hsl(var(--destructive));
}

.bg-gold {
  --tw-bg-opacity: 1;
  background-color: rgb(255 199 0 / var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-muted {
  background-color: hsl(var(--muted));
}

.bg-muted\/50 {
  background-color: hsl(var(--muted) / .5);
}

.bg-neutral-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.bg-popover {
  background-color: hsl(var(--popover));
}

.bg-primary {
  background-color: hsl(var(--primary));
}

.bg-secondary {
  background-color: hsl(var(--secondary));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-white\/10 {
  background-color: #ffffff1a;
}

.bg-white\/90 {
  background-color: #ffffffe6;
}

.bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 204 21 / var(--tw-bg-opacity));
}

.bg-highlighted {
  background-image: linear-gradient(#ffd84c, #ffe380);
}

.fill-current {
  fill: currentColor;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-3 {
  padding: .75rem;
}

.p-3\.5 {
  padding: .875rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.p-\[0\.5em\] {
  padding: .5em;
}

.p-\[1\.5em\] {
  padding: 1.5em;
}

.p-\[1em\] {
  padding: 1em;
}

.p-\[1px\] {
  padding: 1px;
}

.p-\[2em\] {
  padding: 2em;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-3\.5 {
  padding-left: .875rem;
  padding-right: .875rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-\[0\.25em\] {
  padding-left: .25em;
  padding-right: .25em;
}

.px-\[0\.5em\] {
  padding-left: .5em;
  padding-right: .5em;
}

.px-\[1em\] {
  padding-left: 1em;
  padding-right: 1em;
}

.px-\[4px\] {
  padding-left: 4px;
  padding-right: 4px;
}

.px-\[5px\] {
  padding-left: 5px;
  padding-right: 5px;
}

.px-\[6\.5em\] {
  padding-left: 6.5em;
  padding-right: 6.5em;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-\[0\.25em\] {
  padding-top: .25em;
  padding-bottom: .25em;
}

.py-\[0\.5em\] {
  padding-top: .5em;
  padding-bottom: .5em;
}

.py-\[1em\] {
  padding-top: 1em;
  padding-bottom: 1em;
}

.py-\[2em\] {
  padding-top: 2em;
  padding-bottom: 2em;
}

.py-\[4em\] {
  padding-top: 4em;
  padding-bottom: 4em;
}

.py-\[7px\] {
  padding-top: 7px;
  padding-bottom: 7px;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-2\.5 {
  padding-bottom: .625rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-28 {
  padding-bottom: 7rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-\[0\.1em\] {
  padding-bottom: .1em;
}

.pb-\[1rem\] {
  padding-bottom: 1rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-7 {
  padding-left: 1.75rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-9 {
  padding-left: 2.25rem;
}

.pl-\[4rem\] {
  padding-left: 4rem;
}

.pr-0\.5 {
  padding-right: .125rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pr-\[0\.75em\] {
  padding-right: .75em;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-3\.5 {
  padding-top: .875rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-\[0\.2em\] {
  padding-top: .2em;
}

.pt-\[1em\] {
  padding-top: 1em;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.\!text-sm {
  font-size: .875rem !important;
  line-height: 1.25rem !important;
}

.\!text-xs {
  font-size: .75rem !important;
  line-height: 1rem !important;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-\[0\.85em\] {
  font-size: .85em;
}

.text-\[0\.8rem\] {
  font-size: .8rem;
}

.text-\[0\.95em\] {
  font-size: .95em;
}

.text-\[0\.9em\] {
  font-size: .9em;
}

.text-\[1\.2em\] {
  font-size: 1.2em;
}

.text-\[1\.4em\] {
  font-size: 1.4em;
}

.text-\[1\.75rem\] {
  font-size: 1.75rem;
}

.text-\[1\.7em\] {
  font-size: 1.7em;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[13px\] {
  font-size: 13px;
}

.text-\[40px\] {
  font-size: 40px;
}

.text-\[45px\] {
  font-size: 45px;
}

.text-\[length\:inherit\] {
  font-size: inherit;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-\[14px\] {
  line-height: 14px;
}

.leading-\[2em\] {
  line-height: 2em;
}

.leading-none {
  line-height: 1;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.tracking-widest {
  letter-spacing: .1em;
}

.\!text-444 {
  --tw-text-opacity: 1 !important;
  color: rgb(68 68 68 / var(--tw-text-opacity)) !important;
}

.\!text-\[\#333\] {
  --tw-text-opacity: 1 !important;
  color: rgb(51 51 51 / var(--tw-text-opacity)) !important;
}

.\!text-\[\#555\] {
  --tw-text-opacity: 1 !important;
  color: rgb(85 85 85 / var(--tw-text-opacity)) !important;
}

.\!text-gold {
  --tw-text-opacity: 1 !important;
  color: rgb(255 199 0 / var(--tw-text-opacity)) !important;
}

.text-444 {
  --tw-text-opacity: 1;
  color: rgb(68 68 68 / var(--tw-text-opacity));
}

.text-\[\#333\] {
  --tw-text-opacity: 1;
  color: rgb(51 51 51 / var(--tw-text-opacity));
}

.text-\[\#444\] {
  --tw-text-opacity: 1;
  color: rgb(68 68 68 / var(--tw-text-opacity));
}

.text-\[\#666666\], .text-\[\#666\] {
  --tw-text-opacity: 1;
  color: rgb(102 102 102 / var(--tw-text-opacity));
}

.text-\[\#808080\] {
  --tw-text-opacity: 1;
  color: rgb(128 128 128 / var(--tw-text-opacity));
}

.text-accent-foreground {
  color: hsl(var(--accent-foreground));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-current {
  color: currentColor;
}

.text-destructive {
  color: hsl(var(--destructive));
}

.text-destructive-foreground {
  color: hsl(var(--destructive-foreground));
}

.text-foreground {
  color: hsl(var(--foreground));
}

.text-gold {
  --tw-text-opacity: 1;
  color: rgb(255 199 0 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-muted-foreground {
  color: hsl(var(--muted-foreground));
}

.text-muted-foreground\/70 {
  color: hsl(var(--muted-foreground) / .7);
}

.text-popover-foreground {
  color: hsl(var(--popover-foreground));
}

.text-primary {
  color: hsl(var(--primary));
}

.text-primary-foreground {
  color: hsl(var(--primary-foreground));
}

.text-secondary-foreground {
  color: hsl(var(--secondary-foreground));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.underline-offset-4 {
  text-underline-offset: 4px;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.opacity-70 {
  opacity: .7;
}

.opacity-90 {
  opacity: .9;
}

.\!shadow-\[inset_6px_6px_13px_-10px_rgba\(0\,0\,0\,0\.22\)\] {
  --tw-shadow: inset 6px 6px 13px -10px #00000038 !important;
  --tw-shadow-colored: inset 6px 6px 13px -10px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[6px_6px_16px_-1px_\#00000033\] {
  --tw-shadow: 6px 6px 16px -1px #0003;
  --tw-shadow-colored: 6px 6px 16px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[inset_0px_2px_8px_1px_rgba\(0\,0\,0\,0\.2\)\] {
  --tw-shadow: inset 0px 2px 8px 1px #0003;
  --tw-shadow-colored: inset 0px 2px 8px 1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[rgba\(0\,0\,0\,0\.2\)_0px_4px_8px\] {
  --tw-shadow: #0003 0px 4px 8px;
  --tw-shadow-colored: 0px 4px 8px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[rgba\(0\,0\,0\,0\.2\)_0px_8px_16px\] {
  --tw-shadow: #0003 0px 8px 16px;
  --tw-shadow-colored: 0px 8px 16px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline {
  outline-style: solid;
}

.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-offset-background {
  --tw-ring-offset-color: hsl(var(--background));
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.\!invert {
  --tw-invert: invert(100%) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.invert {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

@keyframes enter {
  from {
    opacity: var(--tw-enter-opacity, 1);
    transform: translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0) scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0));
  }
}

@keyframes exit {
  to {
    opacity: var(--tw-exit-opacity, 1);
    transform: translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0) scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0));
  }
}

.animate-in {
  --tw-enter-opacity: initial;
  --tw-enter-scale: initial;
  --tw-enter-rotate: initial;
  --tw-enter-translate-x: initial;
  --tw-enter-translate-y: initial;
  animation-name: enter;
  animation-duration: .15s;
}

.fade-in-0 {
  --tw-enter-opacity: 0;
}

.zoom-in-95 {
  --tw-enter-scale: .95;
}

.duration-200 {
  animation-duration: .2s;
}

.duration-300 {
  animation-duration: .3s;
}

.running {
  animation-play-state: running;
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert-info {
  background-color: #e6f7ff;
  border-color: #91d5ff;
}

.time-entry__date-hover, .time-entry__time-options-hover {
  transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
}

.timesheet-widget__task-row--highlighted {
  animation: 3s task-row-highlight;
}

@keyframes task-row-highlight {
  0% {
    background: #ffc700;
  }

  100% {
    background: none;
  }
}

.rw-input {
  font-size: 13px !important;
}

.coincraft-table-cell--textarea {
  cursor: text;
  padding: 0;
}

.coincraft-table-cell--textarea textarea {
  box-shadow: none;
  resize: none;
  background-color: #0000;
  border: none;
  width: 100%;
  height: 100%;
  padding: .5em;
}

.coincraft-table-cell--tax-dropdown, .coincraft-table-cell--dropdown, .coincraft-table-cell--bool, .coincraft-table-cell--project, .coincraft-table-cell--phase, .coincraft-table-cell--staff, .coincraft-table-cell--role, .coincraft-table-cell--contact, .coincraft-table-cell--costCentre, .coincraft-table-cell--staffOrRole, .coincraft-table-cell--status, .coincraft-table-cell--staffOrRole {
  cursor: pointer;
  padding: 0;
}

:is(.coincraft-table-cell--tax-dropdown, .coincraft-table-cell--dropdown, .coincraft-table-cell--bool, .coincraft-table-cell--project, .coincraft-table-cell--phase, .coincraft-table-cell--staff, .coincraft-table-cell--role, .coincraft-table-cell--contact, .coincraft-table-cell--costCentre, .coincraft-table-cell--staffOrRole, .coincraft-table-cell--status, .coincraft-table-cell--staffOrRole):hover {
  background-color: #eee;
}

.coincraft-table-cell__tax-dropdown, .coincraft-table-cell__dropdown, .coincraft-table-cell__bool, .coincraft-table-cell__project, .coincraft-table-cell__phase, .coincraft-table-cell__staff, .coincraft-table-cell__role, .coincraft-table-cell__contact, .coincraft-table-cell__costCentre, .coincraft-table-cell__staffOrRole, .coincraft-table-cell__status, .coincraft-table-cell__staffOrRole {
  background-color: #0000;
  border: none;
  border-radius: 0;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

@media print {
  .dont-print {
    display: none !important;
  }

  * {
    color: #000;
  }

  .dont-print, .buttons, .search-field, .report-selector {
    display: none !important;
  }

  body {
    color: #000;
    background-color: #0000;
    background-image: none !important;
  }

  .dashboard-layout .dashboard-layout__column.dashboard-layout__main-column {
    width: 100%;
  }

  a[href]:after {
    content: "" !important;
  }

  .table-widget__header-row {
    border-bottom: 1px solid #444;
  }

  .coincraft-page-layout {
    height: auto;
    display: block !important;
  }

  .coincraft-page-layout__header {
    width: auto;
    height: auto;
    display: none !important;
  }

  .coincraft-page-layout__content {
    height: auto;
    display: block !important;
  }

  .coincraft-page-layout__content .coincraft-page-layout__page {
    height: auto;
    position: absolute;
    overflow: hidden;
    display: block !important;
  }

  .coincraft-page-layout__content .coincraft-page-layout__page .coincraft-page-layout__page-header {
    height: auto;
    display: block !important;
  }

  .coincraft-page-layout__content .coincraft-page-layout__page .coincraft-page-layout__page-body {
    box-shadow: none;
    height: auto;
    display: block !important;
  }

  .coincraft-page-layout__content .coincraft-page-layout__page .coincraft-page-layout__page-body > * {
    display: block !important;
  }

  .export-button-dropdown {
    display: none !important;
  }

  .coincraft-page-layout__page-header {
    color: #333;
    background-color: #fff;
    min-height: 0;
    margin: 1em;
    padding: 0;
  }

  .coincraft-page-layout__content .coincraft-page-layout__page .coincraft-page-layout__page-body {
    box-shadow: none;
    padding: 2em 0 0;
  }

  .coincraft-page-layout__page-body {
    box-shadow: none;
    background-color: #fff;
    padding: 2em 0 0;
  }

  .invoice-page {
    box-shadow: none;
    width: 100vw;
    height: 100vh;
    margin: 0;
    display: flex;
  }

  .coincraft-page-layout__page-body.grey {
    background-color: #fff;
  }

  .coincraft-page-layout__page-body {
    padding: 0 !important;
  }

  .page-layout__tabs {
    display: none !important;
  }

  .rw-input {
    font-size: 10px !important;
  }

  .plus-btn {
    display: none !important;
  }
}

.coincraft-page-layout {
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

@media print {
  .coincraft-page-layout {
    height: auto;
    display: block !important;
  }
}

.coincraft-page-layout__header {
  width: 100%;
  position: relative;
}

@media print {
  .coincraft-page-layout__header {
    width: auto;
    height: auto;
    display: none !important;
  }
}

.coincraft-page-layout__page {
  flex: 1;
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
  overflow: auto;
}

@media print {
  .coincraft-page-layout__page {
    height: auto;
    position: absolute;
    display: block !important;
  }
}

.grid-all {
  grid-template: "header sidebar"
                 "body sidebar" 1fr
                 / 1fr auto;
}

.grid-body-only {
  grid-template: "body" 1fr
  / 1fr;
}

.grid-header-body {
  grid-template: "header"
                 "body" 1fr
                 / 1fr;
}

.grid-body-sidebar {
  grid-template: "body sidebar" 1fr
  / 1fr auto;
}

.coincraft-page-layout__page-header {
  flex: none;
  grid-area: header;
  width: 100%;
  position: relative;
  overflow: visible;
}

@media print {
  .coincraft-page-layout__page-header {
    height: auto;
    display: block !important;
  }
}

.coincraft-page-layout__page-body {
  --tw-shadow: 0px -2px 10px 0px #0000003d;
  --tw-shadow-colored: 0px -2px 10px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  flex: 1;
  grid-area: body;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
}

@media print {
  .coincraft-page-layout__page-body {
    box-shadow: none;
    height: auto;
    display: block !important;
  }

  .coincraft-page-layout__page-body > * {
    display: block !important;
  }
}

.coincraft-page-layout__sidebar {
  --tw-border-opacity: 1;
  border-left-width: 1px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  flex: none;
  grid-area: sidebar;
  max-width: 72rem;
  height: 100%;
  display: flex;
  position: relative;
}

.coincraft-page-layout__sidebar-toggle {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
  flex: none;
  width: 2rem;
}

.coincraft-page-layout__sidebar-content {
  flex: 1;
  padding-bottom: 7rem;
  overflow: hidden auto;
}

.coincraft-page-layout__page:has(.coincraft-page-layout__page-header):has(.coincraft-page-layout__sidebar) {
  grid-template: "header sidebar"
                 "body sidebar" 1fr
                 / 1fr auto;
}

.coincraft-page-layout__page:has(.coincraft-page-layout__page-header):not(:has(.coincraft-page-layout__sidebar)) {
  grid-template: "header"
                 "body" 1fr
                 / 1fr;
}

.coincraft-page-layout__page:not(:has(.coincraft-page-layout__page-header)):has(.coincraft-page-layout__sidebar) {
  grid-template: "body sidebar" 1fr
  / 1fr auto;
}

.coincraft-page-layout__page:not(:has(.coincraft-page-layout__page-header)):not(:has(.coincraft-page-layout__sidebar)) {
  grid-template: "body" 1fr
  / 1fr;
}

.calendar, .calendar > :not(.not-calendar), .calendar > :not(.not-calendar) * {
  position: relative;
}

.calendar .calendar-item {
  -webkit-user-select: none;
  user-select: none;
  color: #fff;
  z-index: 0;
  background: #00f;
  border: 1px solid #ffffffbf;
  border-radius: .5em;
  padding: .5em;
  font-size: .85rem;
  font-weight: 600;
  position: absolute;
  overflow: hidden;
}

.calendar .calendar-item.allocation {
  background: #666;
}

.calendar .calendar-item > * {
  font-size: .75rem;
}

.calendar .calendar-item.right {
  z-index: 3;
}

.calendar .calendar-item.left, .calendar .calendar-item:hover {
  z-index: 2;
}

.calendar .calendar-item:hover.right {
  z-index: 5;
}

.calendar .calendar-item:hover.left {
  z-index: 4;
}

.calendar .calendar-item:after {
  content: "";
  z-index: -1;
  border: 1px solid #fff;
  border-radius: .5rem;
  display: block;
  position: absolute;
  inset: -1px;
}

.calendar .calendar-item .time {
  background: #00f;
  width: 100%;
  padding: .25rem .75rem;
  font-size: .85rem;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  left: 0;
}

.calendar .calendar-item.allocation .time {
  background: #666;
}

.calendar .calendar-item.selected, .calendar .calendar-item.selected .time {
  background: #00c1ff;
}

.calendar .calendar-item.selected.allocation, .calendar .calendar-item.selected.allocation .time {
  background: #638bab;
}

.calendar .vertical-drag {
  cursor: ns-resize;
  z-index: 10;
}

input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"], .uneditable-input {
  min-height: 30px;
}

[data-radix-popper-content-wrapper] {
  margin-top: 0 !important;
}

.file\:border-0::file-selector-button {
  border-width: 0;
}

.file\:bg-transparent::file-selector-button {
  background-color: #0000;
}

.file\:text-sm::file-selector-button {
  font-size: .875rem;
  line-height: 1.25rem;
}

.file\:font-medium::file-selector-button {
  font-weight: 500;
}

.placeholder\:text-muted-foreground::placeholder {
  color: hsl(var(--muted-foreground));
}

.focus-within\:relative:focus-within {
  position: relative;
}

.focus-within\:z-20:focus-within {
  z-index: 20;
}

.hover\:m-0:hover {
  margin: 0;
}

.hover\:border-2:hover {
  border-width: 2px;
}

.hover\:border-\[\#618fb0\]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(97 143 176 / var(--tw-border-opacity));
}

.hover\:border-\[\#adadad\]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(173 173 173 / var(--tw-border-opacity));
}

.hover\:border-gold:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 199 0 / var(--tw-border-opacity));
}

.hover\:bg-\[\#428bca\]\/70:hover {
  background-color: #428bcab3;
}

.hover\:bg-\[\#e0e0e0\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(224 224 224 / var(--tw-bg-opacity));
}

.hover\:bg-\[\#f0f0f0\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(240 240 240 / var(--tw-bg-opacity));
}

.hover\:bg-\[\#f5c510\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(245 197 16 / var(--tw-bg-opacity));
}

.hover\:bg-\[\#f5f5f5\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.hover\:bg-\[\#fdf5d4\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(253 245 212 / var(--tw-bg-opacity));
}

.hover\:bg-accent:hover {
  background-color: hsl(var(--accent));
}

.hover\:bg-destructive\/80:hover {
  background-color: hsl(var(--destructive) / .8);
}

.hover\:bg-destructive\/90:hover {
  background-color: hsl(var(--destructive) / .9);
}

.hover\:bg-muted:hover {
  background-color: hsl(var(--muted));
}

.hover\:bg-muted\/50:hover {
  background-color: hsl(var(--muted) / .5);
}

.hover\:bg-primary:hover {
  background-color: hsl(var(--primary));
}

.hover\:bg-primary\/80:hover {
  background-color: hsl(var(--primary) / .8);
}

.hover\:bg-primary\/90:hover {
  background-color: hsl(var(--primary) / .9);
}

.hover\:bg-secondary\/80:hover {
  background-color: hsl(var(--secondary) / .8);
}

.hover\:bg-transparent:hover {
  background-color: #0000;
}

.hover\:bg-white\/10:hover {
  background-color: #ffffff1a;
}

.hover\:bg-white\/25:hover {
  background-color: #ffffff40;
}

.hover\:bg-\[linear-gradient\(hsla\(47\,100\%\,80\%\,1\)\,hsla\(47\,100\%\,90\%\,1\)\)\]:hover {
  background-image: linear-gradient(#ffe999, #fff4cc);
}

.hover\:bg-highlighted:hover {
  background-image: linear-gradient(#ffd84c, #ffe380);
}

.hover\:text-accent-foreground:hover {
  color: hsl(var(--accent-foreground));
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.hover\:text-muted-foreground:hover {
  color: hsl(var(--muted-foreground));
}

.hover\:text-primary-foreground:hover {
  color: hsl(var(--primary-foreground));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.focus\:bg-accent:focus {
  background-color: hsl(var(--accent));
}

.focus\:bg-primary:focus {
  background-color: hsl(var(--primary));
}

.focus\:bg-highlighted:focus {
  background-image: linear-gradient(#ffd84c, #ffe380);
}

.focus\:text-accent-foreground:focus {
  color: hsl(var(--accent-foreground));
}

.focus\:text-primary-foreground:focus {
  color: hsl(var(--primary-foreground));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-ring:focus {
  --tw-ring-color: hsl(var(--ring));
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus-visible\:outline-none:focus-visible {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus-visible\:ring-1:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:ring-2:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:ring-ring:focus-visible {
  --tw-ring-color: hsl(var(--ring));
}

.focus-visible\:ring-offset-2:focus-visible {
  --tw-ring-offset-width: 2px;
}

.disabled\:pointer-events-none:disabled {
  pointer-events: none;
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:bg-white:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.disabled\:text-\[\#444\]:disabled {
  --tw-text-opacity: 1;
  color: rgb(68 68 68 / var(--tw-text-opacity));
}

.disabled\:text-\[\#ccc\]:disabled {
  --tw-text-opacity: 1;
  color: rgb(204 204 204 / var(--tw-text-opacity));
}

.disabled\:opacity-50:disabled {
  opacity: .5;
}

.group.toaster .group-\[\.toaster\]\:border-border {
  border-color: hsl(var(--border));
}

.group.toast .group-\[\.toast\]\:bg-muted {
  background-color: hsl(var(--muted));
}

.group.toast .group-\[\.toast\]\:bg-primary {
  background-color: hsl(var(--primary));
}

.group.toaster .group-\[\.toaster\]\:bg-background {
  background-color: hsl(var(--background));
}

.group.toast .group-\[\.toast\]\:text-muted-foreground {
  color: hsl(var(--muted-foreground));
}

.group.toast .group-\[\.toast\]\:text-primary-foreground {
  color: hsl(var(--primary-foreground));
}

.group.toaster .group-\[\.toaster\]\:text-foreground {
  color: hsl(var(--foreground));
}

.group.toaster .group-\[\.toaster\]\:shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.peer:disabled ~ .peer-disabled\:cursor-not-allowed {
  cursor: not-allowed;
}

.peer:disabled ~ .peer-disabled\:opacity-70 {
  opacity: .7;
}

.aria-selected\:bg-accent[aria-selected="true"] {
  background-color: hsl(var(--accent));
}

.aria-selected\:bg-accent\/50[aria-selected="true"] {
  background-color: hsl(var(--accent) / .5);
}

.aria-selected\:text-accent-foreground[aria-selected="true"] {
  color: hsl(var(--accent-foreground));
}

.aria-selected\:text-muted-foreground[aria-selected="true"] {
  color: hsl(var(--muted-foreground));
}

.aria-selected\:opacity-100[aria-selected="true"] {
  opacity: 1;
}

.aria-selected\:opacity-30[aria-selected="true"] {
  opacity: .3;
}

.data-\[disabled\=true\]\:pointer-events-none[data-disabled="true"], .data-\[disabled\]\:pointer-events-none[data-disabled] {
  pointer-events: none;
}

.data-\[side\=bottom\]\:translate-y-1[data-side="bottom"] {
  --tw-translate-y: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[side\=left\]\:-translate-x-1[data-side="left"] {
  --tw-translate-x: -.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[side\=right\]\:translate-x-1[data-side="right"] {
  --tw-translate-x: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[side\=top\]\:-translate-y-1[data-side="top"] {
  --tw-translate-y: -.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[state\=active\]\:border-gray-900[data-state="active"] {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity));
}

.data-\[selected\=true\]\:bg-accent[data-selected="true"] {
  background-color: hsl(var(--accent));
}

.data-\[state\=active\]\:bg-background[data-state="active"] {
  background-color: hsl(var(--background));
}

.data-\[state\=checked\]\:bg-primary[data-state="checked"] {
  background-color: hsl(var(--primary));
}

.data-\[state\=on\]\:bg-accent[data-state="on"], .data-\[state\=open\]\:bg-accent[data-state="open"] {
  background-color: hsl(var(--accent));
}

.data-\[state\=selected\]\:bg-muted[data-state="selected"] {
  background-color: hsl(var(--muted));
}

.data-\[selected\=true\]\:text-accent-foreground[data-selected="true"] {
  color: hsl(var(--accent-foreground));
}

.data-\[state\=active\]\:text-foreground[data-state="active"] {
  color: hsl(var(--foreground));
}

.data-\[state\=active\]\:text-gray-900[data-state="active"] {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.data-\[state\=checked\]\:text-primary-foreground[data-state="checked"] {
  color: hsl(var(--primary-foreground));
}

.data-\[state\=on\]\:text-accent-foreground[data-state="on"] {
  color: hsl(var(--accent-foreground));
}

.data-\[state\=open\]\:text-muted-foreground[data-state="open"] {
  color: hsl(var(--muted-foreground));
}

.data-\[disabled\=true\]\:opacity-50[data-disabled="true"], .data-\[disabled\]\:opacity-50[data-disabled] {
  opacity: .5;
}

.data-\[state\=active\]\:shadow-sm[data-state="active"] {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.data-\[state\=open\]\:animate-in[data-state="open"] {
  --tw-enter-opacity: initial;
  --tw-enter-scale: initial;
  --tw-enter-rotate: initial;
  --tw-enter-translate-x: initial;
  --tw-enter-translate-y: initial;
  animation-name: enter;
  animation-duration: .15s;
}

.data-\[state\=closed\]\:animate-out[data-state="closed"] {
  --tw-exit-opacity: initial;
  --tw-exit-scale: initial;
  --tw-exit-rotate: initial;
  --tw-exit-translate-x: initial;
  --tw-exit-translate-y: initial;
  animation-name: exit;
  animation-duration: .15s;
}

.data-\[state\=closed\]\:fade-out-0[data-state="closed"] {
  --tw-exit-opacity: 0;
}

.data-\[state\=open\]\:fade-in-0[data-state="open"] {
  --tw-enter-opacity: 0;
}

.data-\[state\=closed\]\:zoom-out-95[data-state="closed"] {
  --tw-exit-scale: .95;
}

.data-\[state\=open\]\:zoom-in-95[data-state="open"] {
  --tw-enter-scale: .95;
}

.data-\[side\=bottom\]\:slide-in-from-top-2[data-side="bottom"] {
  --tw-enter-translate-y: -.5rem;
}

.data-\[side\=left\]\:slide-in-from-right-2[data-side="left"] {
  --tw-enter-translate-x: .5rem;
}

.data-\[side\=right\]\:slide-in-from-left-2[data-side="right"] {
  --tw-enter-translate-x: -.5rem;
}

.data-\[side\=top\]\:slide-in-from-bottom-2[data-side="top"] {
  --tw-enter-translate-y: .5rem;
}

.data-\[state\=closed\]\:slide-out-to-left-1\/2[data-state="closed"] {
  --tw-exit-translate-x: -50%;
}

.data-\[state\=closed\]\:slide-out-to-top-\[48\%\][data-state="closed"] {
  --tw-exit-translate-y: -48%;
}

.data-\[state\=open\]\:slide-in-from-left-1\/2[data-state="open"] {
  --tw-enter-translate-x: -50%;
}

.data-\[state\=open\]\:slide-in-from-top-\[48\%\][data-state="open"] {
  --tw-enter-translate-y: -48%;
}

.dark\:border-gray-800:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-border-opacity));
}

.dark\:text-gray-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.hover\:dark\:text-gray-200:is(.dark *):hover {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.data-\[state\=active\]\:dark\:border-gray-50:is(.dark *)[data-state="active"] {
  --tw-border-opacity: 1;
  border-color: rgb(249 250 251 / var(--tw-border-opacity));
}

.data-\[state\=active\]\:dark\:text-gray-50:is(.dark *)[data-state="active"] {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

@media (width >= 640px) {
  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:gap-6 {
    gap: 1.5rem;
  }

  .sm\:gap-8 {
    gap: 2rem;
  }

  .sm\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .sm\:rounded-lg {
    border-radius: var(--radius);
  }

  .sm\:text-left {
    text-align: left;
  }
}

@media (width >= 1024px) {
  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:\!w-full {
    width: 100% !important;
  }

  .lg\:w-60 {
    width: 15rem;
  }

  .lg\:w-64 {
    width: 16rem;
  }

  .lg\:w-\[250px\] {
    width: 250px;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:gap-8 {
    gap: 2rem;
  }

  .lg\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }
}

@media (width >= 1310px) {
  .min-\[1310px\]\:w-\[92\%\] {
    width: 92%;
  }
}

@media (width >= 1381px) {
  .min-\[1381px\]\:w-\[30\%\] {
    width: 30%;
  }

  .min-\[1381px\]\:w-\[65\%\] {
    width: 65%;
  }

  .min-\[1381px\]\:w-\[92\%\] {
    width: 92%;
  }
}

@media print {
  .print\:block {
    display: block;
  }

  .print\:hidden {
    display: none;
  }

  .print\:h-auto {
    height: auto;
  }

  .print\:shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

.\[\&\:has\(\[aria-selected\]\)\]\:bg-accent:has([aria-selected]) {
  background-color: hsl(var(--accent));
}

.first\:\[\&\:has\(\[aria-selected\]\)\]\:rounded-l-md:has([aria-selected]):first-child {
  border-top-left-radius: calc(var(--radius)  - 2px);
  border-bottom-left-radius: calc(var(--radius)  - 2px);
}

.last\:\[\&\:has\(\[aria-selected\]\)\]\:rounded-r-md:has([aria-selected]):last-child {
  border-top-right-radius: calc(var(--radius)  - 2px);
  border-bottom-right-radius: calc(var(--radius)  - 2px);
}

.\[\&\:has\(\[aria-selected\]\.day-outside\)\]\:bg-accent\/50:has([aria-selected].day-outside) {
  background-color: hsl(var(--accent) / .5);
}

.\[\&\:has\(\[aria-selected\]\.day-range-end\)\]\:rounded-r-md:has([aria-selected].day-range-end) {
  border-top-right-radius: calc(var(--radius)  - 2px);
  border-bottom-right-radius: calc(var(--radius)  - 2px);
}

.\[\&\:has\(\[role\=checkbox\]\)\]\:pr-0:has([role="checkbox"]) {
  padding-right: 0;
}

.\[\&\:not\(\:disabled\)\:hover\]\:bg-white\/70:not(:disabled):hover {
  background-color: #ffffffb3;
}

.\[\&\>div\]\:\!block > div {
  display: block !important;
}

.\[\&\>span\]\:line-clamp-1 > span {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.\[\&\>tr\]\:last\:border-b-0:last-child > tr {
  border-bottom-width: 0;
}

.\[\&_\.disabled\]\:cursor-default .disabled {
  cursor: default;
}

.\[\&_\.rw-select\]\:\!border-none .rw-select {
  border-style: none !important;
}

.\[\&_\[cmdk-group-heading\]\]\:px-2 [cmdk-group-heading] {
  padding-left: .5rem;
  padding-right: .5rem;
}

.\[\&_\[cmdk-group-heading\]\]\:py-1\.5 [cmdk-group-heading] {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.\[\&_\[cmdk-group-heading\]\]\:text-xs [cmdk-group-heading] {
  font-size: .75rem;
  line-height: 1rem;
}

.\[\&_\[cmdk-group-heading\]\]\:font-medium [cmdk-group-heading] {
  font-weight: 500;
}

.\[\&_\[cmdk-group-heading\]\]\:text-muted-foreground [cmdk-group-heading] {
  color: hsl(var(--muted-foreground));
}

.\[\&_\[cmdk-group\]\:not\(\[hidden\]\)_\~\[cmdk-group\]\]\:pt-0 [cmdk-group]:not([hidden]) ~ [cmdk-group] {
  padding-top: 0;
}

.\[\&_\[cmdk-group\]\]\:px-2 [cmdk-group] {
  padding-left: .5rem;
  padding-right: .5rem;
}

.\[\&_\[cmdk-input-wrapper\]\]\:border-0 [cmdk-input-wrapper] {
  border-width: 0;
}

.\[\&_\[cmdk-input-wrapper\]\]\:px-0 [cmdk-input-wrapper] {
  padding-left: 0;
  padding-right: 0;
}

.\[\&_\[cmdk-input-wrapper\]_svg\]\:h-5 [cmdk-input-wrapper] svg {
  height: 1.25rem;
}

.\[\&_\[cmdk-input-wrapper\]_svg\]\:w-5 [cmdk-input-wrapper] svg {
  width: 1.25rem;
}

.\[\&_\[cmdk-input\]\]\:h-12 [cmdk-input] {
  height: 3rem;
}

.\[\&_\[cmdk-item\]\]\:px-2 [cmdk-item] {
  padding-left: .5rem;
  padding-right: .5rem;
}

.\[\&_\[cmdk-item\]\]\:py-3 [cmdk-item] {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.\[\&_\[cmdk-item\]_svg\]\:h-5 [cmdk-item] svg {
  height: 1.25rem;
}

.\[\&_\[cmdk-item\]_svg\]\:w-5 [cmdk-item] svg {
  width: 1.25rem;
}

.\[\&_button\]\:\!bg-transparent button {
  background-color: #0000 !important;
}

.\[\&_input\]\:border-\[\#ccc\] input {
  --tw-border-opacity: 1;
  border-color: rgb(204 204 204 / var(--tw-border-opacity));
}

.\[\&_input\]\:\!bg-black\/\[0\.01\] input {
  background-color: #00000003 !important;
}

.\[\&_input\]\:\!px-0 input {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.\[\&_input\]\:\!text-xs input {
  font-size: .75rem !important;
  line-height: 1rem !important;
}

.\[\&_input\]\:\!text-black input {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}

.\[\&_input\]\:\!shadow-none input {
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.\[\&_span\]\:\!text-xs span {
  font-size: .75rem !important;
  line-height: 1rem !important;
}

.\[\&_span\]\:text-black span {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.\[\&_svg\]\:invisible svg {
  visibility: hidden;
}

.\[\&_tr\:last-child\]\:border-0 tr:last-child {
  border-width: 0;
}

.\[\&_tr\]\:border-b tr {
  border-bottom-width: 1px;
}

.rw-btn, .rw-input {
  color: inherit;
  font: inherit;
  margin: 0;
}

button.rw-input {
  overflow: visible;
}

button.rw-input, select.rw-input {
  text-transform: none;
}

button.rw-input, html input[type="button"].rw-input, input[type="reset"].rw-input, input[type="submit"].rw-input {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled].rw-input, html input[disabled].rw-input {
  cursor: not-allowed;
}

button.rw-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input.rw-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

@font-face {
  font-family: RwWidgets;
  src: url("rw-widgets.948f1aaf.eot");
  src: url("rw-widgets.948f1aaf.eot#iefix&v=4.1.0") format("embedded-opentype"), url("rw-widgets.a1d4baaf.woff") format("woff"), url("rw-widgets.0033417a.ttf") format("truetype"), url("rw-widgets.3d4e6c1b.svg#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

.rw-i {
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: RwWidgets;
  font-style: normal;
  font-weight: normal;
  line-height: 1em;
  display: inline-block;
}

.rw-i-caret-down:before {
  content: "";
}

.rw-i-caret-up:before {
  content: "";
}

.rw-i-caret-left:before {
  content: "";
}

.rw-i-caret-right:before {
  content: "";
}

.rw-i-clock-o:before {
  content: "";
}

.rw-i-calendar:before {
  content: "";
}

.rw-i-search:before {
  content: "";
}

.rw-sr {
  clip: rect(0, 0, 0, 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.rw-widget, .rw-widget *, .rw-widget:before, .rw-widget :before, .rw-widget:after, .rw-widget :after {
  box-sizing: border-box;
}

.rw-widget {
  -webkit-background-clip: border-box;
  -moz-background-clip: border-box;
  background-clip: border-box;
  outline: 0;
}

.rw-btn {
  color: #333;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  background: none;
  border: 1px solid #0000;
  margin: 0;
  padding: 0;
  line-height: 2.286em;
  display: inline-block;
}

.rw-rtl {
  direction: rtl;
}

.rw-input {
  color: #555;
  background-color: #fff;
  height: 2.286em;
  padding: .429em .857em;
}

.rw-input[disabled] {
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #eee;
  border-color: #ccc;
}

.rw-input[readonly] {
  cursor: not-allowed;
}

.rw-filter-input {
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 2px;
  padding-right: 1.9em;
  position: relative;
}

.rw-rtl .rw-filter-input {
  padding-left: 1.9em;
  padding-right: 0;
}

.rw-filter-input > .rw-input {
  border: none;
  outline: none;
  width: 100%;
}

.rw-filter-input > span {
  margin-top: -2px;
}

.rw-i.rw-loading {
  background: url("loading.b0e37346.gif") center no-repeat;
  width: 16px;
  height: 100%;
}

.rw-i.rw-loading:before {
  content: "";
}

.rw-loading-mask {
  border-radius: 4px;
  position: relative;
}

.rw-loading-mask:after {
  content: "";
  opacity: .7;
  background: #fff url("loader-big.253b5ca3.gif") center no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.rw-now {
  font-weight: 600;
}

.rw-state-focus {
  color: #333;
  background-color: #fff;
  border: 1px solid #66afe9;
}

.rw-state-selected {
  color: #333;
  background-color: #adadad;
  border: 1px solid #adadad;
}

.rw-state-disabled {
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.rw-btn, .rw-dropdownlist {
  cursor: pointer;
}

.rw-btn[disabled], .rw-state-disabled .rw-btn, .rw-state-readonly .rw-btn {
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
  cursor: not-allowed;
  filter: alpha(opacity= 65);
  opacity: .65;
}

ul.rw-list, .rw-selectlist {
  outline: 0;
  height: 100%;
  margin: 0;
  padding: 5px 0;
  list-style: none;
  overflow: auto;
}

ul.rw-list > li, .rw-selectlist > li {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

ul.rw-list > li.rw-list-optgroup, .rw-selectlist > li.rw-list-optgroup {
  font-weight: bold;
}

ul.rw-list > li.rw-list-option, ul.rw-list > li.rw-list-empty, .rw-selectlist > li.rw-list-option, .rw-selectlist > li.rw-list-empty {
  padding-left: 10px;
  padding-right: 10px;
}

ul.rw-list > li.rw-list-option, .rw-selectlist > li.rw-list-option {
  cursor: pointer;
  border: 1px solid #0000;
  border-radius: 3px;
}

ul.rw-list > li.rw-list-option:hover, .rw-selectlist > li.rw-list-option:hover {
  background-color: #e6e6e6;
  border-color: #adadad;
}

ul.rw-list > li.rw-list-option.rw-state-focus, .rw-selectlist > li.rw-list-option.rw-state-focus {
  color: #333;
  background-color: #fff;
  border: 1px solid #66afe9;
}

ul.rw-list > li.rw-list-option.rw-state-selected, .rw-selectlist > li.rw-list-option.rw-state-selected {
  color: #333;
  background-color: #adadad;
  border: 1px solid #adadad;
}

ul.rw-list > li.rw-list-option.rw-state-disabled, ul.rw-list > li.rw-list-option.rw-state-readonly, .rw-selectlist > li.rw-list-option.rw-state-disabled, .rw-selectlist > li.rw-list-option.rw-state-readonly {
  color: #777;
  cursor: not-allowed;
}

ul.rw-list > li.rw-list-option.rw-state-disabled:hover, ul.rw-list > li.rw-list-option.rw-state-readonly:hover, .rw-selectlist > li.rw-list-option.rw-state-disabled:hover, .rw-selectlist > li.rw-list-option.rw-state-readonly:hover {
  background: none;
  border-color: #0000;
}

ul.rw-list.rw-list-grouped > li.rw-list-optgroup, .rw-selectlist.rw-list-grouped > li.rw-list-optgroup {
  padding-left: 10px;
}

ul.rw-list.rw-list-grouped > li.rw-list-option, .rw-selectlist.rw-list-grouped > li.rw-list-option {
  padding-left: 20px;
}

.rw-widget {
  position: relative;
}

.rw-open.rw-widget, .rw-open > .rw-multiselect-wrapper {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rw-open-up.rw-widget, .rw-open-up > .rw-multiselect-wrapper {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rw-combobox .rw-list, .rw-datetimepicker .rw-list, .rw-numberpicker .rw-list, .rw-dropdownlist .rw-list, .rw-multiselect .rw-list {
  height: auto;
  max-height: 200px;
}

.rw-widget {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.rw-widget .rw-input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.rw-rtl .rw-widget .rw-input {
  border-radius: 0 4px 4px 0;
}

.rw-widget > .rw-select {
  border-left: 1px solid #ccc;
}

.rw-widget.rw-rtl > .rw-select {
  border-left: none;
  border-right: 1px solid #ccc;
}

.rw-widget.rw-state-focus, .rw-widget.rw-state-focus:hover {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000013, 0 0 8px #66afe999;
}

.rw-widget.rw-state-readonly, .rw-widget.rw-state-readonly > .rw-multiselect-wrapper {
  cursor: not-allowed;
}

.rw-widget.rw-state-disabled, .rw-widget.rw-state-disabled:hover, .rw-widget.rw-state-disabled:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #eee;
  border-color: #ccc;
}

.rw-combobox, .rw-datetimepicker, .rw-numberpicker, .rw-dropdownlist {
  padding-right: 1.9em;
}

.rw-combobox.rw-rtl, .rw-datetimepicker.rw-rtl, .rw-numberpicker.rw-rtl, .rw-dropdownlist.rw-rtl {
  padding-left: 1.9em;
  padding-right: 0;
}

.rw-combobox > .rw-input, .rw-datetimepicker > .rw-input, .rw-numberpicker > .rw-input, .rw-dropdownlist > .rw-input {
  border: none;
  outline: 0;
  width: 100%;
}

.rw-combobox > .rw-input::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.rw-datetimepicker > .rw-input::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.rw-numberpicker > .rw-input::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.rw-dropdownlist > .rw-input::-moz-placeholder {
  color: #999;
  opacity: 1;
}

:is(.rw-combobox > .rw-input:-ms-input-placeholder, .rw-datetimepicker > .rw-input:-ms-input-placeholder, .rw-numberpicker > .rw-input:-ms-input-placeholder, .rw-dropdownlist > .rw-input:-ms-input-placeholder) {
  color: #999;
}

.rw-combobox > .rw-input::-webkit-input-placeholder {
  color: #999;
}

.rw-datetimepicker > .rw-input::-webkit-input-placeholder {
  color: #999;
}

.rw-numberpicker > .rw-input::-webkit-input-placeholder {
  color: #999;
}

.rw-dropdownlist > .rw-input::-webkit-input-placeholder {
  color: #999;
}

.rw-placeholder {
  color: #999;
}

.rw-select {
  width: 1.9em;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.rw-select.rw-btn, .rw-select > .rw-btn {
  vertical-align: middle;
  outline: 0;
  height: 100%;
}

.rw-rtl .rw-select {
  left: 0;
  right: auto;
}

.rw-multiselect, .rw-combobox input.rw-input, .rw-datetimepicker input.rw-input, .rw-numberpicker input.rw-input {
  box-shadow: inset 0 1px 1px #00000013;
}

.rw-combobox:active, .rw-datetimepicker:active, .rw-dropdownlist:active, .rw-header > .rw-btn:active, .rw-numberpicker .rw-btn.rw-state-active, .rw-combobox:active.rw-state-focus, .rw-datetimepicker:active.rw-state-focus, .rw-dropdownlist:active.rw-state-focus, .rw-header > .rw-btn:active.rw-state-focus, .rw-numberpicker .rw-btn.rw-state-active.rw-state-focus {
  background-image: none;
  box-shadow: inset 0 3px 5px #00000020;
}

.rw-combobox:hover, .rw-datetimepicker:hover, .rw-numberpicker:hover, .rw-dropdownlist:hover {
  background-color: #e6e6e6;
  border-color: #adadad;
}

.rw-dropdownlist.rw-state-disabled, .rw-dropdownlist.rw-state-readonly {
  cursor: not-allowed;
}

.rw-dropdownlist > .rw-input {
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #0000;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  line-height: 2.286em;
  overflow: hidden;
}

.rw-dropdownlist.rw-rtl > .rw-input {
  padding: 0 .857em 0 0;
}

.rw-dropdownlist > .rw-select, .rw-dropdownlist.rw-rtl > .rw-select {
  border-width: 0;
}

.rw-numberpicker .rw-btn {
  border-width: 0;
  width: 100%;
  height: 1.143em;
  line-height: 1.143em;
  display: block;
}

.rw-popup {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 0 0 3px 3px;
  margin-bottom: 10px;
  padding: 2px;
  position: absolute;
  left: 10px;
  right: 10px;
  overflow: auto;
  box-shadow: 0 5px 6px #0003;
}

.rw-dropup > .rw-popup {
  border-radius: 3px 3px 0 0;
  margin-top: 10px;
  margin-bottom: 0;
  box-shadow: 0 0 6px #0003;
}

.rw-popup-container {
  z-index: 1005;
  margin-top: 1px;
  position: absolute;
  top: 100%;
  left: -11px;
  right: -11px;
}

.rw-widget.rw-state-focus .rw-popup-container {
  z-index: 1015;
}

.rw-popup-container.rw-dropup {
  top: auto;
  bottom: 100%;
}

.rw-popup-container.rw-calendar-popup {
  width: 18em;
  right: auto;
}

.rw-datetimepicker .rw-btn {
  width: 1.8em;
}

.rw-datetimepicker.rw-has-neither {
  padding-left: 0;
  padding-right: 0;
}

.rw-datetimepicker.rw-has-neither .rw-input {
  border-radius: 4px;
}

.rw-datetimepicker.rw-has-both {
  padding-right: 3.8em;
}

.rw-datetimepicker.rw-has-both.rw-rtl {
  padding-left: 3.8em;
  padding-right: 0;
}

.rw-datetimepicker.rw-has-both > .rw-select {
  width: 3.8em;
  height: 100%;
}

.rw-calendar {
  background-color: #fff;
}

.rw-calendar thead > tr {
  border-bottom: 2px solid #ccc;
}

.rw-calendar .rw-header {
  padding-bottom: 5px;
}

.rw-calendar .rw-header .rw-btn-left, .rw-calendar .rw-header .rw-btn-right {
  width: 12.5%;
}

.rw-calendar .rw-header .rw-btn-view {
  background-color: #eee;
  border-radius: 4px;
  width: 75%;
}

.rw-calendar .rw-header .rw-btn-view[disabled] {
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
}

.rw-calendar .rw-footer {
  border-top: 1px solid #ccc;
}

.rw-calendar .rw-footer .rw-btn {
  white-space: normal;
  width: 100%;
}

.rw-calendar .rw-footer .rw-btn:hover {
  background-color: #e6e6e6;
}

.rw-calendar .rw-footer .rw-btn[disabled] {
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
}

.rw-calendar-grid {
  table-layout: fixed;
  outline: none;
  width: 100%;
  height: 14.2857em;
}

.rw-calendar-grid th {
  text-align: right;
  padding: 0 .4em 0 .1em;
}

.rw-calendar-grid .rw-btn {
  text-align: right;
  width: 100%;
}

.rw-calendar-grid td .rw-btn {
  border-radius: 4px;
  outline: 0;
  padding: 0 .4em 0 .1em;
}

.rw-calendar-grid td .rw-btn:hover {
  background-color: #e6e6e6;
}

.rw-calendar-grid td .rw-btn.rw-off-range {
  color: #b3b3b3;
}

.rw-calendar-grid.rw-nav-view .rw-btn {
  text-align: center;
  white-space: normal;
  padding: .25em 0 .3em;
  display: block;
  overflow: hidden;
}

.rw-selectlist {
  padding: 2px;
}

.rw-selectlist > ul {
  height: 100%;
  overflow: auto;
}

.rw-selectlist > ul > li.rw-list-option {
  cursor: auto;
  outline: none;
  min-height: 27px;
  padding-left: 5px;
  position: relative;
}

.rw-selectlist > ul > li.rw-list-option > label > input {
  margin: 4px 0 0 -20px;
  position: absolute;
}

.rw-selectlist > ul > li.rw-list-option > label {
  padding-left: 20px;
  line-height: 1.423em;
  display: inline-block;
}

.rw-selectlist.rw-rtl > ul > li.rw-list-option {
  padding-left: 0;
  padding-right: 5px;
}

.rw-selectlist.rw-rtl > ul > li.rw-list-option > label > input {
  margin: 4px -20px 0 0;
}

.rw-selectlist.rw-rtl > ul > li.rw-list-option > label {
  padding-left: 0;
  padding-right: 20px;
}

.rw-selectlist.rw-state-disabled > ul > li:hover, .rw-selectlist.rw-state-readonly > ul > li:hover {
  background: none;
  border-color: #0000;
}

.rw-multiselect {
  background-color: #fff;
}

.rw-multiselect:hover {
  border-color: #adadad;
}

.rw-multiselect-wrapper {
  cursor: text;
  border-radius: 4px;
  position: relative;
}

.rw-multiselect-wrapper:before, .rw-multiselect-wrapper:after {
  content: " ";
  display: table;
}

.rw-multiselect-wrapper:after {
  clear: both;
}

.rw-multiselect-wrapper span.rw-loading {
  position: absolute;
  right: 3px;
}

.rw-multiselect-wrapper > .rw-input {
  border-width: 0;
  outline: 0;
  width: auto;
  max-width: 100%;
  line-height: normal;
}

.rw-multiselect-wrapper > .rw-input::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.rw-multiselect-wrapper > .rw-input:-ms-input-placeholder {
  color: #999;
}

.rw-multiselect-wrapper > .rw-input::-webkit-input-placeholder {
  color: #999;
}

.rw-state-readonly > .rw-multiselect-wrapper, .rw-state-disabled > .rw-multiselect-wrapper {
  cursor: not-allowed;
}

.rw-rtl .rw-multiselect-wrapper > .rw-input {
  float: right;
}

.rw-multiselect-create-tag {
  border-top: 1px solid #ccc;
  margin-top: 5px;
  padding-top: 5px;
}

.rw-multiselect-taglist {
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  list-style: none;
  display: inline;
}

.rw-multiselect-taglist > li {
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  background-color: #ccc;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin: 1px;
  padding: .214em .15em .214em .4em;
  line-height: 1.4em;
  display: inline-block;
}

.rw-multiselect-taglist > li.rw-state-focus {
  color: #333;
  background-color: #fff;
  border: 1px solid #66afe9;
}

.rw-multiselect-taglist > li.rw-state-readonly, .rw-multiselect-taglist > li.rw-state-disabled, .rw-multiselect.rw-state-readonly .rw-multiselect-taglist > li, .rw-multiselect.rw-state-disabled .rw-multiselect-taglist > li {
  cursor: not-allowed;
  filter: alpha(opacity= 65);
  opacity: .65;
}

.rw-multiselect-taglist > li .rw-btn {
  outline: 0;
  font-size: 115%;
  line-height: normal;
}

.rw-rtl .rw-multiselect-taglist > li {
  float: right;
}

.react-calendar {
  background: #fff;
  border: 1px solid #a0a096;
  width: 350px;
  max-width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  margin: -.5em;
  display: flex;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: .5em;
}

.react-calendar, .react-calendar *, .react-calendar :before, .react-calendar :after {
  box-sizing: border-box;
}

.react-calendar button {
  border: 0;
  outline: none;
  margin: 0;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
  display: flex;
}

.react-calendar__navigation button {
  background: none;
  min-width: 44px;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-size: .75em;
  font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
  padding: .5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  justify-content: center;
  align-items: center;
  font-size: .75em;
  font-weight: bold;
  display: flex;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile {
  padding: 2em .5em;
}

.react-calendar__tile {
  text-align: center;
  background: none;
  max-width: 100%;
  padding: 10px 6.6667px;
  line-height: 16px;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover, .react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  color: #fff;
  background: #006edc;
}

.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
/*# sourceMappingURL=index.5a3a50d9.css.map */
