@import '~/node_modules/antd/dist/antd.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @layer base {
        :root {
            --background: 39 12% 98%;
            --foreground: 0, 0%, 7%;
            --card: 45 12% 95%;
            --card-foreground: 240 10% 3.9%;
            --popover: 45 12% 95%;
            --popover-foreground: 240 10% 3.9%;
            --primary: 47.9 95.8% 53.1%;
            --primary-foreground: 26 83.3% 14.1%;
            --secondary: 40, 11%, 89%;
            --secondary-foreground: 240 5.9% 10%;
            --muted: 40, 11%, 89%;
            --muted-foreground: 240 3.8% 46.1%;
            --accent: 40, 11%, 89%;
            --accent-foreground: 240 5.9% 10%;
            --destructive: 0 84.2% 60.2%;
            --destructive-foreground: 0 0% 98%;
            --border: 48, 13%, 84%;
            --input: 240 5.9% 90%;
            --ring: 240 5.9% 10%;
            --radius: 0.5rem;
            --sticky: hsla(45, 12%, 95%, 0.75);
        }

        .dark {
            --background: 20 14.3% 4.1%;
            --foreground: 60 9.1% 97.8%;
            --card: 20 14.3% 4.1%;
            --card-foreground: 60 9.1% 97.8%;
            --popover: 20 14.3% 4.1%;
            --popover-foreground: 60 9.1% 97.8%;
            --primary: 47.9 95.8% 53.1%;
            --primary-foreground: 26 83.3% 14.1%;
            --secondary: 12 6.5% 15.1%;
            --secondary-foreground: 60 9.1% 97.8%;
            --muted: 12 6.5% 15.1%;
            --muted-foreground: 24 5.4% 63.9%;
            --accent: 12 6.5% 15.1%;
            --accent-foreground: 60 9.1% 97.8%;
            --destructive: 0 62.8% 30.6%;
            --destructive-foreground: 60 9.1% 97.8%;
            --border: 12 6.5% 15.1%;
            --input: 12 6.5% 15.1%;
            --ring: 35.5 91.7% 32.9%;
        }
    }
    body {
        @apply text-444;
    }
    h1 {
        @apply text-4xl leading-[1.1] font-medium mt-0 mb-[0.5em] text-444;
    }
    h2 {
        @apply text-444 mt-0 mb-[0.5em] text-[30px] leading-[1.1] font-medium;
    }
    h3 {
        @apply text-2xl leading-normal text-444 mt-0 mb-[0.5em] font-medium;
    }
    h4 {
        @apply text-444 mb-4 font-medium text-base leading-[17px];
    }
    a {
        @apply hover:text-[#40a9ff] text-444 transition-colors visited:text-444 active:text-444 duration-300;
    }
    p {
        @apply text-sm text-444;
    }
    label {
        @apply my-1.5 text-sm text-444;
    }
    textarea {
        @apply text-[#555] bg-white border border-[#ccc] max-w-full min-h-[30px] px-6 text-sm input-transition shadow-[inset_0_1px_1px_#00000013] focus:outline-0 focus:border-[#618fb0];
    }
    input:focus {
        @apply outline-none;
    }
}

@layer base {
    * {
        @apply border-border;
    }
    body {
        /* @apply bg-background text-foreground; */
        font-feature-settings:
            'rlig' 1,
            'calt' 1;
    }
}

@layer components {
    .input-base {
        @apply text-[#555] bg-white border border-[#ccc] max-h-full min-h-[30px] px-1.5 text-sm input-transition focus:outline-none focus:border-[#618fb0] shadow-[inset_0_1px_1px_#00000013] rounded-[6px];
    }

    /* Buttons */
    .btn-success {
        @apply text-white bg-[#5cb85c] border border-[#4cae4c] cursor-pointer inline-block px-3 py-[6px] font-normal text-sm leading-[1.42857143] text-center align-middle no-underline select-none rounded [&:not(:disabled):hover]:bg-[#449d44] [&:not(:disabled):hover]:border-[#398439] focus:bg-[#449d44] focus:border-[#398439];
    }
    .btn-success:active,
    .btn-success.active,
    .open > .dropdown-toggle.btn-success {
        @apply bg-[#449d44] border-[#398439] shadow-inner;
    }
    .btn-success:focus {
        @apply outline-none ring-4 ring-[#5cb85c] ring-opacity-50;
    }
    .btn-success:active {
        @apply shadow-none;
    }
    .btn-success.disabled,
    .btn-success[disabled],
    fieldset[disabled] .btn-success {
        @apply cursor-not-allowed opacity-65 shadow-none;
    }
    .btn-default {
        @apply bg-white text-[#333] border border-[#ccc] text-sm flex-none whitespace-nowrap text-center cursor-pointer select-none touch-manipulation py-1.5 px-3 leading-[1.4] inline-block rounded;
    }
    .btn-danger {
        @apply bg-[#d9534f] text-white border border-[#d43f3a] text-sm flex-none whitespace-nowrap text-center cursor-pointer select-none touch-manipulation py-1.5 px-3 leading-[1.4] inline-block rounded;
    }

    .plus-btn {
        @apply bg-white/90 text-[#444] py-1.5 px-4 text-sm rounded-[6px] border border-white cursor-pointer hover:bg-white/70 disabled:opacity-50 disabled:cursor-not-allowed;
    }

    .btn-sm {
        @apply py-[2px] px-2.5 text-xs leading-[1.9] text-[#333] border border-[#ccc] rounded-[6px] bg-white hover:bg-[#e0e0e0] hover:border-[#adadad];
    }

    .delete-button,
    .edit-button,
    .lock-button,
    .add-button {
        @apply text-[#333] bg-white border border-[#ccc] whitespace-nowrap py-[2px] px-2.5 text-xs hover:border-[#adadad] hover:bg-[#e0e0e0];
    }

    .btn-primary {
        @apply text-white bg-[#428bca] border border-[#357ebd] hover:border-[#285e8e] hover:bg-[#3071a9] text-sm flex-none whitespace-nowrap text-center cursor-pointer select-none touch-manipulation py-1.5 px-3 leading-[1.4] inline-block rounded;
    }
    .btn-connect {
        @apply text-sm flex-none whitespace-nowrap text-center cursor-pointer select-none touch-manipulation py-1.5 px-3 leading-[1.4] inline-block rounded;
    }

    .btn-info {
        @apply py-1.5 px-3 rounded text-white bg-[#5bc0de] border border-[#46b8da] text-center whitespace-nowrap cursor-pointer select-none hover:border-[#269abc] hover:bg-[#31b0d5];
    }

    .coincraft-table {
        @apply shadow-[9px_7px_17px_-4px_#ccc] bg-[white] text-[13px] leading-5;
    }
    .coincraft-table-row {
        @apply border-b border-b-[#eee] flex;
    }
    .coincraft-table-row.header {
        @apply font-bold bg-gold text-[#555] border-b-0;
    }

    .coincraft-table-row.header .coincraft-table-cell {
        @apply border-r-0 first:border-l-0;
    }
    .coincraft-table-sub-section {
        @apply shadow-[inset_0px_8px_12px_-4px_rgba(0,0,0,0.1)] bg-[#f7f7f7] border-y border-y-[#ccc];
    }
    .coincraft-table-sub-section .coincraft-table-row {
        @apply bg-transparent border-b border-b-[#ddd] last:border-b-0;
    }
    .coincraft-table-cell {
        @apply p-[0.5em] border-r border-[#eee] text-[#777] cursor-default !text-[13px] leading-5;
        @apply first:border-l first:border-[#ddd];
        @apply last:border-r-0;
    }
    .coincraft-table-cell.coincraft-table-cell--duration {
        @apply !p-0 min-h-full;
    }

    .coincraft-table-cell.selected {
        @apply outline-4 outline outline-[#379] z-[5];
    }

    .coincraft-table-cell.text-link,
    .coincraft-table-cell .text-link {
        @apply cursor-pointer hover:text-[#444] underline;
    }

    .coincraft-table-cell--text,
    .coincraft-table-cell--number,
    .coincraft-table-cell--percent,
    .coincraft-table-cell--currency,
    .coincraft-table-cell--textarea,
    .coincraft-table-cell--date,
    .coincraft-table-cell--durationMinutes {
        @apply text-444 bg-black/[0.01] shadow-[inset_6px_6px_13px_-10px_rgba(0,0,0,0.22)];
        @apply hover:bg-transparent [&_.selected]:bg-transparent hover:shadow-[1px_1px_10px_rgba(0,0,0,0.2)] [&_.selected]:shadow-[1px_1px_10px_rgba(0,0,0,0.2)] focus-within:outline focus-within:outline-4 focus-within:outline-[#379] focus-within:z-[5];
    }

    .coincraft-table-cell--text,
    .coincraft-table-cell--number,
    .coincraft-table-cell--percent,
    .coincraft-table-cell--currency,
    .coincraft-table-cell--durationMinutes {
        @apply cursor-text flex items-center justify-between p-[0.25em];
    }

    .coincraft-table-cell--text,
    .coincraft-table-cell--number,
    .coincraft-table-cell--percent,
    .coincraft-table-cell--currency,
    .coincraft-table-cell--durationMinutes .coincraft-table-cell__symbol {
        @apply flex-grow-0 flex-shrink-0 basis-auto p-[0.25em];
    }

    .coincraft-table-cell--text__container,
    .coincraft-table-cell--number__container,
    .coincraft-table-cell--percent__container,
    .coincraft-table-cell--currency__container,
    .coincraft-table-cell--durationMinutes__container {
        @apply cursor-text p-0 flex items-center justify-between size-full;
    }
    .coincraft-table-cell__text,
    .coincraft-table-cell__number,
    .coincraft-table-cell__percent,
    .coincraft-table-cell__currency,
    .coincraft-table-cell--durationMinutes {
        @apply size-full border-0 shadow-none flex-1 min-w-0 bg-transparent focus:shadow-none;
    }
    .coincraft-table-cell--currency {
        @apply p-[0.25em] flex items-center justify-between;
    }

    .coincraft-table-cell--currency__container {
        @apply p-0 flex items-center justify-between size-full;
    }

    .coincraft-table-cell__symbol {
        @apply flex-shrink-0 flex-grow-0 basis-auto p-[0.25em];
    }

    .coincraft-table-cell__value {
        @apply text-right flex-1 p-[0.25em] focus:shadow-none;
    }
    .coincraft-table-cell--date {
        @apply p-0;
    }
    .rw-input {
        @apply !text-sm !text-[#555];
    }
    .coincraft-table-cell--duration {
        @apply !p-0;
    }
    .coincraft-table-cell--tax-dropdown,
    .coincraft-table-cell--dropdown,
    .coincraft-table-cell--bool,
    .coincraft-table-cell--project,
    .coincraft-table-cell--phase,
    .coincraft-table-cell--staff,
    .coincraft-table-cell--role,
    .coincraft-table-cell--contact,
    .coincraft-table-cell--costCentre,
    .coincraft-table-cell--staffOrRole,
    .coincraft-table-cell--status {
        @apply p-0 cursor-pointer hover:bg-[#eee];
    }

    .coincraft-table-cell--tax-dropdown,
    .coincraft-table-cell--dropdown,
    .coincraft-table-cell--bool,
    .coincraft-table-cell--project,
    .coincraft-table-cell--phase,
    .coincraft-table-cell--staff,
    .coincraft-table-cell--role,
    .coincraft-table-cell--contact,
    .coincraft-table-cell--costCentre,
    .coincraft-table-cell--staffOrRole,
    .coincraft-table-cell--status > button {
        @apply w-full flex items-center border-0 rounded-none bg-transparent;
    }
    .coincraft-table-cell--btn,
    .coincraft-table-cell--button {
        @apply flex justify-around items-center p-0 cursor-pointer hover:bg-[#eee];
    }

    .coincraft-table-cell--btn button,
    .coincraft-table-cell--button button,
    .coincraft-table-cell__button {
        @apply bg-transparent size-full border-0 cursor-pointer hover:bg-transparent;
    }
    .coincraft-table-cell--text {
        @apply flex items-center justify-start [&_*]:pl-[0.5em];
    }
    input[type='text']:focus {
        @apply !outline-offset-0;
    }

    /* MODAL */
    .scheduling-tool .modal-body,
    .info-panel .modal-body {
        @apply p-0;
    }

    .scheduling-tool .inner-modal .modal-body {
        @apply p-4;
    }

    /* ALERT */
    .alert {
        @apply border border-black rounded mb-5 p-4 !text-sm;
    }
    /* CALENDAR  */

    .calendar,
    .calendar > *:not(.not-calendar),
    .calendar > *:not(.not-calendar) * {
        @apply relative;
    }

    .calendar .calendar-item {
        @apply select-none absolute text-white rounded-[6px] border border-white/75 p-1.5 z-0 font-semibold text-[1em] overflow-hidden hover:z-[2];
    }
    .calendar .calendar-item:hover.right {
        @apply z-[5];
    }
    .calendar .calendar-item:hover.left {
        @apply z-[4];
    }
    .calendar .calendar-item > * {
        @apply text-[9px];
    }
    .calendar .calendar-item.allocation {
        @apply bg-[#666];
    }
    .calendar .calendar-item.allocation .time {
        @apply bg-[#666];
    }
    .calendar .calendar-item.right {
        @apply z-[3];
    }
    .calendar .calendar-item.left {
        @apply z-[2];
    }
    .calendar .calendar-item::after {
        @apply content-[""] block absolute -top-px -bottom-px -left-px -right-px rounded-[6px] border border-white z-[-1];
    }
    .calendar .calendar-item .time {
        @apply text-[11px] absolute bottom-0 left-0 py-[0.25rem] px-[0.75rem] font-bold w-full;
    }
    .calendar .calendar-item.selected {
        @apply bg-[#00c1ff];
    }
    .calendar .calendar-item.selected .time {
        @apply bg-[#00c1ff];
    }
    .calendar .calendar-item.selected.allocation {
        @apply bg-[#638bab];
    }
    .calendar .calendar-item.selected.allocation .time {
        @apply bg-[#638bab];
    }

    /* TIMESHEET WIDGET */

    .timesheet-widget__row {
        @apply text-center leading-normal mb-[0.8em] min-w-[36em] xl:text-[1.5em] lg:text-[1.4em] md:text-[1.3em] text-[1.25em];
    }

    .timesheet-widget__row__inner {
        @apply inline-block w-[92%] text-left;
    }

    .timesheet-widget__task-row {
        @apply flex items-center;
    }

    .time-entry {
        @apply text-[1em] relative;
    }

    .timesheet-widget__phase-title {
        @apply inline-block flex items-center;
    }

    .timesheet-widget__left-section {
        @apply inline-block lg:w-[30%] md:w-1/4 w-1/5;
    }

    .timesheet-widget__middle-section {
        @apply inline-block min-[1381px]:w-[70%] w-3/4;
    }

    .timesheet-widget__right-section {
        @apply inline-block w-[5%];
    }

    .time-entry__note {
        @apply text-[0.75em] p-1;
    }

    .time-entry--daily,
    .time-entry--daily__note {
        @apply whitespace-nowrap flex items-center;
    }

    .time-entry:hover .time-entry__input,
    .time-entry--daily:hover .time-entry__input,
    .time-entry__note-input {
        @apply outline outline-1 outline-[#ddd];
    }

    .time-entry:hover .time-entry__note {
        @apply visible;
    }

    .time-entry__input {
        @apply !min-h-5 !max-h-5 !text-sm pr-1 box-border border-none my-1 mx-[2px] w-[3.4em] text-right shadow-none leading-normal outline outline-1 outline-[#ccc] focus:!outline-[#ccc];
    }

    .time-entry.locked {
        @apply cursor-not-allowed;
        & span {
            @apply text-[0.8em] mr-[0.5em];
        }
    }

    .time-entry__note,
    .timesheet-widget__task-delete,
    .timesheet-widget__task-edit,
    .timesheet-widget__task-lock {
        @apply border border-[#ccc] bg-white text-[#777] cursor-pointer rounded;
    }

    .time-entry__note:hover,
    .timesheet-widget__task-delete:hover,
    .timesheet-widget__task-edit:hover,
    .timesheet-widget__task-lock:hover {
        @apply !bg-[#eee] text-[#333] border-[#aaa];
    }

    .time-entry__note i,
    .timesheet-widget__task-delete i,
    .timesheet-widget__task-edit i,
    .timesheet-widget__task-lock i {
        @apply m-[0.2em];
    }

    .timesheet-widget__task-edit,
    .timesheet-widget__task-lock {
        @apply mr-[1em];
    }

    .time-entry__note {
        @apply text-[0.6em];
    }

    .timesheet-widget__task-delete,
    .timesheet-widget__task-edit,
    .timesheet-widget__task-lock {
        @apply mr-[0.4em] p-[0.1em];
    }

    .timesheet-widget__task-delete i,
    .timesheet-widget__task-edit i,
    .timesheet-widget__task-lock i {
        @apply m-0;
    }

    .timesheet-widget__task-title {
        @apply inline-flex text-[0.7em] italic items-center;
    }

    .timesheet-widget__task-row {
        @apply border-t border-transparent border-b border-[#ddd] p-px;
    }

    .timesheet-widget__task-row:hover {
        @apply border-y border-[#aaa];
    }

    .timesheet-widget__task-row:hover .timesheet-widget__task-delete {
        @apply visible;
    }

    .timesheet-widget__progress-bar {
        @apply text-center p-0 text-[0.75em];
    }

    .time-entry.weekend {
        @apply opacity-60;
    }

    .time-entry.weekend:hover {
        @apply opacity-100;
    }

    .time-entry__note-input {
        @apply border-none my-[2px] mx-[1em]  flex-grow w-full overflow-hidden resize-none text-[1em] min-h-0 shadow-none p-1;
    }

    .time-entry__note-input:focus {
        @apply outline outline-1 outline-[#ccc] my-[2px] mx-[0.9em];
    }

    /* padding is -2px on focus in webkit */
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
        .time-entry__note-input:focus {
            @apply outline outline-1 outline-[#ccc] !p-1.5 my-0 mx-[0.83em];
        }
    }

    .time-entry--daily__note {
        @apply text-[0.7em] text-[#888] flex-grow relative;
        i {
            @apply flex-grow-0 w-[1em];
        }
    }

    .time-entry__input {
        @apply flex-grow-0;
    }

    .time-entry--daily {
        @apply w-full;
    }

    .time-entry__note-input::-webkit-input-placeholder {
        @apply italic text-[#aaa];
    }

    .time-entry__note-input:-moz-placeholder {
        @apply italic text-[#aaa] opacity-100;
    }

    .time-entry__note-input::-moz-placeholder {
        @apply italic text-[#aaa] opacity-100;
    }

    .time-entry__note-input:-ms-input-placeholder {
        @apply italic text-[#aaa];
    }

    .time-entry__date-hover {
        @apply absolute bg-white z-[2] text-[0.75em] w-[4em] text-center pt-px px-1 pb-1 mt-[0.5em] hidden text-[#666] opacity-0 italic border-t-2 border-[#666];
    }

    .time-entry:hover .time-entry__date-hover {
        @apply visible opacity-100;
    }

    .time-entry__time-options-hover {
        @apply absolute bg-white z-[2] text-center pt-px px-1 pb-1 mt-[1em] ml-[-2em] hidden text-[#666] opacity-0 border border-[#aaa] italic shadow-[1px_1px_4px_rgba(0,0,0,0.2)];
    }

    .time-entry__time-options:hover .time-entry__time-options-hover {
        @apply block opacity-100 text-[#222] whitespace-nowrap;
    }

    .timesheet-widget__tasks-block:hover .timesheet-widget__task-row {
        @apply opacity-70 transition-opacity duration-200;
    }

    .timesheet-widget__task-row:hover {
        @apply !opacity-100 text-[#222] transition-opacity duration-200;
    }

    .timesheet-widget__task-row:hover input,
    .timesheet-widget__task-row:hover textarea {
        @apply text-[#222];
    }

    .timesheet-widget__task-row:hover + .timesheet-widget__task-row {
        @apply !opacity-50 transition-opacity duration-200;
    }

    .time-entry__input--invalid,
    .time-entry__input--invalid:focus {
        @apply outline outline-1 !outline-[#d9534f]  !text-[#d9534f];
    }

    .timesheets__navigation {
        @apply flex items-center;
    }

    .timesheets__day-nav-button {
        @apply border border-[#ccc] m-px;
    }

    .timesheets__day-nav-button:hover {
        @apply border-2 border-[#ffc700] !bg-white m-0;
    }

    .timesheets__day-nav-button:focus {
        @apply outline-none;
    }

    .timesheets__day-nav-button.selected,
    .timesheets__day-nav-button.selected:hover {
        @apply border-2 border-[#ffc700] !bg-white m-0;
    }

    .coincraft-table-cell .fa-caret-left {
        margin-right: 10px;
    }

    /* INVOICE  */

    .invoice-page {
        @apply text-[0.75rem] w-[21cm] h-[29.7cm] box-border bg-white overflow-hidden m-[1em] p-[1cm] inline-flex flex-col text-left shadow-[0.25em_0.25em_1em_rgba(75,75,75,0.2)] whitespace-pre-line;
    }

    /* New rule to make font size smaller for all children */
    .invoice-page * {
        font-size: 0.75rem;
    }

    .invoice-page .inv-bold {
        @apply font-semibold text-444;
    }

    .invoice-page > .header {
        @apply uppercase p-[1em] mb-[2em] grid;
        grid-template-areas:
            '. logo logo'
            'project logo logo'
            'project logo logo'
            'client logo logo'
            'client issue .'
            'number due amount';
    }

    .invoice-page > .header .logo {
        @apply m-0 self-start justify-self-end;
        grid-area: logo;
    }

    .invoice-page > .header .project-info {
        @apply mr-[2em] self-end mb-[1em] leading-[1.5em];
        grid-area: project;
    }

    .invoice-page > .header .client-info {
        @apply mr-[2em] self-end mb-[0.75em] leading-[1.5em];
        grid-area: client;
    }

    .invoice-page > .header .issue-date {
        @apply mr-[2em] self-end mb-[0.75em] leading-[1.5em];
        grid-area: issue;
    }

    .invoice-page > .header .inv-number {
        grid-area: number;
    }

    .invoice-page > .header .due-date {
        grid-area: due;
    }

    .invoice-page > .header .amount-due {
        grid-area: amount;
    }

    .invoice-page > .header .inv-highlight {
        @apply uppercase py-[0.75em] px-0 border-y border-444;
    }

    .invoice-page > .header .inv-highlight:not(:last-child) {
        @apply mr-[2em];
    }

    .invoice-page > .header .inv-highlight .label {
        @apply text-[1.2em]  font-semibold text-444;
    }

    .invoice-page > .header .inv-highlight .value {
        @apply text-[2em];
    }

    @media print {
        .invoice-page {
            @apply m-0 shadow-none flex w-screen h-screen;
        }
    }

    .invoice-page .invoice-table {
        @apply grid;
    }

    .invoice-page .invoice-table .cell {
        @apply p-[0.75em] border-b border-444;
    }

    .invoice-page .invoice-table .cell.last {
        @apply border-b-0;
    }

    .invoice-page .invoice-table .cell.header {
        @apply bg-444 text-white uppercase text-[1.1em];
    }

    .invoice-page .invoice-table .cell.right {
        @apply text-right;
    }

    .invoice-page .invoice-table .cell.total {
        @apply text-[1.1em];
    }

    .invoice-page .invoice-table .cell.total.label {
        @apply uppercase pr-[3em];
        grid-column: 2 / 4;
    }

    .invoice-page .invoice-table .cell.total.amount {
        grid-column: 4 / 5;
    }

    .invoice-page .invoice-table .cell.total.last {
        @apply font-bold border-b-0;
    }

    .invoice-page .invoice-table .cell.add-item {
        @apply border-b-0;
    }

    .invoice-page .invoice-table.invoice-table-totals {
        @apply border-t-2 border-444;
    }

    .invoice-page .ordering {
        @apply absolute left-[-0.7em] top-[0.8em] text-[1.2em];
    }

    .invoice-page .ordering .arrow {
        @apply m-0 p-0 absolute cursor-pointer opacity-25;
    }

    .invoice-page .ordering .arrow:hover {
        @apply opacity-80;
    }

    .invoice-page .ordering .arrow.up {
        @apply top-[-0.35em];
    }

    .invoice-page .ordering .arrow.down {
        @apply top-[0.35em];
    }
}

@layer utilities {
    .input-transition {
        @apply transition-all;
        transition:
            border 0.2s linear,
            box-shadow 0.2s linear;
    }
}

.alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.alert-info {
    background-color: #e6f7ff;
    border-color: #91d5ff;
}

.time-entry__date-hover,
.time-entry__time-options-hover {
    transition:
        opacity 0.2s ease-in-out,
        visibility 0.2s ease-in-out;
    -webkit-transition:
        opacity 0.2s ease-in-out,
        visibility 0.2s ease-in-out;
}

.timesheet-widget__task-row--highlighted {
    animation: task-row-highlight 3s;
}
@keyframes task-row-highlight {
    0% {
        background: #ffc700;
    }
    100% {
        background: none;
    }
}

.rw-input {
    font-size: 13px !important;
}

.coincraft-table-cell--textarea {
    cursor: text;
    padding: 0;
}
.coincraft-table-cell--textarea textarea {
    height: 100%;
    border: none;
    box-shadow: none;
    width: 100%;
    padding: 0.5em;
    background-color: transparent;
    resize: none;
}

.coincraft-table-cell--tax-dropdown,
.coincraft-table-cell--dropdown,
.coincraft-table-cell--bool,
.coincraft-table-cell--project,
.coincraft-table-cell--phase,
.coincraft-table-cell--staff,
.coincraft-table-cell--role,
.coincraft-table-cell--contact,
.coincraft-table-cell--costCentre,
.coincraft-table-cell--staffOrRole,
.coincraft-table-cell--status,
.coincraft-table-cell--staffOrRole {
    padding: 0;
    cursor: pointer;
    &:hover {
        background-color: #eee;
    }
}

.coincraft-table-cell__tax-dropdown,
.coincraft-table-cell__dropdown,
.coincraft-table-cell__bool,
.coincraft-table-cell__project,
.coincraft-table-cell__phase,
.coincraft-table-cell__staff,
.coincraft-table-cell__role,
.coincraft-table-cell__contact,
.coincraft-table-cell__costCentre,
.coincraft-table-cell__staffOrRole,
.coincraft-table-cell__status,
.coincraft-table-cell__staffOrRole {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 0;
    background-color: transparent;
}

.dont-print {
    @media print {
        display: none !important;
    }
}

@media print {
    * {
        color: black;
    }
    .dont-print,
    .buttons,
    .search-field,
    .report-selector {
        display: none !important;
    }
    body {
        background-color: transparent;
        background-image: none !important;
        color: black;
    }
    .dashboard-layout .dashboard-layout__column.dashboard-layout__main-column {
        width: 100%;
    }
    a[href]:after {
        content: '' !important;
    }
    .table-widget__header-row {
        border-bottom: 1px solid #444;
    }
    .coincraft-page-layout {
        display: block !important;
        height: auto;
    }

    .coincraft-page-layout__header {
        display: none !important;
        height: auto;
        width: auto;
    }
    .coincraft-page-layout__content {
        display: block !important;
        height: auto;
    }

    .coincraft-page-layout__content .coincraft-page-layout__page {
        display: block !important;
        height: auto;
        position: absolute;
        overflow: hidden;
    }

    .coincraft-page-layout__content
        .coincraft-page-layout__page
        .coincraft-page-layout__page-header {
        height: auto;
        display: block !important;
    }

    .coincraft-page-layout__content
        .coincraft-page-layout__page
        .coincraft-page-layout__page-body {
        box-shadow: none;
        height: auto;
        display: block !important;
    }

    .coincraft-page-layout__content
        .coincraft-page-layout__page
        .coincraft-page-layout__page-body
        > * {
        display: block !important;
    }

    .export-button-dropdown {
        display: none !important;
    }
    .coincraft-page-layout__page-header {
        background-color: white;
        color: #333;
        min-height: 0;
        padding: 0;
        margin: 1em;
    }
    .coincraft-page-layout__content
        .coincraft-page-layout__page
        .coincraft-page-layout__page-body {
        box-shadow: none;
        padding: 2em 0 0 0;
    }

    .coincraft-page-layout__page-body {
        box-shadow: none;
        padding: 2em 0 0 0;
        background-color: #fff;
    }
    .invoice-page {
        margin: 0;
        box-shadow: none;
        display: flex;
        width: 100vw;
        height: 100vh;
    }

    .coincraft-page-layout__page-body.grey {
        background-color: white;
    }

    .coincraft-page-layout__page-body {
        padding: 0 !important;
    }
    .page-layout__tabs {
        display: none !important;
    }
    .rw-input {
        font-size: 10px !important;
    }
    .plus-btn {
        display: none !important;
    }
}

.coincraft-page-layout {
    @apply flex flex-col absolute w-full h-full;
}

@media print {
    .coincraft-page-layout {
        display: block !important;
        height: auto;
    }
}

/* header */
.coincraft-page-layout__header {
    @apply relative w-full;
}

@media print {
    .coincraft-page-layout__header {
        display: none !important;
        height: auto;
        width: auto;
    }
}

.coincraft-page-layout__page {
    @apply relative h-full w-full overflow-auto flex-1 grid;
}

@media print {
    .coincraft-page-layout__page {
        display: block !important;
        height: auto;
        position: absolute;
    }
}

/* Conditional Grid Layouts */
.grid-all {
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr auto;
    grid-template-areas: 'header sidebar' 'body sidebar';
}

.grid-body-only {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    grid-template-areas: 'body';
}

.grid-header-body {
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
    grid-template-areas: 'header' 'body';
}

.grid-body-sidebar {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr auto;
    grid-template-areas: 'body sidebar';
}

/* page header */
.coincraft-page-layout__page-header {
    @apply relative w-full flex-none overflow-visible;
    grid-area: header;
}

@media print {
    .coincraft-page-layout__page-header {
        height: auto;
        display: block !important;
    }
}

/* page body */
.coincraft-page-layout__page-body {
    @apply relative w-full h-full flex-1 shadow-[0px_-2px_10px_0px_rgba(0,0,0,0.24)] overflow-auto;
    grid-area: body;
}

@media print {
    .coincraft-page-layout__page-body {
        box-shadow: none;
        height: auto;
        display: block !important;
    }

    .coincraft-page-layout__page-body > * {
        display: block !important;
    }
}

/* sidebar */
.coincraft-page-layout__sidebar {
    @apply relative h-full flex-none flex max-w-6xl border-l border-gray-300;
    grid-area: sidebar;
}

.coincraft-page-layout__sidebar-toggle {
    @apply flex-none w-8 bg-gray-500;
}

.coincraft-page-layout__sidebar-content {
    @apply pb-28 flex-1 overflow-y-auto overflow-x-hidden;
}

/* Apply conditional grid layout based on available elements */
.coincraft-page-layout__page:has(.coincraft-page-layout__page-header):has(
        .coincraft-page-layout__sidebar
    ) {
    @apply grid-all;
}

.coincraft-page-layout__page:has(.coincraft-page-layout__page-header):not(
        :has(.coincraft-page-layout__sidebar)
    ) {
    @apply grid-header-body;
}

.coincraft-page-layout__page:not(:has(.coincraft-page-layout__page-header)):has(
        .coincraft-page-layout__sidebar
    ) {
    @apply grid-body-sidebar;
}

.coincraft-page-layout__page:not(:has(.coincraft-page-layout__page-header)):not(
        :has(.coincraft-page-layout__sidebar)
    ) {
    @apply grid-body-only;
}

.calendar,
.calendar > *:not(.not-calendar),
.calendar > *:not(.not-calendar) * {
    position: relative;
}

.calendar .calendar-item {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    position: absolute;
    background: blue;
    color: white;
    padding: 0.5em;
    border-radius: 0.5em;
    border: solid 1px hsla(0deg, 0%, 100%, 0.75);
    z-index: 0;
    font-weight: 600;
    font-size: 0.85rem;
    overflow: hidden;
}

.calendar .calendar-item.allocation {
    background: #666;
}

.calendar .calendar-item > * {
    font-size: 0.75rem;
}

.calendar .calendar-item.right {
    z-index: 3;
}

.calendar .calendar-item.left {
    z-index: 2;
}

.calendar .calendar-item:hover {
    z-index: 2;
}

.calendar .calendar-item:hover.right {
    z-index: 5;
}

.calendar .calendar-item:hover.left {
    z-index: 4;
}

.calendar .calendar-item:after {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    border-radius: 0.5rem;
    border: solid 1px white;
    z-index: -1;
}

.calendar .calendar-item .time {
    font-size: 0.85rem;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0.25rem 0.75rem;
    font-weight: bold;
    background: blue;
    width: 100%;
}

.calendar .calendar-item.allocation .time {
    background: #666;
}

.calendar .calendar-item.selected {
    background: #00c1ff;
}

.calendar .calendar-item.selected .time {
    background: #00c1ff;
}

.calendar .calendar-item.selected.allocation {
    background: #638bab;
}

.calendar .calendar-item.selected.allocation .time {
    background: #638bab;
}

.calendar .vertical-drag {
    cursor: ns-resize;
    z-index: 10;
}

input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'],
.uneditable-input {
    min-height: 30px;
}

[data-radix-popper-content-wrapper] {
    margin-top: 0 !important;
}
